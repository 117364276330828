import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import useCollapseDrawer from '@hooks/useCollapseDrawer';

import LockBanner from '@components/receipt-lock/LockBanner';
import MigrationFullscreenLock from '@components/migration-lock/MigrationFullscreenLock';
import Header from './Header';
import Navbar from './Navbar';
import { MainStyle } from './styles';

// ----------------------------------------------------------------------
export default function AppLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <LockBanner />
      <MigrationFullscreenLock />

      <Header isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
      <Navbar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
