import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------

export type RecurringDonationListItem = {
  id: string;
  name: string;
  frequency: string;
  amount: string;
  finalOccurrence: string | null;
  nextOccurrence?: string | null;
  isExpired: boolean;
};

// ----------------------------------------------------------------------
export type RecurringDonationListHeader =
  | 'name'
  | 'frequency'
  | 'nextOccurrence'
  | 'finalOccurrence'
  | 'amount';

export const RECURRING_DONATION_LIST_HEADERS: TColumn<RecurringDonationListHeader>[] = [
  {
    id: 'name',
    type: 'string',
    label: 'Donor',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount ($)',
    align: 'right',
    visible: true,
    filterable: true,
    searchable: false,
    pdfWidth: 10,
  },
  {
    id: 'frequency',
    type: 'string',
    label: 'Frequency',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'nextOccurrence',
    type: 'string',
    label: 'Next Occurrence',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'finalOccurrence',
    type: 'string',
    label: 'Final Occurrence',
    align: 'left',
    visible: true,
    filterable: true,
    searchable: true,
    pdfWidth: 15,
  },
];
