import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Stack, Typography } from '@mui/material';

import PATHS, { getPath } from '@routes/paths';
import Il500 from '@assets/il_500';

// ----------------------------------------------------------------------
export default function Page500() {
  const goHomeLink = useMemo(() => getPath(PATHS.root, {}), []);

  return (
    <>
      <Helmet>
        <title> Internal Server</title>
      </Helmet>

      <Stack alignItems="center" justifyContent="center" height="100%">
        <Typography variant="h3" paragraph>
          Internal Error
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          There was an error, please try again later.
          <br />
          If the issue persists, please contact Support.
        </Typography>

        <Il500 sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

        <Stack direction="row" spacing={4}>
          <Button href={goHomeLink} size="large" variant="outlined">
            Go Home
          </Button>

          <Button onClick={() => window.location.reload()} size="large" variant="contained">
            Reload page
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
