import { useMemo, useState } from 'react';
import { Card, Stack, Typography, Divider, Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { isArray, sortBy } from 'lodash';

import { Category, Donation, Donor, Receipt } from '@shared/types';
import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';

import { RHFAutocomplete, RHFCheckbox, RHFTextField } from '@components/hook-form';
import { renderCategoryOption } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import CategoryDialog from '@pages/donations/dialogs/Settings/DonationCategories/Dialog';

// ----------------------------------------------------------------------
type Props = {
  readonly donor?: Donor.Donor | null;
  readonly donation?: Donation.Donation;
  readonly receipt?: Receipt.Receipt;
  readonly watchedNonReceiptable?: boolean;
  readonly formDisabled: boolean;
};
// ----------------------------------------------------------------------
export default function DonationAdditionalForm({
  donor,
  donation,
  receipt,
  watchedNonReceiptable,
  formDisabled,
}: Props) {
  const { fReceiptNumber, fDate } = useFormat();
  const { org } = useOrg();
  const { categories } = useDonation();
  const { setValue, clearErrors } = useFormContext();
  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);

  const handleCategoryCreate = (newCategory?: Category.Category) => {
    if (newCategory) {
      // delay is required due to Form re-render
      setTimeout(() => {
        setValue('category', newCategory);
        clearErrors('category');
      }, 250);
    }
    setCreateCategoryOpen(false);
  };

  const lastCorrection = useMemo(
    () => donation?.corrections?.filter((c) => c.receiptId === receipt?.id).reverse()[0],
    [donation, receipt?.id]
  );

  return (
    <Stack spacing={2}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <CategoryDialog open={createCategoryOpen} onClose={handleCategoryCreate} />
          <RHFAutocomplete
            name="category"
            label="Category"
            required
            fullWidth
            freeSolo={false}
            disabled={formDisabled}
            options={[
              ...sortBy(categories, (c) => c.name.toUpperCase()),
              { id: 'create-new', name: '', description: '' } as Category.Category,
            ]}
            // getOptionLabel={(c) => c.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderOption={renderCategoryOption}
            beforeOnChange={(newValue, commitChange) => {
              if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
                setCreateCategoryOpen(true);
              } else {
                commitChange();
              }
            }}
          />

          <Tooltip
            title={
              formDisabled
                ? "You'll have the option to include this Description when issuing Receipts or creating Reports"
                : ''
            }
            placement="top-end"
          >
            <Stack width="100%">
              <RHFTextField
                name="description"
                label="Description"
                disabled={formDisabled}
                inputProps={{ maxLength: 60 }}
              />
            </Stack>
          </Tooltip>

          <Divider sx={{ my: 1 }} />

          {!receipt && !formDisabled && (
            <Tooltip
              title={
                donor?.nonReceiptable
                  ? 'This donor is non-receiptable, so there is no need to mark its donations as non-receiptable'
                  : ''
              }
            >
              <Stack>
                <RHFCheckbox
                  name="nonReceiptable"
                  label="Non-Receiptable"
                  disabled={donor?.nonReceiptable || formDisabled}
                />
              </Stack>
            </Tooltip>
          )}
          {/* checkbox dirties the form if its disabled... */}
          {formDisabled && (
            <Typography variant="body2" color="text.secondary">
              Non-Receiptable: {watchedNonReceiptable ? 'Yes' : 'No'}
            </Typography>
          )}

          {receipt?.number && (
            <Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2" color="text.secondary">
                  Receipt:{' '}
                </Typography>

                <Link
                  variant="body2"
                  component={RouterLink}
                  to={`${getPath(PATHS.org.receipts.view, {
                    orgId: org?.id,
                    receiptId: receipt.id,
                  })}`}
                  sx={{ textDecoration: receipt.invalidated ? 'line-through' : 'none' }}
                >
                  {fReceiptNumber(receipt.number, receipt.year)}
                </Link>
              </Stack>

              {lastCorrection && (
                <Stack direction="row" alignItems="center" color="text.secondary" spacing={1}>
                  <Typography variant="body2">Corrected on: </Typography>
                  <Typography variant="subtitle2">{fDate(lastCorrection.date)}</Typography>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Card>

      <Card sx={{ p: 2 }}>
        <RHFTextField
          name="notes"
          label="Notes (internal)"
          disabled={formDisabled}
          multiline
          rows={4}
          inputProps={{ maxLength: 500 }}
        />
      </Card>
    </Stack>
  );
}
