import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Donor, Country, Tag } from '@shared/types';
import { AutocompleteItem } from '@typedefs/app';
import { isString } from 'lodash';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
// we decided to not validate phone #s, allowing anything, so don't need the following
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// ----------------------------------------------------------------------
type TOriginalNumbers = { [year: number]: number | string };
type FMemberNbrInUse = (year: number, number: number) => boolean;
const DonorSchema = (originalNumbers: TOriginalNumbers, memberNumberInUse: FMemberNbrInUse) =>
  Yup.object().shape({
    firstName: Yup.string().when(['type'], ([type], schema) =>
      type === Donor.DonorType.individual ? schema.required('First name is required') : schema
    ),
    middleName: Yup.string(),
    lastName: Yup.string().when(['type'], ([type], schema) =>
      type === Donor.DonorType.individual ? schema.required('Last name is required') : schema
    ),
    email: Yup.string().matches(EmailValidOrEmpty, 'Email must be a valid email address'),
    phone: Yup.string(),
    address: Yup.object().shape({
      address1: Yup.string(),
      address2: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      postalCode: Yup.string(),
      country: Yup.object().required('Country is required'),
    }),
    type: Yup.string().required('[internal] donor type required'),

    organization: Yup.string().when(['type'], ([type], schema) =>
      type === Donor.DonorType.business ? schema.required('Business name is required') : schema
    ),
    tags: Yup.array().default([]),
    nonReceiptable: Yup.bool().default(false),
    notes: Yup.string(),
    customFields: Yup.array().default([]),
    memberNumbers: Yup.object().shape({
      [new Date().getFullYear() - 1]: Yup.number()
        .integer('Only integers are accepted.')
        .typeError('Only integers are accepted.')
        .min(1, 'Min value is 1')
        .max(9999999999, 'Max value is 9999999999')
        .nullable()
        .transform((value: string, originalValue: string) =>
          isString(originalValue) && originalValue.trim() === '' ? null : value
        )
        .test('is-in-use', 'Already in use', function (val) {
          if (!val) return true;
          const year = Number((this.options as any).key);
          if (originalNumbers[year] === val) return true;
          return !memberNumberInUse(year, val);
        }),
      [new Date().getFullYear()]: Yup.number()
        .integer('Only integers are accepted.')
        .typeError('Only integers are accepted.')
        .min(1, 'Min value is 1')
        .max(9999999999, 'Max value is 9999999999')
        .nullable()
        .transform((value: string, originalValue: string) =>
          isString(originalValue) && originalValue.trim() === '' ? null : value
        )
        .test('is-in-use', 'Already in use', function (val) {
          if (!val) return true;
          const year = Number((this.options as any).key);
          if (originalNumbers[year] === val) return true;
          return !memberNumberInUse(year, val);
        }),
      [new Date().getFullYear() + 1]: Yup.number()
        .integer('Only integers are accepted.')
        .typeError('Only integers are accepted.')
        .min(1, 'Min value is 1')
        .max(9999999999, 'Max value is 9999999999')
        .nullable()
        .transform((value: string, originalValue: string) =>
          isString(originalValue) && originalValue.trim() === '' ? null : value
        )
        .test('is-in-use', 'Already in use', function (val) {
          if (!val) return true;
          const year = Number((this.options as any).key);
          if (originalNumbers[year] === val) return true;
          return !memberNumberInUse(year, val);
        }),
    }),
  });

export const donorSchemaResolver = (on: TOriginalNumbers, fn: FMemberNbrInUse) =>
  yupResolver(DonorSchema(on, fn));
export type DonorSchemaForm = {
  firstName: string;
  middleName: string;
  lastName: string;

  email?: string;
  phone?: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: AutocompleteItem<Country>;
  };
  type: Donor.DonorType;
  organization?: string;

  tags: Tag.Tag[];
  customFields?: string[];
  nonReceiptable: boolean;
  memberNumbers: { [year: number]: number | string };
  notes: string;

  afterSubmit?: string;
};
