import { analytics } from '@/firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

// ----------------------------------------------------------------------
export function setUserId(userId?: string) {
  console.log('userId', userId);
  firebaseAnalytics.setUserId(analytics, userId || null);
}

export function setOrgId(orgId?: string) {
  console.log('orgId', orgId);
  firebaseAnalytics.setDefaultEventParameters({ org_id: orgId || null });
  firebaseAnalytics.setUserProperties(analytics, { org_id: orgId || null });
}

// ----------------------------------------------------------------------
export * as firestore from './firestore';
export * as user from './user';
export * as org from './org';
export * as donation from './donation';
export * as view from './view';
