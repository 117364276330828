import { useEffect, useMemo } from 'react';
import { Card, Stack, Typography, Grid, Button, Link, Box, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Outlet, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import useFormat from '@hooks/useFormat';
import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useAuth from '@hooks/useAuth';
import * as analytics from '@fire/analytics';

import Dialog from '@components/Dialog';
import ReceiptHistory from './History';
import ReceiptDonations from './Donations';

// ----------------------------------------------------------------------
type Props = { context: 'receipts' | 'reissue-batch' };
// ----------------------------------------------------------------------
export default function ReceiptView({ context }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const { fDate, fReceiptNumber, fFullName } = useFormat();
  const { user } = useAuth();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const { getReceiptById, getDonorById } = useDonation();
  const canEdit = hasAccess([Role.editor]);

  // --------------- effects ---------------
  const receipt = useMemo(() => getReceiptById(params.receiptId), [getReceiptById, params]);
  const receiptDonor = useMemo(() => getDonorById(receipt?.donorId), [getDonorById, receipt]);

  const isLocked = org?.lock.isLocked && user?.id !== org.lock.lockedBy;
  const lockTooltip = useMemo(() => {
    if (isLocked) {
      const user = (org.lock.lockedBy && org.users[org.lock.lockedBy]?.displayName) || '';
      return `Your ability to issue receipts is temporary paused while ${user}'s issuance is in progress.`;
    }
    return '';
  }, [isLocked, org?.lock, org?.users]);

  // --------------- actions ---------------
  const handleClose = () => {
    if (context === 'reissue-batch') {
      navigate(PATHS.org.receipts.reissueBatch.root);
    } else {
      navigate(PATHS.org.receipts.root);
    }
  };

  // --------------- ui ---------------
  useEffect(() => receipt && analytics.donation.receiptView(), [receipt]);
  if (!receipt || !receiptDonor) return null;

  return (
    <Dialog
      title={`Receipt ${fReceiptNumber(receipt.number, receipt.year)}`}
      onClose={handleClose}
      maxWidth="lg"
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          {canEdit && !receipt.replacedById ? (
            <Tooltip title={lockTooltip}>
              <span>
                <Button
                  component={RouterLink}
                  to={getPath(PATHS.org.receipts.reissue.root, {
                    orgId: org!.id,
                    receiptId: receipt.id,
                  })}
                  color="error"
                >
                  Reissue
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Box />
          )}
          <LoadingButton onClick={handleClose}>Close</LoadingButton>
        </Stack>
      }
    >
      <Outlet />

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Card sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Date issued:</Typography>
                  <Typography variant="subtitle2">{fDate(receipt.date)}</Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography variant="body2">Donor:</Typography>
                  <Link
                    variant="subtitle2"
                    component={RouterLink}
                    to={`${getPath(PATHS.org.donors.donor.edit, {
                      orgId: org?.id,
                      donorId: receiptDonor.id,
                    })}`}
                  >
                    {fFullName(receiptDonor)}
                  </Link>
                </Stack>
              </Stack>

              <Stack>
                <Typography variant="subtitle2" color="text.secondary" mb={1}>
                  Action history
                </Typography>
                <ReceiptHistory receipt={receipt} />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={7}>
          <ReceiptDonations receipt={receipt} />
        </Grid>
      </Grid>
    </Dialog>
  );
}
