import { Card, Typography, Box, CardProps, Stack } from '@mui/material';
import useFormat from '@hooks/useFormat';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
interface Props extends CardProps {
  title: string;
  total: number | string;
  icon: string;
  currency?: boolean;
  suffix?: JSX.Element;
}

// ----------------------------------------------------------------------
export default function DonorWidget({ title, total, icon, currency, suffix, sx, ...other }: Props) {
  const { fShortenNumber, fCurrency } = useFormat();
  const value = currency ? fCurrency(total, false) : fShortenNumber(total);

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        py: 1,
        // pl: 3,
        ...sx,
      }}
      {...other}
    >
      <div>
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography variant="h4">{value}</Typography>
          {suffix}
        </Stack>

        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </div>

      <Box
        sx={{
          width: 60,
          height: 60,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Iconify icon={icon} width={34} height={34} />
      </Box>
    </Card>
  );
}
