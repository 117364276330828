import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { forEach, uniq } from 'lodash';

import Dialog from '@components/Dialog';
import Scrollbar from '@components/Scrollbar';
import Iconify from '@components/Iconify';
import { AvailableSortOrder, SortOrder } from '@pages/reports/reportSorting';

type SortDialogItem = {
  label: string;
  order: 'Ascending' | 'Descending';
};

export type SortProps = {
  availableOrders: AvailableSortOrder[];
  currentOrders: SortOrder[];
  setOrders: Dispatch<SetStateAction<SortOrder[]>>;
  sortButtonText?: string;
};
// ----------------------------------------------------------------------
export default function SortDialog({
  availableOrders,
  currentOrders,
  setOrders,
  sortButtonText = 'Sort',
}: SortProps) {
  const [openDialog, setOpenDialog] = useState<HTMLElement | null>(null);
  const [activeOrders, setActiveOrders] = useState<SortDialogItem[]>(
    currentOrders.map((o) => {
      const { label } = availableOrders[o.availablesIndex];
      return {
        label: label,
        order: o.descending ? 'Descending' : 'Ascending',
      };
    })
  );
  const [errors, setErrors] = useState('');

  const handleOpenSort = (e: React.MouseEvent<HTMLElement>) => setOpenDialog(e.currentTarget);
  const handleCloseSort = () => setOpenDialog(null);

  const partialUpdateOrders = (i: number, field: 'label' | 'order', value: string) => {
    let newOrders = [...activeOrders];
    if (field === 'label') newOrders[i].label = value;
    else if (field === 'order' && (value === 'Ascending' || value === 'Descending'))
      newOrders[i].order = value;
    setActiveOrders(newOrders);
  };

  // --------------- effects ---------------
  const labelList = useMemo(() => availableOrders.map((d) => d.label), [availableOrders]);

  useEffect(() => {
    setErrors('');
    // validate - not sure any of this can happen given how Autocomplete works!
    forEach(activeOrders, (o, index) => {
      if (!availableOrders.find((ao) => o.label === ao.label))
        setErrors(`${errors}The field on row ${index + 1} is not on the list. `);
      if (!['Ascending', 'Descending'].includes(o.order))
        setErrors(
          `${errors}The order on row ${index + 1} is not valid (Ascending or Descending). `
        );
    });
    const allOrders = activeOrders.map((o) => o.label);
    if (allOrders.length !== uniq(allOrders).length)
      setErrors('You cannot use the same Column twice!');
  }, [activeOrders, availableOrders, errors]);

  // --------------- actions ---------------
  const handleAddNew = () => {
    const newOrders = [...activeOrders];
    newOrders.push({ label: '', order: 'Ascending' });
    setActiveOrders(newOrders);
  };

  const handleRemove = (i: number) => {
    setActiveOrders(activeOrders.filter((value, index) => index !== i));
  };

  const onSubmit = useCallback(() => {
    setOrders(
      activeOrders.map((item) => {
        const availablesIndex = availableOrders.findIndex((ao) => ao.label === item.label);
        return {
          availablesIndex: availablesIndex,
          descending: item.order === 'Descending',
        };
      })
    );

    handleCloseSort();
  }, [activeOrders, setOrders, availableOrders]);

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Iconify icon="basil:sort-solid" />}
        onClick={handleOpenSort}
        sx={{
          fontWeight: 400,
          justifyContent: 'left',
          width: 'fit-content',
          minWidth: 160,
        }}
      >
        {sortButtonText}
      </Button>
      <Dialog
        open={!!openDialog}
        onClose={handleCloseSort}
        title="Set Sort Order"
        maxWidth="sm"
        sx={{
          '& .MuiDialogContent-root': {
            minHeight: '60vh',
            maxHeight: '60vh',
          },
          '& form': {
            flex: '1 1 auto !important',
          },
        }}
        actions={
          <Stack width="100%" direction="row" justifyContent="space-between">
            <LoadingButton size="small" onClick={handleCloseSort}>
              Close
            </LoadingButton>
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              onClick={onSubmit}
              disabled={!!errors}
            >
              Apply Sort
            </LoadingButton>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <Card variant="outlined" sx={{ px: 1, minHeight: '50vh', maxHeight: '50vh' }}>
            <Scrollbar>
              <Stack
                sx={{
                  py: 1,
                  '& .MuiFormHelperText-root': { marginTop: '-2px' },
                  minHeight: '45vh',
                  maxHeight: '45vh',
                }}
              >
                {activeOrders.map((order, i) => (
                  <Stack
                    key={i}
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                    sx={{ height: 60, maxHeight: 60 }}
                  >
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: 300, overflow: 'auto' } }} // scroll!
                      disablePortal
                      id="select-field"
                      options={labelList}
                      size="small"
                      fullWidth
                      autoComplete
                      autoHighlight
                      disableClearable
                      freeSolo={false}
                      value={order.label}
                      renderInput={(params) => <TextField label="Column" {...params} />}
                      onChange={(e, newValue) => partialUpdateOrders(i, 'label', newValue || '')}
                    />
                    <Autocomplete
                      disablePortal
                      id="select-order"
                      options={['Ascending', 'Descending']}
                      size="small"
                      fullWidth
                      autoComplete
                      autoHighlight
                      disableClearable
                      freeSolo={false}
                      value={order.order}
                      renderInput={(params) => <TextField label="Order" {...params} />}
                      onChange={(e, newValue) => partialUpdateOrders(i, 'order', newValue || '')}
                    />
                    {activeOrders.length > 1 ? (
                      <IconButton
                        aria-label="close"
                        onClick={() => handleRemove(i)}
                        sx={{
                          width: 36,
                          height: 36,
                        }}
                        tabIndex={-1}
                      >
                        <Iconify icon="material-symbols:close" />
                      </IconButton>
                    ) : (
                      <Typography sx={{ width: 36 }} />
                    )}
                  </Stack>
                ))}
              </Stack>
            </Scrollbar>

            <Stack alignItems="flex-start" pb={1}>
              <Button onClick={handleAddNew} startIcon={<Iconify icon="eva:plus-fill" />}>
                Add new
              </Button>
            </Stack>
          </Card>
        </Stack>

        {!!errors && <Alert severity="error">{errors}</Alert>}
      </Dialog>
    </>
  );
}
