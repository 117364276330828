import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
const RegisterSchema = Yup.object().shape({
  displayName: Yup.string().required('Your full name required'),
  email: Yup.string()
    .matches(EmailValidOrEmpty, 'Email must be a valid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const registerSchemaResolver = yupResolver(RegisterSchema);
export type RegisterSchemaForm = {
  displayName: string;
  email: string;
  password: string;

  afterSubmit?: string;
};
