/* `useLocalState`, adapted from https://gist.github.com/Mon4ik/2636100f5b74ee14e35cf283700616fe which called it useLocalStorage
 *
 * Features:
 *  - JSON Serializing
 *  - Also value will be updated everywhere, when value updated (via `storage` event)
 * 
 * WARNING: Unless the type of defaultValue is a primitive (like string or boolean), you 
 * will get a React infinite loop when using this unless you use useRef to save it in a
 *  variable, like:

const { current: initOptions } = useRef<OneDateReportOptions>({
    showDescription: false,
    includeDetails: true,
    includeSummary: true,
  });

  const [options, setOptions] = useLocalState<OneDateReportOptions>(storageKey, initOptions);
 */

import { useEffect, useState } from 'react';

export default function useLocalState<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const item = localStorage.getItem(key);

    if (!item) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
    }

    setValue(item && item !== 'undefined' ? JSON.parse(item) : defaultValue);

    function handler(e: StorageEvent) {
      if (e.key !== key) return;

      const lsi = localStorage.getItem(key);
      setValue(JSON.parse(lsi ?? ''));
    }

    window.addEventListener('storage', handler);

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [defaultValue, key]);

  const setValueWrap = (value: T) => {
    try {
      setValue(value);

      localStorage.setItem(key, JSON.stringify(value));
      if (typeof window !== 'undefined') {
        window.dispatchEvent(new StorageEvent('storage', { key }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return [value, setValueWrap];
}
