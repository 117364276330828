import { Container, Stack, Typography } from '@mui/material';

import Il404 from '@assets/il_404';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import DefaultNavigate from '@routes/DefaultNavigate';

// ----------------------------------------------------------------------
type Props = {
  roles: Role[];

  hasContent?: boolean;
  fallback?: string;

  children: React.ReactNode;
};

// ----------------------------------------------------------------------
export default function RoleGuard({ roles, hasContent, fallback, children }: Props) {
  const { hasAccess } = useRole();

  if (!hasAccess(roles)) {
    if (hasContent) {
      return (
        <Container sx={{ textAlign: 'center' }}>
          <Stack>
            <Typography variant="h3" paragraph>
              Role Denied
            </Typography>
          </Stack>

          <Stack>
            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page
            </Typography>
          </Stack>

          <Stack>
            <Il404 sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </Stack>
        </Container>
      );
    }

    if (fallback) {
      return <DefaultNavigate to={fallback} />;
    }

    return null;
  }

  return <>{children}</>;
}
