import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Stack, Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useOrg from '@hooks/useOrg';
import useAuth from '@hooks/useAuth';

// ----------------------------------------------------------------------
export default function EmailState() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const { triggerEmailConfirmation } = useOrg();

  const handleEmailState = async () => {
    try {
      if (!user) return;
      setLoading(true);
      // TODO: Add a rate limit on backend for this function
      await triggerEmailConfirmation({
        user: user,
      });

      enqueueSnackbar('Confirmation link successfully sent!');
    } catch (e) {
      enqueueSnackbar(
        'There was an issue sending you the email confirmation email. Please try again!',
        { variant: 'error' }
      );
    }
    setLoading(false);
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography variant="h5">Your Email Is Unconfirmed!</Typography>
        <Typography variant="body2">Confirm your email to use all the features</Typography>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            fullWidth={false}
            type="submit"
            variant="contained"
            loading={isLoading}
            onClick={handleEmailState}
          >
            Send Me Email Confirmation Link
          </LoadingButton>
        </Stack>
      </Stack>
    </Card>
  );
}
