import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Stack, Step, StepLabel, Stepper } from '@mui/material';
import { isEqual } from 'lodash';
import useOrg from '@hooks/useOrg';

import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useNavigate from '@hooks/useNavigate';
import Dialog from '@components/Dialog';
import PdfDocument from '@pages/receipts/templates/PdfDocument';
import LockToProceed from '@components/receipt-lock/LockToProceed';

import StepActions from './StepActions';
import {
  ReceiptIssueSchemaForm,
  ReceiptReissueBatchSchemaForm,
  ReceiptReissueSchemaForm,
} from '@/schemas';
import { defaultState, defaultSubject } from '../Create/steps/ReviewEmail/EmailTemplate';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------
export type ReceiptAnySchemaForm =
  | ReceiptIssueSchemaForm
  | ReceiptReissueSchemaForm
  | ReceiptReissueBatchSchemaForm;
export type TStepProps<T = ReceiptAnySchemaForm> = {
  readonly stepData: T;
  readonly updateStepData: (newData: Partial<T>) => void;
  readonly submittingData: {
    readonly isSubmitting: boolean;
    readonly progress: number;
  };
  readonly errors: string[];
};
export enum StepId {
  reissueType,
  sendType,

  recipientList, // create
  donationsReview, // reissue
  receiptsList, // reissue-batch

  receiptReview,
  emailReview,
  overview,
}
type TStep<T> = {
  readonly id: StepId;
  readonly title: string;
  readonly Component: (props: TStepProps<T>) => JSX.Element | null;
};

// ----------------------------------------------------------------------
type TUseSteps<T> = {
  Schema: any;
  onComplete: (data: T) => void;
  renderSteps: (data: T) => TStep<T>[];

  title: string;
  actionText: string;
};

// ----------------------------------------------------------------------
export default function useSteps<T>(
  { Schema, onComplete, renderSteps, title, actionText }: TUseSteps<T>,
  stateData: Partial<ReceiptAnySchemaForm>
) {
  const navigate = useNavigate();
  const { fDateToYearStart, fDateToPreviousYearStart, fDateToPreviousYearEnd } = useFormat();
  const { org, updateOrg } = useOrg();
  const { enqueueSnackbar } = useSnackbar();
  // In Jan or Feb, default the date range to last year. In other months, this YTD.
  const isJanOrFeb = new Date().getMonth() <= 2;

  // --------------- state ---------------
  const [isValid, setValid] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [submittingData, setSubmittingData] = useState({
    isSubmitting: false,
    progress: 0,
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [stepData, setStepData] = useState<ReceiptAnySchemaForm>({
    step: 0,

    actionType: undefined,
    printAllDonors: false,
    dateFrom: isJanOrFeb ? fDateToPreviousYearStart() : fDateToYearStart(),
    dateTo: isJanOrFeb ? fDateToPreviousYearEnd() : new Date(),
    tags: [],
    categories: [],
    excludedIds: [],
    issues: 0,
    letter: { withLetter: false, size: 'letter', Doc: PdfDocument },
    email: { subject: stateData.email?.subject, html: '', state: stateData.email?.state },
    emailAndPrint: true,
    options: {
      showDescription: !!org?.receiptDetailsTableDescription,
      includeDetails: org?.receiptDetailsTable ?? true,
      includeSummary: org?.receiptSummaryTable ?? true,
    },

    // diff between issues
    ...stateData,
  } as ReceiptAnySchemaForm);

  const steps = renderSteps(stepData as T);
  const lastStep = stepData.step === steps.length - 1;

  // --------------- actions ---------------
  const updateStepData = useCallback(
    (newData: Partial<ReceiptAnySchemaForm>) => {
      const update = { ...stepData, ...newData };
      if (isEqual(stepData, update)) return;

      setDirty(true);
      setStepData(update);
    },
    [stepData]
  );

  const updateOrgEmailContents = async () => {
    if (!org) return;
    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          receiptEmailSubject: stepData.email.subject,
          receiptEmailBody: stepData.email.state,
        },
      });
      if (!res) enqueueSnackbar('Failed to save email contents', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar('Failed to save email contents', { variant: 'error' });
    }
  };

  const updateOrgReceiptCheckboxes = async () => {
    if (!org) return;
    try {
      const res = updateOrg({
        orgId: org.id,
        update: {
          receiptDetailsTable: stepData.options.includeDetails,
          receiptDetailsTableDescription: stepData.options.showDescription,
          receiptSummaryTable: stepData.options.includeSummary,
        },
      });

      if (!res) enqueueSnackbar('Failed to save receipt options', { variant: 'error' });
    } catch (error) {}
  };

  // --------------- navigation ---------------
  const handleBack = () => {
    setErrors([]);
    if (steps[stepData.step].id === StepId.emailReview) {
      const different =
        stepData.email.state !== (org?.receiptEmailBody || defaultState) ||
        stepData.email.subject !== (org?.receiptEmailSubject || defaultSubject);
      if (different) updateOrgEmailContents();
    }
    if (steps[stepData.step].id === StepId.receiptReview) {
      const different =
        stepData.options.includeDetails !== (org?.receiptDetailsTable ?? true) ||
        stepData.options.showDescription !== !!org?.receiptDetailsTableDescription ||
        stepData.options.includeSummary !== (org?.receiptSummaryTable ?? true);
      if (different) updateOrgReceiptCheckboxes();
    }
    updateStepData({ step: stepData.step - 1 });
  };

  const handleNext = () => {
    if (steps[stepData.step].id === StepId.emailReview) {
      const different =
        stepData.email.state !== (org?.receiptEmailBody || defaultState) ||
        stepData.email.subject !== (org?.receiptEmailSubject || defaultSubject);
      if (different) updateOrgEmailContents();
    }
    if (steps[stepData.step].id === StepId.receiptReview) {
      const different =
        stepData.options.includeDetails !== (org?.receiptDetailsTable ?? true) ||
        stepData.options.showDescription !== !!org?.receiptDetailsTableDescription ||
        stepData.options.includeSummary !== (org?.receiptSummaryTable ?? true);
      if (different) updateOrgReceiptCheckboxes();
    }
    // last step next
    if (stepData.step === steps.length - 1) {
      onComplete(stepData as any);
      return;
    }

    updateStepData({ step: stepData.step + 1 });
  };

  const handleClose = () => {
    navigate(PATHS.org.receipts.root);
  };

  // --------------- validation ---------------
  useEffect(() => {
    try {
      Schema.validateSync(stepData, { abortEarly: false });
      setValid(true);
      setErrors([]);
    } catch (e) {
      setErrors(e.errors);
      setValid(false);
    }
  }, [Schema, stepData]);

  // --------------- validation ---------------
  const stepProps: TStepProps<any> = {
    stepData,
    updateStepData,
    submittingData,
    errors,
  };

  // --------------- ui ---------------
  const ui = (
    <Dialog
      title={title}
      onClose={handleClose}
      maxWidth="xl"
      isDirty={isDirty}
      isLoading={stepProps.submittingData.isSubmitting}
      contentProps={{ sx: { pt: 1, height: '90vh' } }}
      confirmText="Leave and lose progress?"
      actions={
        <StepActions
          handleNext={handleNext}
          handleBack={stepData.step !== 0 ? handleBack : undefined}
          disabled={!isValid}
          hasFailed={hasFailed}
          isSubmitting={stepProps.submittingData.isSubmitting}
          isLast={lastStep}
          errors={errors}
          actionText={actionText}
        />
      }
    >
      <Outlet />

      <LockToProceed />

      <Stack spacing={3} width="100%" height="100%" flexDirection="column">
        <Stepper activeStep={stepData.step} sx={{ px: 1 }}>
          {steps.map((step, index) => (
            <Step key={step.title} completed={stepData.step > index}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {steps.map(
          (step, index) => stepData.step === index && <step.Component key={index} {...stepProps} />
        )}
      </Stack>
    </Dialog>
  );

  return {
    ui,

    stepData,
    setErrors,
    setSubmittingData,
    handleClose,
    setFailed: () => setFailed(true),
  };
}
