import { useCallback, useMemo } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import { useSelector } from '@redux/store';
import * as fn from '@fire/functions';
import type { Stripe } from '@shared/types';

const useStripe = () => {
  // -------------------- data --------------------
  const { org } = useSelector((s) => s.org);

  // -------------------- effects --------------------
  const subscription = useMemo(() => {
    // org doesn't exist OR never purchased anything
    if (!org?.subscriptions?.donation) return undefined;
    return org.subscriptions.donation;
  }, [org]);

  const isFreeTrial = subscription?.subscriptionStatus === 'trialing';
  const trialRemaining = useMemo(() => {
    if (!isFreeTrial) return '';

    return formatDistanceToNowStrict(new Date(subscription.dateExpiration), {
      roundingMethod: 'ceil',
      unit: 'day',
    });
  }, [isFreeTrial, subscription]);

  // -------------------- actions --------------------
  const getProducts = useCallback(async (): Promise<Stripe.PricingPageProductsData> => {
    try {
      const data = await fn.onRequest<Stripe.PricingPageProductsData>('stripe-getProducts');
      const currency = org?.address.country === 'us' ? 'usd' : 'cad';
      const monthly = data.monthly.map((plan) => ({
        ...plan,
        details: plan.details.filter((d) => d.currency === currency),
      }));
      const yearly = data.yearly.map((plan) => ({
        ...plan,
        details: plan.details.filter((d) => d.currency === currency),
      }));
      return { monthly, yearly };
    } catch (e) {
      throw e;
    }
  }, [org?.address.country]);

  const createPortalSession = useCallback(async (payload: Stripe.CreatePortalSessionReq) => {
    try {
      const {
        data: { sessionUrl },
      } = await fn.createPortalSession(payload);
      return sessionUrl;
    } catch (e) {
      throw e;
    }
  }, []);

  const createCheckoutSession = useCallback(async (payload: Stripe.CreateCheckoutSessionReq) => {
    try {
      const {
        data: { sessionUrl },
      } = await fn.createCheckoutSession(payload);
      return sessionUrl;
    } catch (e) {
      throw e;
    }
  }, []);

  // -------------------- hook --------------------
  return {
    customerId: org?.subscriptions?.customerId,
    subscription,
    isFreeTrial,
    trialRemaining,

    getProducts,
    createPortalSession,
    createCheckoutSession,
  };
};

export default useStripe;
