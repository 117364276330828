import last from 'lodash/last';
import { Avatar as MAvatar, AvatarProps, SxProps } from '@mui/material';

// ----------------------------------------------------------------------
interface Props extends AvatarProps {
  src?: string;
  alt?: string;
  sx?: SxProps;
}

// ----------------------------------------------------------------------
const parseLetters = (name: string) => {
  const parsed = name.split(' ');
  let parts: string[] = [];

  switch (parsed.length) {
    case 0:
      return '';
    case 1:
      parts.push(parsed[0]);
      break;
    default:
      parts = [parsed[0], last(parsed) as string];
  }

  return parts.map((p) => p.charAt(0).toUpperCase()).join('');
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: name ? stringToColor(name) : undefined,
    },
    children: parseLetters(name),
  };
}

// ----------------------------------------------------------------------
export default function Avatar({ src = '', alt = '', sx, ...other }: Props) {
  const properties = stringAvatar(alt);
  return (
    <MAvatar src={src} alt={alt} sx={{ ...sx, ...properties.sx }} {...other}>
      {properties.children || null}
    </MAvatar>
  );
}
