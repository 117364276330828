import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const AssignNumbersSchema = Yup.object().shape({
  year: Yup.number().required('Required'),
  selection: Yup.string().required('Required'),
  order: Yup.string().when(['selection'], ([selection], schema) =>
    selection !== 'copy' ? schema.required('Required') : schema.nullable()
  ),
  start: Yup.number().when(['selection'], ([selection], schema) =>
    selection !== 'copy'
      ? schema.required('Required').typeError('Required')
      : schema
          .transform((_, val) => (val === Number(val) ? val : null))
          .optional()
          .nullable()
  ),
});

export const assignNumbersSchemaResolver = yupResolver(AssignNumbersSchema);
export type AssignNumbersSchemaForm = {
  year: number;
  selection: string | null;
  order: string | null;
  start: number;

  afterSubmit?: string;
};
