import { ReactNode, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import PATHS from '@routes/paths';
import { Limits } from '@shared/limits';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';

import Dialog from '@components/Dialog';

// ----------------------------------------------------------------------
type Props = {
  open?: boolean;
  readonly handleClose?: () => void;
  userCount: number;
  inviteCount: number;
  children?: ReactNode;
};
// ----------------------------------------------------------------------
export default function PaywallUserGuard({
  open,
  handleClose,
  userCount,
  inviteCount,
  children,
}: Props) {
  const navigate = useNavigate();
  const { org } = useOrg();

  // --------------- vars ---------------
  const [ack, setAck] = useState(false);
  const currentCount = userCount + inviteCount;
  const planLimit = Limits.users(org?.id);
  const limitReached = currentCount >= planLimit;

  useEffect(() => {
    if (!open) setAck(false);
  }, [setAck, open]);

  // --------------- actions ---------------
  const handleNavigateToBilling = () => {
    navigate(PATHS.org.settings.org.billing);
  };

  const handleAck = () => setAck(true);

  // --------------- paywall ---------------
  if (limitReached) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        title="Upgrade to increase your User limit"
        maxWidth="sm"
        actions={
          <Button variant="contained" onClick={handleNavigateToBilling}>
            See Pricing Plans
          </Button>
        }
      >
        <Typography>
          You've invited the <strong>maximum number of Users</strong> ({planLimit}) on your current
          pricing plan. Please contact us about pricing plans that accommodate more users.
        </Typography>
      </Dialog>
    );
  } else if (open && !ack && currentCount === planLimit - 1) {
    return (
      <Dialog
        open={open}
        onClose={handleAck}
        title="Upgrade soon to increase your User limit"
        maxWidth="sm"
        actions={
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Button onClick={handleAck}>Skip for now</Button>

            <Button variant="contained" onClick={handleNavigateToBilling}>
              See Pricing Plans
            </Button>
          </Stack>
        }
      >
        <Typography variant="body1">
          You've added or invited {currentCount} Users so far and are approaching the maximum number
          of invited Users ({planLimit}) on your current pricing plan. Please upgrade your plan soon
          to add more Users.
        </Typography>
      </Dialog>
    );
  }

  return children ? children : <Outlet />;
}
