import { useEffect, useRef } from 'react';
// import { useSnackbar } from 'notistack';
// import { LoadingButton } from '@mui/lab';

import useNavigate from '@hooks/useNavigate';
import useAuth from '@hooks/useAuth';
import useOrg from '@hooks/useOrg';
// import ConfirmButton from '@components/ConfirmButton';

// ----------------------------------------------------------------------
export default function LockToProceed() {
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  const { org, enableLock, disableLock } = useOrg();
  const { user: currentUser } = useAuth();
  // const [isLoading, setLoading] = useState(false);
  const multiUserOrg = Object.keys(org?.users || {}).length > 1;

  useEffect(
    () => {
      if (org && !org.lock.isLocked && multiUserOrg) {
        console.log('enable lock');
        enableLock({ orgId: org.id });
      }
      return () => {
        console.log('effect out', org?.lock.lockedBy, currentUser?.id);
        if (org?.lock.lockedBy === currentUser?.id) {
          console.log('fire disable lock');
          disableLock({ orgId: org!.id });
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }

    if (!org || !currentUser) return;

    if (org.lock.isLocked && org.lock.lockedBy !== currentUser?.id) {
      // another user is issuing receipts
      navigate(-1);
    } else if (
      !org.lock.isLocked &&
      org.lock.lockedBy === currentUser.id &&
      org.lock.unlockedBy !== currentUser.id
    ) {
      // someone kicked out user issuing receipts
      navigate(-1);
    }
  }, [navigate, org, currentUser]);

  if (!org || !multiUserOrg) return null;
  // const currentUserIssued = currentUser?.id === org?.lock.lockedBy;

  // const handleBack = () => {
  //   navigate(-1);
  // };

  // const handleLock = async () => {
  //   setLoading(true);
  //   try {
  //     await enableLock({ orgId: org.id });
  //   } catch (e) {
  //     console.error(e);
  //     enqueueSnackbar('Failed to set lock', { variant: 'error' });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return null;

  // return (
  //   <Dialog
  //     open={!lock.isLocked || !currentUserIssued}
  //     maxWidth="sm"
  //     sx={{
  //       backdropFilter: 'blur(8px)',
  //     }}
  //   >
  //     <DialogTitle
  //       sx={{
  //         pb: 0,
  //         display: 'flex',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           position: 'absolute',
  //           top: 0,
  //           left: 0,
  //           right: 0,
  //           height: 8,
  //           background: (theme) => theme.palette.primary.main,
  //         }}
  //       />
  //       Receipt lock
  //     </DialogTitle>

  //     <DialogContent dividers sx={{ pt: 0 }}>
  //       <Stack spacing={2}>
  //         <Typography>
  //           Your app use is <strong>temporarily paused</strong> because another user is issuing
  //           receipts
  //         </Typography>

  //         {/* <Divider /> */}

  //         <Stack spacing={0.5}>
  //           {/* <Typography variant="body2">
  //             To avoid any data conflicts during this important process, we've temporarily paused
  //             your ability to use and make changes in the app. Once {user} is done, this message
  //             will disappear and you will resume full access. This will happen automatically with no
  //             action needed from you.
  //           </Typography>
  //           <Typography variant="body2">
  //             If you want to override this pause, click “Override and stop issuance” below. This
  //             will lose any progress on the current receipt issuance and resume your full access
  //             immediately. We recommend contacting <strong>{user}</strong> before doing this.
  //           </Typography> */}
  //         </Stack>
  //       </Stack>
  //     </DialogContent>

  //     <DialogActions>
  //       <Stack width="100%" spacing={2} direction="row" justifyContent="space-between">
  //         <LoadingButton variant="text" loading={isLoading} onClick={handleBack}>
  //           Back
  //         </LoadingButton>

  //         <ConfirmButton color="error" loading={isLoading} onClick={handleLock}>
  //           Enable lock
  //         </ConfirmButton>
  //       </Stack>
  //     </DialogActions>
  //   </Dialog>
  // );
}
