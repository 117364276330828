import { analytics } from '@/firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

// ----------------------------------------------------------------------
export function create(name: string, country: string) {
  firebaseAnalytics.logEvent(analytics, 'org_create', { name, country });
}

export function emailSignatureDelete(signatureId: number | undefined) {
  firebaseAnalytics.logEvent(analytics, 'email_signature_delete', { signatureId });
}

export function emailSignatureCheck(signatureId: number) {
  firebaseAnalytics.logEvent(analytics, 'email_signature_check', { signatureId });
}

export function emailSignatureVerify(signatureId: number) {
  firebaseAnalytics.logEvent(analytics, 'email_signature_verify', { signatureId });
}

export function invitationCreate(userEmail: string) {
  firebaseAnalytics.logEvent(analytics, 'org_invitation_create', { userEmail });
}

export function invitationCancel(invitationId: string) {
  firebaseAnalytics.logEvent(analytics, 'org_invitation_cancel', { invitationId });
}

export function feedback() {
  firebaseAnalytics.logEvent(analytics, 'feedback');
}
