import { initializeApp } from 'firebase/app';
import {
  CACHE_SIZE_UNLIMITED,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

// ----------------------------------------------------------------------
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MSG_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASURE_ID,
};

// ----------------------------------------------------------------------
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// appcheck
if (import.meta.env.MODE === 'production') {
  // NOTE: we could manually verify the token generated
  // https://firebase.google.com/docs/app-check/web/custom-resource#send_tokens_with_backend_requests
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6Lc0HookAAAAAHDS8xJdcMcMeas3noIImF0gNAT7'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

export default firebaseApp;
export const auth = getAuth(firebaseApp);
// initializeFirestore(firebaseApp, { localCache: persistentLocalCache(/*settings*/ {}) });
// we don't use web-worker, but this has good explanation: https://github.com/firebase/firebase-js-sdk/issues/7364#issuecomment-1599041526
initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache(
    /*settings*/ {
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }
  ),
});
export const db = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
