import { useCallback } from 'react';
import { useSelector, useDispatch } from '@redux/store';
import { logout } from '@redux/slices/auth';

// ----------------------------------------------------------------------
const useAuth = () => {
  const dispatch = useDispatch();
  const { isInitialized, isAuthenticated, user, claims } = useSelector((s) => s.auth);
  return {
    isInitialized,
    isAuthenticated,
    user,
    claims,
    logout: useCallback(() => dispatch(logout()), [dispatch]),
  };
};

export default useAuth;
