import { useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router';
import {
  Button,
  Card,
  FormGroup,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';

import PATHS from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { Donation } from '@shared/types';

import { ReceiptReissueSchemaForm } from '@/schemas';
import Iconify from '@components/Iconify';
import { TStepProps } from '../../../useSteps';
import ReceiptDonations from './ReceiptDonations';

// ----------------------------------------------------------------------
export default function StepDonations({
  stepData,
  updateStepData,
  errors,
}: TStepProps<ReceiptReissueSchemaForm>) {
  const { org } = useOrg();
  const navigate = useNavigate();
  const { getDonorById, getDonorReceiptIssues, getDonorsForReceipt, getPaymentMethodById } =
    useDonation();
  
  const filterAvailableDonations = useCallback(
    (receiptDonations: Donation.Donation[], allDonations: Donation.Donation[]) => {
      // NOTE: similar to useDonation.getReceiptPartitions
      if (org?.address.country === 'ca') {
        // CA GiK can only be a single donation per receipt
        const isGiK = receiptDonations.find(
          (d) =>
            getPaymentMethodById(d.paymentMethodId)?.type ===
            Donation.PaymentMethodInitialList.giftInKind
        );
        if (isGiK) return [];
        return allDonations.filter(
          (d) =>
            getPaymentMethodById(d.paymentMethodId)?.type !==
            Donation.PaymentMethodInitialList.giftInKind
        );
      }
      return allDonations;
    },
    [getPaymentMethodById, org?.address.country]
  );

  // --------------- variables ---------------
  // filter in only donations that fit with filters and receipt type
  // donations form receipt + additional donations = end receipt
  const [receiptDonations, donorDonations, issues] = useMemo(() => {
    const donor = getDonorById(stepData.receipt.donorId);
    if (!donor) {
      return [[], [], []];
    }
    const issues = getDonorReceiptIssues(donor, stepData.actionType!);
    const receiptDonations = donor.donations.filter((d) =>
      d.receiptIds.includes(stepData.receipt.id)
    );
    const allDonorDonations =
      getDonorsForReceipt(
        [donor],
        stepData.tags.map((t) => t.id),
        stepData.categories.map((t) => t.id),
        stepData.dateFrom,
        stepData.dateTo
      )[0]?.donations || [];
    return [
      receiptDonations,
      filterAvailableDonations(receiptDonations, allDonorDonations),
      issues,
    ];
  }, [
    getDonorById,
    getDonorReceiptIssues,
    getDonorsForReceipt,
    filterAvailableDonations,
    stepData.actionType,
    stepData.tags,
    stepData.categories,
    stepData.dateFrom,
    stepData.dateTo,
    stepData.receipt.id,
    stepData.receipt.donorId,
  ]);

  // --------------- actions ---------------
  const handleOpenDonor = () => {
    navigate(PATHS.org.receipts.reissue.donor, { donorId: stepData.receipt.donorId });
  };
  const handleOpenDonation = (donorId: string, donationId: string) => {
    navigate(PATHS.org.receipts.reissue.donation, { donorId, donationId });
  };

  // --------------- effects ---------------
  useEffect(() => {
    if (issues.length) {
      updateStepData({ donations: [] });
      return;
    }
    updateStepData({
      donations: [...receiptDonations, ...(stepData.includeDonations ? donorDonations : [])],
    });
  }, [updateStepData, issues, receiptDonations, donorDonations, stepData.includeDonations]);

  const handleIncludeChange = (e: React.MouseEvent, value: boolean) =>
    updateStepData({ includeDonations: value });

  return (
    <Grid container spacing={0}>
      <Outlet />

      {!!donorDonations.length && (
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Card sx={{ py: 1, px: 2 }}>
            <FormGroup sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <Iconify icon="material-symbols:info-outline" width={20} height={20} mr={0.5} />
              <Typography variant="body2" mr={2}>
                Note: this donor has{' '}
                <strong>{donorDonations.length} unreceipted donations this year</strong>. Would you
                like to add them to this receipt?
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={stepData.includeDonations}
                exclusive
                onChange={handleIncludeChange}
                aria-label="Platform"
              >
                <ToggleButton value={false} sx={{ width: 100, height: 36 }}>
                  No
                </ToggleButton>
                <ToggleButton value={true} sx={{ width: 100, height: 36 }}>
                  Yes
                </ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
          </Card>
        </Grid>
      )}

      {!!issues.length && (
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Card sx={{ py: 0.5, px: 2 }}>
            <Stack flex="1 1 auto" direction="row" alignItems="center">
              <Tooltip title={issues.join(', ')} placement="top-end">
                <Button
                  size="small"
                  sx={{ color: (theme) => theme.palette.error.main }}
                  startIcon={<Iconify icon="ic:baseline-warning-amber" width={24} height={24} />}
                >
                  Donor issues
                </Button>
              </Tooltip>
              <Button size="small" onClick={handleOpenDonor}>
                Edit donor
              </Button>
            </Stack>
          </Card>
        </Grid>
      )}

      <Grid item xs={12}>
        <ReceiptDonations donations={stepData.donations} onClick={handleOpenDonation} />
      </Grid>
    </Grid>
  );
}
