import { useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

import Dialog from '@components/Dialog';
import {
  PdfTableData,
  PdfTableHeaderProps,
  PdfTableSubtotal,
  PdfTableSummaryField,
} from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { TableDateRange } from '@components/table';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    fDate,
    fCurrency,
  } = useFormat();
  const { donations, getCategoryById } = useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  // ---------- memo
  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Date', width: 20 },
      { name: 'Category', width: 40 },
      { name: 'Amount ($)', width: 16, align: 'right' },
    ];

    // Convert the date and category together into a string, needed to be the keys of the dictionary that groupBy returns when we want to group by both of them
    const dateAndCategory = (d: { date: Date; categoryName: string | undefined }) =>
      `${fDate(d.date)}^${d.categoryName}`;

    const filtered = filter(donations, (d) =>
      isDateBetween(
        new Date(d.date),
        fDateToDayStart(dateFrom || minDateFrom),
        fDateToDayEnd(dateTo || maxDateTo)
      )
    );
    const limited = filtered.map((d) => {
      const date = new Date(d.date);
      const consistentDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1);
      return {
        date: consistentDate,
        categoryName: getCategoryById(d.categoryId)?.name,
        amount: d.amount,
      };
    });
    const sorted = sortBy(
      limited,
      (d) => d.date,
      (d) => d.categoryName
    );
    const grandTotal = filtered.reduce((n, { amount }) => n + Number(amount), 0);

    const grouped = groupBy(sorted, dateAndCategory);
    const items = Object.keys(grouped).map((key) => {
      const [date, categoryName] = key.split('^');
      const categoryTotal = grouped[key].reduce((t, { amount }) => t + Number(amount), 0);
      return [fDate(date), categoryName, fCurrency(categoryTotal)];
    });

    const summary: PdfTableSummaryField[] = [
      { columns: 2, text: 'Grand Total:' },
      { columns: 1, text: `${fCurrency(grandTotal)}` },
    ];

    const itemsForSubtotal = Object.keys(grouped).map((key) => {
      const date = key.split('^')[0];
      const categoryTotal = grouped[key].reduce((t, { amount }) => t + Number(amount), 0);
      return { date: date, amount: categoryTotal };
    });
    let subtotals: PdfTableSubtotal[] = [];
    const groupedByDate = groupBy(itemsForSubtotal, 'date');
    const keys = Object.keys(groupedByDate);
    if (keys.length > 1) {
      let afterRowNumber = -1;
      keys.forEach((key) => {
        const total = groupedByDate[key].reduce((t, { amount }) => t + Number(amount), 0);
        afterRowNumber += groupedByDate[key].length;
        subtotals.push({
          afterRowNumber: afterRowNumber,
          subtotal: [
            { columns: 2, text: `Subtotal for ${fDate(key)}` },
            { columns: 1, text: `${fCurrency(total)}` },
          ],
        });
      });
    }
    return { header, items, summary, subtotals };
  }, [
    donations,
    fCurrency,
    fDate,
    isDateBetween,
    fDateToDayStart,
    dateFrom,
    minDateFrom,
    fDateToDayEnd,
    dateTo,
    maxDateTo,
    getCategoryById,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={name}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      orderBy="Date then Category"
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Summary by Date and Category report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !blob ||
                !!error
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'summary_by_date_and_category'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />

            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
