import { Category, Receipt, Tag } from '@shared/types';
import { TColumn } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';

// ----------------------------------------------------------------------
export type ReceiptListItem = {
  readonly id: string;
  readonly _receipt: Receipt.Receipt;
  readonly _donor: TDonorWithDonations;
  readonly _tags: Tag.Tag[];
  readonly _categories: Category.Category[];
  readonly _amount: number;
  readonly _amountEligible: number;

  number: string;
  date: string;
  donorName: string;
  state: string;
  tags: string;
  categories: string;
  amount: string;
  amountEligible: string;
};

// ----------------------------------------------------------------------
export type ReceiptListHeader =
  | 'number'
  | 'date'
  | 'state'
  | 'donorName'
  | 'amount'
  | 'amountEligible'
  | 'tags'
  | 'categories';

// ----------------------------------------------------------------------
export const RECEIPT_LIST_HEADERS: TColumn<ReceiptListHeader>[] = [
  {
    id: 'number',
    type: 'string',
    label: 'Receipt',
    align: 'left',
    visible: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'date',
    type: 'date',
    label: 'Date',
    align: 'left',
    visible: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'donorName',
    type: 'string',
    label: 'Donor',
    align: 'left',
    visible: true,
    searchable: true,
    pdfWidth: 20,
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount',
    align: 'left',
    visible: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'amountEligible',
    type: 'currency',
    label: 'Eligible Amount',
    align: 'left',
    visible: false,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'state',
    type: 'string',
    label: 'State',
    align: 'left',
    visible: true,
    searchable: true,
    pdfWidth: 10,
  },
  {
    id: 'tags',
    label: 'Donor Tags',
    align: 'left',
    visible: false,
    searchable: true,
    pdfWidth: 15,
  },
  {
    id: 'categories',
    label: 'Donation Categories',
    align: 'left',
    visible: false,
    searchable: true,
    pdfWidth: 15,
  },
];
