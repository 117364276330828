import { Autocomplete, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { PaymentMethod, Tag } from '@shared/types';
import useDonation from '@hooks/useDonation';
import renderTags, {
  renderTagOption,
} from '@pages/donors/dialogs/Settings/DonorTags/utils/renderTags';

import { ImportType, MatchActions, MatchAction, NROption, NROptions } from '@/schemas/importData';
import { HTMLAttributes, SyntheticEvent, useEffect, useMemo } from 'react';
import { sortBy } from 'lodash';
import { TStepProps } from './useSteps';
import { AutocompleteItem } from '@typedefs/app';

const noPaymentMethod = {
  id: '',
  name: '(None)',
  type: 'custom',
} as PaymentMethod.PaymentMethod;

const noTag = {
  id: '',
  name: '(None)',
  type: 'donor',
} as Tag.Tag;

// ----------------------------------------------------------------------
export default function StepOptions({ stepData, updateStepData }: TStepProps) {
  const { paymentMethods, tags } = useDonation();

  const { importType, matchField, matchAction, mapFields, paymentMethod, tag, nrOption } = stepData;

  const [matchFieldList, hasPaymentMethod, hasTag] = useMemo(() => {
    const fieldNames = mapFields.map((f) => f.fieldName);

    const list: AutocompleteItem<string>[] = [];
    if (fieldNames.includes('email')) list.push({ value: 'email', label: 'Email address' });
    if (fieldNames.includes('memberNumber'))
      list.push({ value: 'memberNumber', label: 'Member / Envelope #' });
    if (importType === ImportType.onlyDonors) list.push({ value: 'none', label: 'None' });

    return [list, fieldNames.includes('paymentMethod'), fieldNames.includes('tag')];
  }, [importType, mapFields]);

  // create the appropriate list of match actions
  const matchActionList = useMemo(() => {
    const list = [...MatchActions];
    if (importType !== ImportType.onlyDonors) list.shift(); // remove 1st element, add
    return list;
  }, [importType]);

  // creat the appropriate list of Non-Receiptable Options
  const nrOptionList = useMemo(() => {
    const list: AutocompleteItem<NROption>[] = [];
    for (const o of NROptions) {
      switch (o.value) {
        case NROption.none:
          list.push(o);
          break;
        case NROption.allDonations:
          if (importType !== ImportType.onlyDonors) list.push(o);
          break;
        case NROption.businessDonors:
          if (!!mapFields.find((mf) => mf.fieldName === 'organization')) list.push(o);
          break;
        case NROption.allDonors:
          if (importType !== ImportType.onlyDonations) list.push(o);
          break;
      }
    }
    return list;
  }, [importType, mapFields]);

  useEffect(() => {
    // set initial values
    updateStepData({
      matchField: matchField || matchFieldList[0],
      matchAction: matchAction || matchActionList[0],
    });
  }, [matchAction, matchActionList, matchField, matchFieldList, updateStepData]);

  // Handle selects on the non-receiptable options drop-down.
  const handleSelectNROption = (event: SyntheticEvent, value: AutocompleteItem<NROption>) => {
    if (value) updateStepData({ nrOption: value });
  };

  const handleSelectMatchField = (
    event: SyntheticEvent,
    value: AutocompleteItem<string> | null
  ) => {
    if (value) updateStepData({ matchField: value });
  };

  const handleSelectMatchAction = (
    event: SyntheticEvent,
    value: AutocompleteItem<MatchAction> | null
  ) => {
    if (value) updateStepData({ matchAction: value });
  };

  const handleSelectPaymentMethod = (
    event: SyntheticEvent,
    value: PaymentMethod.PaymentMethod | null
  ) => {
    if (value) updateStepData({ paymentMethod: value });
  };

  const handleSelectTag = (event: SyntheticEvent, value: Tag.Tag | null) => {
    if (value) updateStepData({ tag: value });
  };

  // Render an option in a drop-down which may have one bold section of its code,
  // surrounded with '<b>' on each side, like
  // 'Yes, <b>all donations<b> are non-receiptable'.
  const renderOptionWithBold = (
    props: HTMLAttributes<HTMLLIElement>,
    option: AutocompleteItem<NROption>
  ) => {
    const { label } = option;
    const arr = label.split('<b>');
    return (
      <li {...props}>
        {arr.length === 1 ? (
          <>{label}</>
        ) : (
          <Typography>
            {arr[0]}
            <span style={{ fontWeight: 'bold' }}>{arr[1]}</span>
            {arr[2]}
          </Typography>
        )}
      </li>
    );
  };

  return (
    <FormGroup>
      <Stack spacing={2}>
        <Typography variant="subtitle1" sx={{ pb: 1 }}>
          Choose how your imported data will impact existing records
        </Typography>
        <Autocomplete
          value={matchField || matchFieldList[0]}
          size="small"
          sx={{ width: 600 }}
          disableClearable
          options={matchFieldList}
          renderInput={(params) => (
            <TextField {...params} label="Match to Donors on Field" variant="outlined" />
          )}
          onChange={handleSelectMatchField}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
        {importType !== ImportType.onlyDonations && (
          <Autocomplete
            value={
              matchAction ||
              (importType === ImportType.onlyDonors
                ? MatchActions[MatchAction.add]
                : MatchActions[MatchAction.update])
            }
            sx={{ width: 600 }}
            size="small"
            disableClearable
            renderInput={(params) => (
              <TextField {...params} label="Action on Match" variant="outlined" />
            )}
            onChange={handleSelectMatchAction}
            options={matchActionList}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        )}
        {!hasPaymentMethod && importType !== ImportType.onlyDonors && (
          <Autocomplete
            value={paymentMethod || noPaymentMethod}
            size="small"
            sx={{ width: 600 }}
            disableClearable
            options={[noPaymentMethod, ...sortBy(paymentMethods, (c) => c.name.toUpperCase())]}
            renderInput={(params) => (
              <TextField {...params} label="Payment Method for all donations" variant="outlined" />
            )}
            onChange={handleSelectPaymentMethod}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        )}
        {!hasTag && importType !== ImportType.onlyDonations && (
          <Autocomplete
            value={tag || noTag}
            size="small"
            sx={{ width: 600 }}
            disableClearable
            options={[
              noTag,
              ...sortBy(
                tags.filter((t) => t.type === 'donor'),
                (t) => t.name.toUpperCase()
              ),
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Tag for all donors" variant="outlined" />
            )}
            onChange={handleSelectTag}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={renderTags}
            renderOption={renderTagOption}
          />
        )}
        <Autocomplete
          value={nrOption || nrOptionList[0]}
          size="small"
          sx={{ width: 600 }}
          disableClearable
          options={nrOptionList}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Are any donations or donors non-receiptable?"
              variant="outlined"
            />
          )}
          onChange={handleSelectNROption}
          getOptionLabel={(option) => option.label.replace(/<b>/g, '')}
          renderOption={renderOptionWithBold}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Stack>
    </FormGroup>
  );
}
