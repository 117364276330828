import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router';
import { Card, Grid, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';

import PATHS from '@routes/paths';
import useDonation from '@hooks/useDonation';
import useTable, { emptyRows } from '@hooks/useTable';
import useNavigate from '@hooks/useNavigate';
import { Category, Donor, Tag } from '@shared/types';
import { TColumn } from '@typedefs/app';
import useFormat from '@hooks/useFormat';

import TablePaginationCustom from '@components/table/TablePaginationCustom';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Scrollbar from '@components/Scrollbar';

import { MinAmountType, ReceiptIssueSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import { RECIPIENTS_LIST_HEADERS, TIssues, RecipientsListHeader } from './config';
import applySorts from './sorts';
import Toolbar from './Toolbar';
import Row from './Row';

// ----------------------------------------------------------------------
export default function StepRecipients({
  stepData,
  updateStepData,
  errors,
}: TStepProps<ReceiptIssueSchemaForm>) {
  const navigate = useNavigate();
  const { fReversedName } = useFormat();
  const { donorsWithDonations, getDonorReceiptIssues, getDonorsForReceipt } = useDonation();
  const {
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    selected,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    onSelectRow,
  } = useTable({
    defaultRowsPerPage: 50,
    defaultColumns: RECIPIENTS_LIST_HEADERS,
    defaultOrderBy: 'name',
    defaultSelected: stepData.excludedIds,
  });

  // make sure we have a default min amount type for filtering
  useEffect(() => {
    if (!stepData.filterMinAmountType)
      updateStepData({ filterMinAmountType: MinAmountType.atLeastOneGE });
  }, [stepData, updateStepData]);

  // --------------- variables ---------------
  // filter in only donors & donations that fit into the timeframe selected
  // those donors - selected (excluded) = recipients
  const [ donors, issues, donorsWithIssues] = useMemo(() => {
    const donors = getDonorsForReceipt(
      donorsWithDonations.filter((d) => !stepData.singleDonor || d.id === stepData.singleDonor.id)
      .filter(
        (d) => !stepData.filterDonor || d.id === stepData.filterDonor?.id
      ),
      stepData.tags.map((t) => t.id),
      stepData.categories.map((t) => t.id),
      stepData.dateFrom,
      stepData.dateTo,
      stepData.actionType,
      stepData.printAllDonors,
      stepData.filterMinAmount,
      stepData.filterMinAmountType,
    );
    let donorsWithIssues = 0;
    const issues = donors.reduce((acc, donor) => {
      const issues = getDonorReceiptIssues(donor, stepData.actionType!);

      if (issues.length) {
        donorsWithIssues += 1;
        acc[donor.id] = issues;
      }
      return acc;
    }, {} as TIssues);

    return [ donors, issues, donorsWithIssues];
  }, [getDonorsForReceipt, donorsWithDonations, stepData.tags, stepData.categories, stepData.dateFrom, stepData.dateTo, stepData.actionType, stepData.printAllDonors, stepData.filterMinAmount, stepData.filterMinAmountType, stepData.singleDonor, stepData.filterDonor, getDonorReceiptIssues]);

  // ----- FILTERING -------
  const data = applySorts({
    data: donors,
    order,
    orderBy,
    fReversedName,
  });

  // --------------- effects ---------------
  useEffect(() => {
    // NOTE: updates the final recipient list
    const chosen = data.filter((d) => !selected.includes(d.id));
    const issueIds = Object.keys(issues);
    const chosenIssues = chosen.filter((donor) => issueIds.includes(donor.id)).length;
    updateStepData({ donors: chosen, issues: chosenIssues, excludedIds: selected });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, issues, selected]);

  // --------------- actions ---------------
  const handleClickRow = (donorId: string) => {
    navigate(PATHS.org.receipts.create.donor, { donorId });
  };

  const setDateFrom = (dateFrom?: Date) => updateStepData({ dateFrom });
  const setDateTo = (dateTo?: Date) => updateStepData({ dateTo });
  const setCategories = (categories: Category.Category[]) => updateStepData({ categories });
  const setTags = (tags: Tag.Tag[]) => updateStepData({ tags });
  const setFilterDonor = (donor: Donor.Donor | undefined) => updateStepData({ filterDonor: donor });
  const setFilterMinAmount = (amount: number | undefined) =>
    updateStepData({ filterMinAmount: amount });
  const setFilterMinAmountType = (type: MinAmountType) =>
    updateStepData({ filterMinAmountType: type });

  // Clear all the filters at once: for some reason coding this in Filters.tsx using
  // the set... methods above here only cleared one at a time!
  const handleClearAll = () =>
    updateStepData({
      tags: [],
      categories: [],
      filterDonor: undefined,
      filterMinAmount: 0,
    });

  return (
    <Grid container spacing={0}>
      <Outlet />

      <Grid item xs={12} sx={{ mb: 2 }}>
        <Toolbar
          filterTags={stepData.tags}
          onFilterTagChange={setTags}
          filterCategories={stepData.categories}
          onFilterCategoryChange={setCategories}
          dateFrom={stepData.dateFrom}
          setDateFrom={setDateFrom}
          dateTo={stepData.dateTo}
          setDateTo={setDateTo}
          donorCount={stepData.donors.length}
          dateError={errors.find((e) => e.indexOf('Date') !== -1)}
          filterDonor={stepData.filterDonor}
          onFilterDonorChange={setFilterDonor}
          donorsWithDonations={donors}
          isSingleDonor={!!stepData.singleDonor}
          filterMinAmount={stepData.filterMinAmount}
          onFilterMinAmountChange={setFilterMinAmount}
          filterMinAmountType={stepData.filterMinAmountType}
          onFilterMinAmountTypeChange={setFilterMinAmountType}
          handleClearAll={handleClearAll}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
            <Scrollbar>
              <Table size={'small'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  columns={columns}
                  rowCount={data.length}
                  onSort={onSort as (id: string) => void}
                />

                <TableBody>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((donor) => (
                    <Row
                      key={donor.id}
                      donor={donor}
                      issues={issues[donor.id]}
                      selected={selected.includes(donor.id)}
                      onSelectRow={onSelectRow}
                      columns={columns as TColumn<RecipientsListHeader>[]}
                      onClickRow={handleClickRow}
                    />
                  ))}

                  <TableEmptyRows
                    height={52}
                    emptyRows={emptyRows(page, rowsPerPage, data.length)}
                  />

                  {/* change this isNotFound */}
                  <TableNoData isNotFound={!data.length} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={data.length}
            customSelected={
              selected.length && (
                <Stack px={2} py={1}>
                  {!!donorsWithIssues && (
                    <Typography variant="body2">{donorsWithIssues} donors with issues</Typography>
                  )}
                </Stack>
              )
            }
            rowsPerPageOptions={[10, 25, 50, 100]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
