// Show the three checkbox options for the ReviewReceipt panels
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';

import { ReceiptOptions } from '@/schemas';
import { ReceiptAnySchemaForm } from '@pages/receipts/useSteps';

export type Props = {
  readonly stepData: ReceiptAnySchemaForm;
  readonly updateStepData: (newData: Partial<ReceiptAnySchemaForm>) => void;
};
// ----------------------------------------------------------------------
export default function ReviewReceiptSections({ stepData, updateStepData }: Props) {
  const partialUpdateOptions = (newOptions: Partial<ReceiptOptions>) => {
    updateStepData({ options: { ...stepData.options, ...newOptions } });
  };

  return (
    <>
      <Typography style={{ fontWeight: 'bold' }}>Sections to Include</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={stepData.options.includeDetails}
              onChange={() => {
                partialUpdateOptions({ includeDetails: !stepData.options.includeDetails });
              }}
            />
          }
          label={'Donation Details'}
        />
        {stepData.options.includeDetails && (
          <FormControlLabel
            control={
              <Checkbox
                checked={stepData.options.showDescription}
                onChange={() => {
                  partialUpdateOptions({ showDescription: !stepData.options.showDescription });
                }}
              />
            }
            label={'Add Description Column'}
            sx={{ paddingLeft: 4 }}
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={stepData.options.includeSummary}
              onChange={() => {
                partialUpdateOptions({ includeSummary: !stepData.options.includeSummary });
              }}
            />
          }
          label={'Summary by Category'}
        />
      </FormGroup>
    </>
  );
}
