/* Types and functions to help with user-specified sorts, possibly on multiple fields.
 * Once an array of SortOrder elements is created, you can sort a collection that has
 * matching fields on it with the sortByOrders method.
 */

import { orderBy } from 'lodash';

// An array of these is the user-choosable fields for sort orders for the report
export type AvailableSortOrder = {
  label: string;
  field: string | ((d: any) => string);
};

// An array of these is the initial or user-selected sort order for the report
export type SortOrder = {
  availablesIndex: number; // the index into the availableSortOrders array in the report for this order
  // label: string;
  // field: string | ((d: any) => string);
  descending?: boolean; // ascending if true, descending if missing or false
};

// Change SortOrder array to things like "Name then Date descending"
export const orderDescription = (
  orders: SortOrder[],
  availableOrders: AvailableSortOrder[]
): string =>
  orders
    .map((o) => {
      const { label } = availableOrders[o.availablesIndex];
      return `${label}${o.descending ? ' descending' : ''}`;
    })
    .join(' then ');

// Do the sort using the selected orders
export const sortByOrders = <T>(c: Array<T>, sortOrders: SortOrder[], availableOrders: AvailableSortOrder[]): Array<T> => {
  const fields = sortOrders.map((order) => availableOrders[order.availablesIndex].field);
  const orders = sortOrders.map((order) => (order.descending ? 'desc' : 'asc'));
  return orderBy(c, fields, orders);
};
