import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const DonorNotesSchema = Yup.object().shape({
  notes: Yup.string(),
});

export const donorNotesSchemaResolver = yupResolver(DonorNotesSchema);
export type DonorNotesSchemaForm = {
  notes: string;

  afterSubmit?: string;
};
