import { useCallback, useState } from 'react';
import { Tab, Box } from '@mui/material';
import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';

import Dialog from '@components/Dialog';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import useOrg from '@hooks/useOrg';
import DonorCustomFields from './DonorCustomFields';
import DonorTags from './DonorTags';
import { Outlet } from 'react-router';
import MemberEnvelope from './MemberEnvelope';

type ContextTab = 'tags' | 'custom-fields' | 'member-envelope';

type Props = { context?: ContextTab };

// --------------- effects ---------------
export default function SettingsDonors({ context }: Props) {
  const { org } = useOrg();

  const navigate = useNavigate();
  const [value, setValue] = useState<ContextTab>(context || 'tags');

  const handleChange = (event: React.SyntheticEvent, tab: ContextTab) => {
    setValue(tab);

    switch (tab) {
      case 'tags':
        navigate(getPath(PATHS.org.donors.settings.donors.tags, { orgId: org!.id }));
        break;
      case 'member-envelope':
        navigate(getPath(PATHS.org.donors.settings.donors.memberEnvelope, { orgId: org!.id }));
        break;
      case 'custom-fields':
        navigate(getPath(PATHS.org.donors.settings.donors.customFields, { orgId: org!.id }));
        break;
      default:
        console.warn(`Invalid tab ${tab}`);
    }
  };

  const handleClose = useCallback(() => {
    navigate(PATHS.org.donors.root);
  }, [navigate]);

  return (
    <>
      <Dialog
        title="Donor Settings"
        onClose={handleClose}
        maxWidth="md"
        sx={{
          '& .MuiDialogContent-root': {
            minHeight: { sx: '100vh', sm: '35vh' },
            maxHeight: { sx: '100vh', sm: '70vh' },
            height: { sx: '100vh', sm: '60vh' },
          },
          '& form': {
            flex: '1 1 auto !important',
          },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Tags" value="tags" />
                <Tab label="Custom Fields" value="custom-fields" />
                <Tab label="Member / Envelope #" value="member-envelope" />
              </TabList>
            </Box>
            <TabPanel value="tags">
              <DonorTags />
            </TabPanel>
            <TabPanel value="custom-fields">
              <DonorCustomFields />
            </TabPanel>
            <TabPanel value="member-envelope">
              <MemberEnvelope />
            </TabPanel>
          </TabContext>
        </Box>
        <Outlet />
      </Dialog>
    </>
  );
}
