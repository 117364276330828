import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------
export type TIssues = { [donorId: string]: string[] };

// ----------------------------------------------------------------------
export type RecipientsListHeader =
  | 'name'
  | 'donationsTotal'
  | 'donationsCount'
  | 'tags'
  | 'categories'
  | 'issues'
  | 'exclude';

export const RECIPIENTS_LIST_HEADERS: TColumn<RecipientsListHeader>[] = [
  { id: 'name', type: 'string', label: 'Donor name', align: 'left', visible: true },
  {
    id: 'donationsCount',
    type: 'number',
    label: 'Donation Count (#)',
    align: 'right',
    visible: true,
  },
  {
    id: 'donationsTotal',
    type: 'currency',
    label: 'Donation Amount ($)',
    align: 'right',
    visible: true,
  },
  { id: 'tags', label: 'Tags', align: 'left', visible: true },
  { id: 'categories', label: 'Categories', align: 'left', visible: true },
  {
    id: 'issues',
    type: 'string',
    label: 'Incomplete',
    align: 'left',
    visible: true,
  },
  {
    id: 'exclude',
    type: 'checkbox',
    label: 'Exclude',
    visible: true,
  },
];
