import { useEffect, useState } from 'react';
import { Card, Container, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useSelector } from '@redux/store';
import PATHS from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import { OrganizationSchema, OrganizationSchemaForm } from '@/schemas';
import {
  CountryList,
  CurrencyList,
  FormatDateList,
  FormatTimeList,
  LanguageList,
  StateList,
} from '@typedefs/org';

import Page from '@components/Page';
// import HomeHeader from '../Home/HomeHeader';
import { steps } from './steps/config';

// ----------------------------------------------------------------------
export default function OrganizationCreate() {
  const { createOrg } = useOrg();
  const navigate = useNavigate();

  const { orgs } = useSelector((s) => s.org);

  const [newOrgId, setNewOrgId] = useState<string>();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [data, setData] = useState<OrganizationSchemaForm>({
    name: '',
    registrationNumber: '',
    phone: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: StateList[CountryList[0].value][0],
      postalCode: '',
      country: CountryList[0],
    },
    currency: CurrencyList[0],
    language: LanguageList[0],
    dateFormat: FormatDateList[0],
    timeFormat: FormatTimeList[0],
    signatoryName: '',
    signatoryPosition: '',
    religiousBenefit: false,
  });

  const isStepOptional = (step: number) => steps[step].optional;
  const isStepSkipped = (step: number) => skipped.has(step);

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(-1);
    } else {
      setErrors([]);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleNext = (stepData?: Partial<OrganizationSchemaForm>, dontMove = false) => {
    if (stepData) {
      setData({ ...data, ...stepData });
    }

    if (dontMove) return;

    // last step next
    if (activeStep === steps.length - 1) {
      onSubmit();
      return;
    }

    // otherwise continue
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      // Validate organization data
      await OrganizationSchema.validate(data, { abortEarly: false });
      if (!data.currency || !data.language || !data.dateFormat || !data.timeFormat) {
        setErrors(['Missing required data!']);
        return;
      }

      // Create the new organization
      const newOrg = await createOrg({
        ...data,
        currency: data.currency.value,
        language: data.language.value,
        dateFormat: data.dateFormat.value,
        timeFormat: data.timeFormat.value,
        address: {
          ...data.address,
          state: data.address.state.value,
          country: data.address.country.value,
        },
        signatoryName: data.signatoryName,
        signatoryPosition: data.signatoryPosition,
        religiousBenefit: data.religiousBenefit,
      });

      // Set errors state to indicate pending organization creation
      setErrors(['Pending organization creation...']);

      setNewOrgId(newOrg.id);
    } catch (e) {
      // Handle validation errors or any other errors
      console.error(e);
      setErrors(e.errors || ['An error occurred during organization creation']);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!newOrgId) return;
    let timerId: any;

    // Set a timer to navigate after 5 seconds if orgs don't change
    timerId = setTimeout(() => {
      //navigate to main root and orgs guard will insert the old org id
      navigate(PATHS.org.root);
      setNewOrgId(undefined);
    }, 5000);

    // Check if orgs state changes and clear the timer if it does
    if (orgs.some((org) => org.id === newOrgId)) {
      clearTimeout(timerId);
      navigate(PATHS.org.root, { orgId: newOrgId });
      setNewOrgId(undefined);
    }

    // Cleanup function to clear the timer if orgs change before 5 seconds
    return () => {
      clearTimeout(timerId);
    };
  }, [orgs, newOrgId, navigate, setNewOrgId]);

  const stepProps = {
    data,
    errors,
    isSubmitting,
    isOptional: false,
    onBack: handleBack,
    onSkip: handleSkip,
    onNext: handleNext,
  };

  return (
    <Page
      title="Create Organization"
      sx={{
        background: (t) =>
          (t.components?.MuiBackdrop?.styleOverrides?.root as any)?.background || 'inherit',
      }}
    >
      {/* <Outlet />
        <HomeHeader /> */}

      <Container
        maxWidth="lg"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h4" color="common.white" mb={3}>
          Create your organization in a few quick steps
        </Typography>

        <Stack spacing={2} width="100%" justifyContent="center" flexDirection="column">
          <Card sx={{ p: 1.5, width: '100%' }}>
            <Stepper activeStep={activeStep} sx={{ px: 1 }}>
              {steps.map((step, index) => (
                <Step key={step.title} completed={isStepSkipped(index) ? false : undefined}>
                  <StepLabel
                    optional={step.optional && <Typography variant="caption">Optional</Typography>}
                  >
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Card>

          <Card
            variant="outlined"
            // height 564px = 500px base + 32px (p: 3) x2
            sx={{
              p: 2,
              display: 'flex',
              width: '100%',
              minHeight: 564,
              '& > form': {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto !important',
                justifyContent: 'space-between',
              },
            }}
          >
            {steps.map(
              (step, index) =>
                activeStep === index && (
                  <step.Component key={index} {...stepProps} isOptional={isStepOptional(index)} />
                )
            )}
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
