import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

// ----------------------------------------------------------------------
type Props = {
  readonly onConfirm: MouseEventHandler<HTMLButtonElement>;
  readonly contentText?: string;
};

// ----------------------------------------------------------------------
export default function ConflictDialog({ onConfirm, contentText }: Props) {
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter') {
      onConfirm(e as any);
    }
  };

  return (
    <Dialog open={!!contentText} maxWidth="sm" onKeyDown={handleKeyDown}>
      <DialogTitle>Data conflict</DialogTitle>

      <DialogContent dividers>
        <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
          {contentText}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Stack spacing={2} direction="row">
          <Button type="submit" size="large" variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
