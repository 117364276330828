// import useDonation from '@hooks/useDonation';

import { Stack, Typography } from '@mui/material';
import { TStepProps } from './useSteps';
import useDonation from '@hooks/useDonation';
import { ImportEntity } from '@shared/types/importData';
import OneList from './OneList';
import { useEffect, useState } from 'react';

// common parts of types of categories, tags and paymentMethods
export type ListObject = {
  id: string;
  name: string;
  importAliases?: string[];
};

// ----------------------------------------------------------------------
export default function StepLists({ stepData, updateStepData, setIsValid }: TStepProps) {
  const { categories, paymentMethods, tags } = useDonation();
  const { categoriesMap, tagsMap, paymentMethodsMap } = stepData;

  const [categoryErrors, setCategoryErrors] = useState<string[]>([]);
  const [tagErrors, setTagErrors] = useState<string[]>([]);
  const [pmErrors, setPMErrors] = useState<string[]>([]);

  useEffect(() => {
    setIsValid(categoryErrors.length + tagErrors.length + pmErrors.length === 0);
  }, [categoryErrors.length, pmErrors.length, setIsValid, tagErrors.length]);

  return (
    <Stack>
      <Typography variant="subtitle1" sx={{ pb: 1 }}>For each Imported Name, select the Existing Name it should map to (or Create New)</Typography>
      {categoriesMap.filter((c) => c.isNew).length > 0 && (
        <OneList
          name="Category"
          pluralName="Categories"
          myErrors={categoryErrors}
          setMyErrors={setCategoryErrors}
          newObject={{
            id: '',
            name: '<Create New Category>',
          }}
          objectList={categories as ListObject[]}
          objectsMap={categoriesMap}
          updateStepDataMap={(newMap: ImportEntity[]) => updateStepData({ categoriesMap: newMap })}
        />
      )}
      {tagsMap.filter((t) => t.isNew).length > 0 && (
        <OneList
          name="Tag"
          pluralName="Tags"
          myErrors={tagErrors}
          setMyErrors={setTagErrors}
          newObject={{
            id: '',
            name: '<Create New Tag>',
          }}
          objectList={tags as ListObject[]}
          objectsMap={tagsMap}
          updateStepDataMap={(newMap: ImportEntity[]) => updateStepData({ tagsMap: newMap })}
        />
      )}
      {paymentMethodsMap.filter((pm) => pm.isNew).length > 0 && (
        <OneList
          name="Payment Method"
          pluralName="Payment Methods"
          myErrors={pmErrors}
          setMyErrors={setPMErrors}
          newObject={{
            id: '',
            name: '<Create New Payment Method>',
          }}
          objectList={paymentMethods as ListObject[]}
          objectsMap={paymentMethodsMap}
          updateStepDataMap={(newMap: ImportEntity[]) =>
            updateStepData({ paymentMethodsMap: newMap })
          }
        />
      )}
    </Stack>
  );
}
