// Functions for splitting data in joint fields like firstLast, cityState etc.
// Also some validation functions

import { ImportData } from '@shared/types';
import { ImportDonor } from '@shared/types/importData';
import { AllCountryList } from '@utils/allCountryList';
import { AllProvincesList, AllStatesList, AllStatesProvsList } from '@utils/allStatesProvs';

// add in abbreviations for and full version of United States of America to the
// AllCountryList (it already contains a row with label 'United States')
const CountryList = [
  { value: 'us', label: 'USA' },
  { value: 'us', label: 'U.S.A.' },
  { value: 'us', label: 'United States of America' },
  ...AllCountryList,
];

// Return strings for first and last split out of firstLast, at the last space
export const splitFirstLast = (firstLast: string): [string, string] => {
  const lastSpace = firstLast.lastIndexOf(' ');
  if (lastSpace >= 0) {
    return [firstLast.substring(0, lastSpace), firstLast.substring(lastSpace + 1)];
  } else {
    return ['', firstLast];
  }
};

// Return strings for first and last split out of lastFirst, at the last comma
export const splitLastFirst = (lastFirst: string): [string, string] => {
  const lastComma = lastFirst.lastIndexOf(',');
  if (lastComma >= 0) {
    return [lastFirst.substring(lastComma + 1).trim(), lastFirst.substring(0, lastComma).trim()];
  } else {
    return ['', lastFirst];
  }
};

// Return the equivalent of useFormat.fFullName for an ImportDonor
export const donorFullName = (donor: Partial<ImportData.ImportDonor>): string => {
  if (donor.organization) return donor.organization;
  else return `${donor.firstName} ${donor.lastName}`.trim();
};

// Make sure that a country field is (or is converted to) a country code
export const fixCountry = (country: string): string => {
  country = country.toLowerCase();
  // search for either the country code or the entire full name
  let found = CountryList.find((c) => c.value === country || c.label.toLowerCase() === country);
  if (!found) {
    // if that didn't work, search for the country name being part of a full name
    found = CountryList.find((c) => c.label.toLowerCase().indexOf(country) >= 0);
  }
  return found ? found.value : '';
};

// Return the abbreviation for a state or province if a full name or version
// with periods (like B.C. or N.Y.) is provided.
export const fixState = (state: string): string => {
  const lower = state.toLowerCase();
  // search for either the state code or the entire full name
  let found = AllStatesProvsList.find((s) => {
    const valueWithPeriods = ` ${s.value.charAt(0)}.${s.value.charAt(1)}.`;
    return s.value === lower || valueWithPeriods === lower || s.label.toLowerCase() === lower;
  });
  return found ? found.value.toUpperCase() : state;
};

// Return a country code from a state code if one matches; otherwise undefined
export const countryFromState = (state: string) => {
  const lower = state.toLowerCase();
  if (AllStatesList.find((s) => s.value === lower)) return 'us';
  if (AllProvincesList.find((p) => p.value === lower)) return 'ca';
  return undefined;
};
// Return strings for city and state split out of cityState
export const splitCityState = (cityState: string): [string, string] => {
  // first get rid of commas and multiple spaces in the input, and make
  // a lower-case version for easy comparisons
  cityState = cityState.replace(/,/g, ' ').replace(/\s+/g, ' ');
  const lower = cityState.toLowerCase();

  // search for either the state/prov code (or same with periods, like B.C. or N.Y.)
  //  or name at the end of the input
  for (const s of AllStatesProvsList) {
    if (lower.endsWith(` ${s.value}`)) {
      const pos = cityState.length - 2;
      return [cityState.substring(0, pos).trim(), s.value.toUpperCase()];
    }
    const valueWithPeriods = ` ${s.value.charAt(0)}.${s.value.charAt(1)}.`;
    if (lower.endsWith(valueWithPeriods)) {
      const pos = cityState.length - 4;
      return [cityState.substring(0, pos).trim(), s.value.toUpperCase()];
    }
    if (lower.endsWith(s.label.toLowerCase())) {
      const pos = cityState.length - s.label.length;
      return [cityState.substring(0, pos).trim(), s.value.toUpperCase()];
    }
  }
  // if that didn't work, assume it's all the city
  return [cityState, ''];
};

// Return strings for city, state and country split out of cityStateCountry
export const splitCityStateCountry = (cityStateCountry: string): [string, string, string] => {
  // first get rid of commas and multiple spaces in the input, and make
  // a lower-case version for easy comparisons
  cityStateCountry = cityStateCountry.replace(/,/g, ' ').replace(/\s+/g, ' ');
  const lower = cityStateCountry.toLowerCase();

  // look for the country at the end of the string
  for (const c of CountryList) {
    if (lower.endsWith(` ${c.value}`)) {
      const pos = cityStateCountry.length - 2;
      return [...splitCityState(cityStateCountry.substring(0, pos).trim()), c.value.toLowerCase()];
    }
    if (lower.endsWith(c.label.toLowerCase())) {
      const pos = cityStateCountry.length - c.label.length;
      return [...splitCityState(cityStateCountry.substring(0, pos).trim()), c.value.toLowerCase()];
    }
  }
  // no country, just split the city and state
  return [...splitCityState(cityStateCountry), ''];
};

// Determine whether value on row i of the input is a valid amount value (number > 0)
// for fieldName, if not push an error message onto errors and return 0.
// Return the numeric value.
export const validateInputAmount = (
  value: string,
  rowNum: number,
  fieldName: string,
  errors: string[]
): number => {
  // get rid of dollar signs and commas to avoid parsing problems
  const fixedValue = value.replace(/\$/g, '').replace(/,/g, '');
  const n = parseFloat(fixedValue);
  if (isNaN(n)) {
    errors.push(
      `Line ${rowNum + 1} of the input contains the value "${value}" for the ${fieldName}, which cannot be converted to a number.`
    );
    return 0;
  }
  if (n <= 0) {
    errors.push(
      `Line ${rowNum + 1} of the input contains the value ${value} for the ${fieldName}, which is less than or equal to 0.`
    );
    return 0;
  }
  return n;
};

// Check whether a value on row rowNum of the data for fieldName is longer than maxLength.
// If it is, add an appropriate warning.
// Sample call: validateLength(value, rowNum, 'First Name', 40, warnings);
export const validateLength = (
  value: string,
  rowNum: number,
  fieldName: string,
  maxLength: number,
  warnings: string[]
) => {
  if (value.length > maxLength)
    warnings.push(
      `The ${fieldName} "${value}" on line ${rowNum} is longer than is normally allowed. If it has to be edited in the future it will have a maximum length of ${maxLength} characters.`
    );
};

// Note: the following method is required because if I use for instance
//       donor.tagIds.push(tagMap.id) I get the error
//       "TypeError: Cannot add property 0, object is not extensible"
export const addToDonorArray = (
  donor: Partial<ImportDonor>,
  fieldName: 'tagIds' | 'tagNames',
  value: string,
  caseSensitive: boolean
) => {
  const arr = donor[fieldName]?.slice(0) || [];
  if (
    !(caseSensitive
      ? arr.find((a) => value === a)
      : arr.find((a) => value.toLowerCase() === a.toLowerCase()))
  ) {
    arr.push(value);
  }
  donor[fieldName] = arr;
};
