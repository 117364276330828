import { enUS } from '@mui/material/locale';
import { Country } from '@shared/types';

// ----------------------------------------------------------------------
export const allLangs = [
  {
    label: 'English (US)',
    value: 'en',
    systemValue: enUS,
    icon: '/flags/ic_flag_en.svg',
    relatedCountries: [Country.us],
  },
  {
    label: 'English (CA)',
    value: 'en-GB',
    systemValue: enUS,
    icon: '/flags/ic_flag_en.svg',
    relatedCountries: [Country.ca],
  },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: '/assets/icons/flags/ic_flag_fr.svg',
  // },
];

export const defaultLang = allLangs[0]; // English
