import {
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  Box,
  TableRow,
  TableCell,
  Typography,
  Stack,
} from '@mui/material';

import PATHS from '@routes/paths';
import { TColumn } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';
import useTable, { emptyRows } from '@hooks/useTable';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import useNavigate from '@hooks/useNavigate';

import { TableNoData, TableEmptyRows, TableHeadCustom } from '@components/table';
import Scrollbar from '@components/Scrollbar';
import { orderBy as lodashOrderBy } from 'lodash';

// ----------------------------------------------------------------------
const TABLE_HEAD: TColumn[] = [
  { id: 'dateStart', type: 'date', label: 'Start', align: 'left', visible: true },
  { id: 'dateEnd', type: 'date', label: 'End', align: 'left', visible: true },
  { id: 'category', label: 'Category', align: 'left', visible: true },
  { id: 'amount', type: 'number', label: 'Pledge Amount', align: 'right', visible: true },
  { id: 'donationsTotal', type: 'number', label: 'Actual Amount', align: 'right', visible: true },
];

// ----------------------------------------------------------------------
type Props = {
  donor: TDonorWithDonations;
};
// ----------------------------------------------------------------------
export default function DonorPledgeList({ donor }: Props) {
  const navigate = useNavigate();
  const { getPledgesForDonor, getPledgeCategory } = useDonation();
  const { fDate, fCurrency } = useFormat();
  const { columns, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 5,
    defaultColumns: TABLE_HEAD,
    defaultOrderBy: 'dateStart',
    defaultOrder: 'desc',
  });
  const pledges = getPledgesForDonor(donor);

  const handlePledgeClick = (pledgeId: string) => {
    navigate(PATHS.org.donors.donor.pledge.edit, {
      pledgeId: pledgeId,
    });
  };

  return (
    <>
      <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
        <Scrollbar>
          <Table stickyHeader size="small">
            <TableHeadCustom
              // order={order}
              // orderBy={orderBy}
              columns={columns}
              rowCount={pledges.length}
              // onSort={onSort}
            />

            <TableBody>
              {lodashOrderBy(pledges, 'dateStart', 'desc')
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((pledge) => (
                  <TableRow
                    key={pledge.id}
                    hover
                    sx={{ cursor: 'pointer', height: 44 }}
                    onClick={() => handlePledgeClick(pledge.id)}
                  >
                    <TableCell align="left">{fDate(pledge.dateStart)}</TableCell>
                    <TableCell align="left">{fDate(pledge.dateEnd)}</TableCell>
                    <TableCell align="left">
                      <Tooltip
                        title={
                          getPledgeCategory(pledge)?.description ||
                          (pledge.categoryId === '' &&
                            'Donations in all categories will satisfy this pledge')
                        }
                        placement="top"
                        enterDelay={600}
                      >
                        <Stack direction="row" alignItems="center">
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              width: 16,
                              height: 16,
                              margin: 0.5,
                              marginRight: 1,
                              borderRadius: '50%',
                              background: getPledgeCategory(pledge)?.colorBackground || undefined,
                            }}
                          />
                          <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(14) }}>
                            {getPledgeCategory(pledge)?.name || '(All Categories)'}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">{fCurrency(pledge.amount)}</TableCell>
                    <TableCell align="right">{fCurrency(pledge.donationsTotal)}</TableCell>
                  </TableRow>
                ))}

              <TableEmptyRows
                height={44}
                emptyRows={emptyRows(page, rowsPerPage, pledges.length)}
              />

              {/* change this isNotFound */}
              <TableNoData isNotFound={!pledges.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={pledges.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
