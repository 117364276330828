import { useCallback, useState } from 'react';
import { isValid, startOfDay, endOfDay } from 'date-fns';
import { TColumn, TColumnFilter } from '@typedefs/app';

// ----------------------------------------------------------------------
type State<T> = {
  order?: 'asc' | 'desc';
  orderBy?: T;
  rowsPerPage?: number;
  page?: number;
  columns?: TColumn<T>[];
  dateFrom?: Date;
  dateTo?: Date;
  filters?: TColumnFilter[];
};

type Props<T> = {
  defaultOrder?: 'asc' | 'desc';
  defaultOrderBy?: T | string;
  defaultSelected?: string[];
  defaultRowsPerPage?: number;
  defaultCurrentPage?: number;
  defaultColumns?: TColumn<T>[];
  defaultDateFrom?: Date;
  defaultDateTo?: Date;
  defaultFilters?: TColumnFilter[];
};

// ----------------------------------------------------------------------
export default function useTable<T>(props?: Props<T>, key?: string) {
  let persisted: State<T> | undefined = undefined;

  if (key && !persisted) {
    // read state from localStore
    persisted = persistorGet(key, '-state');
  }

  // -----------------------------------
  // assignments
  const [orderBy, setOrderBy] = useState<T>(
    (persisted?.orderBy || props?.defaultOrderBy || 'name') as T
  );
  const [order, setOrder] = useState<'asc' | 'desc'>(
    persisted?.order || props?.defaultOrder || 'asc'
  );
  const [page, setPage] = useState(persisted?.page || props?.defaultCurrentPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    persisted?.rowsPerPage || props?.defaultRowsPerPage || 5
  );
  const [selected, setSelected] = useState<string[]>(props?.defaultSelected || []);
  const [columns, setColumns] = useState<TColumn<T>[]>(
    ((persisted?.columns &&
      persisted?.columns.length === props?.defaultColumns?.length &&
      persisted?.columns) ||
      props?.defaultColumns ||
      []) as TColumn<T>[]
  );
  const [dateFrom, _setDateFrom] = useState<Date | undefined>(
    persisted?.dateFrom || props?.defaultDateFrom
  );
  const [dateTo, _setDateTo] = useState<Date | undefined>(
    persisted?.dateTo || props?.defaultDateTo
  );
  const [filters, setFilters] = useState<TColumnFilter[]>(
    persisted?.filters || props?.defaultFilters || []
  );

  // -----------------------------------
  // ops
  const onSort = (id: T, overrideOver?: 'asc' | 'desc') => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(overrideOver || isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const onSelectRow = (id: string) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllRows = (checked: boolean, newSelecteds: string[]) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setDateFrom = useCallback(
    (date?: Date) => _setDateFrom(date ? startOfDay(date) : date),
    [_setDateFrom]
  );
  const setDateTo = useCallback(
    (date?: Date) => _setDateTo(date ? endOfDay(date) : date),
    [_setDateTo]
  );

  // -----------------------------------
  // persistance of state
  if (key) {
    persistorSet(key, '-state', {
      order,
      orderBy,
      page,
      rowsPerPage,
      columns,
      dateFrom,
      dateTo,
      filters,
    });
  }

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
    //
    columns,
    setColumns,
    //
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    //
    filters,
    setFilters,
  };
}

// ----------------------------------------------------------------------
export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

// ----------------------------------------------------------------------
function persistorSet<T>(key: string, sub: string, cache: State<T>) {
  localStorage.setItem(key + sub, JSON.stringify(cache));
}
function persistorGet<T>(key: string, sub: string): State<T> | undefined {
  const raw = localStorage.getItem(key + sub);
  const res: State<T> | undefined = raw ? JSON.parse(raw) : undefined;
  if (res?.dateFrom && isValid(new Date(res.dateFrom))) {
    res.dateFrom = new Date(res.dateFrom);
  } else {
    delete res?.dateFrom;
  }
  if (res?.dateTo && isValid(new Date(res.dateTo))) {
    res.dateTo = new Date(res.dateTo);
  } else {
    delete res?.dateTo;
  }
  return res;
}
