import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import PATHS from '@routes/paths';

// ----------------------------------------------------------------------
export default function Logout() {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, [logout]);

  return <Navigate to={PATHS.root} />;
}
