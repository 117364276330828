import { useCallback, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Checkbox,
  Tooltip,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useFieldArray, useForm } from 'react-hook-form';
import { filter, isArray, last, sortBy } from 'lodash';
import { addYears, subYears } from 'date-fns';

import PATHS from '@routes/paths';
import { Category, Donation, Donor, Organization } from '@shared/types';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import useFormat from '@hooks/useFormat';
import useRole from '@hooks/useRole';
import useLocales from '@hooks/useLocales';

import PaywallDonorGuard from '@/guards/PaywallDonorGuard';
import { renderCategoryOption } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import CategoryDialog from '@pages/donations/dialogs/Settings/DonationCategories/Dialog';
import DonorDialog from '@pages/donors/dialogs/Donor';
import { donationBatchSchemaResolver, DonationBatchSchemaForm } from '@/schemas';
import { RHFAutocomplete, RHFDatePicker, RHFTextField } from '@components/hook-form';
import Dialog from '@components/Dialog';
import Scrollbar from '@components/Scrollbar';
import Iconify from '@components/Iconify';
import { renderPaymentMethodOption } from '@pages/donations/dialogs/Settings/PaymentMethods/utils/renderPaymentMethods';
import TotalAmount from './TotalAmount';
import ConfirmDialog from '@components/ConfirmDialog';
import { DonationBatchUpdateForm } from '@shared/types/donation';

// ----------------------------------------------------------------------
export default function DonationBatchDialog() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { fReversedName, fDateToISO } = useFormat();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const {
    categories,
    getDonationsByDate,
    getDupDonorTooltip,
    donors,
    createBatchDonation,
    paymentMethods,
    getCategoryById,
    getPaymentMethodById,
  } = useDonation();
  const { t } = useLocales();
  const [createCategoryOpen, setCreateCategoryOpen] = useState(-1);
  const [createDonorOpen, setCreateDonorOpen] = useState(-1);
  const [showExisting, setShowExisting] = useState(false);
  const [isConfirmingLeave, setIsConfirmingLeave] = useState(false);

  const canEdit = hasAccess([Organization.Role.editor, Organization.Role.contributor]);
  const defaultValues: DonationBatchSchemaForm = useMemo(
    () => ({
      date: new Date(),
      items: [
        {
          donor: null,
          amount: 0,
          category: getCategoryById(org?.defaultCategoryId) || null,
          paymentMethod: getPaymentMethodById(org?.defaultPaymentMethodId) || null,
          paymentInfo: '',
        },
      ],
    }),
    [getCategoryById, getPaymentMethodById, org?.defaultCategoryId, org?.defaultPaymentMethodId]
  );

  const paymentMethodsNoGIK = useMemo(
    () =>
      sortBy(
        filter(paymentMethods, (p) => p.type !== Donation.PaymentMethodInitialList.giftInKind),
        (p) => p.name.toUpperCase()
      ),
    [paymentMethods]
  );

  // --------------- form ---------------
  const methods = useForm<DonationBatchSchemaForm>({
    resolver: donationBatchSchemaResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    control,
    watch,
    setError,
    setFocus,
    handleSubmit,
    clearErrors,
    formState: { errors, isDirty, isSubmitting },
  } = methods;
  const { fields, append, remove, update, insert } = useFieldArray({
    control,
    name: 'items',
  });
  const watchedYear = watch('date').getFullYear();
  const watchedItems = watch('items');
  const controlledItems = fields.map((field, index) => ({
    ...field,
    ...watchedItems[index],
  }));

  // --------------- routing ---------------
  const handleClose = useCallback(() => {
    navigate(PATHS.org.donations.root);
  }, [navigate]);

  // --------------- effects ---------------
  const formDisabled = false;
  const donorList = useMemo(
    () =>
      sortBy(
        donors.map((d) => ({ ...d, name: fReversedName(d, watchedYear) })),
        'name'
      ),
    [fReversedName, donors, watchedYear]
  );

  // --------------- actions ---------------
  const handleDonorCreate = (newDonor?: Donor.Donor) => {
    if (newDonor) {
      update(createDonorOpen, { ...watchedItems[createDonorOpen], donor: newDonor });
      clearErrors(`items.${createDonorOpen}`);
    }
    setCreateDonorOpen(-1);
  };

  const handleCategoryCreate = (newCategory?: Category.Category) => {
    if (newCategory) {
      // delay is required due to Form re-render
      update(createCategoryOpen, { ...watchedItems[createCategoryOpen], category: newCategory });
      clearErrors(`items.${createCategoryOpen}`);
    }
    setCreateCategoryOpen(-1);
  };

  const handleAddNew = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    addNew();
  };

  const addNew = (donationToAdd: Partial<Donation.DonationBatchUpdateForm> = {}) => {
    const defaultDonation: Donation.DonationBatchUpdateForm = {
      donor: null,
      amount: 0,
      category: last(watchedItems)?.category || null,
      paymentMethod: getPaymentMethodById(org?.defaultPaymentMethodId) || null,
      paymentInfo: '',
      isExisting: donationToAdd.isExisting || false,
      id: donationToAdd.id,
    };

    const donationToAppend: any = {
      ...defaultDonation,
      ...donationToAdd,
    };

    append(donationToAppend);
    setFocus(`items.${fields.length}`, { shouldSelect: true });
  };

  const handleRemove = (i: number) => {
    remove(i);
  };

  const handleFocus = (e: React.FocusEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // if last record is correct (and at least donor selected), add new row
    if (last(watchedItems)?.donor) {
      addNew();
    }
  };

  const searchDonorById = (donorId?: string): any | null => {
    const donor = donorList.find((donorItem) => donorItem.id === donorId);
    return donor || null;
  };

  const handleRemoveSavedDonations = () => {
    for (let i = controlledItems.length - 1; i >= 0; i--) {
      if (controlledItems[i].isExisting) {
        remove(i);
      }
    }
    setShowExisting(false);
    setIsConfirmingLeave(false);
  };

  const handleShowExisting = () => {
    const { date } = control._formValues;

    if (!showExisting) {
      const paymentMethodGiftInKindId = paymentMethods.find(
        (p) => p.type === Donation.PaymentMethodInitialList.giftInKind
      )?.id;

      const savedDonationsByDate = getDonationsByDate(new Date(date)).filter(
        (donation) =>
          donation.paymentMethodId !== paymentMethodGiftInKindId &&
          !donation.withAdvantage &&
          (donation.receiptIds?.length === 0 || !donation.receiptIds)
      );

      savedDonationsByDate.forEach((donation, index) => {
        const donor = searchDonorById(donation.donorId);
        const category = getCategoryById(donation.categoryId);
        const paymentMethod = getPaymentMethodById(donation.paymentMethodId);

        insert(index, {
          donor: donor,
          amount: donation.amount,
          category: category || null,
          paymentMethod: paymentMethod || null,
          paymentInfo: donation.paymentInfo,
          isExisting: true,
          id: donation.id || null,
        });
      });

      setShowExisting(!showExisting);
      return;
    }

    if (hasUpdatedDonation()) {
      setIsConfirmingLeave(true);
      return;
    }
    setShowExisting(false);
  };

  const onSubmit = useCallback(
    async (dataBatchSchemaForm: DonationBatchSchemaForm) => {
      if (!org) {
        setError('afterSubmit', { message: 'Missing organization' });
        return;
      }
      if (!canEdit) {
        setError('afterSubmit', { message: 'You lack editing permissions' });
        return;
      }

      const removeDonationNotUpdated = (data: DonationBatchSchemaForm): DonationBatchSchemaForm => {
        const { date } = control._formValues;
        const savedDonationsByDate = getDonationsByDate(date.toDateString());

        const isDonationEqual = (
          donation: DonationBatchUpdateForm,
          savedDonation: Donation.Donation
        ) =>
          donation.amount === savedDonation.amount &&
          donation.donor?.id === savedDonation.donorId &&
          donation.category?.id === savedDonation.categoryId &&
          donation.paymentMethod?.id === savedDonation.paymentMethodId &&
          donation.paymentInfo === savedDonation.paymentInfo;

        const filteredDonationToUpdate = data.items.filter((donation) => {
          const savedDonation = savedDonationsByDate.find((it) => it.id === donation.id);
          return !savedDonation || !isDonationEqual(donation, savedDonation);
        });

        return {
          ...data,
          items: filteredDonationToUpdate,
        };
      };

      const data = removeDonationNotUpdated(dataBatchSchemaForm);

      try {
        await createBatchDonation({
          orgId: org.id,
          date: fDateToISO(data.date),
          donations: data.items.map((item) => ({
            id: item.id || undefined,
            donorId: item.donor!.id,
            categoryId: item.category!.id,
            amount: Number(item.amount),
            paymentMethodId: item.paymentMethod?.id,
            paymentInfo: item.paymentInfo || '',
          })),
        });

        handleClose();
        enqueueSnackbar(`${data.items.length} donations created!`);
      } catch (e) {
        console.error(e);
        setError('afterSubmit', { message: e.message });
      }
    },
    [
      enqueueSnackbar,
      fDateToISO,
      createBatchDonation,
      setError,
      handleClose,
      getDonationsByDate,
      org,
      canEdit,
      control,
    ]
  );

  const hasUpdatedDonation = () => {
    const { items } = control._formValues;
    return items.some((item: any) => item.isExisting === true);
  };

  return (
    <Dialog
      title="Add Donation Batch"
      onClose={handleClose}
      maxWidth="md"
      isDirty={isDirty}
      isLoading={isSubmitting}
      methods={methods}
      sx={{
        '& .MuiDialogContent-root': {
          minHeight: '60vh',
          maxHeight: '60vh',
        },
        '& form': {
          flex: '1 1 auto !important',
        },
      }}
      actions={
        <Stack width="100%" direction="row" justifyContent="flex-end">
          <LoadingButton
            size="large"
            variant="contained"
            loading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Save {watchedItems.length} Donations
          </LoadingButton>
        </Stack>
      }
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Tooltip
              title={
                showExisting && 'The date is locked when including all entries on a specific date'
              }
            >
              <Stack width={240}>
                <RHFDatePicker
                  required
                  size="small"
                  name="date"
                  label="Date"
                  disabled={formDisabled || showExisting}
                  minDate={subYears(new Date(), 10)}
                  maxDate={addYears(new Date(), 1)}
                />
              </Stack>
            </Tooltip>
            <Box display="flex" alignItems="center" paddingLeft={1}>
              <FormControlLabel
                control={<Checkbox checked={showExisting} onChange={handleShowExisting} />}
                label="Include all entries on date"
              />
            </Box>
          </Stack>

          <TotalAmount control={control} />
        </Stack>

        <Card variant="outlined" sx={{ px: 1 }}>
          {createDonorOpen > -1 && (
            <PaywallDonorGuard onLimitClose={handleDonorCreate}>
              <DonorDialog context="donations" onClose={handleDonorCreate} />
            </PaywallDonorGuard>
          )}
          <CategoryDialog open={createCategoryOpen > -1} onClose={handleCategoryCreate} />

          <Scrollbar>
            <Stack sx={{ py: 1, '& .MuiFormHelperText-root': { marginTop: '-2px' } }}>
              {fields.map((item, i) => (
                <Grid
                  key={item.id}
                  container
                  spacing={1}
                  columnSpacing={2}
                  sx={{ marginBottom: '20px' }}
                >
                  <Grid item xs={4} md={3}>
                    <RHFAutocomplete
                      autoFocus
                      name={`items.${i}.donor`}
                      label="Donor"
                      required
                      fullWidth
                      size="small"
                      freeSolo={false}
                      disabled={formDisabled}
                      options={[
                        ...donorList,
                        { id: 'create-new', name: '', memberNumbers: {} } as any,
                      ]}
                      getOptionLabel={(donor) => donor.name || fReversedName(donor, watchedYear)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option) =>
                        option.id === 'create-new' ? (
                          <li key="create-new" {...props}>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ fontWeight: 700, color: (theme) => theme.palette.primary.dark }}
                            >
                              <Iconify icon="icons8:plus" width={20} height={20} />
                              <Typography variant="body2">Create New Donor</Typography>
                            </Stack>
                          </li>
                        ) : (
                          <li {...props} key={option.id}>
                            <Tooltip title={getDupDonorTooltip(option)}>
                              {option.name || fReversedName(option, watchedYear)}
                            </Tooltip>
                          </li>
                        )
                      }
                      beforeOnChange={(newValue, commitChange) => {
                        if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
                          setCreateDonorOpen(i);
                        } else {
                          commitChange();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <RHFAutocomplete
                      name={`items[${i}].category`}
                      label="Category"
                      required
                      fullWidth
                      size="small"
                      freeSolo={false}
                      disabled={formDisabled}
                      options={[
                        ...sortBy(categories, (c) => c.name.toUpperCase()),
                        { id: 'create-new', name: '', description: '' } as Category.Category,
                      ]}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={renderCategoryOption}
                      getOptionLabel={(option) => option.name}
                      beforeOnChange={(newValue, commitChange) => {
                        if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
                          setCreateCategoryOpen(i);
                        } else {
                          commitChange();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <RHFTextField
                      required
                      name={`items[${i}].amount`}
                      type="number"
                      label="Amount"
                      size="small"
                      disabled={formDisabled}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      // inputProps={{ maxLength: 6 }}
                      // sx={{ maxWidth: 100 }}
                    />
                  </Grid>
                  <Grid item xs={4} md={3} order={{ xs: 5, md: 4 }}>
                    <RHFAutocomplete
                      name={`items.${i}.paymentMethod`}
                      label="Payment Method"
                      fullWidth
                      size="small"
                      freeSolo={false}
                      disabled={formDisabled}
                      options={paymentMethodsNoGIK}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={renderPaymentMethodOption}
                    />
                  </Grid>
                  <Grid item xs={1} md={1} order={{ xs: 4, md: 5 }}>
                    {!controlledItems[i].isExisting && (
                      <IconButton
                        aria-label="close"
                        onClick={() => handleRemove(i)}
                        sx={{
                          width: 36,
                          height: 36,
                        }}
                        tabIndex={-1}
                      >
                        <Iconify icon="material-symbols:close" />
                      </IconButton>
                    )}
                  </Grid>
                  {controlledItems[i].paymentMethod?.type ===
                    Donation.PaymentMethodInitialList.check && (
                    <>
                      <Grid
                        item
                        xs={0}
                        md={8}
                        order={{ xs: 5, md: 6 }}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                      />
                      <Grid item xs={4} md={3} order={{ xs: 6, md: 7 }}>
                        <RHFTextField
                          name={`items[${i}].paymentInfo`}
                          size="small"
                          label={`${t('Check')} Number`}
                          disabled={formDisabled}
                          inputProps={{ maxLength: 60 }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </Stack>
          </Scrollbar>

          <Stack alignItems="flex-start" pb={1}>
            <Button
              /* Note: If the following is onClick instead of onMouseDown, a click can cause two rows to be added because both events happen!
            See first answer in https://stackoverflow.com/questions/57756002/react-prevent-onclick-firing-when-onfocus-fires */
              onMouseDown={handleAddNew}
              onFocus={handleFocus}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add new
            </Button>
          </Stack>
        </Card>
      </Stack>

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      <ConfirmDialog
        open={isConfirmingLeave}
        onClose={() => setIsConfirmingLeave(false)}
        onConfirm={handleRemoveSavedDonations}
        title="Remove Existing Donations?"
        textConfirmButton="Yes, hide existing donations"
        textCancelButton="No, go back"
        sizeButton="small"
        bgColorConfirm="error"
      >
        <Typography>
          This will hide all existing donations from view and you will lose any unsaved changes.
          Only newly added donations will remain shown.
        </Typography>
        <Typography sx={{ marginTop: 2 }}>Are you sure you want to continue?</Typography>
      </ConfirmDialog>
    </Dialog>
  );
}
