import { useCallback, useState } from 'react';
import { Button, Card, Divider, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Invitation, Organization } from '@shared/types';
import { RoleList } from '@typedefs/org';
import { User } from '@shared/types/user';
import useFormat from '@hooks/useFormat';

import useOrg from '@hooks/useOrg';
import Iconify from '@components/Iconify';
import PaywallUserGuard from '@/guards/PaywallUserGuard';
import InviteUserDialog from './InviteUserDialog';

// ----------------------------------------------------------------------
type Props = {
  readonly org: Organization.Organization;
  readonly users: User[];
  readonly invitations: Invitation.Invitation[];
  readonly onCreate: (inv: Invitation.Invitation) => void;
  readonly onCancel: (invId: string) => void;
};

// ----------------------------------------------------------------------
export default function Invitations({ org, users, invitations, onCreate, onCancel }: Props) {
  const { resolveInvitation } = useOrg();
  const { fToNow } = useFormat();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = (inv?: Invitation.Invitation) => {
    inv && onCreate(inv);
    setOpen(false);
  };

  const [isLoading, setLoading] = useState<string | undefined>();
  const handleResolveInvitation = useCallback(
    async (invitationId: string) => {
      setLoading(invitationId);
      try {
        await resolveInvitation({ invitationId, state: 'cancelled' });
        onCancel(invitationId);
      } catch (e) {
        console.error(e);
      }
      setLoading(undefined);
    },
    [resolveInvitation, onCancel]
  );

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Typography variant="h5">Pending Invitations</Typography>

        <Stack spacing={1} divider={<Divider sx={{ width: '60%', mx: 'auto !important' }} />}>
          {invitations
            .filter((inv) => inv.state === 'pending')
            .map((inv) => (
              <Stack
                key={inv.id}
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="column">
                  <Typography variant="h6">{inv.email}</Typography>

                  <Stack spacing={1} direction="row" alignItems="center">
                    <Typography variant="body2">
                      Role:{' '}
                      <strong>
                        {RoleList.find((p) => p.value === inv.role)?.label || 'Unknown'}
                      </strong>
                    </Typography>
                    <Typography>-</Typography>
                    <Typography variant="caption">{fToNow(inv._meta.createdAt)}</Typography>
                  </Stack>
                </Stack>

                <LoadingButton
                  loading={isLoading === inv.id}
                  onClick={() => handleResolveInvitation(inv.id)}
                >
                  Cancel
                </LoadingButton>
              </Stack>
            ))}
        </Stack>

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

        <Stack
          spacing={2}
          direction={{ xs: 'column-reverse', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent="flex-end"
        >
          <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
            Invite user
          </Button>
          <PaywallUserGuard
            open={open}
            handleClose={handleClose}
            userCount={users.length}
            inviteCount={invitations.length}
          >
            <InviteUserDialog
              org={org}
              users={users}
              invitations={invitations}
              open={open}
              handleClose={handleClose}
            />
          </PaywallUserGuard>
        </Stack>
      </Stack>
    </Card>
  );
}
