import { TableRow, TableCell, IconButton } from '@mui/material';

import { TColumn } from '@typedefs/app';
import Iconify from '@components/Iconify';
import type { ViewListHeader, ViewListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<ViewListHeader>[];
  readonly item: ViewListItem;
  readonly onClick: (id: string) => void;
  readonly onEdit: (id: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ item, onClick, onEdit, columns }: Props) {
  const handleEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onEdit(item.id);
  };

  const cells = columns
    .filter((c) => c.visible || ['options'].includes(c.id))
    .map((column) => {
      switch (column.id) {
        case 'options':
          return (
            <TableCell key={column.id} align="right">
              <IconButton
                size="small"
                color="inherit"
                onClick={handleEdit}
                sx={{ color: 'text.secondary', p: 1 }}
              >
                <Iconify icon={'mingcute:pencil-fill'} width={18} height={18} />
              </IconButton>
            </TableCell>
          );
        default:
          const value = item[column.id] || '';
          return (
            <TableCell
              key={column.id}
              align={column.align}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            />
          );
      }
    });

  return (
    <TableRow
      hover
      onClick={() => onClick(item.id)}
      sx={{ cursor: 'pointer', '& strong': { color: (theme) => theme.palette.secondary.light } }}
    >
      {cells}
    </TableRow>
  );
}
