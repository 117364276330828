import Scrollbar from '@components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import useTable, { emptyRows } from '@hooks/useTable';
import {
  Grid,
  TablePagination,
  TableContainer,
  Card,
  Table,
  TableBody,
  Box,
  Button,
} from '@mui/material';
import { recurringDonationsViewCacheId } from '@typedefs/donation';
import { RECURRING_DONATION_LIST_HEADERS, RecurringDonationListHeader } from './List/config';
import Row from './List/Row';
import useData from './List/useData';
import { useEffect } from 'react';
import { View } from '@shared/types';
import Iconify from '@components/Iconify';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';

type Props = { cachedView?: View.View; clearCache?: VoidFunction };

export default function RecurringDonationList({ cachedView, clearCache }: Props) {
  const {
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    setColumns,
  } = useTable<RecurringDonationListHeader>(
    {
      defaultRowsPerPage: 25,
      defaultColumns: RECURRING_DONATION_LIST_HEADERS,
      defaultOrderBy: 'name',
      defaultOrder: 'desc',
    },
    recurringDonationsViewCacheId
  );

  const data = useData({ order, orderBy });
  const navigate = useNavigate();
  const { org } = useOrg();

  useEffect(() => {
    if (cachedView && cachedView?.type === 'donation') {
      const columns = RECURRING_DONATION_LIST_HEADERS.map((h) => ({
        ...h,
        visible: cachedView.config.columnIds.includes(h.id),
      }));
      setColumns(columns);
      onSort(cachedView.config.orderBy as RecurringDonationListHeader, cachedView.config.order);
      clearCache?.();
    }
  }, [cachedView, onSort, setColumns, clearCache]);

  const handleAdd = () => {
    navigate(getPath(PATHS.org.donations.settings.recurringDonationsCreate, { orgId: org!.id }));
  };

  const handleEdit = (recurringDonationId: string) => {
    navigate(
      getPath(PATHS.org.donations.settings.recurringDonationsEdit, {
        orgId: org!.id,
        recurringDonationId,
      })
    );
  };

  return (
    <Box sx={{ paddingTop: 3 }}>
      <Grid item xs={10} md={12}>
        <Card>
          <TableContainer sx={{ minWidth: '100%', position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table stickyHeader size="small">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  columns={columns}
                  rowCount={data.length}
                  onSort={onSort as (id: string) => void}
                />

                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any) => (
                      <Row key={item.id} columns={columns} item={item} onEdit={handleEdit} />
                    ))}

                  <TableEmptyRows
                    height={52}
                    emptyRows={emptyRows(page, rowsPerPage, data.length)}
                  />
                  <TableNoData isNotFound={!data.length} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Grid>
      <Box display="flex" justifyContent="flex-end" paddingTop={1}>
        <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd}>
          New Recurring Donation
        </Button>
      </Box>
    </Box>
  );
}
