import { useForm } from 'react-hook-form';
import { Stack, Box } from '@mui/material';

import { FormProvider, RHFAutocomplete } from '@components/hook-form';
import {
  organizationPreferencesSchemaResolver,
  OrganizationPreferencesSchemaForm,
} from '@/schemas';
import { CurrencyList, FormatDateList, FormatTimeList, LanguageList } from '@typedefs/org';
import { TStepProps } from './config';
import StepActions from './StepActions';

// ----------------------------------------------------------------------
export default function StepPreferences({
  data,
  isSubmitting,
  isOptional,
  onBack,
  onSkip,
  onNext,
}: TStepProps) {
  const defaultValues: OrganizationPreferencesSchemaForm = {
    currency: data.currency,
    language: data.language,
    dateFormat: data.dateFormat,
    timeFormat: data.timeFormat,
  };

  const methods = useForm<OrganizationPreferencesSchemaForm>({
    resolver: organizationPreferencesSchemaResolver,
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: OrganizationPreferencesSchemaForm) => {
    const stepData = {
      currency: data.currency,
      language: data.language,
      dateFormat: data.dateFormat,
      timeFormat: data.timeFormat,
    };
    onNext(stepData);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={4}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box width="100%">
            <RHFAutocomplete
              required
              name="currency"
              label="Currency"
              fullWidth
              freeSolo={false}
              options={CurrencyList}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Box>
          <div style={{ width: '100%' }} />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box width="100%">
            <RHFAutocomplete
              required
              name="language"
              label="Language"
              fullWidth
              freeSolo={false}
              options={LanguageList}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Box>
          <div style={{ width: '100%' }} />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box width="100%">
            <RHFAutocomplete
              required
              name="dateFormat"
              label="Date format"
              fullWidth
              freeSolo={false}
              options={FormatDateList}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Box>
          <Box width="100%" />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box width="100%">
            <RHFAutocomplete
              required
              name="timeFormat"
              label="Time format"
              fullWidth
              freeSolo={false}
              options={FormatTimeList}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Box>
          <Box width="100%" />
        </Stack>
      </Stack>

      <StepActions
        isSubmitting={isSubmitting}
        errors={Object.values(errors).map((e) => e.message || '')}
        handleBack={onBack}
        handleSkip={isOptional ? onSkip : undefined}
      />
    </FormProvider>
  );
}
