import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------
export type ViewListItem = {
  id: string;
  type: 'donor' | 'donation';
  typeDisplay: string;
  name: string;
  description: string;
  createdBy: string;
  createdAt: Date;
  createdDisplay: string;
};

// ----------------------------------------------------------------------
export type ViewListHeader =
  | 'name'
  | 'description'
  | 'typeDisplay'
  | 'createdBy'
  | 'createdDisplay'
  | 'options';

// ----------------------------------------------------------------------
export const VIEW_LIST_HEADERS: TColumn<ViewListHeader>[] = [
  {
    id: 'name',
    type: 'string',
    label: 'Name',
    align: 'left',
    visible: true,
    searchable: true,
  },
  {
    id: 'description',
    type: 'string',
    label: 'Description',
    align: 'left',
    visible: true,
    searchable: true,
  },
  {
    id: 'typeDisplay',
    type: 'string',
    label: 'View Type',
    align: 'left',
    visible: true,
    searchable: true,
  },
  {
    id: 'createdBy',
    type: 'string',
    label: 'Created By',
    align: 'left',
    visible: true,
    searchable: true,
  },
  {
    id: 'createdDisplay',
    type: 'date',
    label: 'Created On',
    align: 'left',
    visible: true,
    searchable: true,
  },
  { id: 'options', label: '', align: 'left', visible: false, internal: true },
];
