import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Avatar, Box, Divider, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
export default function OrgSwitch({ isCollapse }: { isCollapse: boolean }) {
  const { orgs, org } = useOrg();
  const navigate = useNavigate();

  // popup
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = (orgId: string) => {
    if (orgId === org?.id) {
      handleClose();
      return;
    }

    navigate(getPath(PATHS.org.root, { orgId }), { replace: false });
  };
  const handleCreateNew = () => {
    navigate(PATHS.createOrganization);
  };

  return (
    <>
      <Tooltip title="Switch organization">
        <Box
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            mx: 2,
            borderRadius: 1,
            backgroundColor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            transition: (theme) =>
              theme.transitions.create('background', {
                duration: theme.transitions.duration.shorter,
              }),
            '&:hover': {
              cursor: 'pointer',
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            },
          }}
        >
          <Stack sx={{ height: 56, maxHeight: 56, overflow: 'hidden' }}>
            <Stack direction="row" alignItems="center" sx={{ p: 1.5 }}>
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                  mr: 1,
                  color: 'text.primary',
                  backgroundColor: 'common.white',
                }}
              >
                <Iconify icon="mdi:company" />
              </Avatar>

              <Typography
                variant="subtitle2"
                sx={{
                  flexShrink: 1,
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  transition: (theme) =>
                    theme.transitions.create(['width', 'opacity'], {
                      duration: theme.transitions.duration.shorter,
                    }),
                  ...(isCollapse && {
                    width: 0,
                    opacity: 0,
                  }),
                }}
              >
                {org?.name}
              </Typography>

              <Iconify
                icon="mdi:chevron-down"
                sx={{
                  ...(isCollapse && {
                    width: 0,
                    opacity: 0,
                  }),
                  flex: '1 0 auto',
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        // PaperProps={{
        //   style: {
        //     maxHeight: 52 * 4.5,
        //     width: 247, // same as parent button
        //   },
        // }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        {orgs.map((o) => (
          <MenuItem
            key={o.id}
            onClick={() => handleSwitch(o.id)}
            sx={{ height: 44, px: 2 }}
            selected={o.id === org?.id}
          >
            <Avatar sx={{ width: 22, height: 22, p: 0.4, mr: 1 }}>
              <Iconify icon="mdi:company" />
            </Avatar>
            <Typography variant="body2" noWrap>
              {o.name}
            </Typography>
          </MenuItem>
        ))}

        <Divider sx={{ my: '4px !important' }} />

        <MenuItem onClick={handleCreateNew} sx={{ height: 44, px: 1 }}>
          <Iconify icon="material-symbols:add" width={24} height={24} mr={1} color="text.primary" />
          <Typography variant="body2" noWrap>
            New Organization
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
