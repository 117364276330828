import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Country, Organization } from '@shared/types';
import { AutocompleteItem } from '@typedefs/app';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
const OrganizationGeneralSchema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  registrationNumber: Yup.string().when('address', ([address], schema) =>
    address.country.value === 'ca'
      ? schema.required('Charitable registration number is required')
      : schema
  ),
  phone: Yup.string(),
  address: Yup.object()
    .required()
    .shape({
      address1: Yup.string().required(),
      address2: Yup.string(),
      city: Yup.string().required(),
      state: Yup.object().required(),
      postalCode: Yup.string().required(),
      country: Yup.object().required('Country is required'),
    }),
});

export const organizationGeneralSchemaResolver = yupResolver(OrganizationGeneralSchema);
export type OrganizationGeneralSchemaForm = {
  name: string;
  registrationNumber: string;
  phone: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: AutocompleteItem;
    postalCode: string;
    country: AutocompleteItem<Country>;
  };

  afterSubmit?: string;
};

// ----------------------------------------------------------------------

const OrganizationPreferencesSchema = Yup.object().shape({
  currency: Yup.object().nullable().required('Name required'),
  language: Yup.object().nullable().required('Language required'),
  dateFormat: Yup.object().nullable().required('Date format required'),
  timeFormat: Yup.object().nullable().required('Time format required'),
});

export const organizationPreferencesSchemaResolver = yupResolver(OrganizationPreferencesSchema);

export type OrganizationPreferencesSchemaForm = {
  currency: AutocompleteItem<Organization.Currency> | null;
  language: AutocompleteItem | null;
  dateFormat: AutocompleteItem | null;
  timeFormat: AutocompleteItem | null;
  afterSubmit?: string;
};

// ----------------------------------------------------------------------
export const OrganizationSchema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  registrationNumber: Yup.string().when('address', ([address], schema) =>
    address.country.value === 'ca'
      ? schema.required('Charitable registration number is required')
      : schema
  ),
  phone: Yup.string(),
  address: Yup.object()
    .required()
    .shape({
      address1: Yup.string().required(),
      address2: Yup.string(),
      city: Yup.string().required(),
      state: Yup.object().required(),
      postalCode: Yup.string().required(),
      country: Yup.object().required('Country is required'),
    }),
  currency: Yup.object().nullable().required('Currency required'),
  language: Yup.object().nullable().required('Language required'),
  dateFormat: Yup.object().nullable().required('Date format required'),
  timeFormat: Yup.object().nullable().required('Time format required'),
  signatoryName: Yup.string().required('Signatory name required'),
  signatoryPosition: Yup.string(),
  religiousBenefit: Yup.bool(),
});

export type OrganizationSchemaForm = {
  name: string;
  registrationNumber: string;
  phone: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    state: AutocompleteItem;
    postalCode: string;
    country: AutocompleteItem<Country>;
  };
  currency: AutocompleteItem<Organization.Currency> | null;
  language: AutocompleteItem | null;
  dateFormat: AutocompleteItem | null;
  timeFormat: AutocompleteItem | null;
  signatoryName: string;
  signatoryPosition: string;
  religiousBenefit: boolean;

  afterSubmit?: string;
};

// ----------------------------------------------------------------------
const OrganizationUsersSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      user: Yup.object().required(),
      role: Yup.string().required('Role required'),
    })
  ),
});
export const organizationUsersSchemaResolver = yupResolver(OrganizationUsersSchema);

export type OrganizationUsersSchemaForm = {
  readonly users: {
    user: Organization.OrgUser;
    role: Organization.Role;
  }[];

  afterSubmit?: string;
};

// ----------------------------------------------------------------------
const OrganizationInvitationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EmailValidOrEmpty, 'Email must be a valid email address')
    .required('Email required'),
  role: Yup.string().required('Role required'),
});
export const organizationInvitationSchemaResolver = yupResolver(OrganizationInvitationSchema);

export type OrganizationInvitationSchemaForm = {
  readonly email: string;
  readonly role: Organization.Role;

  afterSubmit?: string;
};

// ----------------------------------------------------------------------
const OrganizationReceiptSchema = Yup.object().shape({
  signatoryName: Yup.string().required('Signatory name is required'),
  signatoryPosition: Yup.string(),
  religiousBenefit: Yup.bool(),
  signatoryEmail: Yup.string().matches(EmailValidOrEmpty, 'Must be a valid email address'),
});

export const organizationReceiptSchemaResolver = yupResolver(OrganizationReceiptSchema);
export type OrganizationReceiptSchemaForm = {
  signatoryName: string;
  signatoryPosition: string;
  religiousBenefit: boolean;
  signatoryEmail: string;

  afterSubmit?: string;
};
