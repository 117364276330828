import { OrganizationSchemaForm } from '@/schemas';
import StepGeneral from './General';
import StepPreferences from './Preferences';
import StepReceipts from './Receipts';
import StepOverview from './Overview';

export type TStepProps = {
  readonly data: OrganizationSchemaForm;
  readonly errors: string[];
  readonly isSubmitting: boolean;
  readonly isOptional: boolean;
  readonly onBack: VoidFunction;
  readonly onSkip: VoidFunction;
  readonly onNext: (data?: Partial<OrganizationSchemaForm>, dontMove?: boolean) => void;
};

export const steps = [
  {
    title: 'General',
    optional: false,
    Component: StepGeneral,
  },
  {
    title: 'Preferences',
    optional: false,
    Component: StepPreferences,
  },
  {
    title: 'Receipts',
    optional: false,
    Component: StepReceipts,
  },
  {
    title: 'Overview',
    optional: false,
    Component: StepOverview,
  },
];
