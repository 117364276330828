import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import type { RecurringDonation } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Create
export const recurringDonationCreate = httpsCallable<
  RecurringDonation.CreateReq,
  RecurringDonation.CreateRes
>(firebaseFunctions, 'recurringDonation-create');

// Update
export const recurringDonationUpdate = httpsCallable<
  RecurringDonation.UpdateReq,
  RecurringDonation.CreateRes
>(firebaseFunctions, 'recurringDonation-update');

// Delete
export const recurringDonationDelete = httpsCallable<
  RecurringDonation.DeleteReq,
  RecurringDonation.DeleteRes
>(firebaseFunctions, 'recurringDonation-deleteRecord');
