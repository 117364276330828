import { Stack, Tooltip, Typography } from '@mui/material';

import useLocales from '@hooks/useLocales';
import { AllCountryList } from '@utils/allCountryList';
import { RHFTextField, RHFAutocomplete } from '@components/hook-form';
import MemberNumbers from './MemberNumbers';

// ----------------------------------------------------------------------
type Props = {
  readonly isBusiness: boolean;
  readonly formDisabled: boolean;
};
// ----------------------------------------------------------------------
export default function DonorForm({ isBusiness, formDisabled }: Props) {
  const { t } = useLocales();

  return (
    <>
      <Stack spacing={2}>
        {isBusiness && (
          <>
            <Typography variant="caption" mt={-2} mb={1.5}>
              Business donor type will always use "Business name" to display a donor, or when
              issuing a receipt. Excluding "Business name" field, all other name fields refer to the
              Contact within that business.
            </Typography>

            <RHFTextField
              required
              autoFocus
              name="organization"
              label="Business name"
              disabled={formDisabled}
              inputProps={{ maxLength: 60 }}
            />
          </>
        )}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Tooltip
            title={isBusiness ? 'First name of your primary Business contact' : ''}
            placement="top-end"
          >
            <Stack width="100%">
              <RHFTextField
                required={!isBusiness}
                autoFocus={!isBusiness}
                name="firstName"
                label="First name"
                disabled={formDisabled}
                inputProps={{ maxLength: 40 }}
              />
            </Stack>
          </Tooltip>
          <Tooltip
            title={isBusiness ? 'Middle name of your primary Business contact' : ''}
            placement="top-end"
          >
            <Stack width="100%">
              <RHFTextField
                name="middleName"
                label="Middle name"
                disabled={formDisabled}
                inputProps={{ maxLength: 40 }}
              />
            </Stack>
          </Tooltip>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Tooltip
            title={isBusiness ? 'Last name of your primary Business contact' : ''}
            placement="top-end"
          >
            <Stack width="100%">
              <RHFTextField
                required={!isBusiness}
                name="lastName"
                label="Last name"
                disabled={formDisabled}
                inputProps={{ maxLength: 40 }}
              />
            </Stack>
          </Tooltip>
        </Stack>

        <RHFTextField
          name="email"
          type="email"
          label="Email address"
          disabled={formDisabled}
          inputProps={{ maxLength: 60 }}
        />
        <MemberNumbers formDisabled={formDisabled} />
        <RHFTextField
          name="phone"
          type="tel"
          label="Phone"
          disabled={formDisabled}
          inputProps={{ maxLength: 20 }}
        />
      </Stack>

      <Stack spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField
            name="address.address1"
            label="Address 1"
            disabled={formDisabled}
            inputProps={{ maxLength: 40 }}
          />
          <RHFTextField
            name="address.address2"
            label="Address 2"
            disabled={formDisabled}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField
            name="address.city"
            label="City"
            disabled={formDisabled}
            inputProps={{ maxLength: 40 }}
          />
          <RHFTextField
            name="address.state"
            label={t('State')}
            disabled={formDisabled}
            inputProps={{ maxLength: 40 }}
          />
          <RHFTextField
            name="address.postalCode"
            label={t('Zip Code')}
            disabled={formDisabled}
            inputProps={{ maxLength: 12 }}
          />
        </Stack>

        <RHFAutocomplete
          name="address.country"
          label="Country"
          fullWidth={false}
          freeSolo={false}
          disabled={formDisabled}
          options={AllCountryList}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Stack>
    </>
  );
}
