import { Outlet } from 'react-router-dom';

import Logo from '@components/Logo';
import { HeaderStyle } from './styles';

// ----------------------------------------------------------------------
export default function LogoLayout() {
  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
