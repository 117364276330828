import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Donation } from '@shared/types';
import { TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  row: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  bold: { fontFamily: 'Helvetica-Bold' },
});

// ----------------------------------------------------------------------
type Props = {
  readonly vars: TReceiptVariables;
  readonly receiptNumber: number;
  readonly receiptDate: string | Date;
  readonly year: number;
  readonly donations: Donation.Donation[];
  readonly receiptEligible?: number;
};
// ----------------------------------------------------------------------
export default function ReceiptInfo({
  vars,
  receiptNumber,
  receiptDate,
  year,
  donations,
  receiptEligible,
}: Props) {
  const isSingleDonation = donations.length === 1;

  return (
    <View>
      <View style={styles.row}>
        <Text style={styles.bold}>Receipt No.</Text>
        <Text>{vars.fReceiptNumber(receiptNumber, year)}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.bold}>Issued on:</Text>
        <Text>{vars.fLongDate(receiptDate)}</Text>
      </View>

      {receiptEligible ? (
        <View style={styles.row}>
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>Total:</Text>
          <Text>{vars.fCurrency(receiptEligible)}</Text>
        </View>
      ) : null}

      {isSingleDonation ? (
        <View style={styles.row}>
          <Text style={styles.bold}>Donation received:</Text>
          <Text>{vars.fLongDate(donations[0].date)}</Text>
        </View>
      ) : (
        <View style={styles.row}>
          <Text style={styles.bold}>Year received:</Text>
          <Text>{year}</Text>
        </View>
      )}

      {/* <View style={styles.row}>
            <Text style={styles.bold}>Envelope #:</Text>
            <Text>1234</Text>
          </View> */}
    </View>
  );
}
