import { Box, Stack, Toolbar } from '@mui/material';

import useOffSetTop from '@hooks/useOffSetTop';
import useResponsive from '@hooks/useResponsive';
import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';

import { HEADER } from '../../../config';
import Iconify from '@components/Iconify';
import { IconButtonAnimate } from '@components/animate';
import Logo from '@components/Logo';

import AccountPopover from './AccountPopover';
import Banner from './Banner';
import { RootStyle } from './styles';

// ----------------------------------------------------------------------
type Props = {
  onOpenSidebar?: VoidFunction;
  isCollapse?: boolean;
  isFullWidth?: boolean;
  showAccount?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  isFullWidth = false,
  showAccount = false,
}: Props) {
  const { org } = useOrg();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);
  const isDesktop = useResponsive('up', 'lg');

  if (isDesktop && !showAccount) return null;

  return (
    <>
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} isFullWidth={isFullWidth}>
        <Toolbar
          sx={{
            minHeight: '100% !important',
            px: { lg: 5 },
          }}
        >
          {!isDesktop && onOpenSidebar && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          {!onOpenSidebar && <Logo path={getPath(PATHS.org.root, { orgId: org?.id })} />}

          <Box sx={{ flexGrow: 1 }} />

          {showAccount && (
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <AccountPopover />
            </Stack>
          )}
        </Toolbar>
      </RootStyle>

      <Banner isOffset={isOffset} isCollapse={isCollapse} isFullWidth={isFullWidth} />
    </>
  );
}
