import { useCallback, useMemo } from 'react';
import { orderBy as _orderBy, cloneDeep, difference, filter, isString, sortBy, uniq } from 'lodash';
import Fuse from 'fuse.js';

import { TColumn } from '@typedefs/app';
import { Category, Tag } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { ReceiptListHeader, ReceiptListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<ReceiptListHeader>[];
  readonly dateFrom?: Date;
  readonly dateTo?: Date;
  readonly order: 'asc' | 'desc';
  readonly orderBy: ReceiptListHeader;
  readonly search: string;

  // filters
  readonly filterTags: Tag.Tag[];
  readonly filterCategories: Category.Category[];
};
// ----------------------------------------------------------------------
export default function useData({ columns, search, order, orderBy, ...filters }: Props) {
  const {
    receipts,
    donorsWithDonations,
    getReceiptDonations,
    getTagsFromIds,
    getCategoriesFromIds,
  } = useDonation();
  const { fFullName, fDate, fReceiptNumber, fReceiptState, fCurrency } = useFormat();
  // We are forcing all columns to be searchable, if we don't omit the condition in the next
  // line, stored views will have them non-searchable!
  const searchableColumns = columns.filter((c) => c.visible /* && c.searchable */).map((c) => c.id);

  // ----- parsing -------
  const getDonor = useCallback(
    (donorId: string) => donorsWithDonations.find((d) => d.id === donorId),
    [donorsWithDonations]
  );

  const parsedData = useMemo(
    () =>
      receipts.map((receipt): ReceiptListItem => {
        const donor = getDonor(receipt.donorId);
        const donations = getReceiptDonations(receipt);
        const amount = donations.reduce((sum, d) => (sum += d.amount), 0);
        const amountEligible = donations.reduce((sum, d) => (sum += d.amountEligible || 0), 0);
        const tags = sortBy(getTagsFromIds(donor?.tagIds || []), (t) => t.name.toUpperCase());
        const categories = sortBy(
          getCategoriesFromIds(uniq(donations.map((d) => d.categoryId))),
          'name'
        );

        return {
          id: receipt.id,
          _receipt: receipt,
          _donor: donor!,
          _tags: tags,
          _categories: categories,
          _amount: amount,
          _amountEligible: amountEligible,

          number: fReceiptNumber(receipt.number, receipt.year),
          date: fDate(receipt.date),
          donorName: fFullName(donor),
          state: fReceiptState(receipt),
          tags: tags.map((c) => c!.name).join(', '),
          categories: categories.map((c) => c!.name).join(', '),
          amount: fCurrency(amount),
          amountEligible: fCurrency(amountEligible),
        };
      }),
    [
      fDate,
      fReceiptNumber,
      fReceiptState,
      fFullName,
      fCurrency,
      getCategoriesFromIds,
      getReceiptDonations,
      getDonor,
      getTagsFromIds,
      receipts,
    ]
  );

  // ----- FILTERING -------
  const filteredData = useMemo(() => applyFilters(parsedData, filters), [parsedData, filters]);

  // ----- SEARCHING -------
  const fuse = useMemo(
    () =>
      new Fuse(filteredData, {
        keys: searchableColumns,
        includeMatches: true,
        includeScore: false,
        threshold: 0.2,
        shouldSort: false,
      }),
    [filteredData, searchableColumns]
  );

  const searchedData = useMemo(
    () =>
      search
        ? fuse.search(search).map(({ item, matches, score }) => {
            let clone = cloneDeep(item) as any;
            matches?.forEach(({ key, indices }) => {
              let prop = clone[key as string];
              if (isString(prop)) {
                [...indices].reverse().forEach(([start, finish]) => {
                  prop = prop.substring(0, finish + 1) + '</strong>' + prop.substring(finish + 1);
                  prop = prop.substring(0, start) + '<strong>' + prop.substring(start);
                });
                clone[key as string] = prop;
              }
            });

            return clone as ReceiptListItem;
          })
        : ((fuse as any)._docs as ReceiptListItem[]),
    [fuse, search]
  );

  // ----- ORDERING -------
  return useMemo(() => {
    switch (orderBy) {
      case 'date':
        return _orderBy(searchedData, (f) => new Date(f._receipt.date).getTime(), order);
      case 'amount':
        return _orderBy(searchedData, (f) => f._amount, order);
      case 'amountEligible':
        return _orderBy(searchedData, (f) => f._amountEligible, order);
      default:
        return _orderBy(searchedData, orderBy, order);
    }
  }, [searchedData, orderBy, order]);
}

// ----------------------------------------------------------------------
type FilterProps = Omit<Props, 'columns' | 'search' | 'order' | 'orderBy'>;
function applyFilters(items: ReceiptListItem[], dataProps: FilterProps): ReceiptListItem[] {
  return filter(items, (item) =>
    [
      applyDateFilter(item, dataProps.dateFrom, dataProps.dateTo),
      applyTagsFilter(item, dataProps.filterTags),
      applyCategoriesFilter(item, dataProps.filterCategories),
    ].every(Boolean)
  );
}

// -----
function applyDateFilter(item: ReceiptListItem, dateFrom?: Date, dateTo?: Date) {
  const receiptDate = new Date(item._receipt.date).getTime();
  return (
    (dateFrom?.getTime() || 0) <= receiptDate && receiptDate <= (dateTo?.getTime() || Infinity)
  );
}
function applyTagsFilter(item: ReceiptListItem, tags: Tag.Tag[]) {
  const tagIds = tags.filter((t) => t.type === 'donor').map((t) => t.id);
  const donorTagIds = item._tags.map((t) => t.id);
  return difference(tagIds, donorTagIds).length === 0;
}
function applyCategoriesFilter(item: ReceiptListItem, categories: Category.Category[]) {
  const catIds = categories.map((c) => c.id);
  const receiptCatIds = item._categories.map((c) => c.id);
  return difference(catIds, receiptCatIds).length === 0;
}
