import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Donation } from '@shared/types';
import { TReceiptRenderGroup, TReceiptVariables } from '@typedefs/donation';

import ReceiptHeader from '../components/Header';
import ReceiptDonorInfo from '../components/DonorInfo';
import ReceiptSignature from '../components/Signature';
import ReceiptDonationsTable from '../components/DonationsTable';

import ReceiptBody from './Body';
import ReceiptBodyInfo from './BodyInfo';
import ReceiptInfo from './ReceiptInfo';
import ReceiptFootnote from './Footnote';
import { ReceiptOptions } from '@/schemas';
import ReceiptSummaryTable from '../components/SummaryTable';

const styles = StyleSheet.create({
  body: { display: 'flex', flexDirection: 'row' },
  bodyLeft: { flex: '1 1 auto', display: 'flex', rowGap: 16 },
  bodyRight: { width: '38%', display: 'flex', rowGap: 32 },
  dividerDetach: {
    width: '100%',
    borderBottom: '1px dashed #000',
    marginBottom: 4,
  },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
  group: TReceiptRenderGroup;
  options: ReceiptOptions;
  year: number;
};
// ----------------------------------------------------------------------
export default function ReceiptCA({ vars, group, options, year }: Props) {
  const gikDonation = group.donations.find(
    (d) =>
      vars.getPaymentMethodById(d.paymentMethodId)?.type ===
      Donation.PaymentMethodInitialList.giftInKind
  );
  const advantageDonations = group.donations.filter((d) => d.withAdvantage && d.amountAdvantage);
  const advantageTotal = advantageDonations.reduce((acc, d) => acc + (d.amountAdvantage || 0), 0);
  const receiptTotal = group.donations.reduce((acc, d) => acc + d.amount, 0);
  const receiptEligible = receiptTotal - advantageTotal;

  return (
    <View>
      {/* -------------------------------------------------- */}
      {/* REQUIRED PART */}
      <ReceiptHeader vars={vars} />

      <View style={styles.body}>
        <View style={styles.bodyLeft}>
          <ReceiptBody
            vars={vars}
            receiptTotal={receiptTotal}
            advantageTotal={advantageTotal}
            receiptEligible={receiptEligible}
          />

          <ReceiptDonorInfo vars={vars} />
          <ReceiptBodyInfo
            vars={vars}
            gikDonation={gikDonation}
            advantageDonations={advantageDonations}
          />
        </View>

        <View style={styles.bodyRight}>
          <ReceiptInfo
            vars={vars}
            receiptNumber={group.number}
            receiptDate={group.date}
            year={year}
            donations={group.donations}
          />
          <ReceiptSignature vars={vars} />
        </View>
      </View>

      <ReceiptFootnote vars={vars} year={year} type={group.type} linkNumber={group.linkNumber} />

      {/* -------------------------------------------------- */}
      <View
        style={{
          textAlign: 'center',
          fontFamily: 'Helvetica-Oblique',
          marginTop: 24,
          marginBottom: 32,
        }}
      >
        <View style={styles.dividerDetach} />
        <Text>detach and retain for your records</Text>
      </View>
      {/* -------------------------------------------------- */}

      {/* DETACHED INFO */}
      <View style={styles.body}>
        <View style={styles.bodyLeft}>
          <View>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>{vars['org.name']}</Text>
            <Text>
              {vars['org.address'].address1} {vars['org.address'].address2}{' '}
              {vars['org.address'].city} {vars['org.address'].state}{' '}
              {vars['org.address'].postalCode}
            </Text>
            {/* TODO: receiptType */}
          </View>

          <ReceiptDonorInfo vars={vars} />
        </View>

        <View style={styles.bodyRight}>
          <ReceiptInfo
            vars={vars}
            receiptNumber={group.number}
            receiptDate={group.date}
            year={year}
            donations={group.donations}
            receiptEligible={receiptEligible}
          />
        </View>
      </View>

      {options.includeDetails ? (
        <ReceiptDonationsTable
          vars={vars}
          donations={group.donations}
          showDescription={options.showDescription}
        />
      ) : null}
      {options.includeSummary ? (
        <ReceiptSummaryTable vars={vars} donations={group.donations} />
      ) : null}
    </View>
  );
}
