// Print the top part of a report (up to but not including any tables with their headers)
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { isUndefined } from 'lodash';

// Create styles
const styles = StyleSheet.create({
  pageNumbers: {
    position: 'absolute',
    top: 24,
    right: 32,
    flexDirection: 'row',
    width: 100,
    textAlign: 'right',
  },
  header: {
    width: '100%',
    flexDirection: 'column',
  },
  orgName: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  reportData: {
    fontWeight: 'bold',
    flexDirection: 'row',
    marginBottom: 2,
  },
});

// Redefining this instead of retrieving it with useFormat() because using that here gives error "Error: could not find react-redux context value; please ensure the component is wrapped in a <Provider>".
const fLongDate = (date: Date | string | number) => format(new Date(date), 'MMMM d, yyyy');

// ----------------------------------------------------------------------
type ReportHeaderProps = {
  title: string;
  dateReport: Date;
  dateFrom?: Date | undefined | null; // use null to skip printing date range, undefined for both dates for "All Time"
  dateTo?: Date | undefined | null;
  filterDescription?: string; // additional description of any filtering
  orderBy?: string;
  orgName: string;
  startPageNumber?: number;
};
// ----------------------------------------------------------------------
export default function ReportHeader({
  title,
  dateReport,
  dateFrom,
  dateTo,
  filterDescription,
  orderBy,
  orgName,
  startPageNumber,
}: ReportHeaderProps) {
  const renderDateRange = () => {
    if (dateFrom === null) {
      return null;
    } else if (dateFrom && dateTo && dateFrom === dateTo) {
      return ` - For ${fLongDate(dateFrom)}`;
    } else if (dateFrom && dateTo) {
      return ` - Between ${fLongDate(dateFrom)} and ${fLongDate(dateTo)}`;
    } else if (dateFrom) {
      return ` - From ${fLongDate(dateFrom)}`;
    } else if (dateTo) {
      return ` - To ${fLongDate(dateTo)}`;
    }

    return ' - All time';
  };

  const renderOrderBy = () => {
    if (orderBy) {
      return ` - Ordered by ${orderBy}`;
    }
    return null;
  };

  return (
    <>
      <Text
        style={styles.pageNumbers}
        render={({ pageNumber, totalPages }) =>
          !isUndefined(startPageNumber)
            ? `Page ${startPageNumber + pageNumber}`
            : `Page ${pageNumber} / ${totalPages}`
        }
        fixed
      />

      <View style={styles.header} fixed>
        <Text style={styles.orgName}>{orgName}</Text>

        <View style={styles.reportData}>
          <Text style={{ marginRight: 4 }}>
            {title}
            {renderDateRange()}
            {renderOrderBy()}
          </Text>
        </View>
        {filterDescription ? (
          <Text style={{ marginRight: 4, marginBottom: 4 }}>{filterDescription}</Text>
        ) : null}
        <Text>Printed: {fLongDate(dateReport)}</Text>
      </View>
    </>
  );
}
