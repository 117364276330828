import { Font, Document, Page, StyleSheet } from '@react-pdf/renderer';
import { PdfTableData, fonts, PdfTable } from '@components/pdf';
import { PageOrientation } from '@typedefs/app';
import ReportHeader from './ReportHeader';
import { format } from 'date-fns';

Font.register(fonts);

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: 'Lato',
    fontSize: 10,
  },
});

// Redefining this instead of retrieving it with useFormat() because using that here gives error "Error: could not find react-redux context value; please ensure the component is wrapped in a <Provider>".
const fLongDate = (date: Date | string | number) => format(new Date(date), 'MMMM d, yyyy');

// ----------------------------------------------------------------------
export type ReportDocumentProps = {
  title: string;
  description: string;
  dateReport: Date;
  dateFrom?: Date | undefined | null; // use null to skip printing date range, undefined for both dates for "All Time"
  dateTo?: Date | undefined | null;
  filterDescription?: string; // additional description of any filtering
  orderBy?: string;
  orgName: string;
  data: PdfTableData;
  orientation: PageOrientation;
  size: { width: number; height: number };
  startPageNumber?: number;
  renderSummary?: boolean;
};
// ----------------------------------------------------------------------
export default function ReportDocument({
  title,
  description,
  dateReport,
  dateFrom,
  dateTo,
  filterDescription,
  orderBy,
  orgName,
  data,
  orientation = 'portrait',
  size: { width, height },
  startPageNumber,
  renderSummary = true,
}: ReportDocumentProps) {
  const size = orientation === 'landscape' ? { width: height, height: width } : { width, height };

  return (
    <Document
      title={`${title} - ${fLongDate(dateReport)}`}
      subject={description}
      author="Software4Nonprofits"
      creator="s4np web-app"
      producer="s4np web-app"
      pdfVersion="1.7"

      // onRender={(blob) => console.log('doc render: ', blob)}
    >
      {/* US letter size is different than A4 iso size */}
      {/* below is calculated using 72dpi */}
      <Page style={styles.page} size={size} wrap>
        <ReportHeader
          title={title}
          dateReport={dateReport}
          dateFrom={dateFrom}
          dateTo={dateTo}
          filterDescription={filterDescription}
          orderBy={orderBy}
          orgName={orgName}
          startPageNumber={startPageNumber}
        />
        <PdfTable data={data} renderSummary={renderSummary} />
      </Page>
    </Document>
  );
}
