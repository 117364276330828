import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
export const UserSchema = Yup.object().shape({
  displayName: Yup.string().required('Your full name required'),
  email: Yup.string()
    .matches(EmailValidOrEmpty, 'Email must be a valid email address')
    .required('Email required'),
});

export const userSchemaResolver = yupResolver(UserSchema);
export type UserSchemaForm = {
  displayName: string;
  email: string;

  afterSubmit?: string;
};
