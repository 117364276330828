import { addDays, addMonths, getDate, isAfter, isBefore, lastDayOfMonth } from 'date-fns';

interface IPeriod {
  label: string;
  value: string;
}

export const minOccurences = 2;
export const maxOccurences = 100;

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const weeks: IPeriod[] = [
  { label: 'Mondays', value: 'monday' },
  { label: 'Tuesdays', value: 'tuesday' },
  { label: 'Wednesdays', value: 'wednesday' },
  { label: 'Thursdays', value: 'thursday' },
  { label: 'Fridays', value: 'friday' },
  { label: 'Saturdays', value: 'saturday' },
  { label: 'Sundays', value: 'sunday' },
];

export const months: IPeriod[] = [
  ...Array.from({ length: 28 }, (_, i) => {
    const day = i + 1;
    return {
      label: `on the ${day}${getOrdinalSuffix(day)}`,
      value: `${day}`,
    };
  }),
  { label: 'on the last day of the month.', value: '31' },
];

export const getLabelFrequency = (period: string, startDate: Date) => {
  if (period === 'Monthly') {
    const dayOfMonth = startDate!.getDate().toString();

    // Check if dayOfMonth is between 28 and 31
    if (dayOfMonth >= '28' && dayOfMonth <= '31') {
      return months.find((m) => m.value === '31')?.label; // Return label for the last day of the month
    }

    return months.find((m) => m.value === dayOfMonth)?.label; // Return label for the selected day of the month
  }
  // For Weekly and Bi-Weekly
  const day = startDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
  const dayOfWeek = weeks.find((w) => w.value === day)?.label;
  return `on ${dayOfWeek}`;
};

export const getNextFutureOccurrence = (startDate: Date, period: string): Date => {
  const today = new Date();

  if (isAfter(startDate, today)) {
    return startDate;
  }
  return calculateNextFutureOccurrence(startDate, period);
};

// There's a dup of this function in functions/shared/utils/recurringDonationUtil.ts that must be identical
export const calculateNextOccurrence = (startDate: Date, period: string): Date => {
  switch (period) {
    case 'Weekly':
      return addDays(startDate, 7);
    case 'Bi-Weekly':
      return addDays(startDate, 14);
    case 'Monthly': {
      const nextMonthDate = addMonths(startDate, 1);
      if (getDate(startDate) >= 28) {
        return lastDayOfMonth(nextMonthDate);
      }
      return nextMonthDate;
    }
    default:
      throw new Error(`Unknown repeat type: ${period}`);
  }
};

// There's a dup of this function in functions/shared/utils/recurringDonationUtil.ts that must be identical
export const calculateNextFutureOccurrence = (startDate: Date, period: string): Date => {
  let nextOccurrence = calculateNextOccurrence(startDate, period);
  const today = new Date();

  while (isBefore(nextOccurrence, today)) {
    nextOccurrence = calculateNextOccurrence(nextOccurrence, period);
  }
  return nextOccurrence;
};
