import { useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import PATHS from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import { useContext } from '@pages/receipts/List';
import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps } from '@components/pdf';
import ReportDocument from '@pages/reports/ReportDocument';
import ReportLayout from '@pages/reports/ReportLayout';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import * as analytics from '@fire/analytics';

// ----------------------------------------------------------------------
export default function Export() {
  const { columns, data, dateFrom, dateTo, orderBy } = useContext();
  const navigate = useNavigate();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const { org } = useOrg();
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');

  const tableData: PdfTableData = useMemo(() => {
    // TODO: some generics should be applied here, based if its number etc.
    const selectedColumns = columns.filter((c) => c.visible && !c.internal);
    const totalWidth = selectedColumns.reduce((n, { pdfWidth }) => n + Number(pdfWidth), 0);
    const widthMultiplier = 99 / totalWidth; // for safety, only use 99% of the width
    let remainingWidth = 100;
    const header: PdfTableHeaderProps[] = selectedColumns.map((c) => {
      const cellWidth = (c.pdfWidth || 10) * widthMultiplier;
      const width = remainingWidth - cellWidth >= 0 ? cellWidth : remainingWidth;
      remainingWidth -= width;

      return {
        name: c.label,
        width,
        align: c.align as any,
      };
    });

    const items = data.map((receiptItem) =>
      selectedColumns.map((column) => {
        switch (column.id) {
          case 'tags':
            return receiptItem._tags.map((t) => t.name).join(', ');
          case 'categories':
            return receiptItem._categories.map((c) => c.name).join(', ');
          default:
            // @ts-ignore-next-line
            return receiptItem[column.id];
        }
      })
    );

    return { header, items };
  }, [columns, data]);

  // --------------------------------------------------
  const ReportDoc = useMemo(
    () => (
      <ReportDocument
        title={'Receipts export'}
        description={'desc'}
        dateReport={new Date()}
        dateFrom={dateFrom}
        dateTo={dateTo}
        orderBy={orderBy}
        orgName={org!.name}
        data={tableData}
        orientation={orientation}
        size={PageSize[size]}
      />
    ),
    [dateFrom, dateTo, orderBy, org, tableData, orientation, size]
  );

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.receipts.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  return (
    <Dialog
      title="Receipts export"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <Button size="large" variant="contained" onClick={handleOpenMenu}>
          Download
        </Button>
      }
    >
      <PdfCsvMenu
        tableData={tableData}
        reportDoc={ReportDoc}
        basename={'export_receipts'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.exportPDF()}
        analyticsCsvFn={() => analytics.donation.exportCSV()}
      />
      {/* 
      <MenuPopover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        arrow="bottom-left"
        sx={{
          mt: -1,
          width: 120,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 1, width: 20, height: 20 },
          },
        }}
      >
        <MenuItem onClick={handleCreateDownloadPDF}>
          <Iconify icon={'fa6-solid:file-pdf'} />
          PDF
        </MenuItem>
        <MenuItem
          onClick={() => handleDownloadCSV(tableData, enqueueSnackbar, 'export_csv', handleClose)}
        >
          <Iconify icon={'fa6-solid:file-csv'} />
          CSV
        </MenuItem>
      </MenuPopover>
      */}
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <ReportLayout
            orientation={orientation}
            setOrientation={setOrientation}
            size={size}
            setSize={setSize}
          />
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
