import { TableRow, TableCell, Stack, Link, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import PATHS, { getPath } from '@routes/paths';
import { TColumn } from '@typedefs/app';

import { CategoryItem } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import { TagItem } from '@pages/donors/dialogs/Settings/DonorTags/utils/renderTags';
import type { DonationListHeader, DonationListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<DonationListHeader>[];
  readonly item: DonationListItem;
  readonly onEdit: (donorId: string, donationId: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ item, onEdit, columns }: Props) {
  const cells = columns
    .filter((c) => c.visible || ['options'].includes(c.id))
    .map((column) => {
      switch (column.id) {
        case 'tags':
          return (
            <TableCell key={column.id} align={column.align}>
              {item._tags.map((tag) => (
                <TagItem key={tag.id} tag={tag} />
              ))}
            </TableCell>
          );
        case 'category':
          return (
            <TableCell key={column.id} align={column.align}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {item._category && <CategoryItem category={item._category} text={item.category} />}
              </Stack>
            </TableCell>
          );
        case 'receipt':
          return (
            <TableCell
              key={column.id}
              align={column.align}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Link
                variant="body2"
                component={RouterLink}
                to={`${getPath(PATHS.org.receipts.view, {
                  orgId: item._donation.orgId,
                  receiptId: item.receiptId,
                })}`}
                sx={{ color: 'initial' }}
              >
                <Box dangerouslySetInnerHTML={{ __html: item.receipt }} />
              </Link>
            </TableCell>
          );
        default:
          const value = item[column.id] || '';
          return (
            <TableCell
              key={column.id}
              align={column.align}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            />
          );
      }
    });

  return (
    <TableRow
      hover
      onClick={() => onEdit(item.donorId, item.id)}
      sx={{
        cursor: 'pointer',
        '&.MuiTableRow-hover:hover': {
          background: '#007b5514',
        },
        '& strong': { color: (theme) => theme.palette.secondary.light },
      }}
    >
      {cells}
    </TableRow>
  );
}
