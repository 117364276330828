import { Stack, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import useFormat from '@hooks/useFormat';
import Iconify from '@components/Iconify';
import PATHS, { getPath } from '@routes/paths';

// ----------------------------------------------------------------------
type Props = {
  readonly orgId: string;
  readonly deleteDate: string;
};
// ----------------------------------------------------------------------
export default function BannerOrgDeletion({ orgId, deleteDate }: Props) {
  const { fLongDate } = useFormat();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 0.5, px: 2 }}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <Iconify icon={'dashicons:warning'} width={22} height={22} />
        <Typography variant="body2">
          Organization will be deleted on <strong>{fLongDate(deleteDate)}</strong>
        </Typography>

        <Typography>-</Typography>

        <Link
          variant="body2"
          component={RouterLink}
          to={getPath(PATHS.org.settings.org.general, { orgId })}
          sx={{
            lineHeight: 2,
            display: 'flex',
            alignItems: 'center',
            color: 'text.primary',
            '& > div': { display: 'inherit' },
          }}
        >
          Cancel
        </Link>
      </Stack>
    </Stack>
  );
}
