import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
export const EmailSignatureSchema = Yup.object().shape({
  signatoryName: Yup.string().required('From name is required'),
  signatoryEmail: Yup.string()
    .matches(EmailValidOrEmpty, 'From email must be a valid email address')
    .required('From email required'),
});

export const emailSignatureSchemaResolver = yupResolver(EmailSignatureSchema);
export type EmailSignatureForm = {
  signatoryName: string;
  signatoryEmail: string;

  afterSubmit?: string;
};
