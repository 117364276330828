import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { addYears, isWithinInterval, subYears, subDays, addDays } from 'date-fns';
import { Donation, Donor, Country, Category, PaymentMethod } from '@shared/types';

const dateTimeframeLimitTest = (val: Date) =>
  val
    ? isWithinInterval(val, {
        start: subDays(subYears(new Date(), 10), 1),
        end: addDays(addYears(new Date(), 1), 1),
      })
    : true;

// ----------------------------------------------------------------------
const DonationSchema = (country?: Country) =>
  Yup.object().shape(
    {
      date: Yup.date()
        .typeError('Date is required')
        .required('Date is required')
        .test(
          'timeframe-invalid',
          'Timeframe is invalid, must be between -10/+1 year',
          dateTimeframeLimitTest
        ),
      donor: Yup.object().nullable().required('Donor is required'),
      amount: Yup.number()
        .typeError('Amount is required')
        .required('Amount is required')
        .min(0, "Amount can't be negative."),
      paymentMethod: Yup.object().nullable(),
      paymentInfo: Yup.string().when(['paymentMethod'], ([paymentMethod], schema) => {
        if (
          country === 'ca' &&
          paymentMethod?.type === Donation.PaymentMethodInitialList.giftInKind
        ) {
          return schema.required('GIK description is required for Gift in Kind');
        }
        return schema;
      }),
      category: Yup.object().nullable().required('Category is required'),

      withAdvantage: Yup.bool().default(false),
      amountEligible: Yup.number()
        .nullable()
        .typeError('Eligible amount must be a number')
        .when(['withAdvantage', 'amount'], ([withAdvantage, amount], schema) =>
          withAdvantage
            ? schema
                .required('Eligible amount is required for donation with advantage')
                .lessThan(
                  amount,
                  'For donations with Advantage, the Eligible Amount must be less than the Amount'
                )
            : schema
        ),
      amountAdvantage: Yup.number()
        .nullable()
        .typeError('Advantage amount must be a number')
        .when(['withAdvantage'], ([withAdvantage], schema) =>
          withAdvantage
            ? schema.required('Advantage amount is required for donation with advantage')
            : schema
        ),
      advantageDescription: Yup.string().when(['withAdvantage'], ([withAdvantage], schema) => {
        if (country === 'ca' && withAdvantage) {
          return schema.required('Advantage description is required for donation with advantage');
        }
        return schema;
      }),

      appraiserName: Yup.string().when('appraiserAddress', ([address], schema) =>
        !!address ? schema.required('Name required if Appraiser address is provided') : schema
      ),
      appraiserAddress: Yup.string().when('appraiserName', ([name], schema) =>
        !!name ? schema.required('Address required if Appraiser name is provided') : schema
      ),

      notes: Yup.string(),

      nonReceiptable: Yup.bool().default(false),
      description: Yup.string(),
    },
    [['appraiserName', 'appraiserAddress']]
  );

export const donationSchemaResolver = (country?: Country) => yupResolver(DonationSchema(country));
export type DonationSchemaForm = {
  donor: Donor.Donor | null;
  date: Date;
  amount: number | string;
  category: Category.Category | null;
  notes: string;
  paymentMethod: PaymentMethod.PaymentMethod | null;
  paymentInfo: string;

  withAdvantage: boolean;
  amountEligible?: number;
  amountAdvantage?: number;
  advantageDescription?: string;

  appraiserName: string;
  appraiserAddress: string;

  nonReceiptable: boolean;
  description?: string;

  afterSubmit?: string;
};

// ----------------------------------------------------------------------
const DonationBatchSchema = Yup.object().shape({
  date: Yup.date()
    .typeError('Date is required')
    .required('Date is required')
    .test(
      'timeframe-invalid',
      'Timeframe is invalid, must be between -10/+1 year',
      dateTimeframeLimitTest
    ),
  items: Yup.array().of(
    Yup.object().shape({
      donor: Yup.object().nullable().required('Donor is required'),
      amount: Yup.number()
        .typeError('Amount is required')
        .required('Amount is required')
        .min(0, "Amount can't be negative."),
      category: Yup.object().nullable().required('Category is required'),
      paymentMethod: Yup.object().nullable(),
      paymentInfo: Yup.string().nullable(),
    })
  ),
});
export const donationBatchSchemaResolver = yupResolver(DonationBatchSchema);

export type DonationBatchSchemaForm = {
  readonly date: Date;
  readonly items: Donation.DonationBatchUpdateForm[];

  afterSubmit?: string;
};
