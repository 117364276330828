import { Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------
type Props = {
  readonly isLast?: boolean;
  readonly isSubmitting: boolean;
  readonly handleBack?: VoidFunction;
  readonly handleSkip?: VoidFunction;
  readonly errors?: string[];
};
// ----------------------------------------------------------------------
export default function StepActions({
  isLast,
  isSubmitting,
  handleBack,
  handleSkip,
  errors = [],
}: Props) {
  return (
    <Stack>
      <Divider sx={{ mt: 6 }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          background: (theme) => theme.palette.common.white,
        }}
      >
        <Button color="inherit" disabled={!handleBack} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>

        <Box sx={{ flex: '1 1 auto' }} />

        {handleSkip && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}

        {isLast ? (
          <Tooltip
            title={
              errors.length ? (
                <Stack>
                  {errors.map((e) => (
                    <Typography key={e} variant="body2">
                      - {e}
                    </Typography>
                  ))}
                </Stack>
              ) : (
                ''
              )
            }
            placement="top-start"
            arrow
          >
            <span>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!!errors.length}
              >
                Create Organization
              </LoadingButton>
            </span>
          </Tooltip>
        ) : (
          <Button size="large" type="submit" variant="contained" disabled={!!errors.length}>
            Next
          </Button>
        )}
      </Box>
    </Stack>
  );
}
