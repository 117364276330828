// Small component to calc and display the totals for Batch Entry.
// Solution figured out from video https://www.youtube.com/watch?v=4MrbfGSFY2A&t=906s
//   linked from docs page https://react-hook-form.com/docs/usefieldarray.

import { DonationBatchSchemaForm } from '@/schemas';
import useFormat from '@hooks/useFormat';
import { Typography } from '@mui/material';
import { sumBy } from 'lodash';
import { Control, useWatch } from 'react-hook-form';

type Props = {
  control: Control<DonationBatchSchemaForm>;
};

export default function TotalAmount({ control }: Props) {
  const { fCurrency } = useFormat();
  const itemValues = useWatch({
    control,
    name: 'items',
  });
  const count = itemValues.length;
  if (count === 1 && Number(itemValues[0].amount) === 0) return null;
  const total = sumBy(itemValues, (item) => Number(item.amount));
  return <Typography variant="h6">{`Total of ${count} Donations: ${fCurrency(total)}`}</Typography>;
}
