import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const ContactUsSchema = Yup.object().shape({
  text: Yup.string().required('Text is required'),
  topic: Yup.string().required('Topic is required'),
});

export const contactUsSchemaResolver = yupResolver(ContactUsSchema);
export type ContactUsSchemaForm = {
  text: string;
  afterSubmit?: string;
};

const FeedbackSchema = Yup.object().shape({
  text: Yup.string().required('Text is required'),
});

export const feedbackSchemaResolver = yupResolver(FeedbackSchema);
export type FeedbackSchemaForm = {
  topic: string;
  text: string;

  afterSubmit?: string;
};
