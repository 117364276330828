import { styled } from '@mui/material/styles';
import { Stack, SxProps, Typography } from '@mui/material';

import Logo from '../Logo';
import ProgressBar from '../ProgressBar';
import './loader.css';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const LogoStyles = styled('div')({
  width: 200,
  height: 200,
  animation: '5s linear infinite',
  animationName: 'rotate-0',
  animationDelay: '0s',
  transformOrigin: '50% 50%',
  transform: 'rotate(0deg)',
});
const PathStyles = styled('path')(({ theme }) => ({
  fill: 'none',
  strokeWidth: 2,
  stroke: theme.palette.primary.main,
  top: 0,
  left: 0,
  transformOrigin: '50% 50%',
  transform: 'rotate(0deg)',
  animation: '4s linear infinite',
}));

// ----------------------------------------------------------------------
type Props = {
  id?: string;
  withLoader?: boolean;
  sx?: SxProps;
  text?: string;
};

export default function LoadingScreen({ id, withLoader, text, ...other }: Props) {
  return (
    <>
      <ProgressBar />

      {withLoader && (
        <RootStyle {...other} id={id}>
          <Stack alignItems="center" justifyContent="center">
            <Logo disabledLink sx={{ width: 64, height: 64, position: 'absolute' }} />

            <LogoStyles className="logo">
              <svg width="200px" height="200px">
                <PathStyles
                  d="M140.773,59.227C137.316,55.771,130.055,50,100,50
        s-37.317,5.771-40.774,9.227C55.77,62.684,49.999,69.104,50,100c-0.001,30.896,5.77,37.316,9.227,40.773
        C62.683,144.229,69.103,150,100,150c30.895,0,37.317-5.771,40.772-9.227C144.229,137.316,150,130.896,150,100
        S144.229,62.683,140.773,59.227z"
                  style={{ opacity: 1, animationName: 'rotate-1' }}
                />
                <PathStyles
                  d="M140.773,59.227C137.316,55.771,130.055,50,100,50
        s-37.317,5.771-40.774,9.227C55.77,62.684,49.999,69.104,50,100c-0.001,30.896,5.77,37.316,9.227,40.773
        C62.683,144.229,69.103,150,100,150c30.895,0,37.317-5.771,40.772-9.227C144.229,137.316,150,130.896,150,100
        S144.229,62.683,140.773,59.227z"
                  style={{ opacity: 0.5, animationName: 'rotate-2', animationDelay: '.1s' }}
                />

                <PathStyles
                  d="M140.773,59.227C137.316,55.771,130.055,50,100,50
        s-37.317,5.771-40.774,9.227C55.77,62.684,49.999,69.104,50,100c-0.001,30.896,5.77,37.316,9.227,40.773
        C62.683,144.229,69.103,150,100,150c30.895,0,37.317-5.771,40.772-9.227C144.229,137.316,150,130.896,150,100
        S144.229,62.683,140.773,59.227z"
                  style={{ opacity: 0.8, animationName: 'rotate-3', animationDelay: '.2s' }}
                />
              </svg>
            </LogoStyles>
          </Stack>

          {text && (
            <Typography variant="body1" fontStyle="italic" mt={-2}>
              {text}
            </Typography>
          )}
        </RootStyle>
      )}
    </>
  );
}
