import { useCallback, useState } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import ConfirmDialog from '@components/ConfirmDialog';
import { Upload } from '@components/upload';

type Props = {
  currentFile: File | undefined;
  setCurrentFile: (file: File | undefined) => void;
  isValid: boolean;
  setError: (error: string) => void;
};

// ----------------------------------------------------------------------
export default function CSVFile({ currentFile, setCurrentFile, isValid, setError }: Props) {
  const { hasAccess } = useRole();
  const { enqueueSnackbar } = useSnackbar();

  const canManage = hasAccess([Role.editor, Role.contributor]);
  const [loading, setLoading] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  // --------------- effects ---------------

  // --------------- actions ---------------
  const handleDeleteClose = () => {
    if (!loading) {
      setConfirmDeleteDialog(false);
    }
  };
  const handleDelete = async () => {
    if (!canManage) {
      enqueueSnackbar('You lack permissions', { variant: 'error' });
      return;
    }

    setLoading(true);
    setCurrentFile(undefined);
    setLoading(false);
    handleDeleteClose();
  };

  const handleAdd = useCallback(
    async (files: File[]) => {
      if (!files.length) return;

      if (!canManage) {
        enqueueSnackbar('You lack permissions', { variant: 'error' });
        return;
      }

      setLoading(true);
      setCurrentFile(files[0]);

      setLoading(false);
    },
    [canManage, enqueueSnackbar, setCurrentFile]
  );

  return (
    <Card sx={{ p: 2, width: '50%' }}>
      <Stack direction="column" spacing={1} alignItems={'left'}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="subtitle1">CSV File to Import:</Typography>
          <Typography variant="subtitle2">
            <a
              href="https://docs.google.com/document/d/1uim011AnCLC0V5N7sT2eO9utMiZSLfyAmApW1qQ8490/edit#heading=h.1ytjlzjgcl43"
              target="_blank"
              rel="noreferrer"
            >
              Help on Importing
            </a>
          </Typography>
        </Stack>
        {!currentFile ? (
          <Upload
            accept={{ 'text/csv': ['.csv'] }}
            file={currentFile}
            onDrop={handleAdd}
            error={isValid}
            setError={setError}
          />
        ) : (
          <>
            <Box
              sx={{
                position: 'relative',
                width: 'fit-content',
                boxShadow: (theme) => theme.shadows[10],
              }}
            >
              <Typography>{currentFile.name}</Typography>
            </Box>
            <LoadingButton
              loading={loading}
              color="error"
              size="small"
              sx={{ fontSize: 12 }}
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Delete
            </LoadingButton>
          </>
        )}
      </Stack>

      <ConfirmDialog
        open={confirmDeleteDialog}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        loading={loading}
      />
    </Card>
  );
}
