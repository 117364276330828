// @mui
import { Theme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material';
import { TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------
const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------
type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  columns: TColumn[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  // the next one is for extra sx on the table cells for headers,
  // such as cellsSx={{ whiteSpace: 'nowrap' }}
  cellsSx?: SxProps<Theme>;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  columns,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  cellsSx,
}: Props) {
  return (
    <TableHead sx={sx}>
      <TableRow>
        {/* {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )} */}

        {columns
          .filter((c) => c.visible || c.internal)
          .map((headCell) => {
            if (headCell.type === 'checkbox' && onSelectAllRows) {
              return (
                <TableCell key={headCell.id} padding="checkbox">
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onSelectAllRows(event.target.checked)
                    }
                  />
                </TableCell>
              );
            }

            if (headCell.internal) {
              return (
                <TableCell key={headCell.id} sx={cellsSx}>
                  {headCell.label}
                </TableCell>
              );
            }

            return (
              <TableCell
                key={headCell.id}
                align={headCell.align || 'left'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                  cursor: onSort ? 'pointer' : 'inherit',
                  ...(onSort
                    ? {
                        '&:hover': {
                          background: (theme) => theme.palette.grey[300],
                        },
                      }
                    : {}),
                  ...cellsSx,
                }}
                onClick={onSort ? () => onSort(headCell.id) : undefined}
              >
                {onSort ? (
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    sx={{
                      textTransform: 'capitalize',
                      textWrap: 'nowrap',
                      '& .MuiTableSortLabel-icon': {
                        color: (theme) => `${theme.palette.common.black} !important`,
                      },
                      '&:hover': {
                        color: 'inherit',
                      },
                      '&.Mui-active': {
                        color: 'initial',
                      },
                    }}
                  >
                    {headCell.label}

                    {orderBy === headCell.id ? (
                      <Box sx={{ ...visuallyHidden }}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            );
          })}
      </TableRow>
    </TableHead>
  );
}
