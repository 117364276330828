import { ImportDataSchemaForm } from '@/schemas/importData';
import useSteps from './steps/useSteps';
import { useSnackbar } from 'notistack';
import useStripe from '@hooks/useStripe';
import { useMemo } from 'react';
import { calcDonorPaywall } from '@/guards/PaywallDonorGuard';
import useDonation from '@hooks/useDonation';
import { processImportData } from '@fire/functions';
import useOrg from '@hooks/useOrg';
import { useParams } from 'react-router';
import * as analytics from '@fire/analytics';

// ----------------------------------------------------------------------
export default function ImportData() {
  const { enqueueSnackbar } = useSnackbar();
  const { donors } = useDonation();
  const { subscription, isFreeTrial } = useStripe();
  const { org } = useOrg();

  const onComplete = (stepData: ImportDataSchemaForm) => onSubmit(stepData);
  
  // Did we access from an Import menu item from the Donations View (true) 
  // or the Donors View (false)?
  const params = useParams();
  const fromDonation = params.source === 'donations';
  
  const paywallWarning = useMemo(() => {
    const planLimit = calcDonorPaywall(subscription);
    if (!isFreeTrial && donors.length >= planLimit) {
      return `Importing donors is not allowed, because you've reached the maximum number of donors (${planLimit}) on your current pricing plan. Upgrade your plan to import or add new donors.`;
    } else return '';
  }, [donors.length, isFreeTrial, subscription]);

  const { ui, setFailed, setError, setSubmittingData, handleClose } = useSteps({
    // Schema: ImportDataSchema,
    onComplete,
    title: 'Import your data in a few quick steps',
    actionText: 'Import',
    paywallWarning: paywallWarning,
    fromDonation: fromDonation,
  });

  const onSubmit = async (stepData: ImportDataSchemaForm) => {
    try {
      setSubmittingData({ isSubmitting: true, progress: 1 });
      // Validate  data
      // await ImportDataSchema.validate(stepData, { abortEarly: false });
      if (!stepData.file) {
        setError('Missing required data!');
        return;
      }

      // Do the importing!
      const { data } = await processImportData({
        orgId: org!.id,
        categoriesMap: stepData.categoriesMap,
        tagsMap: stepData.tagsMap,
        paymentMethodsMap: stepData.paymentMethodsMap,
        importDonors: stepData.donors,
        importDonations: stepData.donations,
      });
      if (!data) throw(new Error('Response not OK'));
      
      enqueueSnackbar('Importing Completed!');
      analytics.donation.importData()
      handleClose();
    } catch (e) {
      enqueueSnackbar('Error in Importing!', { variant: 'error' });
      console.error(e);
      setError(e.errors[0] || 'An error occurred during importing');
      setFailed();
    } finally {
      setSubmittingData({ isSubmitting: false, progress: 0 });
    }
  };

  return ui;
}
