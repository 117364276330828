import { useMemo } from 'react';
import { Box, Button, Stack, Popover } from '@mui/material';
import { useForm } from 'react-hook-form';

import { TColumn, TColumnFilter, ColumnFilterOperator } from '@typedefs/app';
import { TableFilterSchemaForm, tableFilterSchemaResolver } from '@/schemas';
import generateId from '@utils/generateId';
import { FormProvider, RHFAutocomplete, RHFDatePicker, RHFTextField } from '@components/hook-form';
import useFormat from '@hooks/useFormat';

import { getOperator } from './getOperator';

// ----------------------------------------------------------------------
type Props = {
  readonly open: HTMLElement | null;
  readonly onAdd: (filter: TColumnFilter) => void;
  readonly onClose: VoidFunction;
  readonly filterableColumns: TColumn[];
};

// ----------------------------------------------------------------------
export default function FilterModal({ open, onAdd, onClose, filterableColumns }: Props) {
  const { fDateToISO } = useFormat();

  // --------------- defaults ---------------
  const defaultValues: TableFilterSchemaForm = {
    column: null,
    operator: null,
    value: '',
    valueDate: new Date(),
  };

  // --------------- form ---------------
  const methods = useForm<TableFilterSchemaForm>({
    resolver: tableFilterSchemaResolver,
    defaultValues,
  });
  const { reset, setValue, watch, handleSubmit } = methods;
  const watchColumn = watch('column', null);
  const watchOperator = watch('operator', null);

  // --------------- effects ---------------
  const operatorOptions = useMemo(() => getOperator(watchColumn?.type), [watchColumn]);

  // --------------- actions ---------------
  const onColumnChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: TColumn | TColumn[] | null
  ) => {
    setValue('operator', null);
    Array.isArray(newValue) ? setValue('column', newValue[0]) : setValue('column', newValue);
  };

  const handleAdd = (data: TableFilterSchemaForm) => {
    if (!data.column || !data.operator) return;

    onAdd({
      id: generateId(),
      columnId: data.column.id,
      columnType: data.column.type!,
      operator: data.operator.value,
      value: data.column.type! === 'date' ? fDateToISO(data.valueDate) : data.value || '',
    });
    onClose();
    reset();
  };

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <Box sx={{ position: 'relative', minWidth: 250 }} p={2}>
          <Stack spacing={3}>
            <Stack spacing={2} direction="column">
              <RHFAutocomplete
                name="column"
                label="Column"
                fullWidth
                freeSolo={false}
                onChange={onColumnChange}
                options={filterableColumns}
                getOptionLabel={(column) => column.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />

              <RHFAutocomplete
                name="operator"
                label="Operator"
                fullWidth
                freeSolo={false}
                options={operatorOptions}
                getOptionLabel={(operator: any) => operator.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />

              {[ColumnFilterOperator.isEmpty, ColumnFilterOperator.isNotEmpty].includes(
                watchOperator?.value as any
              ) ? null : watchColumn?.type === 'date' ? (
                <RHFDatePicker name="valueDate" label="Date" />
              ) : (
                <RHFTextField name="value" label="Value" />
              )}
            </Stack>

            <Stack sx={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Button type="submit" size="medium" variant="contained">
                Add
              </Button>
            </Stack>
          </Stack>
        </Box>
      </FormProvider>
    </Popover>
  );
}
