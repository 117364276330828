import { useCallback, useEffect, useState } from 'react';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  Button,
  Popover,
  Typography,
  IconButton,
  Link,
  debounce,
} from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  EditorState,
  LexicalEditor,
} from 'lexical';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import { $isHeadingNode } from '@lexical/rich-text';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import Iconify from '@components/Iconify';
import ConfirmDialog from '@components/ConfirmDialog';
import TextSize from './TextSize';
import { defaultState } from '@pages/receipts/Create/steps/ReviewEmail/EmailTemplate';

// ----------------------------------------------------------------------
const supportedBlockTypes = new Set(['paragraph', 'h1', 'h2', 'ul', 'ol']);
const LowPriority = 1;

// ----------------------------------------------------------------------
type Props = {
  onChange: (htmlString: string, stateString: string) => void;
  onReset: (htmlString: string) => void;
};
// ----------------------------------------------------------------------
export default function ToolbarPlugin({ onChange, onReset }: Props) {
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const [varsOpen, setVarsOpen] = useState<HTMLElement | null>(null);
  const handleVarsOpen = (event: React.MouseEvent<HTMLElement>) => setVarsOpen(event.currentTarget);
  const handleVarsClose = () => setVarsOpen(null);
  const [helpOpen, setHelpOpen] = useState<HTMLElement | null>(null);
  const handleHelpOpen = (event: React.MouseEvent<HTMLElement>) => setHelpOpen(event.currentTarget);
  const handleHelpClose = () => setHelpOpen(null);
  const [isConfirming, setConfirming] = useState(false);

  // --------------------
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
        }
      }

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
    }
  }, [editor]);

  // --------------------
  const handleVariable = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      editor.update(() => {
        const selection = $getSelection();
        const variable = e.currentTarget.getAttribute('data-var');
        if (selection) {
          selection.insertText(`{{ ${variable} }}`);
        }
      });
    },
    [editor]
  );

  const handleEditorChange = debounce(
    (editorState: EditorState, editor: LexicalEditor) =>
      editorState.read(() => {
        const stateString = JSON.stringify(editorState.toJSON());
        const htmlString = $generateHtmlFromNodes(editor);
        onChange(htmlString, stateString);
      }),
    400
  );

  const handleReset = async () => {
    editor.setEditorState(editor.parseEditorState(defaultState as string));

    editor.getEditorState().read(() => {
      const htmlString = $generateHtmlFromNodes(editor);
      onReset(htmlString);
      setConfirming(false);
      setHelpOpen(null);
    });
  };

  // --------------------
  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          (_payload, newEditor) => {
            updateToolbar();
            return false;
          },
          LowPriority
        )
      ),
    [editor, updateToolbar]
  );

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', xl: 'center' }}
      flexDirection={{ xs: 'column', xl: 'row' }}
      sx={{
        background: (theme) => theme.palette.common.white,
        p: 0.5,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <OnChangePlugin onChange={handleEditorChange} />

      <Stack direction="row">
        {supportedBlockTypes.has(blockType) && (
          <>
            <TextSize editor={editor} blockType={blockType} />{' '}
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          </>
        )}

        <ToggleButtonGroup aria-label="text formatting" sx={{ border: 'none' }}>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="bold"
            aria-label="bold"
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            selected={isBold}
          >
            <Iconify icon="mdi:format-bold" width={20} height={20} />
          </ToggleButton>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="italic"
            aria-label="italic"
            selected={isItalic}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
          >
            <Iconify icon="mdi:format-italic" width={20} height={20} />
          </ToggleButton>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="underline"
            aria-label="underline"
            selected={isUnderline}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
          >
            <Iconify icon="mdi:format-underline" width={20} height={20} />
          </ToggleButton>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="strikethrough"
            aria-label="strikethrough"
            selected={isStrikethrough}
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')}
          >
            <Iconify icon="mdi:format-strikethrough" width={20} height={20} />
          </ToggleButton>
          {/* <ToggleButton
          sx={{ p: 1, m: '0 !important' }}
          value="link"
          aria-label="link"
          selected={isLink}
          onClick={insertLink}
        >
          <Iconify icon="mdi:link" width={20} height={20} />
        </ToggleButton> */}
        </ToggleButtonGroup>
      </Stack>

      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

      <Stack direction="row">
        <ToggleButtonGroup size="small" aria-label="text alignment" sx={{ border: 'none' }}>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="left"
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')}
          >
            <Iconify icon="mdi:format-align-left" width={20} height={20} />
          </ToggleButton>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="center"
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')}
          >
            <Iconify icon="mdi:format-align-center" width={20} height={20} />
          </ToggleButton>
          <ToggleButton
            sx={{ p: 1, m: '0 !important' }}
            value="right"
            onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')}
          >
            <Iconify icon="mdi:format-align-right" width={20} height={20} />
          </ToggleButton>
          {/* <ToggleButton
          sx={{ p: 1, m: '0 !important' }}
          value="justify"
          onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')}
        >
          <Iconify icon="mdi:format-align-justify" width={20} height={20} />
        </ToggleButton> */}
        </ToggleButtonGroup>

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

        <Button
          variant="outlined"
          onClick={handleVarsOpen}
          startIcon={<Iconify icon="fluent:braces-variable-20-regular" />}
          sx={{ textWrap: 'nowrap' }}
        >
          Add Variable
        </Button>
        <Popover
          open={Boolean(varsOpen)}
          anchorEl={varsOpen}
          onClose={handleVarsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Stack direction="row" p={2} spacing={2}>
            <Stack spacing={0} sx={{ '& > button': { marginRight: 'auto', minWidth: 'auto' } }}>
              <Typography variant="subtitle2">Donor</Typography>
              <Button size="small" data-var="donor.firstName" onClick={handleVariable}>
                First name
              </Button>
              <Button size="small" data-var="donor.lastName" onClick={handleVariable}>
                Last name
              </Button>
              <Button size="small" data-var="donor.fullName" onClick={handleVariable}>
                Full name
              </Button>

              <Typography variant="subtitle2">Donations</Typography>
              <Button size="small" data-var="donations.thisYearTotal" onClick={handleVariable}>
                This year total
              </Button>
            </Stack>

            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            <Stack spacing={0} sx={{ '& > button': { marginRight: 'auto', minWidth: 'auto' } }}>
              <Typography variant="subtitle2">Organization</Typography>
              <Button size="small" data-var="org.name" onClick={handleVariable}>
                Name
              </Button>
              <Button size="small" data-var="org.phone" onClick={handleVariable}>
                Phone
              </Button>
              <Button size="small" data-var="org.fullAddress" onClick={handleVariable}>
                Full address
              </Button>

              <Typography variant="subtitle2">Signatory</Typography>
              <Button size="small" data-var="signatory.name" onClick={handleVariable}>
                Name
              </Button>
              <Button size="small" data-var="signatory.position" onClick={handleVariable}>
                Position
              </Button>
              <Button size="small" data-var="signatory.email" onClick={handleVariable}>
                Email
              </Button>
            </Stack>
          </Stack>
        </Popover>

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

        <IconButton onClick={handleHelpOpen}>
          <Iconify icon="material-symbols:info-outline" width={22} height={22} />
        </IconButton>
        <ConfirmDialog
          open={isConfirming}
          onClose={() => setConfirming(false)}
          onConfirm={handleReset}
        />
        <Popover
          open={Boolean(helpOpen)}
          anchorEl={helpOpen}
          onClose={handleHelpClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Stack p={2} spacing={2} maxWidth={280}>
            <Typography variant="body2">
              Your updates will be saved for next time once you click final submission.
            </Typography>

            <Typography variant="body2">
              Unhappy with your email changes?{' '}
              <Link
                component="button"
                variant="body2"
                onClick={() => setConfirming(true)}
                sx={{ mt: '-3px' }}
              >
                Reset
              </Link>{' '}
              to the default message.
            </Typography>
          </Stack>
        </Popover>
      </Stack>
    </Stack>
  );
}
