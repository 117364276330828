import { useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { TableDateRange } from '@components/table';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import SortDialog from '@pages/reports/Dialogs/Sort';
import { AvailableSortOrder, SortOrder,sortByOrders } from '@pages/reports/reportSorting';

export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    fMonthYear,
    fCurrency,
    fDateToISO,
  } = useFormat();
  const { donations } = useDonation();
  const { org } = useOrg();

  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([
    { availablesIndex: 0 }
  ]);

  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Month', field: 'date' },
      { label: 'Total', field: 'amount' }
    ],
    []
  );

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Month', width: 50 },
      { name: 'Total', width: 50, align: 'right' },
    ];

    let filtered = filter(donations, (d) =>
      isDateBetween(
        new Date(d.date),
        fDateToDayStart(dateFrom || minDateFrom),
        fDateToDayEnd(dateTo || maxDateTo)
      )
    );

    const grouped = groupBy(filtered, (d) => fDateToISO(new Date(d.date)).slice(0, 7));

    const itemsUnsorted = Object.keys(grouped).map((month) => {
      const total = grouped[month].reduce((sum, donation) => sum + Number(donation.amount), 0);
      const [year, monthIndex] = month.split('-').map(Number);
      const date = new Date(year, monthIndex - 1);
      const formattedMonth = fMonthYear(date);
      return { month: formattedMonth, amount: total, date: date };
    });

    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);

    const items = sorted.map((d) => [
      d.month,
      fCurrency(d.amount)
    ]);


    const totalAmount = filtered.reduce((sum, donation) => sum + Number(donation.amount), 0);
    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `Total` },
      { columns: 1, text: `${fCurrency(totalAmount)}` },
    ];

    return { header, items, summary };
  }, [
    donations,
    fCurrency,
    isDateBetween,
    fDateToDayStart,
    dateFrom,
    minDateFrom,
    fDateToDayEnd,
    dateTo,
    maxDateTo,
    fMonthYear,
    fDateToISO,
    availableSortOrders,
    sortOrders,
  ]);


  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  const ReportDoc = (
    <ReportDocument
      title={name}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Summary by Month"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !blob ||
                !!error
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'total_donations_by_month'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
