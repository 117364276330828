import { HTMLAttributes } from 'react';
import { AutocompleteGetTagProps, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { Category } from '@shared/types';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
export default function renderCategories(
  options: Category.Category[],
  getTagProps: AutocompleteGetTagProps
) {
  return options.map((option, index) => (
    <Tooltip key={option.id} title={option.description} placement="top" enterDelay={600}>
      <Chip
        {...getTagProps({ index })}
        size="small"
        key={option.id}
        label={option.name}
        variant="outlined"
        sx={{ fontSize: (theme) => theme.typography.pxToRem(12) }}
        avatar={
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              marginRight: 8,
              background: option.colorBackground,
            }}
          />
        }
      />
    </Tooltip>
  ));
}

// ----------------------------------------------------------------------
export const renderCategoryOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: Category.Category
) =>
  option.id === 'create-new' ? (
    <li {...props} key="create-new">
      <Stack
        direction="row"
        spacing={0.5}
        sx={{ fontWeight: 700, color: (theme) => theme.palette.primary.dark }}
      >
        <Iconify icon="icons8:plus" width={20} height={20} />
        <Typography variant="body2">Create New Category</Typography>
      </Stack>
    </li>
  ) : (
    <li {...props} key={option.id} style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: '50%',
          marginRight: 8,
          background: option.colorBackground,
        }}
      />
      <Typography>{option.name}</Typography>
    </li>
  );

// ----------------------------------------------------------------------
export const CategoryItem = ({
  category,
  text,
}: {
  category: Category.Category;
  text?: string;
}) => (
  <Tooltip title={category.description} placement="top" enterDelay={600}>
    <Stack direction="row" alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 16,
          minWidth: 16,
          height: 16,
          minHeight: 16,
          margin: 0.5,
          marginRight: 1,
          borderRadius: '50%',
          background: category?.colorBackground || undefined,
        }}
      />
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: text || category?.name || '',
        }}
      />
    </Stack>
  </Tooltip>
);
