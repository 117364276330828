import { useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Document, PDFViewer, BlobProvider, Page, View } from '@react-pdf/renderer';
import filter from 'lodash/filter';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';

import Dialog from '@components/Dialog';
import { ReportProps } from '@pages/reports/ReportCard';
import PdfMailingLabels from '@components/pdf/PdfMailingLabels';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TDonorWithDonations } from '@typedefs/donation';
import { sortBy, uniq } from 'lodash';
import { PageSize } from '@typedefs/app';
import { TableDateRange } from '@components/table';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import { PdfTableData, PdfTableHeaderProps } from '@components/pdf';

// Create styles

// ----------------------------------------------------------------------
export default function Report({ id }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateBetween,
    fFullName,
    fReversedName,
    fAddressLines,
    fLongDate,
    getDateFormat,
    fDateToDayStart,
    fDateToDayEnd,
    fDateToYearStart,
    fDateToYearEnd,
  } = useFormat();
  const { donorsWithDonations, donors, receipts } = useDonation();

  const [minDateFrom, maxDateTo, doingReceipts] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31), id === 'receipt-mailing-labels'],
    [id]
  );

  // ---------- form state
  // const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  // const [size, setSize] = useState<PageSize>('letter');
  const [labelsPerPage, setLabelsPerPage] = useState(20);
  const [startingTop, setStartingTop] = useState(0.65);
  const [startingLeft, setStartingLeft] = useState(0.35);
  const [skipLabels, setSkipLabels] = useState<number>();
  const [onlyWithDonations, setOnlyWithDonations] = useState(false);
  const [onlyWithoutEmail, setOnlyWithoutEmail] = useState(true);
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    doingReceipts ? new Date() : fDateToYearStart(new Date())
  );
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  // ---------- memo
  const data: PdfTableData = useMemo(() => {
    // This header is only used with the CSV export
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 26 },
      { name: 'Addr1', width: 16 },
      { name: 'Addr2', width: 12, align: 'right' },
      { name: 'Addr3', width: 20 },
      { name: 'Addr4', width: 24 },
    ];

    let unsortedItems: { fullName: string; reversedName: string; address: string[] }[] = [];
    if (doingReceipts) {
      const filteredReceipts = receipts.filter((r) =>
        isDateBetween(
          new Date(r.date),
          fDateToDayStart(dateFrom || minDateFrom),
          fDateToDayEnd(dateFrom || minDateFrom)
        )
      );
      const filteredDonors = filteredReceipts
        .map((r) => donors.find((d) => d.id === r.donorId))
        .filter((d) => !onlyWithoutEmail || !d!.email);

      unsortedItems = uniq(filteredDonors).map((d) => ({
        fullName: fFullName(d),
        reversedName: fReversedName(d),
        address: fAddressLines(d?.address),
      }));
    } else {
      let filteredDonors: TDonorWithDonations[] = [];
      if (onlyWithDonations) {
        filteredDonors = filter(
          donorsWithDonations,
          (donor) =>
            donor.donations.filter((donation) =>
              isDateBetween(
                new Date(donation.date),
                fDateToDayStart(dateFrom || minDateFrom),
                fDateToDayEnd(dateTo || maxDateTo)
              )
            ).length > 0
        );
      } else {
        filteredDonors = donorsWithDonations;
      }
      unsortedItems = filteredDonors.map((d) => ({
        fullName: fFullName(d),
        reversedName: fReversedName(d),
        address: fAddressLines(d?.address),
      }));
    }
    /* TESTING: add more labels!
    const len = data.length;
    for (let i = len; i <= 35; ++i) data[i] = [`${data[0][0]} #${i}`, ...data[0].slice(1)];
    */
    const sorted = sortBy(unsortedItems, (d) => d.reversedName.toUpperCase());
    const items = sorted.map((s) => [s.fullName, ...s.address] as string[]);
    return { header, items };
  }, [
    dateFrom,
    dateTo,
    doingReceipts,
    donors,
    donorsWithDonations,
    fAddressLines,
    fDateToDayEnd,
    fDateToDayStart,
    fFullName,
    fReversedName,
    isDateBetween,
    maxDateTo,
    minDateFrom,
    onlyWithDonations,
    onlyWithoutEmail,
    receipts,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  // Because this report doesn't use PdfTable, have to replicate
  // the surrounding <Document> element, then <PdfMailingLabels> does the rest.
  const ReportDoc = useMemo(
    () => (
      <Document
        title={`${doingReceipts ? 'Receipt' : 'Donor'} Mailing Labels - ${fLongDate(new Date())}`}
        author="Software4Nonprofits"
        creator="s4np web-app"
        producer="s4np web-app"
        pdfVersion="1.7"

        // onRender={(blob) => console.log('doc render: ', blob)}
      >
        {data.items.length > 0 ? (
          <PdfMailingLabels
            data={data.items}
            labelsPerPage={labelsPerPage}
            startingTop={startingTop}
            startingLeft={startingLeft}
            skipLabels={skipLabels || 0}
            size={'letter'}
          />
        ) : (
          <Page size={PageSize.letter}>
            <View />
          </Page>
        )}
      </Document>
    ),
    [data, doingReceipts, fLongDate, labelsPerPage, skipLabels, startingLeft, startingTop]
  );

  const handleChangeLabelsPerPage = (e: SelectChangeEvent<number>) => {
    setLabelsPerPage(Number(e.target.value));
  };

  return (
    <Dialog
      title={`${doingReceipts ? 'Receipt' : 'Donor'} Mailing Labels Report`}
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!blob || !!error || data.items.length === 0}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={`${doingReceipts ? 'receipt' : 'donor'}_mailing_labels`}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            {/*
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
          */}
            <Stack spacing={1} direction="column" width={'100%'}>
              {doingReceipts && (
                <>
                  <DesktopDatePicker
                    format={getDateFormat()}
                    label="Receipts Date"
                    value={dateFrom}
                    onChange={(v, context) => {
                      !context.validationError && v && setDateFrom(v);
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onlyWithoutEmail}
                          onChange={() => {
                            setOnlyWithoutEmail(!onlyWithoutEmail);
                          }}
                        />
                      }
                      label={'Skip Donors with Email Addresses'}
                    />
                  </FormGroup>
                </>
              )}
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Labels per Page
              </Typography>
              <Select
                id="select-labels-per-page"
                value={labelsPerPage}
                onChange={handleChangeLabelsPerPage}
              >
                <MenuItem key={20} value={20}>
                  20 (1" x 4")
                </MenuItem>
                <MenuItem key={30} value={30}>
                  30 (1" x 2.65")
                </MenuItem>
              </Select>
              <Stack spacing={2} direction="column" width={'100%'}>
                <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                  Starting Margins and Skipped Labels
                </Typography>
                <Stack spacing={2} direction="row" width={'100%'}>
                  <TextField
                    label="Left Margin (in)"
                    value={startingLeft}
                    onChange={(e) => setStartingLeft(Number(e.target.value))}
                    type="number"
                  />
                  <TextField
                    label="Top Margin (in)"
                    value={startingTop}
                    onChange={(e) => setStartingTop(Number(e.target.value))}
                    type="number"
                  />
                </Stack>
                <TextField
                  label="Initial Labels to Skip"
                  value={skipLabels}
                  onChange={(e) => setSkipLabels(Number(e.target.value))}
                  type="number"
                />
              </Stack>
              {!doingReceipts && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onlyWithDonations}
                          onChange={() => {
                            setOnlyWithDonations(!onlyWithDonations);
                          }}
                        />
                      }
                      label={'Only with Donations in a Date Range'}
                    />
                  </FormGroup>
                  {onlyWithDonations && (
                    <TableDateRange
                      dateFrom={dateFrom}
                      setDateFrom={setDateFrom}
                      dateTo={dateTo}
                      setDateTo={setDateTo}
                    />
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
