import { useEffect, useMemo, useState } from 'react';
import useAuth from '@hooks/useAuth';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';

// ----------------------------------------------------------------------
export default function PendoProvider() {
  const [init, setInit] = useState(false);
  const { user } = useAuth();
  const { org } = useOrg();
  const { fAddress } = useFormat();

  const visitor = useMemo(
    () =>
      user
        ? {
            id: user.id,
            email: user.email,
            displayName: user.displayName,
            emailVerified: user.emailVerified,
            providers: user.providers,
            createdAt: user._meta?.createdAt,
          }
        : undefined,
    [user]
  );
  const account = org
    ? {
        id: org.id,
        name: org.name,
        address: fAddress(org.address),
        language: org.language,
        currency: org.currency,
        userCount: Object.keys(org.users).length,
        customerId: org.subscriptions?.customerId,
        subscriptionStatus: org.subscriptions?.donation?.subscriptionStatus,
      }
    : undefined;

  useEffect(() => {
    if (init) {
      if (visitor) {
        (window as any).pendo?.identify({
          visitor,
          account,
        });
      } else {
        // assumption that we need to clean state before new one jumps in
        // (window as any).pendo.clearSession()
        // docs: https://agent.pendo.io/public/Identity
      }
    } else {
      if (visitor) {
        (window as any).pendo?.initialize({
          visitor,
          account,
        });
        setInit(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitor, account]);

  return null;
}
