import Iconify from '@components/Iconify';
import PATHS from '@routes/paths';

import { Role } from '@shared/types/organization';
import { NavListProps } from './Navbar/NavbarSection/NavList';

// ----------------------------------------------------------------------
const getIcon = (icon: string) => <Iconify icon={icon} sx={{ width: 1, height: 1 }} />;

export type TNavConfig = {
  key: string;
  subheader?: string;
  items: NavListProps[];
};

const navConfig: TNavConfig[] = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'Overview',
  //       path: PATHS.org.overview,
  //       icon: getIcon('ic:sharp-dashboard-customize'),
  //     },
  //     // { title: 'Two', path: PATHS.org.two, icon: getIcon('carbon:shopping-cart') },
  //     // {
  //     //   title: 'Three',
  //     //   path: PATHS.org.three,
  //     //   icon: getIcon('ant-design:line-chart-outlined'),
  //     // },
  //   ],
  // },
  // DONATIONS
  // ----------------------------------------------------------------------
  {
    key: 'donations',
    // subheader: 'donations',
    items: [
      {
        title: 'Overview',
        path: PATHS.org.overview,
        icon: getIcon('material-symbols:dashboard-outline'),
        roles: [Role.editor, Role.contributor, Role.readOnly],
      },
      {
        title: 'Donors',
        path: PATHS.org.donors.root,
        icon: getIcon('ic:outline-people'),
        roles: [Role.editor, Role.contributor, Role.readOnly, Role.donorOnly],
      },
      {
        title: 'Donations',
        path: PATHS.org.donations.root,
        icon: getIcon('akar-icons:money'),
        roles: [Role.editor, Role.contributor, Role.readOnly],
      },
      // {
      //   title: 'Tags',
      //   path: PATHS.org.tags,
      //   icon: getIcon('icon-park-solid:label'),
      // },
      {
        title: 'Reports',
        path: PATHS.org.reports.root,
        icon: getIcon('mdi:report-box-outline'),
        roles: [Role.editor, Role.contributor, Role.readOnly],
      },
      {
        title: 'Receipts',
        path: PATHS.org.receipts.root,
        icon: getIcon('material-symbols:receipt-long-outline'),
        roles: [Role.editor, Role.contributor, Role.readOnly],
      },
      // {
      //   title: 'Reports',
      //   path: PATHS.org.report.root,
      //   icon: getIcon('carbon:report'),
      //   children: [
      //     { title: 'Five', path: PATHS.org.report.five },
      //     { title: 'Six', path: PATHS.org.report.six },
      //   ],
      // },
    ],
  },

  // SETTINGS
  // ----------------------------------------------------------------------
  {
    key: 'settings',
    subheader: 'settings',
    items: [
      // {
      //   title: 'Notifications',
      //   path: PATHS.org.settings.account,
      //   icon: getIcon('healthicons:ui-user-profile'),
      // },
      {
        title: 'Organization',
        path: PATHS.org.settings.org.root,
        icon: getIcon('mdi:building'),
        roles: [Role.editor, Role.contributor, Role.donorOnly],
      },

      {
        title: 'My Account',
        path: PATHS.org.settings.account.root,
        icon: getIcon('iconoir:profile-circle'),
        roles: Object.values(Role),
      },
    ],
  },
];

export default navConfig;
