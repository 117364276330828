import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collection, onSnapshot, query, Unsubscribe, where, documentId } from 'firebase/firestore';
import db from '@fire/store';

import type {
  TOrgGetUsersReq,
  TOrgInitReq,
  TOrgInitRes,
  TOrgState,
  TOrgSwitchReq,
  TOrgSwitchRes,
} from '@typedefs/org';
import type { Organization, Invitation, Feedback, User } from '@shared/types';
import * as fn from '@fire/functions';
import * as analytics from '@fire/analytics';
import { dispatch, useState } from '../store';
import { refreshToken } from './auth';
import * as donation from './donation';
import * as view from './view';

// ----------------------------------------------------------------------
const initialState: TOrgState = {
  // global org state
  isInitialized: false,
  orgs: [],

  // dedicated org state
  orgId: undefined,
  org: undefined,
  orgInitialized: false,
};

const slice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    // --------------- org ---------------
    init: (state: TOrgState, action: PayloadAction<TOrgInitRes>) => {
      // in case we fail to retrieve any org, clear up orgId from cache
      if (!action.payload.orgs.length) {
        state.orgId = undefined;
      }
      if (!action.payload.orgs.find((o) => o.id === state.orgId)) {
        state.orgId = undefined;
      }
      state.orgs = action.payload.orgs;
      state.isInitialized = true;
      analytics.firestore.orgsLoaded(action.payload.orgs.length);
    },

    updateOrgs: (state: TOrgState, action: PayloadAction<TOrgInitRes>) => {
      const { orgs } = action.payload;
      state.orgs = orgs;
    },

    switchOrg: (state: TOrgState, action: PayloadAction<TOrgSwitchRes>) => {
      state.org = action.payload.org;
      state.orgId = action.payload.org?.id;
      state.orgInitialized = true;
    },

    resetOrg: (state: TOrgState) => {
      state.org = undefined;
      state.orgInitialized = false;
    },

    logout: (state: TOrgState) => {
      orgsListener?.();
      return initialState;
    },
  },
});

export default slice.reducer;
export const { logout } = slice.actions;

// ----------------------------------------------------------------------
// called from auth slice to further load the app
let orgsListener: Unsubscribe | null = null;
let orgsInit = false;
export function init({ userId }: TOrgInitReq) {
  return async () => {
    orgsListener?.();
    orgsInit = false;

    if (!userId) return;
    const impersonate = window.location.pathname.match(/\/impersonate\/(.*)/)?.[1];
    const qWhere = impersonate
      ? where(documentId(), '==', impersonate)
      : where(`users.${userId}`, '!=', false);

    // get all orgs that user has access to
    const q = query(
      collection(db, 'organizations'),
      qWhere
      // NOTE: we can't use multiple compact queries, so we need to filter out on the client
      // https://stackoverflow.com/questions/52065946/firestore-compound-query-with
      // where('_meta.archived', '!=', true),
    );
    orgsListener = onSnapshot(
      q,
      async (snap) => {
        // current org state
        const {
          org: { org: activeOrg, orgs: currentOrgs },
        } = useState();

        // NOTE: this must match how functions package organization
        const orgs = snap.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() }) as Organization.Organization
        );
        // .filter((org) => !org._meta.archived);
        const orgIds = orgs.map((o) => o.id);

        // if updates come, update the orgs passively
        // in case there are no orgs anymore
        if (orgsInit) {
          if (currentOrgs.length !== orgs.length) {
            await dispatch(refreshToken());
            // refresh listeners because now we have latest token
            await enableListeners(orgIds);
          }

          dispatch(slice.actions.updateOrgs({ orgs }));

          // if active org is changed, propagate the update
          snap.docChanges().forEach(({ type, doc }) => {
            if (activeOrg?.id === doc.id) {
              if (type === 'modified') {
                // can soft update the state without recreating switchOrg listeners for donation
                dispatch(
                  slice.actions.switchOrg({
                    org: { id: doc.id, ...doc.data() } as Organization.Organization,
                  })
                );
              } else if (type === 'removed') {
                dispatch(switchOrg({}));
              }
            }
          });
        } else {
          // update donation to listen to all orgs data (we segment in hooks)
          await enableListeners(orgIds);
          dispatch(slice.actions.init({ orgs }));
          orgsInit = true;
        }
      },
      async (error) => {
        orgsInit = true;
        console.log('orgInit error:', error);
        dispatch(slice.actions.init({ orgs: [] }));
      }
    );
  };
}

export function switchOrg({ orgId }: TOrgSwitchReq) {
  return async () => {
    const { org: orgState } = useState();
    // if sub is to the same org, ignore
    if (orgState.org?.id === orgId) {
      return;
    }

    const org = orgState.orgs.find((org) => org.id === orgId);
    dispatch(slice.actions.switchOrg({ org }));
    analytics.setOrgId(org?.id);
  };
}

export function resetOrg() {
  return async () => {
    await dispatch(slice.actions.resetOrg());
  };
}

async function enableListeners(orgIds: string[]) {
  if (!orgIds.length) return;

  await Promise.all([
    dispatch(donation.enableListeners(orgIds)),
    dispatch(view.enableListeners(orgIds)),
  ]);
}

export function enableLock(action: Organization.SetLockReq) {
  return async () => {
    await fn.orgSetLock({ ...action, enable: true });
  };
}

export function disableLock(action: Organization.SetLockReq) {
  return async () => {
    await fn.orgSetLock({ ...action, enable: false });
    console.log('lock disabled');
  };
}

// created org
export function createOrg(action: Organization.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.orgCreate(action);
      // in org listener, jwt is refreshed before sub
      analytics.org.create(data.org.name, data.org.address.country);
      return data.org;
    } catch (e) {
      throw e;
    }
  };
}

export function updateOrg(action: Organization.UpdateReq) {
  return async () => {
    try {
      await fn.orgUpdate(action);
      return true;
    } catch (e) {
      throw e;
    }
  };
}

export function archiveOrg(action: Organization.ArchiveReq) {
  return async () => {
    try {
      const { data } = await fn.orgArchive(action);
      return data.org;
    } catch (e) {
      throw e;
    }
  };
}

export function getOrgUsers({ orgId, userIds }: TOrgGetUsersReq) {
  return async () => {
    try {
      const [
        {
          data: { users },
        },
        {
          data: { invitations },
        },
      ] = await Promise.all([
        fn.userGetById({ userId: userIds }),
        fn.invitationGetAllForOrg({ orgId }),
      ]);
      analytics.firestore.read('users', false, [...users, ...invitations].length);
      return { users, invitations };
    } catch (e) {
      throw e;
    }
  };
}

export function updateOrgUsers(action: Organization.UpdateOrgUsersReq) {
  return async () => {
    const { data } = await fn.orgUpdateUsers(action);
    return data.org;
  };
}

export function deleteEmailSignature(action: Organization.DeleteEmailSignatureReq) {
  return async () => {
    try {
      const { data } = await fn.orgDeleteEmailSignature(action);
      analytics.org.emailSignatureDelete(action.signatureId);
      return data.org;
    } catch (e) {
      throw e;
    }
  };
}

export function checkEmailSignatureState(action: Organization.CheckEmailSignatureStateReq) {
  return async () => {
    try {
      const { data } = await fn.orgCheckEmailSignatureState(action);
      analytics.org.emailSignatureCheck(action.signatureId);
      return data.org;
    } catch (e) {
      throw e;
    }
  };
}

export function triggerEmailSignatureVerification(
  action: Organization.TriggerEmailSignatureVerificationReq
) {
  return async () => {
    try {
      await fn.orgTriggerEmailSignatureVerification(action);
      analytics.org.emailSignatureVerify(action.signatureId);
    } catch (e) {
      throw e;
    }
  };
}

export function createGmailSenderSignature(action: Organization.CreateGmailSenderSignatureAuthReq) {
  return async () => {
    try {
      const {
        data: { authUrl },
      } = await fn.createGmailSenderSignature(action);
      // analytics.org.emailSignatureVerify(action.signatureId);
      return authUrl;
    } catch (e) {
      throw e;
    }
  };
}

export function createInvitation(action: Invitation.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.invitationCreate(action);
      analytics.org.invitationCreate(action.email);
      // its an existing user and we auto-accepted
      if (data.invitation.state === 'accepted') {
        // TODO: any work?
      }

      return data.invitation;
    } catch (e) {
      throw e;
    }
  };
}

export function resolveInvitation(action: Invitation.ResolveReq) {
  return async () => {
    try {
      const { data } = await fn.invitationResolve(action);
      analytics.org.invitationCancel(action.invitationId);
      return data.invitationId;
    } catch (e) {
      throw e;
    }
  };
}

export function confirmEmail(action: User.ConfirmEmailReq) {
  return async () => {
    try {
      const { data } = await fn.confirmEmail(action);
      return data.user;
    } catch (e) {
      throw e;
    }
  };
}

export function triggerEmailConfirmation(action: User.TriggerEmailConfirmationReq) {
  return async () => {
    try {
      const { data } = await fn.triggerEmailConfirmation(action);
      return data.user;
    } catch (e) {
      throw e;
    }
  };
}

export function submitFeedback(action: Feedback) {
  return async () => {
    try {
      await fn.submitFeedback(action);
      analytics.org.feedback();
      return;
    } catch (e) {
      throw e;
    }
  };
}
