import { analytics } from '@/firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

// ----------------------------------------------------------------------
export function orgsLoaded(count: number) {
  firebaseAnalytics.logEvent(analytics, 'firestore-orgs', { loaded: count });
}

export function read(key: string, cache: boolean, read: number) {
  firebaseAnalytics.logEvent(analytics, 'firestore-read', { key, cache, read });
}
