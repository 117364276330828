import { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';
import { parseDateTimezone } from '@redux/slices/donation';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { Category } from '@shared/types';
import FilterCategories from '@components/table/TableFilters/FilterCategories';
import {
  AvailableSortOrder,
  SortOrder,
  orderDescription,
  sortByOrders,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import { TableDateRange } from '@components/table';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDate,
    fDateToISO,
    fDateToYearStart,
    fDateToYearEnd,
    fCurrency,
    fReversedName,
    fJoinWithConjunction,
    isDateBetween,
  } = useFormat();
  const { getDonorById, getPledgeWithActual, pledges, getCategoryById } = useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>([]);
  const [fulfilledType, setFulfilledType] = useState('all');
  const [filterDescription, setFilterDescription] = useState('');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([{ availablesIndex: 0 }]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(() => {
    const arr = [
      { label: 'Name', field: (d: any) => d.name.toUpperCase() },
      { label: 'Start Date', field: (d: any) => fDateToISO(new Date(d.dateStart)) },
      { label: 'Category', field: (d: any) => d.category.toUpperCase() },
      { label: 'Pledge Amount', field: 'amount' },
      { label: 'Actual Amount', field: 'donationsTotal' },
    ];
    return arr;
  }, [fDateToISO]);

  // Set the description of the filter based on the selections
  useEffect(() => {
    let filterDesc = '';
    const fulfilledString = fulfilledType === 'all' ? '' : fulfilledType + ' ';
    if (filterCategories.length) {
      const categoryNames = filterCategories.map((c) => c.name);
      filterDesc = `Only ${fulfilledString}pledges with ${
        filterCategories.length === 1 ? 'the category ' : 'any of the categories '
      } ${fJoinWithConjunction(categoryNames, 'or')}`;
    }
    if (fulfilledString && !filterDesc)
      filterDesc = `Only ${fulfilledString}pledges`;
    setFilterDescription(`${filterDesc}`);
  }, [fJoinWithConjunction, filterCategories, fulfilledType]);

  const donorsAndPledges = useMemo(() => 
    pledges.map((p) => ({
      donor: getDonorById(p.donorId),
      ...getPledgeWithActual(p),
    }))
    .filter((p) => 
      isDateBetween(
        parseDateTimezone(p.dateStart),
        dateFrom || minDateFrom,
        dateTo || maxDateTo
      ) &&
      isDateBetween(parseDateTimezone(p.dateEnd), dateFrom || minDateFrom, dateTo || maxDateTo) &&
      (filterCategories.length === 0 || filterCategories.some((c) => c.id === p.categoryId)) &&
      (fulfilledType === 'all' || (fulfilledType === 'fulfilled' && p.amount <= p.donationsTotal) || (fulfilledType === 'unfulfilled' && p.amount > p.donationsTotal))
    )
  , [dateFrom, dateTo, filterCategories, fulfilledType, getDonorById, getPledgeWithActual, isDateBetween, maxDateTo, minDateFrom, pledges]);

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 26 },
      { name: 'Start Date', width: 15 },
      { name: 'End Date', width: 15 },
      { name: 'Category', width: 20 },
      { name: 'Pledge Amount', width: 12, align: 'right' },
      { name: 'Actual Amount', width: 12, align: 'right' },
    ];

    const itemsUnsorted = donorsAndPledges.map((dp) => ({
      name: fReversedName(dp.donor) || '',
      dateStart: dp.dateStart,
      dateEnd: dp.dateEnd,
      category: !!dp.categoryId ? getCategoryById(dp.categoryId)?.name || '' : '(All Categories)',
      amount: dp.amount,
      donationsTotal: dp.donationsTotal,
    }));
    const pledgesTotal = itemsUnsorted.reduce((n, { amount }) => n + Number(amount), 0);
    const actualsTotal = itemsUnsorted.reduce(
      (n, { donationsTotal }) => n + Number(donationsTotal),
      0
    );
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((s) => [
      s.name,
      fDate(s.dateStart),
      fDate(s.dateEnd),
      s.category,
      fCurrency(s.amount),
      fCurrency(s.donationsTotal),
    ]);
    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `Totals: ${items.length} pledges` },
      { columns: 4, text: `${fCurrency(pledgesTotal)}` },
      { columns: 1, text: `${fCurrency(actualsTotal)}` },
    ];
    return { header, items, summary };
  }, [donorsAndPledges, sortOrders, availableSortOrders, fCurrency, fReversedName, getCategoryById, fDate]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={name}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      filterDescription={filterDescription}
      orderBy={orderDescription(sortOrders, availableSortOrders)}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Pledges and Actuals report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !blob ||
                !!error
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'summary_by_donor'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
            <FilterCategories
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
            />
            <FormControl>
              <InputLabel id="select-include">Include</InputLabel>
              <Select
                value={fulfilledType}
                size="small"
                labelId="select-include"
                label="Include"
                onChange={(e) => setFulfilledType(e.target.value)}
              >
                <MenuItem key={'all'} value={'all'}>
                  All
                </MenuItem>
                <MenuItem key={'fulfilled'} value={'fulfilled'}>
                  Fulfilled
                </MenuItem>
                <MenuItem key={'unfulfilled'} value={'unfulfilled'}>
                  Unfulfilled
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
