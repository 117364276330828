import { Alert, Stack, Typography } from '@mui/material';
import { isUndefined } from 'lodash';

import Iconify from '@components/Iconify';
import BubbleButton from '@components/BubbleButton';
import useOrg from '@hooks/useOrg';

import { ReceiptReissueBatchSchemaForm, ReceiptReissueSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';

// ----------------------------------------------------------------------
export default function ReissueType({
  stepData,
  updateStepData,
}: TStepProps<ReceiptReissueSchemaForm | ReceiptReissueBatchSchemaForm>) {
  const { org } = useOrg();
  const handleDidReceived = (reissue: boolean) => {
    updateStepData({ reissue });
  };

  const notReceived = !isUndefined(stepData.reissue) && !stepData.reissue;
  const received = stepData.reissue;

  return (
    <Stack
      height="100%"
      width="50%"
      margin="auto !important"
      alignItems="center"
      justifyContent="center"
    >
      <Stack alignItems="center" px={10} textAlign="center">
        <Typography variant="h6">Has the donor received the receipt?</Typography>
        <Alert
          severity="info"
          icon={false}
          sx={{
            '.MuiAlert-message': { px: 2 },
            border: (theme) => `1p solid ${theme.palette.grey[600]}`,
            background: (theme) => theme.palette.grey[100],
          }}
        >
          <Typography variant="body2">
            If you answer that you never send it (or donor never received it), the receipt{' '}
            <strong>will not</strong> be marked as{' '}
            <strong>{org?.address.country === 'ca' ? 'Replacement' : 'Duplicate'}</strong> or{' '}
            <strong>Corrected</strong>.
          </Typography>
        </Alert>
      </Stack>

      <Stack width="100%" alignItems="center" flexDirection={{ sm: 'column', md: 'row' }} my={6}>
        <BubbleButton
          selected={notReceived}
          onClick={() => handleDidReceived(false)}
          sx={notReceived ? { border: '1px solid' } : {}}
        >
          <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
            <Iconify icon="mdi:cancel-bold" width={36} height={36} mb={1.5} />
            <Typography variant="subtitle1">Not received / was not sent</Typography>
          </Stack>
        </BubbleButton>

        <Stack
          width={40}
          height={40}
          mx={{ xs: 0, md: 2 }}
          my={{ xs: 2, md: 0 }}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: (theme) => theme.palette.common.white,
          }}
        >
          <Typography variant="button" sx={{ mx: 4 }}>
            OR
          </Typography>
        </Stack>

        <BubbleButton
          selected={received}
          onClick={() => handleDidReceived(true)}
          sx={received ? { border: '1px solid' } : {}}
        >
          <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
            <Iconify icon="mdi:hand-coin" width={36} height={36} mb={1.5} />
            <Typography variant="subtitle1">Received / was sent</Typography>
          </Stack>
        </BubbleButton>
      </Stack>
    </Stack>
  );
}
