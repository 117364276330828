import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import blobToDataUrl, { dataUrlToFile } from '@utils/blobToDataUrl';
import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import { CustomFile } from '@components/upload';
import Image from '@components/Image';
import ImageUploadDialog from '@components/ImageUploadDialog';
import ConfirmDialog from '@components/ConfirmDialog';
import ReceiptPreview from './Preview';

// ----------------------------------------------------------------------
export default function Logo() {
  const { org, updateOrg } = useOrg();
  const { hasAccess } = useRole();
  const { enqueueSnackbar } = useSnackbar();

  const canManage = hasAccess([Role.editor, Role.contributor]);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [file, setFile] = useState<CustomFile | undefined>();
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  // --------------- effects ---------------
  const setFileInitial = useCallback(
    async (file: string) => setFile(await dataUrlToFile(file, 'logo')),
    [setFile]
  );
  useEffect(() => {
    if (org?.logo) {
      setFileInitial(org.logo);
    } else {
      setFile(undefined);
    }
  }, [setFileInitial, org?.logo]);

  // --------------- actions ---------------
  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const handleDeleteClose = () => {
    if (!loading) {
      setConfirmDeleteDialog(false);
    }
  };
  const handleDelete = async () => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!canManage) {
      enqueueSnackbar('You lack permissions', { variant: 'error' });
      return;
    }

    setLoading(true);
    try {
      const res = await updateOrg({ orgId: org.id, update: { logo: undefined } });

      res
        ? enqueueSnackbar('Logo deleted!')
        : enqueueSnackbar('Failed to delete Logo', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar('Failed to add Logo', { variant: 'error' });
    }
    setLoading(false);
    handleDeleteClose();
  };

  const handleAdd = async (blob: Blob) => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!canManage) {
      enqueueSnackbar('You lack permissions', { variant: 'error' });
      return;
    }

    setLoading(true);
    // get dataURL
    const logo = blob ? await blobToDataUrl(blob) : undefined;

    try {
      const res = await updateOrg({
        orgId: org.id,
        update: { logo },
      });

      res
        ? enqueueSnackbar('Logo added!')
        : enqueueSnackbar('Failed to add logo', { variant: 'error' });
      setDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <Card sx={{ p: 2, width: '50%' }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="subtitle2">Logo</Typography>
        {!file ? (
          <Button variant="outlined" onClick={handleOpen}>
            Add
          </Button>
        ) : (
          <Stack direction="row" spacing={0}>
            <Button size="small" sx={{ fontSize: 12, ml: '-4px' }} onClick={() => setPreview(true)}>
              Preview
            </Button>
            {preview && <ReceiptPreview onClose={() => setPreview(false)} />}
            <LoadingButton
              loading={loading}
              color="error"
              size="small"
              sx={{ fontSize: 12, ml: '-4px' }}
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Delete
            </LoadingButton>
          </Stack>
        )}
      </Stack>

      <ConfirmDialog
        open={confirmDeleteDialog}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        loading={loading}
      />

      <ImageUploadDialog
        title="Add Logo"
        submitText="Add"
        loading={loading}
        open={dialogOpen}
        onClose={handleClose}
        onSubmit={handleAdd}
        maxWidth={320}
        maxHeight={120}
      />

      {file && (
        <Box
          sx={{
            position: 'relative',
            width: 'fit-content',
            boxShadow: (theme) => theme.shadows[10],
          }}
        >
          <Image
            alt="Dog"
            src={file.preview}
            sx={{
              top: 8,
              left: 8,
              zIndex: 8,
              '& img': {
                objectFit: 'contain',
                maxWidth: 320,
                maxHeight: 120,
              },
            }}
          />
        </Box>
      )}
    </Card>
  );
}
