import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material';

// ----------------------------------------------------------------------
type Props = SelectProps & {
  name: string;
  children: React.ReactNode;
  helperText?: string;
};

export default function RHFSelect({ name, children, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <FormControl fullWidth={other.fullWidth} required={other.required}>
          {other.label && <InputLabel id={other.labelId}>{other.label}</InputLabel>}
          <Select inputRef={ref} {...field} labelId={other.labelId} label={other.label} {...other}>
            {children}
          </Select>
          {helperText && <FormHelperText sx={{ mt: 0 }}>{helperText}</FormHelperText>}
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
