import { useCallback } from 'react';
import { createSelector } from '@reduxjs/toolkit';

import { useDispatch, useSelector } from '@redux/store';
import { View, Organization } from '@shared/types';
import { createView, deleteView, updateView } from '@redux/slices/view';
import { TViewState } from '@typedefs/view';
import useOrg from '@hooks/useOrg';

const selectViews = createSelector(
  [(s: TViewState) => s.views, (s, org?: Organization.Organization) => org],
  (views, org) => views.filter((view) => view.orgId === org?.id)
);

// ----------------------------------------------------------------------
const useView = () => {
  const { org } = useOrg();
  const dispatch = useDispatch();

  // WARNING: state is full with data from all orgs, never use it without filtering!!!
  const _viewState = useSelector((s) => s.view);
  const views: View.View[] = selectViews(_viewState, org);

  // -------------------- selectors --------------------
  const getViewById = useCallback((viewId?: string) => views.find((v) => v.id === viewId), [views]);

  // -------------------- hook --------------------
  return {
    views,
    createView: useCallback((p: View.CreateReq) => dispatch(createView(p)), [dispatch]),
    updateView: useCallback((p: View.UpdateReq) => dispatch(updateView(p)), [dispatch]),
    deleteView: useCallback((p: View.DeleteReq) => dispatch(deleteView(p)), [dispatch]),

    getViewById,
  };
};

export default useView;
