import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collection, onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { isString } from 'lodash';
import { addMinutes } from 'date-fns';

import type { TDonationState, ListenerData } from '@typedefs/donation';
import type {
  Donor,
  Donation,
  Pledge,
  Tag,
  Receipt,
  Category,
  PaymentMethod,
  RecurringDonation,
  ImportData,
} from '@shared/types';
import db from '@fire/store';
import * as fn from '@fire/functions';
import * as analytics from '@fire/analytics';
import { dispatch, useState } from '../store';

// NOTE: this adjusts dates in ISO format without time, YYYY-MM-DD
// by default, each browser casts time into user timezone, which makes the date wrong
// this removes time time and sets date to midnight
export const parseDateTimezone = (rawDate: Date | string | number) => {
  if (isString(rawDate) && rawDate.length === 10) {
    const date = new Date(rawDate);
    const offset = date.getTimezoneOffset();
    return addMinutes(date, offset);
  }
  return new Date(rawDate);
};

// ----------------------------------------------------------------------
const initialState: TDonationState = {
  donors: [],
  donations: [],
  recurringDonations: [],
  pledges: [],
  categories: [],
  paymentMethods: [],
  tags: [],
  receipts: [],
};

const slice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    // general
    logout: (state: TDonationState) => initialState,
    clearListenerData: (state: ListenerData) => {
      state.donors = [];
      state.donations = [];
      state.recurringDonations = [];
      state.pledges = [];
      state.categories = [];
      state.paymentMethods = [];
      state.tags = [];
      state.receipts = [];
    },

    listenerUpdate: (state: TDonationState, action: PayloadAction<ListenerData>) => {
      state.donors = action.payload.donors || state.donors;
      state.donations = action.payload.donations
        ? action.payload.donations.map((d) => ({ ...d, date: parseDateTimezone(d.date) }))
        : state.donations;
      state.recurringDonations = action.payload.recurringDonations || state.recurringDonations;
      state.pledges = action.payload.pledges || state.pledges;
      state.categories = action.payload.categories || state.categories;
      state.paymentMethods = action.payload.paymentMethods || state.paymentMethods;
      state.tags = action.payload.tags || state.tags;
      state.receipts = action.payload.receipts
        ? action.payload.receipts.map((r) => ({ ...r, date: parseDateTimezone(r.date) }))
        : state.receipts;
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------
export function logout() {
  return async () => {
    await disableListeners();
    dispatch(slice.actions.logout());
  };
}

export function disableListeners() {
  return async () => {
    listenerDonors?.();
    listenerDonations?.();
    listenerRecurringDonations?.();
    listenerPledges?.();
    listenerReceipts?.();
    listenerCategories?.();
    listenerPaymentMethods?.();
    listenerTags?.();
    dispatch(slice.actions.clearListenerData());
  };
}

export function enableListeners(orgIds: string[]) {
  return async () => {
    await dispatch(disableListeners());

    try {
      [
        listenerDonors,
        listenerDonations,
        listenerRecurringDonations,
        listenerPledges,
        listenerReceipts,
        listenerCategories,
        listenerPaymentMethods,
        listenerTags,
      ] = await Promise.all([
        createListener(orgIds, 'donors'),
        createListener(orgIds, 'donations'),
        createListener(orgIds, 'recurringDonations'),
        createListener(orgIds, 'pledges'),
        createListener(orgIds, 'receipts'),
        createListener(orgIds, 'categories'),
        createListener(orgIds, 'paymentMethods'),
        createListener(orgIds, 'tags'),
      ]);
    } catch (e) {
      console.error('Donation listeners permission failure: ', e);
    }
  };
}

// #region ---------- CREATE ----------
export function createDonor(payload: Donor.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.donorCreate(payload);
      // dispatch(slice.actions.createDonor(data));
      analytics.donation.donorCreate();
      return data.donor;
    } catch (e) {
      throw e;
    }
  };
}

export function createDonation(payload: Donation.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.donationCreate(payload);
      // dispatch(slice.actions.createDonation(data));
      analytics.donation.donationCreate();
      return data.donation;
    } catch (e) {
      throw e;
    }
  };
}

export function createBatchDonation(payload: Donation.CreateBatchReq) {
  return async () => {
    try {
      await fn.donationBatchCreate(payload);
      analytics.donation.donationBatchCreate(payload.donations.length);
      return;
    } catch (e) {
      throw e;
    }
  };
}

export function createPledge(payload: Pledge.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.pledgeCreate(payload);
      // dispatch(slice.actions.createPledge(data));
      analytics.donation.pledgeCreate();
      return data.pledge;
    } catch (e) {
      throw e;
    }
  };
}
export function createCategory(payload: Category.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.categoryCreate(payload);
      // dispatch(slice.actions.createCategory(data));
      analytics.donation.categoryCreate();
      return data.category;
    } catch (e) {
      throw e;
    }
  };
}

export function createPaymentMethod(payload: PaymentMethod.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.paymentMethodCreate(payload);
      // dispatch(slice.actions.createPaymentMethod(data));
      analytics.donation.paymentMethodCreate();
      return data.paymentMethod;
    } catch (e) {
      throw e;
    }
  };
}

export function createTag(payload: Tag.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.tagCreate(payload);
      // dispatch(slice.actions.createTag(data));
      analytics.donation.tagCreate();
      return data.tag;
    } catch (e) {
      throw e;
    }
  };
}

export function createRecurringDonation(payload: RecurringDonation.CreateReq) {
  return async () => {
    try {
      const { data } = await fn.recurringDonationCreate(payload);
      analytics.donation.recurringDonationCreate();
      return data.recurringDonation;
    } catch (e) {
      throw e;
    }
  };
}

export function updateRecurringDonation(payload: RecurringDonation.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.recurringDonationUpdate(payload);
      analytics.donation.recurringDonationUpdate();
      return data.recurringDonation;
    } catch (e) {
      throw e;
    }
  };
}

// #endregion

// #region ---------- UPDATE ----------
export function updateDonor(payload: Donor.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.donorUpdate(payload);
      // dispatch(slice.actions.updateDonor(data));
      analytics.donation.donorUpdate();
      return data.donor;
    } catch (e) {
      throw e;
    }
  };
}

export function assignMemberNumbers(payload: Donor.AssignMemberNumbersReq) {
  return async () => {
    try {
      await fn.assignMemberNumbers(payload);
      analytics.donation.memberAssign();
      return;
    } catch (e) {
      throw e;
    }
  };
}

export function updateDonation(payload: Donation.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.donationUpdate(payload);
      // dispatch(slice.actions.updateDonation(data));
      analytics.donation.donationUpdate();
      return data.donation;
    } catch (e) {
      throw e;
    }
  };
}

export function updateCategory(payload: Category.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.categoryUpdate(payload);
      // dispatch(slice.actions.updateCategory(data));
      analytics.donation.categoryUpdate();
      return data.category;
    } catch (e) {
      throw e;
    }
  };
}

export function updatePledge(payload: Pledge.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.pledgeUpdate(payload);
      // dispatch(slice.actions.updatePledge(data));
      analytics.donation.pledgeUpdate();
      return data.pledge;
    } catch (e) {
      throw e;
    }
  };
}

export function updatePaymentMethod(payload: PaymentMethod.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.paymentMethodUpdate(payload);
      // dispatch(slice.actions.updatePaymentMethod(data));
      analytics.donation.paymentMethodUpdate();
      return data.paymentMethod;
    } catch (e) {
      throw e;
    }
  };
}

export function updateTag(payload: Tag.UpdateReq) {
  return async () => {
    try {
      const { data } = await fn.tagUpdate(payload);
      // dispatch(slice.actions.updateTag(data));
      analytics.donation.tagUpdate();
      return data.tag;
    } catch (e) {
      throw e;
    }
  };
}

export function processImportData(payload: ImportData.ImportDataReq) {
  return async () => {
    try {
      return await fn.processImportData(payload);
    } catch (e) {
      throw e;
    }
  };
}
// #endregion

// #region ---------- DELETE ----------
export function deleteDonor(payload: Donor.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.donorDelete(payload);
      // dispatch(slice.actions.deleteDonor(data));
      analytics.donation.donorDelete();
      return data.donorId;
    } catch (e) {
      throw e;
    }
  };
}

export function deleteDonation(payload: Donation.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.donationDelete(payload);
      // dispatch(slice.actions.deleteDonation(data));
      analytics.donation.donationDelete();
      return data.donationId;
    } catch (e) {
      throw e;
    }
  };
}

export function deletePledge(payload: Pledge.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.pledgeDelete(payload);
      // dispatch(slice.actions.deletePledge(data));
      analytics.donation.pledgeDelete();
      return data.pledgeId;
    } catch (e) {
      throw e;
    }
  };
}

export function deleteCategory(payload: Category.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.categoryDelete(payload);
      // dispatch(slice.actions.deleteCategory(data));
      analytics.donation.categoryDelete();
      return data.categoryId;
    } catch (e) {
      throw e;
    }
  };
}

export function deletePaymentMethod(payload: PaymentMethod.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.paymentMethodDelete(payload);
      // dispatch(slice.actions.deletePaymentMethod(data));
      analytics.donation.paymentMethodDelete();
      return data.paymentMethodId;
    } catch (e) {
      throw e;
    }
  };
}

export function deleteTag(payload: Tag.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.tagDelete(payload);
      // dispatch(slice.actions.deleteTag(data));
      analytics.donation.tagDelete();
      return data.tagId;
    } catch (e) {
      throw e;
    }
  };
}

export function deleteRecurringDonation(payload: RecurringDonation.DeleteReq) {
  return async () => {
    try {
      const { data } = await fn.recurringDonationDelete(payload);
      analytics.donation.recurringDonationDelete();
      return data.recurringDonationId;
    } catch (e) {
      throw e;
    }
  };
}
// #endregion

// #region ---------- RECEIPTS ----------
export function createReceipt(payload: Receipt.CreateReceiptsReq) {
  return async () => {
    try {
      const { data } = await fn.receiptCreateReceipts(payload);
      // await dispatch(slice.actions.createReceipts(data));

      const allDonations = useState().donation.donations;
      const actionDonationIds = data.receipts.map((r) => r.donationIds).flat();
      const receiptingDonations = allDonations.filter((d) => actionDonationIds.includes(d.id));
      const issuedReceipts = data.receipts.map((r) => r.receipt);
      return { issuedReceipts, receiptingDonations };
    } catch (e) {
      throw e;
    }
  };
}

export function reissueReceipt(payload: Receipt.ReissueReceiptReq) {
  return async () => {
    try {
      const { data } = await fn.receiptReissueReceipt(payload);
      // await dispatch(slice.actions.reissueReceipt(data));
      return data;
    } catch (e) {
      throw e;
    }
  };
}

export function reissueReceipts(payload: Receipt.ReissueReceiptsReq) {
  return async () => {
    try {
      const { data } = await fn.receiptReissueReceipts(payload);
      // await dispatch(slice.actions.reissueReceipts(data));
      return data;
    } catch (e) {
      throw e;
    }
  };
}

export function sendReceiptTestEmail(payload: Receipt.SendTestEmailReq) {
  return async () => {
    try {
      await fn.receiptSendTestEmail(payload);
    } catch (e) {
      throw e;
    }
  };
}

export function sendReceiptEmail(payload: Receipt.SendEmailReq) {
  return async () => {
    try {
      await fn.receiptSendEmail(payload);
    } catch (e) {
      throw e;
    }
  };
}
// #endregion

// ----------------------------------------------------------------------
// LISTENERS
let listenerDonors: Unsubscribe | null = null;
let listenerDonations: Unsubscribe | null = null;
let listenerRecurringDonations: Unsubscribe | null = null;
let listenerPledges: Unsubscribe | null = null;
let listenerReceipts: Unsubscribe | null = null;
let listenerCategories: Unsubscribe | null = null;
let listenerPaymentMethods: Unsubscribe | null = null;
let listenerTags: Unsubscribe | null = null;
// ---------- SUBSCRIBE ----------
async function createListener(orgIds: string[], key: string): Promise<Unsubscribe> {
  let firstRun = true;
  let listener: Unsubscribe | null = null;

  return new Promise((resolve, reject) => {
    listener = onSnapshot(
      query(collection(db, key), where('orgId', 'in', orgIds)),
      async (snap) => {
        const data = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        if (firstRun) {
          resolve(listener as Unsubscribe);
          firstRun = false;
        }
        dispatch(slice.actions.listenerUpdate({ [key]: data }));
        analytics.firestore.read(key, snap.metadata.fromCache, data.length);
      },
      reject
    );
  });
}
