import { useEffect, useState } from 'react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

// ----------------------------------------------------------------------
export default function ConfirmButton({
  onClick,
  children,
  loading,
  ...other
}: LoadingButtonProps) {
  const [confirming, setConfirming] = useState(false);

  const handleConfirming = () => {
    setConfirming(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (confirming) setConfirming(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [confirming, setConfirming]);

  return (
    <LoadingButton
      {...other}
      loading={loading}
      startIcon={confirming ? undefined : other.startIcon}
      variant={confirming || loading ? 'contained' : 'text'}
      onClick={confirming ? onClick : handleConfirming}
    >
      {confirming ? 'Confirm' : children}
    </LoadingButton>
  );
}
