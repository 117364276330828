import { useState } from 'react';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ConfirmDialog from '@components/ConfirmDialog';

// ----------------------------------------------------------------------
type Props = {
  readonly isLast?: boolean;
  readonly isSubmitting?: boolean;
  readonly disabled?: boolean;
  readonly hasFailed?: boolean;
  readonly handleBack?: VoidFunction;
  readonly handleNext: VoidFunction;
  readonly errors: string[];
  readonly actionText: string;
};

// ----------------------------------------------------------------------
export default function StepActions({
  isLast,
  isSubmitting,
  disabled,
  hasFailed,
  handleBack,
  handleNext,
  errors,
  actionText,
}: Props) {
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleClose = () => {
    setConfirmDialog(false);
  };

  const handleConfirm = () => {
    setConfirmDialog(false);
    handleNext();
  };

  return (
    <>
      <ConfirmDialog open={confirmDialog} onClose={handleClose} onConfirm={handleConfirm} />

      <Stack direction="row" spacing={2}>
        {handleBack && (
          <Button
            color="inherit"
            disabled={hasFailed || isSubmitting}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
        )}

        {isLast ? (
          <Tooltip
            title={
              hasFailed
                ? 'Receipt issuing failed, please close and start again. If the issue persists, please contact support.'
                : ''
            }
            placement="top-start"
            arrow
          >
            <span>
              <LoadingButton
                size="large"
                variant="contained"
                disabled={hasFailed || disabled}
                loading={isSubmitting}
                onClick={() => setConfirmDialog(true)}
                sx={{ minWidth: 180 }}
              >
                {actionText}
              </LoadingButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              errors.length ? (
                <Stack>
                  {errors.map((e) => (
                    <Typography key={e} variant="body2">
                      - {e}
                    </Typography>
                  ))}
                </Stack>
              ) : (
                ''
              )
            }
            placement="top-start"
            arrow
          >
            <span>
              <Button
                size="large"
                variant="contained"
                disabled={disabled}
                onClick={handleNext}
                sx={{ minWidth: 180 }}
              >
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Stack>
    </>
  );
}
