import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, Link, Container, Typography } from '@mui/material';

import PATHS from '@routes/paths';
import useResponsive from '@hooks/useResponsive';
import Page from '@components/Page';
import Logo from '@components/Logo';

import { RootStyle, HeaderStyle, SectionStyle, ContentStyle } from './styles';
import LoginForm from './LoginForm';

// ----------------------------------------------------------------------
export default function Login() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body1" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle1" component={RouterLink} to={PATHS.register}>
                Get started with DONATION Online
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && <SectionStyle />}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Log in to DONATION Online
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  This is only for our DONATION <strong>Online</strong> product. For Standard or
                  OnDemand versions, go{' '}
                  <Link
                    underline="always"
                    color="text.primary"
                    href="https://www.software4nonprofits.com/pricing-legacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>
                  . For ACCOUNTS, go{' '}
                  <Link
                    underline="always"
                    color="text.primary"
                    href="https://www.software4nonprofits.com/pricing/#accounts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>
                  .
                </Typography>
              </Box>
            </Stack>
            <LoginForm />
            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to={PATHS.register}>
                  Get started with DONATION Online
                </Link>
              </Typography>
            )}{' '}
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              Questions? Contact{' '}
              <Link
                underline="always"
                color="text.primary"
                href="mailto:info@software4nonprofits.com"
                target="_blank"
                rel="noreferrer"
              >
                info@software4nonprofits.com
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
