import { useMemo } from 'react';
import { Card, CardHeader, Box } from '@mui/material';
import { groupBy, sumBy } from 'lodash';

import { Donation } from '@shared/types';
import useFormat from '@hooks/useFormat';
import Chart, { useChart } from '@components/chart';
import useDonation from '@hooks/useDonation';

// ----------------------------------------------------------------------
interface Props {
  readonly currentYearDonations: Donation.Donation[];
  readonly startTime: number;
}

// ----------------------------------------------------------------------
export default function DonationsChart({ currentYearDonations, startTime }: Props) {
  const { fCurrency, isDateBetween, fDateToPreviousYearStart, fDateToPreviousYearEnd } =
    useFormat();
  const { donations } = useDonation();

  // previous year calc
  const previousYearStart = fDateToPreviousYearStart(startTime);
  const previousYearEnd = fDateToPreviousYearEnd(startTime);
  const previousYearDonations = useMemo(
    () =>
      donations.filter((d) => isDateBetween(new Date(d.date), previousYearStart, previousYearEnd)),
    [isDateBetween, donations, previousYearStart, previousYearEnd]
  );

  // segmented data by months
  const prevByMonth = useMemo(() => {
    const donationsByMonth = groupBy(previousYearDonations, (d) => new Date(d.date).getMonth());
    const monthsInYear = new Array(12).fill(0);
    Object.keys(donationsByMonth).forEach(
      (key) => (monthsInYear[parseInt(key)] = sumBy(donationsByMonth[key], 'amount'))
    );
    return monthsInYear;
  }, [previousYearDonations]);
  const currentByMonth = useMemo(() => {
    const donationsByMonth = groupBy(currentYearDonations, (d) => new Date(d.date).getMonth());
    const monthsInYear = new Array(12).fill(0);
    Object.keys(donationsByMonth).forEach(
      (key) => (monthsInYear[parseInt(key)] = sumBy(donationsByMonth[key], 'amount'))
    );
    return monthsInYear;
  }, [currentYearDonations]);

  const chartOptions = useChart({
    colors: ['#2EC4B6', '#C4CDD5'],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    yaxis: {
      labels: {
        formatter: (n) => fCurrency(n, true),
      },
    },
    series: [
      { name: 'Current year', data: currentByMonth },
      { name: 'Previous year', data: prevByMonth },
    ],
  });

  return (
    <Card>
      <CardHeader title="Donation Amount" />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart type="area" series={chartOptions.series} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
