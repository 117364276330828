import { useCallback, useState } from 'react';
import { Tab, Box } from '@mui/material';
import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import Dialog from '@components/Dialog';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PaymentMethods from '@pages/donations/dialogs/Settings/PaymentMethods';
import DonationCategories from '@pages/donations/dialogs/Settings/DonationCategories';
import RecurringDonationList from '@pages/donations/dialogs/Settings/RecurringDonations';

import { View } from '@shared/types';
import useOrg from '@hooks/useOrg';
import { Outlet } from 'react-router';
import Pledges from './Pledges';

type ContextTab = 'categories' | 'payment-methods' | 'recurring-donations' | 'pledges';

type Props = {
  context?: ContextTab;
};

// --------------- effects ---------------
export default function Settings({ context }: Props) {
  const { org } = useOrg();
  const navigate = useNavigate();
  const [value, setValue] = useState<ContextTab>(context || 'categories');
  const [cachedView, setCachedView] = useState<View.View>();

  const handleChange = (event: React.SyntheticEvent, tab: ContextTab) => {
    setValue(tab);

    switch (tab) {
      case 'categories':
        navigate(getPath(PATHS.org.donations.settings.categories, { orgId: org!.id }));
        break;
      case 'payment-methods':
        navigate(getPath(PATHS.org.donations.settings.paymentMethods, { orgId: org!.id }));
        break;
      case 'recurring-donations':
        navigate(getPath(PATHS.org.donations.settings.recurringDonations, { orgId: org!.id }));
        break;
      case 'pledges':
        navigate(getPath(PATHS.org.donations.settings.pledges, { orgId: org!.id }));
        break;
      default:
        console.warn(`Invalid tab ${tab}`);
    }
  };

  const handleClose = useCallback(() => {
    navigate(PATHS.org.donations.root);
  }, [navigate]);

  const clearCache = () => {
    setCachedView(undefined);
  };

  return (
    <>
      <Dialog
        title="Donation Settings"
        onClose={handleClose}
        maxWidth="md"
        sx={{
          '& .MuiDialogContent-root': {
            minHeight: { sx: '100vh', sm: '35vh' },
            maxHeight: { sx: '100vh', sm: '70vh' },
            height: { sx: '100vh', sm: '60vh' },
          },
          '& form': {
            flex: '1 1 auto !important',
          },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Categories" value="categories" />
                <Tab label="Payment Methods" value="payment-methods" />
                <Tab label="Recurring Donations" value="recurring-donations" />
                <Tab label="Pledges" value="pledges" />
              </TabList>
            </Box>
            <TabPanel value="categories">
              <DonationCategories />
            </TabPanel>
            <TabPanel value="payment-methods">
              <PaymentMethods />
            </TabPanel>
            <TabPanel value="recurring-donations">
              <RecurringDonationList cachedView={cachedView} clearCache={clearCache} />
            </TabPanel>
            <TabPanel value="pledges">
              <Pledges />
            </TabPanel>
          </TabContext>
        </Box>
        <Outlet />
      </Dialog>
    </>
  );
}
