import { useEffect, useMemo } from 'react';
import { Alert, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { User } from '@shared/types/user';
import { Organization, Role } from '@shared/types/organization';
import { Invitation } from '@shared/types/invitation';
import { OrganizationInvitationSchemaForm, organizationInvitationSchemaResolver } from '@/schemas';
import { RoleList } from '@typedefs/org';
import useOrg from '@hooks/useOrg';

import Dialog from '@components/Dialog';
import { RHFSelect, RHFTextField } from '@components/hook-form';

// ----------------------------------------------------------------------
type Props = {
  readonly org: Organization;
  readonly users: User[];
  readonly invitations: Invitation[];
  readonly open: boolean;
  readonly handleClose: (inv?: Invitation) => void;
};
// ----------------------------------------------------------------------
export default function InviteUserDialog({ org, users, invitations, open, handleClose }: Props) {
  const { createInvitation } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: OrganizationInvitationSchemaForm = useMemo(
    () => ({
      email: '',
      role: Role.contributor,
    }),
    []
  );

  const methods = useForm<OrganizationInvitationSchemaForm>({
    resolver: organizationInvitationSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = methods;
  const watchedRole = watch('role');

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open, reset, defaultValues]);

  const handleInvite = async (data: OrganizationInvitationSchemaForm) => {
    if (users.find((u) => u.email === data.email)) {
      setError('afterSubmit', { message: 'User is already part of the organization!' });
      return;
    }

    if (
      invitations.filter((inv) => inv.state === 'pending').find((inv) => inv.email === data.email)
    ) {
      setError('afterSubmit', { message: 'User already has a pending invite!' });
      return;
    }

    try {
      const res = await createInvitation({
        orgId: org.id,
        orgName: org.name,
        state: 'pending',
        email: data.email,
        role: data.role,
      });

      handleClose(res);
      reset(defaultValues);
      enqueueSnackbar('Invite sent!');
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      title="Invite users"
      open={open}
      onClose={() => handleClose()}
      maxWidth="sm"
      methods={methods}
      isDirty={isDirty}
      isLoading={isSubmitting}
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          loading={isSubmitting}
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(handleInvite)}
        >
          Invite
        </LoadingButton>
      }
    >
      <Stack spacing={5}>
        <Stack spacing={0.5}>
          <Typography variant="body2">
            Invited users, if they accept, will receive access to your organization & its data.
          </Typography>
          <Typography variant="body2">
            If the user under the email that you are inviting doesn't exist, they will have to
            create an account under the <strong>same email address</strong>!
          </Typography>
        </Stack>

        <Stack spacing={3}>
          <RHFTextField
            name="email"
            label="Invitee email"
            required
            helperText={errors.email?.message}
            inputProps={{ maxLength: 60 }}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFSelect
              fullWidth
              name="role"
              label="Organization role"
              helperText={RoleList.find((r) => r.value === watchedRole)?.helperText}
            >
              {RoleList.map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
            </RHFSelect>

            <div style={{ width: '100%' }} />
          </Stack>
        </Stack>
      </Stack>

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
    </Dialog>
  );
}
