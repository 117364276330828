import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import type { Donor } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// export const donorGetAllForOrg = httpsCallable<Donor.GetAllForOrgReq, Donor.GetAllForOrgRes>(
//   firebaseFunctions,
//   'donor-getAllForOrg'
// );

// ----------------------------------------------------------------------
// Create
export const donorCreate = httpsCallable<Donor.CreateReq, Donor.CreateRes>(
  firebaseFunctions,
  'donor-create'
);

// ----------------------------------------------------------------------
// Update
export const donorUpdate = httpsCallable<Donor.UpdateReq, Donor.UpdateRes>(
  firebaseFunctions,
  'donor-update'
);
export const assignMemberNumbers = httpsCallable<Donor.AssignMemberNumbersReq, void>(
  firebaseFunctions,
  'donor-assignMemberNumbers'
);

// ----------------------------------------------------------------------
// Delete
export const donorDelete = httpsCallable<Donor.DeleteReq, Donor.DeleteRes>(
  firebaseFunctions,
  'donor-deleteRecord'
);
