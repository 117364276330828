import { Meta } from '../index';
export * from './requests';

// ----------------------------------------------------------------------
export type Tag = {
  id: string;
  readonly _meta: Meta;

  readonly orgId: string;
  readonly type: 'donor';
  readonly colorBackground: string;
  readonly colorText: string;
  readonly name: string;
  readonly description: string;
  readonly importAliases?: string[];

  // V1 fields for migration from V2->V1
  readonly v1TagNum?: number;
  readonly v1ShortName?: string;
};

// ----------------------------------------------------------------------
export function getTagsFromIds(labelIds?: string[], labels?: Tag[]): Tag[] {
  if (!labelIds || !labels) return [];
  return labelIds
    .map((labelId) => labels.find((label) => label.id === labelId))
    .filter(Boolean) as Tag[];
}
