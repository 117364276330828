import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import { Organization, Invitation, Feedback } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Create
export const orgCreate = httpsCallable<Organization.CreateReq, Organization.CreateRes>(
  firebaseFunctions,
  'org-create'
);

// ----------------------------------------------------------------------
// Update
export const orgUpdate = httpsCallable<Organization.UpdateReq, Organization.UpdateRes>(
  firebaseFunctions,
  'org-update'
);
export const orgUpdateUsers = httpsCallable<
  Organization.UpdateOrgUsersReq,
  Organization.UpdateOrgUsersRes
>(firebaseFunctions, 'org-updateUsers');

// ----------------------------------------------------------------------
// Delete
export const orgArchive = httpsCallable<Organization.ArchiveReq, Organization.ArchiveRes>(
  firebaseFunctions,
  'org-archive'
);

// ----------------------------------------------------------------------
// Delete EmailSignature
export const orgDeleteEmailSignature = httpsCallable<
  Organization.DeleteEmailSignatureReq,
  Organization.DeleteEmailSignatureRes
>(firebaseFunctions, 'org-deleteEmailSignature');

// ----------------------------------------------------------------------
// Check EmailSignatureState
export const orgCheckEmailSignatureState = httpsCallable<
  Organization.CheckEmailSignatureStateReq,
  Organization.CheckEmailSignatureStateRes
>(firebaseFunctions, 'org-checkEmailSignatureState');

// ----------------------------------------------------------------------
// Trigger EmailSignature verification
export const orgTriggerEmailSignatureVerification =
  httpsCallable<Organization.TriggerEmailSignatureVerificationReq>(
    firebaseFunctions,
    'org-triggerEmailSignatureVerification'
  );

// ----------------------------------------------------------------------
// Create GmailSignature verification
export const createGmailSenderSignature = httpsCallable<
  Organization.CreateGmailSenderSignatureAuthReq,
  Organization.CreateGmailSenderSignatureAuthRes
>(firebaseFunctions, 'org-createGmailSenderSignature');

// ----------------------------------------------------------------------
// lock
export const orgSetLock = httpsCallable<Organization.SetLockReq, void>(
  firebaseFunctions,
  'org-setLock'
);

// ----------------------------------------------------------------------
// Invitations
export const invitationGetAllForOrg = httpsCallable<
  Invitation.GetAllForOrgReq,
  Invitation.GetAllForOrgRes
>(firebaseFunctions, 'invitation-getAllForOrg');

export const invitationCreate = httpsCallable<Invitation.CreateReq, Invitation.CreateRes>(
  firebaseFunctions,
  'invitation-create'
);

// ----------------------------------------------------------------------
// feedback
export const submitFeedback = httpsCallable<Feedback, void>(
  firebaseFunctions,
  'integration-feedback'
);
