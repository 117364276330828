import { useState } from 'react';
import { useNavigate } from 'react-router';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';

import PATHS from '@routes/paths';
import useAuth from '@hooks/useAuth';
import Avatar from '@components/Avatar';
import MenuPopover from '@components/MenuPopover';
import { IconButtonAnimate } from '@components/animate';

// ----------------------------------------------------------------------
type TMenuOption = {
  readonly label: string;
  readonly linkTo: string;
};
const MENU_OPTIONS: TMenuOption[] = [
  {
    label: 'Account',
    linkTo: PATHS.account,
  },
  {
    label: 'Organizations',
    linkTo: PATHS.root,
  },
];

// ----------------------------------------------------------------------
export default function AccountPopover() {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleMenuItem = (option: TMenuOption) => {
    setOpen(null);
    navigate(option.linkTo);
  };
  const handleLogout = async () => {
    await logout();
    navigate(PATHS.root);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src="" alt={user?.displayName || 'Unknown'} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName || 'Unknown'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleMenuItem(option)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
