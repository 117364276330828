import { useMemo, useState } from 'react';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { sortBy } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';

import { Receipt } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { PageSize } from '@typedefs/app';

import { ReceiptReissueBatchSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import PdfDocument from '@pages/receipts/templates/PdfDocument';
import Iconify from '@components/Iconify';
import ReviewReceiptSections from '@pages/receipts/Create/steps/ReviewReceiptSections';

// ----------------------------------------------------------------------
export default function ReviewReceipt({
  stepData,
  updateStepData,
}: TStepProps<ReceiptReissueBatchSchemaForm>) {
  const { fReceiptNumber, fDateToISO } = useFormat();
  const { getReceiptReissueGroup, getReceiptDonations } = useDonation();
  const { org } = useOrg();

  const receipts = useMemo(
    () => sortBy(stepData.receipts, (d) => fReceiptNumber(d.number, d.year)),
    [fReceiptNumber, stepData.receipts]
  );

  const [size] = useState<PageSize>('letter');
  const [previewReceipt, setPreviewReceipt] = useState<Receipt.Receipt>(receipts[0]);

  // --------------------------------------------------
  const donorGroup = useMemo(
    () =>
      getReceiptReissueGroup(
        org!,
        previewReceipt,
        getReceiptDonations(previewReceipt),
        stepData.reissue
      ),
    [getReceiptReissueGroup, getReceiptDonations, org, previewReceipt, stepData.reissue]
  );

  // --------------------------------------------------
  // const handleWithLetterChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   updateStepData({
  //     letter: {
  //       ...stepData.letter,
  //       withLetter: checked,
  //     },
  //   });
  // };

  const handleDownloadTest = async () => {
    const blob = await pdf(PdfDoc).toBlob();
    saveAs(blob, `${fDateToISO()}_preview_receipt.pdf`);
  };

  // --------------------------------------------------
  const PdfDoc = useMemo(
    () => (
      <PdfDocument
        title="Receipt"
        description="Receipt"
        size={PageSize[size]}
        donorGroups={[donorGroup]}
        withLetter={stepData.letter.withLetter}
        preview
        options={stepData.options}
        year={previewReceipt.year}
      />
    ),
    [size, donorGroup, stepData.letter.withLetter, stepData.options, previewReceipt.year]
  );

  // --------------------------------------------------
  if (!org) return null;

  return (
    <Grid container spacing={1} height="100%">
      <Grid item xs={5} height="100%" mx="auto">
        <Stack spacing={2} height="100%">
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={stepData.letter.withLetter} onChange={handleWithLetterChange} />
              }
              label="Include letter"
              sx={{ whiteSpace: 'nowrap' }}
            />
          </FormGroup>

          <Divider /> */}

          <Typography variant="h6">Preview</Typography>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              disablePortal
              id="select-preview-receipt"
              options={receipts}
              sx={{ width: '70%' }}
              autoComplete
              autoHighlight
              disableClearable
              freeSolo={false}
              value={previewReceipt}
              onChange={(e, newValue) => setPreviewReceipt(newValue)}
              getOptionLabel={(option) => fReceiptNumber(option.number, option.year)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="Choose a preview receipt" />}
            />

            <LoadingButton
              variant="outlined"
              onClick={handleDownloadTest}
              startIcon={<Iconify icon="material-symbols:download" />}
            >
              Download preview receipt
            </LoadingButton>
          </Stack>
          <ReviewReceiptSections stepData={stepData} updateStepData={updateStepData} />
        </Stack>
      </Grid>

      <Grid item xs={7} height="100%">
        <PDFViewer showToolbar={false} width="100%" height="100%">
          {PdfDoc}
        </PDFViewer>
      </Grid>
    </Grid>
  );
}
