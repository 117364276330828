export default function addPendo() {
  const apiKey = '5cc28a65-720a-4439-471b-48b4eee3e88d';
  const pendo = function (p: any, e: any, n: any, d: any, o: any = undefined) {
    var v: any, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];

    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);

    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';

    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  };
  pendo(window, document, 'script', 'pendo');
}
