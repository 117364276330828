import { DonorFieldNames } from '@/schemas/importData';
import { TableEmptyRows, TableHeadCustom } from '@components/table';
import useTable, { emptyRows } from '@hooks/useTable';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ImportData } from '@shared/types';
import { TColumn } from '@typedefs/app';
import { AllCountryList } from '@utils/allCountryList';

type Props = {
  importDonors: Partial<ImportData.ImportDonor>[];
  tableCols: TColumn<'status' | 'nonReceiptable' | DonorFieldNames>[];
};
export default function DonorsTable({ importDonors, tableCols }: Props) {
  const {
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage /*, onSort */,
  } = useTable({
    defaultRowsPerPage: 10,
    defaultColumns: tableCols,
    defaultOrderBy: 'lastName',
    defaultOrder: 'asc',
  });

  const currentYear = new Date().getFullYear();

  const getTableCols = (donor: Partial<ImportData.ImportDonor>) =>
    tableCols.map((col) => {
      let val: string | undefined = '';
      switch (col.id) {
        case 'memberNumber':
          val = donor.memberNumbers?.[currentYear].toString();
          break;
        case 'tag':
          val = donor.tagNames?.join(', ');
          break;
        case 'country':
          let country = AllCountryList.find((c) => c.value === donor.country);
          val = country ? country.label : donor.country?.toUpperCase();
          break;
        case 'custom1':
          val = donor.customFields?.[0];
          break;
        case 'custom2':
          val = donor.customFields?.[1];
          break;
        case 'custom3':
          val = donor.customFields?.[2];
          break;
        case 'custom4':
          val = donor.customFields?.[3];
          break;
        case 'custom5':
          val = donor.customFields?.[4];
          break;
        case 'custom6':
          val = donor.customFields?.[5];
          break;
        default:
          val = donor[col.id as keyof ImportData.ImportDonor]?.toString();
          break;
      }
      return { id: col.id, align: col.align, value: val };
    });

  return (
    <>
      <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
        <Table stickyHeader size="small">
          <TableHeadCustom
            cellsSx={{ whiteSpace: 'nowrap' }}
            order={order}
            orderBy={orderBy}
            columns={columns}
            rowCount={importDonors.length}
            // onSort={onSort}
          />

          <TableBody>
            {importDonors
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((donor, index) => (
                <TableRow key={index} hover sx={{ height: 44 }}>
                  {getTableCols(donor).map((col) => (
                    <TableCell key={col.id} align={col.align} sx={{ whiteSpace: 'nowrap' }}>
                      {col.value || ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            <TableEmptyRows
              height={44}
              emptyRows={emptyRows(page, rowsPerPage, importDonors.length)}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={importDonors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
