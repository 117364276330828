import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { Organization } from '@shared/types';
import useOrg from '@hooks/useOrg';
import Iconify from '@components/Iconify';
import ConfirmDialog from '@components/ConfirmDialog';

// ----------------------------------------------------------------------
type Props = {
  signature?: Organization.PostmarkSignature | Organization.GmailSignature;
};

// ----------------------------------------------------------------------
export default function EmailStatus({ signature }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { org, deleteEmailSignature, checkEmailSignatureState, triggerEmailSignatureVerification } =
    useOrg();
  const [loading, setLoading] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  // --------------- actions ---------------
  const handleEmailResend = async () => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!org.emailSignature) {
      enqueueSnackbar('[internal] Missing email signature to resend to!', { variant: 'error' });
      return;
    }

    setLoading(true);
    try {
      await triggerEmailSignatureVerification({
        orgId: org.id,
        signatureId: (org.emailSignature as Organization.PostmarkSignature).signatureId,
      });
      enqueueSnackbar('Email sent!');
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
    setLoading(false);
  };

  const handleEmailRecheck = async () => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!org.emailSignature) {
      enqueueSnackbar('[internal] Missing email setup to recheck!', { variant: 'error' });
      return;
    }

    setLoading(true);
    try {
      const res = await checkEmailSignatureState({
        orgId: org.id,
        signatureId: (org.emailSignature as Organization.PostmarkSignature).signatureId,
      });

      res.emailSignature?.confirmed
        ? enqueueSnackbar('Email setup is confirmed!')
        : enqueueSnackbar(
            `Email config is not confirmed!!!
            Please check your inbox for the confirmation email or re-trigger the confirmation email sending`
          );
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
    setLoading(false);
  };

  const handleDeleteClose = () => {
    if (!loading) {
      setConfirmDeleteDialog(false);
    }
  };
  const handleEmailDelete = async () => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!org.emailSignature) {
      enqueueSnackbar('[internal] Missing email setup to delete!', { variant: 'error' });
      return;
    }

    setLoading(true);
    try {
      await deleteEmailSignature({
        orgId: org.id,
        signatureId: (org.emailSignature as Organization.PostmarkSignature).signatureId,
      });
      enqueueSnackbar('Email setup deleted!');
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
    setLoading(false);
    handleDeleteClose();
  };

  // --------------- renders ---------------
  const renderStatus = () => {
    if (!signature?.signatoryEmail) {
      return (
        <>
          <Iconify icon="material-symbols:power-plug-off" width={26} height={26} />
          <Stack>
            <Typography variant="subtitle2">Not activated</Typography>
          </Stack>
        </>
      );
    } else if (!signature.confirmed) {
      return (
        <>
          <Iconify
            icon="mdi:question-mark-circle"
            width={26}
            height={26}
            sx={{ color: (theme) => theme.palette.secondary.main }}
          />
          <Stack flex="1 1 auto" direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="subtitle2">Pending email confirmation</Typography>
              <Typography variant="caption" mt={-0.5}>
                Didn't receive an email?{' '}
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  component="span"
                  size="small"
                  sx={{ p: 0, ml: -1, fontSize: 12 }}
                  onClick={handleEmailResend}
                >
                  Resend
                </LoadingButton>
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <LoadingButton
                loading={loading}
                disabled={loading}
                size="medium"
                onClick={handleEmailDelete}
              >
                Delete
              </LoadingButton>
              <LoadingButton
                loading={loading}
                disabled={loading}
                size="medium"
                variant="outlined"
                onClick={handleEmailRecheck}
              >
                Verify
              </LoadingButton>
            </Stack>
          </Stack>
        </>
      );
    } else {
      return (
        <>
          <Iconify
            icon="material-symbols:check-circle"
            width={26}
            height={26}
            sx={{ color: (theme) => theme.palette.success.main }}
          />
          <Stack>
            <Typography variant="subtitle2">Email configured</Typography>
            <Typography variant="caption" mt={-0.5}>
              Delete email setup?{' '}
              <LoadingButton
                loading={loading}
                disabled={loading}
                size="small"
                color="error"
                sx={{ p: 0, ml: -1, fontSize: 12 }}
                onClick={() => setConfirmDeleteDialog(true)}
              >
                Delete
              </LoadingButton>
            </Typography>

            <ConfirmDialog
              open={confirmDeleteDialog}
              onClose={handleDeleteClose}
              onConfirm={handleEmailDelete}
              loading={loading}
            />
          </Stack>
        </>
      );
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      sx={{
        maxHeight: 56,
        p: 2,
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      {renderStatus()}
    </Stack>
  );
}
