import { useNavigate } from 'react-router';
import { Stack, Button, Card, Typography } from '@mui/material';

import PATHS from '@routes/paths';
import useAuth from '@hooks/useAuth';

// ----------------------------------------------------------------------
export default function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate(PATHS.root);
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={5}>
        <Typography variant="h5">Switch to a different account?</Typography>

        <Button fullWidth={false} type="submit" variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </Stack>
    </Card>
  );
}
