import { useFormContext, Controller } from 'react-hook-form';
import {
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';

// ----------------------------------------------------------------------
type Props = RadioGroupProps & {
  name: string;
  label: string;
  options: {
    label: string | React.ReactNode;
    value: any;
    disabled?: boolean;
  }[];
};

export default function RHFRadioGroup({ name, label, options, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ m: 0 }} error={!!error} variant="standard">
          <FormLabel id={other.id}>{label}</FormLabel>
          <RadioGroup {...field} {...other}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                disabled={option.disabled}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
