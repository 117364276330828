import fRegular from '@assets/fonts/Lato-Regular.ttf';
import fBold from '@assets/fonts/Lato-Bold.ttf';
import fLight from '@assets/fonts/Lato-Light.ttf';
import fItalic from '@assets/fonts/Lato-Italic.ttf';
import fThin from '@assets/fonts/Lato-Thin.ttf';

// ----------------------------------------------------------------------
export const fonts = {
  family: 'Lato',
  fonts: [
    { src: fRegular },
    { src: fBold, fontWeight: 700 },
    { src: fLight, fontWeight: 300 },
    { src: fThin, fontWeight: 100 },
    { src: fItalic, fontStyle: 'italic' },
  ],
};

// ----------------------------------------------------------------------
export * from './PdfTable';
export { default as PdfTable } from './PdfTable';
