// Create a PDF Table from PdfTableData, details on the package at
// https://react-pdf.org/
import { View, Text, StyleSheet } from '@react-pdf/renderer';

// ----------------------------------------------------------------------
const styles = StyleSheet.create({
  table: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 0,
  },
  tableHeader: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    minHeight: 24,
    textAlign: 'center',
    fontWeight: 'bold',
    borderBottomWidth: 1,
  },
  tableHeaderItem: {
    padding: '0 4px',
    textAlign: 'left',
    fontSize: 11,
    whiteSpace: 'pre-wrap',
  },
  tableRow: {
    flexDirection: 'row',
    minHeight: 18,
  },
  tableRowItem: {
    padding: '2px 4px 0',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
  },
});

// ------------------------------------------------------------------------
export type PdfTableSummaryField = {
  columns: number; // number of columns this text spans
  text: string; // text to display
};

export type PdfTableSubtotal = {
  afterRowNumber: number;
  subtotal: PdfTableSummaryField[];
};

// ----------------------------------------------------------------------
export type PdfTableData = {
  header: PdfTableHeaderProps[];
  items: string[][];
  summary?: PdfTableSummaryField[];
  subtotals?: PdfTableSubtotal[]; // any subtotals to add after certain rows
};
type Props = {
  data: PdfTableData;
  renderSummary?: boolean;
};
// ----------------------------------------------------------------------
export default function PdfTable({ data, renderSummary = true }: Props) {
  const rowWidth = data.header.reduce((a, v) => a + v.width, 0);
  if (rowWidth > 100) console.error('row size exceeds 100%!');
  return (
    <View style={styles.table}>
      <PdfTableHeader header={data.header} rowWidth={rowWidth} />
      <PdfTableRows data={data} rowWidth={rowWidth} renderSummary={renderSummary} />
      {renderSummary && (
        <PdfTableSummary
          header={data.header}
          summary={data.summary || []}
          rowWidth={rowWidth}
          isGrandTotal={true}
        />
      )}
    </View>
  );
}

// ----------------------------------------------------------------------
export type PdfTableHeaderProps = {
  name: string /* text to be displayed */;
  width: number /* percentage of row width */;
  align?: 'right' | 'left' | 'center' | 'justify';
};
function PdfTableHeader({ header, rowWidth }: { header: PdfTableHeaderProps[]; rowWidth: number }) {
  return (
    <View style={[styles.tableHeader, { width: `${rowWidth}%` }]} fixed>
      {header.map((item, i) => (
        <Text
          key={i}
          style={[
            styles.tableHeaderItem,
            {
              width: `${item.width * (100 / rowWidth)}%`,
              textAlign: item.align || 'left',
              paddingRight: calcPaddingRight(item.align, i, header),
            },
          ]}
        >
          {item.name}
        </Text>
      ))}
    </View>
  );
}

// Determine the paddingRight, which is 4, unless it's a right-aligned column followed by a left-aligned column
function calcPaddingRight(align: string | undefined, index: number, header: PdfTableHeaderProps[]) {
  return align === 'right' &&
    index < header.length - 1 &&
    (header[index + 1].align || 'left') === 'left'
    ? 6
    : 4;
}

// ----------------------------------------------------------------------
function PdfTableRows({
  data,
  rowWidth,
  renderSummary,
}: {
  data: PdfTableData;
  rowWidth: number;
  renderSummary: boolean;
}) {
  const rows = data.items.length;
  let subtotalIndex = 0;
  return (
    <>
      {data.items.map((cells, rowIndex) => {
        const isSubtotal =
          renderSummary &&
          data.subtotals &&
          data.subtotals.length > subtotalIndex &&
          rowIndex === data.subtotals[subtotalIndex].afterRowNumber;
        if (isSubtotal) subtotalIndex++;
        return (
          <View key={'rows'} style={{ width: '100%' }}>
            <View
              key={rowIndex}
              style={[
                styles.tableRow,
                {
                  width: `${rowWidth}%`,
                  borderBottomWidth: `${
                    (rowIndex === rows - 1 && data.summary && renderSummary) || isSubtotal ? 1 : 0
                  }`,
                },
              ]}
            >
              {cells.map((cell, itemIndex) => {
                const headerItem = data.header[itemIndex];
                return (
                  <Text
                    key={itemIndex}
                    style={[
                      styles.tableRowItem,
                      {
                        width: `${headerItem?.width * (100 / rowWidth)}%`,
                        textAlign: headerItem?.align || 'left',
                        paddingRight: calcPaddingRight(headerItem?.align, itemIndex, data.header),
                      },
                    ]}
                  >
                    {/* TODO: parse highlighting? */}
                    {cell ? cell.replaceAll('<strong>', '').replaceAll('</strong>', '') : ''}
                  </Text>
                );
              })}
            </View>
            {isSubtotal && data.subtotals && (
              <PdfTableSummary
                header={data.header}
                summary={data.subtotals[subtotalIndex - 1].subtotal}
                rowWidth={rowWidth}
                isGrandTotal={false}
              />
            )}
          </View>
        );
      })}
    </>
  );
}

// ----------------------------------------------------------------------
function PdfTableSummary({
  header,
  summary = [],
  rowWidth,
  isGrandTotal,
}: {
  header: PdfTableHeaderProps[];
  summary: PdfTableSummaryField[];
  rowWidth: number;
  isGrandTotal: boolean;
}) {
  if (!summary || summary.length === 0) return null;
  let cell = -1;
  return (
    <View
      style={[styles.tableRow, { width: `${rowWidth}%`, paddingBottom: isGrandTotal ? 0 : 10 }]}
    >
      {summary.map((field, index) => {
        let width = 0;
        for (let j = 0; j < field.columns; j++) {
          cell++;
          width += header[cell].width;
        }
        const align = index > 0 ? header[cell].align || 'left' : 'left';
        const padding = calcPaddingRight(align, cell, header);
        return (
          <Text
            key={index}
            style={[
              styles.tableRowItem,
              {
                width: `${width * (100 / rowWidth)}%`,
                textAlign: `${align}`,
                paddingRight: `${padding}`,
              },
            ]}
          >
            {field.text}
          </Text>
        );
      })}
    </View>
  );
}
