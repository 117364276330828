// Multi-select autocomplete drop-down for categories
import { Typography, Autocomplete, TextField, Chip, SxProps } from '@mui/material';

import useDonation from '@hooks/useDonation';

import { Category } from '@shared/types';
import { sortBy } from 'lodash';

// ----------------------------------------------------------------------
type Props = {
  filterCategories: Category.Category[];
  setFilterCategories: (categories: Category.Category[]) => void;
  fullWidth?: boolean;
  sxProps?: SxProps;
};

// ----------------------------------------------------------------------
export default function FilterCategories({
  filterCategories,
  setFilterCategories,
  fullWidth = true,
  sxProps,
}: Props) {
  const { categories } = useDonation();

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      autoComplete
      size="small"
      sx={sxProps}
      limitTags={2}
      disableCloseOnSelect
      value={filterCategories}
      onChange={(e, newValue) => {
        setFilterCategories(newValue);
      }}
      options={sortBy(categories, (c) => c.name.toUpperCase())}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField label="Categories" {...params} />}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            size="small"
            key={option.id}
            label={option.name}
            variant="outlined"
            avatar={
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  background: option.colorBackground,
                }}
              />
            }
          />
        ))
      }
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: 22,
              height: 22,
              borderRadius: '50%',
              marginRight: 8,
              background: option.colorBackground,
            }}
          />
          <Typography>{option.name}</Typography>
        </li>
      )}
    />
  );
}
