import { alpha } from '@mui/material/styles';
import { Card, CardProps } from '@mui/material';

// ----------------------------------------------------------------------
type Props = CardProps & {
  readonly disabled?: boolean;
  readonly selected?: boolean;
};
// ----------------------------------------------------------------------
export default function BubbleButton({
  children,
  sx,
  disabled,
  selected,
  onClick,
  ...other
}: Props) {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 190,
        width: '100%',
        py: 1.5,
        px: 2.5,
        textAlign: 'center',
        color: (theme) => (disabled ? theme.palette.grey[500] : theme.palette.text.primary),
        '& svg': {
          color: (theme) => (disabled ? theme.palette.grey[500] : theme.palette.primary.main),
        },
        bgcolor: (theme) =>
          disabled
            ? theme.palette.grey[200]
            : selected
            ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            : theme.palette.common.white,
        border: (theme) =>
          selected
            ? `1px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.grey[600]}40`, // 40 = opacity
        transition: (theme) =>
          disabled
            ? undefined
            : theme.transitions.create(['background'], {
                duration: theme.transitions.duration.shorter,
              }),
        '&:hover': disabled
          ? {}
          : {
              cursor: 'pointer',
              borderColor: (theme) => theme.palette.primary.main,
            },
        ...sx,
      }}
      {...other}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </Card>
  );
}
