import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import blobToDataUrl, { dataUrlToFile } from '@utils/blobToDataUrl';
import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import { CustomFile } from '@components/upload';
import Image from '@components/Image';
import ImageUploadDialog from '@components/ImageUploadDialog';
import ConfirmDialog from '@components/ConfirmDialog';
import ReceiptPreview from './Preview';

// ----------------------------------------------------------------------
export default function SignatorySignature() {
  const { org, updateOrg } = useOrg();
  const { hasAccess } = useRole();
  const { enqueueSnackbar } = useSnackbar();
  const canManage = hasAccess([Role.editor, Role.contributor]);

  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [file, setFile] = useState<CustomFile | undefined>();
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

  // --------------- effects ---------------
  const setFileInitial = useCallback(
    async (file: string) => setFile(await dataUrlToFile(file, 'signature')),
    [setFile]
  );
  useEffect(() => {
    if (org?.signatorySignature) {
      setFileInitial(org.signatorySignature);
    } else {
      setFile(undefined);
    }
  }, [setFileInitial, org?.signatorySignature]);

  // --------------- actions ---------------
  const handleOpen = () => setDialogOpen(true);
  const handleClose = () => setDialogOpen(false);

  const handleDeleteClose = () => {
    if (!loading) {
      setConfirmDeleteDialog(false);
    }
  };
  const handleDelete = async () => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!canManage) {
      enqueueSnackbar('You lack permissions', { variant: 'error' });
      return;
    }

    setLoading(true);
    try {
      const res = await updateOrg({ orgId: org.id, update: { signatorySignature: undefined } });

      res
        ? enqueueSnackbar('Signatory Signature deleted!')
        : enqueueSnackbar('Failed to delete Signatory Signature', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar('Failed to add Signatory Signature', { variant: 'error' });
    }
    setLoading(false);
    handleDeleteClose();
  };

  const handleAdd = async (blob: Blob) => {
    if (!org) {
      enqueueSnackbar('[internal] Missing organization!', { variant: 'error' });
      return;
    }
    if (!canManage) {
      enqueueSnackbar('You lack permissions', { variant: 'error' });
      return;
    }

    setLoading(true);
    // get dataURL
    const signatorySignature = blob ? await blobToDataUrl(blob) : undefined;

    try {
      const res = await updateOrg({
        orgId: org.id,
        update: { signatorySignature },
      });

      res
        ? enqueueSnackbar('Signatory Signature added!')
        : enqueueSnackbar('Failed to add logo', { variant: 'error' });
      setDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <Card sx={{ p: 2, width: '50%' }}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">Signatory Signature</Typography>
          {!file ? (
            <Button variant="outlined" onClick={handleOpen}>
              Add
            </Button>
          ) : (
            <Stack direction="row" spacing={0}>
              <Button
                size="small"
                sx={{ fontSize: 12, ml: '-4px' }}
                onClick={() => setPreview(true)}
              >
                Preview
              </Button>
              {preview && <ReceiptPreview onClose={() => setPreview(false)} />}
              <LoadingButton
                loading={loading}
                color="error"
                size="small"
                sx={{ fontSize: 12, ml: '-4px' }}
                onClick={() => setConfirmDeleteDialog(true)}
              >
                Delete
              </LoadingButton>
            </Stack>
          )}
        </Stack>
        {!file && (
          <Typography variant="caption">* required for issuing receipts via Email</Typography>
        )}{' '}
      </Stack>

      <ConfirmDialog
        open={confirmDeleteDialog}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        loading={loading}
      />

      <ImageUploadDialog
        title="Add Signatory Signature"
        submitText="Add"
        loading={loading}
        open={dialogOpen}
        onClose={handleClose}
        onSubmit={handleAdd}
        maxWidth={320}
        maxHeight={120}
      />

      {file && (
        <Box
          sx={{
            position: 'relative',
            width: 'fit-content',
            boxShadow: (theme) => theme.shadows[10],
          }}
        >
          <Image
            alt="Signatory Signature"
            src={file.preview}
            sx={{
              top: 8,
              left: 8,
              zIndex: 8,
              '& img': {
                objectFit: 'contain',
                maxWidth: 320,
                maxHeight: 120,
              },
            }}
          />
        </Box>
      )}
    </Card>
  );
}
