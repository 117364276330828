import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import { Stripe } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
export const createCheckoutSession = httpsCallable<
  Stripe.CreateCheckoutSessionReq,
  Stripe.CreateCheckoutSessionRes
>(firebaseFunctions, 'stripe-createCheckoutSession');

export const createPortalSession = httpsCallable<
  Stripe.CreatePortalSessionReq,
  Stripe.CreatePortalSessionRes
>(firebaseFunctions, 'stripe-createPortalSession');
