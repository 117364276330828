import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { groupBy, round } from 'lodash';

import { ReceiptReissueBatchSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
export default function Overview({
  stepData,
  submittingData,
  updateStepData,
}: TStepProps<ReceiptReissueBatchSchemaForm>) {
  const total = 4;
  const normalize = (value: number) => ((value - 0) * 100) / (total - 0);
  const progress = round(normalize(submittingData.progress));
  const donorCount = Object.keys(groupBy(stepData.receipts, 'donorId')).length;

  const handleSelectPrintAllDonors = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ emailAndPrint: e.target.checked });
  };

  return (
    <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
      <Card
        sx={{
          py: 4,
          px: 8,
          width: '50%',
          border: (theme) => `1px solid ${theme.palette.grey[600]}40`,
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {submittingData.isSubmitting ? (
          <Stack spacing={3} textAlign="center">
            <Typography variant="h6">Issuing receipts... </Typography>

            <Stack direction="column" spacing={1}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Stack spacing={2} textAlign="center">
              <Typography variant="h5">Are you ready to reissue Receipts?</Typography>
              <Typography variant="h6" color="text.secondary">
                <strong>{stepData.receipts.length}</strong> receipts to{' '}
                <strong>{donorCount}</strong> donors
              </Typography>
            </Stack>

            {stepData.actionType === 'email' && (
              <>
                <Divider sx={{ width: '100%' }} />

                <Stack spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stepData.emailAndPrint}
                          onChange={handleSelectPrintAllDonors}
                        />
                      }
                      label={
                        <Typography variant="body2" color="text.secondary">
                          Upon "Reissue", also{' '}
                          <strong>download a PDF containing all of the receipts</strong> for my own
                          organization's records.
                        </Typography>
                      }
                    />
                  </FormGroup>

                  <Stack spacing={1} direction="row" alignItems="center" color="text.secondary">
                    <Iconify
                      icon="material-symbols:info-outline"
                      width={22}
                      minWidth={22}
                      height={22}
                    />
                    <Typography variant="body2" sx={{ lineHeight: '15px' }}>
                      PDFs can be printed, but <strong>should not be distributed to donors</strong>{' '}
                      as they duplicate the attachment each donor will already receive via this
                      email send.
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Card>
    </Stack>
  );
}
