import { Box, Link, Container, Typography } from '@mui/material';

import LogoOnlyLayout from '@/layouts/LogoLayout';
import Page from '@components/Page';

import IcSent from '@assets/ic_sent';
import NewPasswordForm from './NewPasswordForm';
import { ContentStyle } from './styles';

// ----------------------------------------------------------------------
export default function NewPassword() {
  return (
    <Page title="New Password">
      <LogoOnlyLayout />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <IcSent sx={{ mb: 5, mx: 'auto', height: 120 }} />

          <Typography variant="h3" gutterBottom>
            Request sent successfully!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            We've sent a 6-digit confirmation email to your email.
            <br />
            Please enter the code in below box to verify your email.
          </Typography>

          <Box sx={{ mt: 5, mb: 3 }}>
            <NewPasswordForm />
          </Box>

          <Typography variant="body2">
            Don’t have a code? &nbsp;
            <Link variant="subtitle2" onClick={() => {}}>
              Resend code
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  );
}
