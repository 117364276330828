import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Box, Card, Grid, Table, TableBody, TableContainer, TablePagination } from '@mui/material';

import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import useTable, { emptyRows } from '@hooks/useTable';
import useFormat from '@hooks/useFormat';
import { Tag, Category, View } from '@shared/types';
import { donationsViewCacheId } from '@typedefs/donation';
import { TColumnFilter } from '@typedefs/app';
import useDonation from '@hooks/useDonation';

import * as analytics from '@fire/analytics';

import Scrollbar from '@components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Toolbar from '@pages/donors/components/Toolbar';
import { DonorsOutletContext } from '@pages/donors/useContext';
import Actions from '../Actions';
import { DONATION_LIST_HEADERS, DonationListHeader } from './config';
import useData from './useData';
import Row from './Row';

// ----------------------------------------------------------------------
type Props = { cachedView?: View.View; clearCache: VoidFunction };
// ----------------------------------------------------------------------
export default function DonationList({ cachedView, clearCache }: Props) {
  const navigate = useNavigate();
  const { getTagsFromIds, getCategoriesFromIds } = useDonation();
  const { fDateToYearStart, fDateToDayEnd } = useFormat();

  const {
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    page,
    rowsPerPage,
    filters,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    setColumns,
    setDateFrom,
    setDateTo,
    setPage,
    setFilters,
  } = useTable<DonationListHeader>(
    {
      defaultRowsPerPage: 25,
      defaultColumns: DONATION_LIST_HEADERS,
      defaultOrderBy: 'dateDisplay',
      defaultOrder: 'desc',
      defaultDateFrom: fDateToYearStart(),
      defaultDateTo: fDateToDayEnd(),
    },
    donationsViewCacheId
  );

  const [search, setSearch] = useState('');
  const [filterTags, setFilterTags] = useState<Tag.Tag[]>(() => {
    const cachedTags = localStorage.getItem(donationsViewCacheId + '-tags');
    const tags = cachedTags ? JSON.parse(cachedTags) : [];
    return tags;
  });
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>(() => {
    const cachedCats = localStorage.getItem(donationsViewCacheId + '-categories');
    const categories = cachedCats ? JSON.parse(cachedCats) : [];
    return categories;
  });

  useEffect(() => {
    if (cachedView && cachedView?.type === 'donation') {
      const columns = DONATION_LIST_HEADERS.map((h) => ({
        ...h,
        visible: cachedView.config.columnIds.includes(h.id),
      }));
      setColumns(columns);
      onSort(cachedView.config.orderBy as DonationListHeader, cachedView.config.order);
      setFilters(cachedView.config.filters as TColumnFilter[]);
      handleFilterTagChange(getTagsFromIds(cachedView.config.tagIds));
      handleFilterCatChange(getCategoriesFromIds(cachedView.config.categoryIds));
      clearCache();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedView]);

  const handleSearchChange = (search: string) => {
    setPage(0);
    setSearch(search);
  };

  const handleFilterTagChange = (newValue: Tag.Tag[]) => {
    setFilterTags(newValue);
    localStorage.setItem(donationsViewCacheId + '-tags', JSON.stringify(newValue));
  };

  const handleFilterCatChange = (newValue: Category.Category[]) => {
    setFilterCategories(newValue);
    localStorage.setItem(donationsViewCacheId + '-categories', JSON.stringify(newValue));
  };

  const handleEdit = (donorId: string, donationId: string) => {
    analytics.donation.recurringDonationView();
    navigate(getPath(PATHS.org.donations.edit, { donationId }));
  };

  // ----- FILTERING -------
  const data = useData({
    columns,
    dateFrom,
    dateTo,
    order,
    orderBy,
    search,

    // filters
    filterTags,
    filterCategories,
    filters,
  });

  const outletContext: DonorsOutletContext = {
    type: 'donation',
    data,

    columns,
    dateFrom,
    dateTo,
    order,
    orderBy: DONATION_LIST_HEADERS.find((h) => h.id === orderBy) || DONATION_LIST_HEADERS[6],

    filterCategories,
    filterTags,
    filters,
  };
  return (
    <Grid container spacing={2}>
      <Outlet context={outletContext} />

      <Grid item xs={12} md={12}>
        <Toolbar
          columns={columns}
          setColumns={setColumns}
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
          search={search}
          onSearchChange={handleSearchChange}
          filterProps={{
            columns,
            filterTags,
            onFilterTagChange: handleFilterTagChange,
            filterCategories,
            onFilterCategoryChange: handleFilterCatChange,
            filters,
            setFilters,
          }}
          actions={<Actions />}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <TableContainer sx={{ minWidth: '100%', position: 'relative', overflow: 'unset' }}>
            <Scrollbar
            // sx={{ maxHeight: 200 }}
            >
              <Table stickyHeader size="small">
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  columns={columns}
                  rowCount={data.length}
                  onSort={onSort as (id: string) => void}
                />

                <TableBody>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <Row key={item.id} columns={columns} item={item} onEdit={handleEdit} />
                  ))}

                  <TableEmptyRows
                    height={52}
                    emptyRows={emptyRows(page, rowsPerPage, data.length)}
                  />

                  <TableNoData isNotFound={!data.length} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
