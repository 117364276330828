import { Stack, Typography, Card } from '@mui/material';
import { Category, Donor, Tag } from '@shared/types';

import { TableDateRange } from '@components/table';
import ToolbarFilters from './Filters';
import { TDonorWithDonations } from '@typedefs/donation';
import { MinAmountType } from '@/schemas';

// ----------------------------------------------------------------------
type Props = {
  filterTags: Tag.Tag[];
  onFilterTagChange: (newValue: Tag.Tag[]) => void;
  filterCategories: Category.Category[];
  onFilterCategoryChange: (newValue: Category.Category[]) => void;
  filterDonor?: Donor.Donor;
  onFilterDonorChange: (donor: Donor.Donor | undefined) => void;
  dateFrom?: Date;
  setDateFrom: (dateFrom?: Date) => void;
  dateTo?: Date;
  setDateTo: (dateTo?: Date) => void;
  donorCount: number;
  dateError?: string;
  donorsWithDonations: TDonorWithDonations[];
  isSingleDonor: boolean;
  filterMinAmount?: number;
  onFilterMinAmountChange: (newValue: number | undefined) => void;
  filterMinAmountType: MinAmountType;
  onFilterMinAmountTypeChange: (newValue: MinAmountType) => void;
  handleClearAll: () => void;
};

// ----------------------------------------------------------------------
export default function Toolbar({
  filterTags,
  onFilterTagChange,
  filterCategories,
  onFilterCategoryChange,
  filterDonor,
  onFilterDonorChange,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  donorCount,
  dateError,
  donorsWithDonations,
  isSingleDonor,
  filterMinAmount,
  onFilterMinAmountChange,
  filterMinAmountType,
  onFilterMinAmountTypeChange,
  handleClearAll,
}: Props) {
  return (
    <Card sx={{ py: 1, px: 2 }}>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing={1} direction="row" width="100%">
          <TableDateRange
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            maxDate={new Date()} // max date is today
            setDateTo={setDateTo}
            error={dateError}
          />

          <ToolbarFilters
            filterTags={filterTags}
            onFilterTagChange={onFilterTagChange}
            filterCategories={filterCategories}
            onFilterCategoryChange={onFilterCategoryChange}
            filterDonor={filterDonor}
            onFilterDonorChange={onFilterDonorChange}
            donorsWithDonations={donorsWithDonations}
            isSingleDonor={isSingleDonor}
            filterMinAmount={filterMinAmount}
            onFilterMinAmountChange={onFilterMinAmountChange}
            filterMinAmountType={filterMinAmountType}
            onFilterMinAmountTypeChange={onFilterMinAmountTypeChange}
            handleClearAll={handleClearAll}       
          />
          <Stack flex="1 1 auto" alignItems="flex-end" justifyContent="center">
            <Typography variant="subtitle2">{donorCount} donor(s) included</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
