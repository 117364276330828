import { Outlet, useLocation } from 'react-router';
import { Container, Tab, Box, Tabs } from '@mui/material';

import useRole from '@hooks/useRole';
import useNavigate from '@hooks/useNavigate';
import { Role } from '@shared/types/organization';

import Page from '@components/Page';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
const ACCOUNT_TABS = [
  {
    value: 'general',
    label: 'General',
    icon: 'ic:round-account-box',
    roles: [Role.editor, Role.contributor],
  },
  {
    value: 'preferences',
    label: 'Preferences',
    icon: 'akar-icons:settings-horizontal',
    roles: [Role.editor, Role.contributor],
  },
  {
    value: 'users',
    label: 'Users',
    icon: 'clarity:users-solid',
    roles: [],
  },
  {
    value: 'billing',
    label: 'Billing',
    icon: 'fluent:payment-48-filled',
    roles: [Role.editor, Role.contributor],
  },
];
// ----------------------------------------------------------------------
export default function Organization() {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasAccess } = useRole();
  const path = location.pathname.match(/\/.*\/settings\/org\/?([a-z]*)\/?/)?.[1];

  const onChangeTab = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    if (newValue) {
      navigate(`/:orgId/settings/org/${newValue}`);
    }
  };

  return (
    <Page title="Organization">
      <Container>
        {path && (
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={path}
            onChange={onChangeTab}
            sx={{ '& .MuiTab-root': { textTransform: 'initial' } }}
          >
            {ACCOUNT_TABS.map(
              (tab) =>
                hasAccess(tab.roles) && (
                  <Tab
                    disableRipple
                    key={tab.value}
                    value={tab.value}
                    label={tab.label}
                    icon={<Iconify icon={tab.icon} width={20} height={20} />}
                  />
                )
            )}
          </Tabs>
        )}

        <Box sx={{ mb: 5 }} />
        <Outlet />
      </Container>
    </Page>
  );
}
