import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import { Alert, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { isEqual } from 'lodash';

import PATHS from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import Dialog from '@components/Dialog';

import StepActions from './StepActions';
import { ImportDataSchemaForm, ImportType } from '@/schemas/importData';
import { getErrorMessage } from './MapFieldsValidation';
import useDonation from '@hooks/useDonation';
import StepGetFile from './GetFile';
import StepMapFields from './MapFields';
import StepLists from './Lists';
import StepOptions from './Options';
import StepReview from './Review';
import calcNewObjects from './calcNewObjects';

// ----------------------------------------------------------------------
export type TStepProps = {
  readonly stepData: ImportDataSchemaForm;
  readonly updateStepData: (newData: Partial<ImportDataSchemaForm>) => void;
  readonly submittingData: {
    readonly isSubmitting: boolean;
    readonly progress: number;
  };
  readonly error: string;
  readonly setError: (error: string) => void;
  readonly isValid: boolean;
  readonly setIsValid: (valid: boolean) => void;
};
export enum StepId {
  getFile,
  mapFields,
  options,
  lists,
  review,
}
export type TStep = {
  readonly id: StepId;
  readonly title: string;
  readonly Component: (props: TStepProps) => JSX.Element | null;
};

// ----------------------------------------------------------------------
type TUseSteps = {
  // Schema: any;
  onComplete: (data: ImportDataSchemaForm) => void;

  title: string;
  actionText: string;
  paywallWarning: string;
  fromDonation: boolean;
};

export const emptyStepData: Partial<ImportDataSchemaForm> = {
  file: undefined,
  data: [],
  hasHeaders: true,
  headers: [],
  mapFields: [],
  matchField: undefined,
  matchAction: undefined,
  paymentMethod: undefined,
  tag: undefined,
  nrOption: undefined,
  calculatedNewObjects: false,
  hasNewObjects: false,
  categoriesMap: [],
  tagsMap: [],
  paymentMethodsMap: [],
  donors: [],
  donations: [],
};

// ----------------------------------------------------------------------
export default function useSteps({
  // Schema,
  onComplete,
  title,
  actionText,
  paywallWarning,
  fromDonation,
}: TUseSteps) {
  const navigate = useNavigate();
  const { categories, tags, paymentMethods } = useDonation();
  const [stepData, setStepData] = useState<ImportDataSchemaForm>({
    ...emptyStepData,
    step: 0,
    paywallWarning: paywallWarning,
    importType: !!paywallWarning ? ImportType.onlyDonations : ImportType.both,
  } as ImportDataSchemaForm);

  // --------------- state ---------------
  const [isDirty, setDirty] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [submittingData, setSubmittingData] = useState({
    isSubmitting: false,
    progress: 0,
  });
  const [error, setError] = useState<string>('');
  const [isValid, setIsValid] = useState(false);

    const steps = useMemo(() => {
    const steps = [
      {
        id: StepId.getFile,
        title: 'File',
        Component: StepGetFile,
      },
      {
        id: StepId.mapFields,
        title: 'Fields',
        Component: StepMapFields,
      },
      ...(stepData.hasNewObjects
        ? [
            {
              id: StepId.lists,
              title: 'Lists',
              Component: StepLists,
            },
          ]
        : []),
      {
        id: StepId.options,
        title: 'Options',
        Component: StepOptions,
      },
      {
        id: StepId.review,
        title: 'Review',
        Component: StepReview,
      },
    ];
    return steps;
  }, [stepData.hasNewObjects]);

  const lastStep = stepData.step === steps.length - 1;

  // --------------- actions ---------------
  const updateStepData = useCallback(
    (newData: Partial<ImportDataSchemaForm>) => {
      const update = { ...stepData, ...newData };
      if (isEqual(stepData, update)) return;

      setDirty(true);
      setStepData(update);
    },
    [stepData, setStepData]
  );

  // --------------- navigation ---------------
  const handleBack = () => {
    setError('');
    setIsValid(true);
    // can add special processing here to save changes that aren't already saved,
    // based on steps[stepData.step].id
    updateStepData({ step: stepData.step - 1 });
  };

  const handleNext = () => {
    // can add special processing here to save changes that aren't already saved,
    // based on stepData.step

    let { calculatedNewObjects, hasNewObjects, categoriesMap, tagsMap, paymentMethodsMap } =
      stepData;
    if (stepData.step === StepId.mapFields && !calculatedNewObjects) {
      [hasNewObjects, categoriesMap, tagsMap, paymentMethodsMap] = calcNewObjects(
        stepData,
        categories,
        tags,
        paymentMethods
      );
      calculatedNewObjects = true;
    }

    // last step next
    if (stepData.step === steps.length - 1) {
      onComplete(stepData);
      return;
    }

    updateStepData({
      step: stepData.step + 1,
      calculatedNewObjects: calculatedNewObjects,
      hasNewObjects: hasNewObjects,
      categoriesMap: categoriesMap,
      tagsMap: tagsMap,
      paymentMethodsMap: paymentMethodsMap,
    });
  };

  const handleClose = () => {
    navigate(fromDonation ? PATHS.org.donations.root : PATHS.org.donors.root);
  };

  // --------------- validation ---------------
  useEffect(() => {
    try {
      // Schema.validateSync(stepData, { abortEarly: false });
      setError('');
    } catch (e) {
      setError(e.errors[0]);
    }
    // extra validation for mapFields
    if (stepData.step === StepId.mapFields) {
      const err = getErrorMessage(stepData.mapFields, stepData.importType);
      if (err) {
        setError(err);
      } else {
        setError('');
      }
    }
  }, [ /* Schema, */ stepData]);

  // --------------- validation ---------------
  const stepProps: TStepProps = {
    stepData,
    updateStepData,
    submittingData,
    error,
    setError,
    isValid,
    setIsValid,
  };

  // --------------- ui ---------------
  const ui = (
    <Dialog
      title={title}
      onClose={handleClose}
      maxWidth="xl"
      isDirty={isDirty}
      isLoading={stepProps.submittingData.isSubmitting}
      contentProps={{ sx: { pt: 1, height: '90vh' } }}
      confirmText="Leave and lose progress?"
      actions={
        <StepActions
          stepData={stepData}
          handleNext={handleNext}
          handleBack={stepData.step !== 0 ? handleBack : undefined}
          disabled={!!error || !isValid}
          hasFailed={hasFailed}
          isSubmitting={stepProps.submittingData.isSubmitting}
          isLast={lastStep}
          error={error}
          actionText={actionText}
        />
      }
    >
      <Outlet />

      <Stack spacing={3} width="100%" height="100%" flexDirection="column">
        <Stepper activeStep={stepData.step} sx={{ px: 1 }}>
          {steps.map((step, index) => (
            <Step key={step.title} completed={stepData.step > index}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {steps.map(
          (step, index) => stepData.step === index && <step.Component key={index} {...stepProps} />
        )}
        {!!error && (
          <Alert severity="error">{error}</Alert>
        )}
      </Stack>
    </Dialog>
  );

  return {
    ui,

    stepData,
    setError,
    setSubmittingData,
    handleClose,
    setFailed: () => setFailed(true),
  };
}
