import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Donation } from '@shared/types';
import { TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  row: { display: 'flex', flexDirection: 'row', columnGap: 4 },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 8,
    marginBottom: 2,
  },
});

// ----------------------------------------------------------------------
type Props = {
  readonly vars: TReceiptVariables;
  readonly gikDonation?: Donation.Donation;
  readonly advantageDonations: Donation.Donation[];
};
// ----------------------------------------------------------------------
export default function ReceiptBodyInfo(props: Props) {
  // Canada has two types of donations - Cash or GiK, and both variants can have advantage
  // difference is that GiK is always single donation, while cash can be multiple

  return (
    <View>
      <InfoGiK {...props} />
      <InfoAdvantage {...props} />
    </View>
  );
}

// ----------------------------------------------------------------------
function InfoGiK({ gikDonation }: Props) {
  if (!gikDonation) return null;

  return (
    <View style={{ display: 'flex', rowGap: 2, marginBottom: 4 }}>
      <View style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
        <Text style={{ fontFamily: 'Helvetica-Bold' }}>
          Description of property received by charity:
        </Text>
        <Text>{gikDonation.paymentInfo}</Text>
      </View>

      {gikDonation.appraiserName && gikDonation.appraiserAddress ? (
        <>
          <View style={styles.row}>
            <Text>Appraised by:</Text>
            <Text>{gikDonation.appraiserName}</Text>
          </View>
          <View style={styles.row}>
            <Text>Address of Appraiser:</Text>
            <Text>{gikDonation.appraiserAddress}</Text>
          </View>
        </>
      ) : null}
    </View>
  );
}

// ----------------------------------------------------------------------
function InfoAdvantage({ vars, advantageDonations }: Props) {
  if (!advantageDonations.length) return null;

  if (advantageDonations.length === 1) {
    return (
      <View>
        <Text>Description of Advantage to Donor: </Text>
        <Text>{advantageDonations[0].advantageDescription}</Text>
      </View>
    );
  }

  return (
    <View>
      <View style={styles.tableHeader}>
        <Text
          style={{
            minWidth: 90,
            borderBottom: '1px solid #000',
            paddingBottom: 2,
          }}
        >
          Date
        </Text>
        <Text
          style={{
            minWidth: 150,
            borderBottom: '1px solid #000',
            fontFamily: 'Helvetica-Bold',
          }}
        >
          Description of Advantage
        </Text>
      </View>

      {/* body */}
      <View>
        {advantageDonations.map((d) => (
          <View key={d.id} style={{ display: 'flex', flexDirection: 'row', columnGap: 8 }}>
            <Text style={{ minWidth: 90 }}>{vars.fDate(d.date)}</Text>
            <Text style={{ minWidth: 150 }}>{d.advantageDescription}</Text>
          </View>
        ))}
      </View>
    </View>
  );
}
