import { useMemo, useState } from 'react';
import { Card, Divider, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';

import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { PageSize } from '@typedefs/app';
import blobToDataUrl from '@utils/blobToDataUrl';
import useResponsive from '@hooks/useResponsive';

import Editor from '@components/Editor';
import { ReceiptReissueSchemaForm } from '@/schemas';
import { TStepProps } from '@pages/receipts/useSteps';
import EmailTemplate, {
  renderTemplate,
  renderSubject,
  defaultState,
  defaultSubject,
} from '@pages/receipts/Create/steps/ReviewEmail/EmailTemplate';

// ----------------------------------------------------------------------
export default function StepReviewEmail({
  stepData,
  updateStepData,
}: TStepProps<ReceiptReissueSchemaForm>) {
  const isDesktop = useResponsive('up', 'xl');
  const { enqueueSnackbar } = useSnackbar();
  const { sendReceiptTestEmail, getReceiptReissueGroup } = useDonation();
  const { org } = useOrg();
  const [isLoading, setLoading] = useState(false);

  // --------------------------------------------------
  const donorGroup = useMemo(
    () => getReceiptReissueGroup(org!, stepData.receipt, stepData.donations, stepData.reissue),
    [getReceiptReissueGroup, org, stepData.receipt, stepData.donations, stepData.reissue]
  );

  // --------------------------------------------------
  const handleIssueTestEmail = async () => {
    if (
      !org ||
      !stepData.email.subject ||
      !stepData.email.html ||
      !stepData.letter.size ||
      !stepData.letter.Doc
    ) {
      enqueueSnackbar('Error sending test email!', { variant: 'error' });
      console.error('Missing data for test email');
      return;
    } else if (!org.emailSignature) {
      enqueueSnackbar('You need to setup your email signature before you can send emails', {
        variant: 'error',
      });
      return;
    }

    setLoading(true);

    const pdfDoc = (
      <stepData.letter.Doc
        title="Receipt"
        description="Receipt"
        size={PageSize[stepData.letter.size]}
        donorGroups={[donorGroup]}
        withLetter={stepData.letter.withLetter}
        preview
        options={stepData.options}
        year={donorGroup.groups[0].year}
      />
    );
    const attachment = await blobToDataUrl(await pdf(pdfDoc).toBlob(), true);
    try {
      await sendReceiptTestEmail({
        orgId: org.id,
        emailSignature: org.emailSignature,
        email: {
          to: '',
          subject: renderSubject(donorGroup.vars, stepData.email.subject),
          body: renderTemplate(donorGroup.vars, stepData.email.html),
          attachment,
        },
      });
      enqueueSnackbar('Test email sent!');
    } catch (e) {
      console.log('Error sending test email: ', e);
      enqueueSnackbar('Error sending test email!', { variant: 'error' });
    }

    setLoading(false);
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({
      email: {
        ...stepData.email,
        subject: event.target.value,
      },
    });
  };

  const handleEditorChange = (htmlString: string, stateString: string) =>
    updateStepData({
      email: {
        ...stepData.email,
        state: stateString,
        html: htmlString,
      },
    });

  const handleEditorReset = (htmlString: string) =>
    updateStepData({
      email: {
        subject: defaultSubject,
        html: htmlString,
        state: defaultState,
      },
    });

  // if we don't include the following, the reset can fail to then display the right subject
  const currentSubject = useMemo(() => stepData.email.subject || '', [stepData.email.subject]);

  // --------------------------------------------------
  if (!org || !org.emailSignature) return null;

  return (
    <Stack spacing={1} height="100%" direction={{ xs: 'column', xl: 'row' }}>
      <Stack spacing={2} p={2} width={{ xs: '100%', xl: '50%' }}>
        {/* <TextField
          fullWidth
          label="Email from"
          value={`${org.emailSignature.signatoryName} <${org.emailSignature.signatoryEmail}>`}
          disabled
        /> */}

        <TextField
          required
          fullWidth
          label="Email subject"
          value={currentSubject}
          onChange={handleSubjectChange}
          inputProps={{ maxLength: 100 }}
        />

        <Editor
          defaultState={stepData.email?.state}
          onChange={handleEditorChange}
          onReset={handleEditorReset}
        />
      </Stack>

      {isDesktop ? <Divider orientation="vertical" flexItem /> : <Divider />}

      <Stack spacing={2} p={2} width={{ xs: '100%', xl: '50%' }}>
        {/* <Typography variant="h6">Preview</Typography> */}
        <Stack direction="row" spacing={2}>
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            onClick={handleIssueTestEmail}
            sx={{ width: '30%', flexDirection: 'column' }}
          >
            Send test email to me
            <Typography
              color={isLoading ? 'inherit' : 'gray'}
              variant="body2"
              mt={-0.5}
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              {org.emailSignature.signatoryEmail}
            </Typography>
          </LoadingButton>
        </Stack>

        <Card
          sx={{
            p: 2,
            overflow: 'visible',
            background: (theme) => theme.palette.grey[100],
            boxShadow: 'none',
          }}
        >
          {renderSubject(donorGroup.vars, stepData.email.subject)}
        </Card>
        <EmailTemplate vars={donorGroup.vars} html={stepData.email.html} />
      </Stack>
    </Stack>
  );
}
