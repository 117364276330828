import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  readonly open: boolean;
  readonly onClose: VoidFunction;
  readonly onConfirm: MouseEventHandler<HTMLButtonElement>;
  readonly loading?: boolean;
  readonly additionalAction?: React.ReactNode;

  readonly bgColorConfirm?: "error" | "success" | "warning" | "info" | "inherit" | "primary" | "secondary";
  readonly sizeButton?: 'large' | 'medium' | 'small';
  readonly bgColorCancel?: string;
  readonly textConfirmButton?: string;
  readonly textCancelButton?: string;

  // overrides
  readonly title?: string;
  readonly children?: React.ReactNode;
};

// ----------------------------------------------------------------------
export default function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  loading = false,
  additionalAction,
  title,
  children,
  bgColorConfirm = "primary",
  textConfirmButton = 'Confirm',
  textCancelButton = 'Cancel',
  sizeButton='large',
}: Props) {
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Escape') {
      onClose();
    } else if (e.key === 'Enter') {
      onConfirm(e as any);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" onKeyDown={handleKeyDown}>
      <DialogTitle>
        {title || 'Confirm?'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          disabled={loading}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[700],
          }}
        >
          <Iconify icon="material-symbols:close" />
        </IconButton>
      </DialogTitle>

      {children && <DialogContent dividers>{children}</DialogContent>}

      <DialogActions>
        <Stack spacing={2} direction="row">
          <Button
            onClick={onClose}
            sx={{ color: (theme) => theme.palette.grey[700] }}
            disabled={loading}
          >
            {textCancelButton}
          </Button>

          <LoadingButton
            type="submit"
            size={sizeButton}
            variant="contained"
            loading={loading}
            onClick={onConfirm}
            color={bgColorConfirm}
           >
            {textConfirmButton}
          </LoadingButton>
          {additionalAction}
          </Stack>
      </DialogActions>
    </Dialog>
  );
}
