import { useLocation } from 'react-router-dom';

import useOrg from '@hooks/useOrg';
import PATHS, { getActive } from '@routes/paths';
import BannerOrgDeletion from './BannerOrgDeletion';
import BannerUsageLimit from './BannerUsageLimit';
import { BannerStyle } from './styles';

// ----------------------------------------------------------------------
type Props = {
  isOffset?: boolean;
  isCollapse?: boolean;
  isFullWidth?: boolean;
};

export default function Banner({
  isOffset = false,
  isCollapse = false,
  isFullWidth = false,
}: Props) {
  const { pathname } = useLocation();
  const orgSubpath = getActive(PATHS.org.root, pathname);
  const { org } = useOrg();
  const overTheLimit = false;

  if (!org || !orgSubpath) return null;

  let content = undefined;
  if (org._meta.archiveAt)
    content = <BannerOrgDeletion orgId={org.id} deleteDate={org._meta.archiveAt} />;
  else if (overTheLimit) content = <BannerUsageLimit orgId={org.id} />;

  return content ? (
    <BannerStyle
      isOffset={isOffset}
      isCollapse={isCollapse}
      isFullWidth={isFullWidth}
      error={!!org._meta.archiveAt}
    >
      {content}
    </BannerStyle>
  ) : null;
}
