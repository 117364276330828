import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';

import useDonation from '@hooks/useDonation';
import Iconify from '@components/Iconify';
import FeedbackDialog from '@/layouts/AppLayout/Navbar/Feedback/FeedbackDialog';
import { Limits } from '@shared/limits';
import DefaultCategory from './DefaultCategory';
import useNavigate from '@hooks/useNavigate';
import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
export default function DonationCategories() {
  const { categories } = useDonation();
  const [feedbackOpen, feedbackSetOpen] = useState(false);
  const navigate = useNavigate();
  const { org } = useOrg();
  // --------------- effects ---------------
  const sortedCategories = useMemo(
    () => sortBy(categories, (c) => c.name.toUpperCase()),
    [categories]
  );

  // --------------- actions ---------------

  const handleCreateCategory = () => {
    navigate(getPath(PATHS.org.donations.settings.categoriesCreate, { orgId: org!.id }));
  };

  const handleEdit = (catId: string) => {
    navigate(getPath(PATHS.org.donations.settings.categoriesEdit, { orgId: org!.id, catId }));
  };

  return (
    <Stack marginTop={3}>
      <DefaultCategory />
      <Divider sx={{ my: 1, borderStyle: 'solid' }} />
      <Stack>
        <Stack spacing={0.5} sx={{ py: 1 }}>
          {sortedCategories.map((cat) => (
            <Stack
              key={cat.id}
              spacing={1}
              direction="row"
              alignItems="center"
              p={0.5}
              sx={{
                borderRadius: 2,
                // border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                cursor: 'pointer',
                // width: 'fit-content',
              }}
              onClick={() => handleEdit(cat.id)}
            >
              <Stack
                key={cat.colorBackground}
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 26,
                  minWidth: 26,
                  height: 26,
                  margin: 0.5,
                  borderRadius: '50%',
                  background: cat.colorBackground,
                }}
              />

              <Typography variant="subtitle1">{cat.name}</Typography>
              {cat.description && (
                <>
                  <Typography>-</Typography>
                  <Typography variant="body2" noWrap>
                    {cat.description}
                  </Typography>
                </>
              )}
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
        <Stack alignItems="flex-end">
          {categories.length < Limits.categories ? (
            <Button
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleCreateCategory()}
            >
              New Category
            </Button>
          ) : (
            <Typography>
              You've reached the limit of {Limits.categories} Categories. Please{' '}
              <Link sx={{ cursor: 'pointer' }} onClick={() => feedbackSetOpen(true)}>
                contact us
              </Link>{' '}
              if this is a problem for you.
            </Typography>
          )}
        </Stack>
      </Stack>

      <FeedbackDialog open={feedbackOpen} setOpen={feedbackSetOpen} />
    </Stack>
  );
}
