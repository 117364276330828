import { useState } from 'react';
import { Button, Stack } from '@mui/material';

import { TColumn, TColumnFilter } from '@typedefs/app';
import Iconify from '@components/Iconify';

import FilterRow from './FilterRow';
import FilterModal from './FilterModal';

// ----------------------------------------------------------------------
type Props = {
  columns: TColumn[];
  filters: TColumnFilter[];
  setFilters: (filters: TColumnFilter[]) => void;
};

// ----------------------------------------------------------------------
export default function TableFilters({ columns, filters, setFilters }: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  // --------------- actions ---------------
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);
  const handleAdd = (newFilter: TColumnFilter) => setFilters([...filters, newFilter]);

  const onRemove = (filterId: string) => {
    setFilters(filters.filter((f) => f.id !== filterId));
  };

  return (
    <Stack spacing={1} alignItems="flex-start">
      {filters.length ? (
        <Stack direction="row" flexWrap="wrap">
          {filters.map((f) => (
            <FilterRow key={f.id} filter={f} columns={columns} onRemove={onRemove} />
          ))}
        </Stack>
      ) : null}

      <Button
        onClick={handleOpen}
        sx={{ whiteSpace: 'nowrap' }}
        startIcon={<Iconify icon="material-symbols:add-circle" />}
      >
        Add filter
      </Button>
      <FilterModal
        open={open}
        onAdd={handleAdd}
        onClose={handleClose}
        filterableColumns={columns.filter((c) => c.filterable)}
      />
    </Stack>
  );
}
