import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collection, onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';

import type { TViewState, ListenerData } from '@typedefs/view';
import type { View } from '@shared/types';
import db from '@fire/store';
import * as fn from '@fire/functions';
import * as analytics from '@fire/analytics';
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState: TViewState = {
  views: [],
};

const slice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    // general
    logout: (state: TViewState) => initialState,
    clearListenerData: (state: TViewState) => {
      state.views = [];
    },

    listenerUpdate: (state: TViewState, action: PayloadAction<ListenerData>) => {
      state.views = action.payload || [];
    },
  },
});

export default slice.reducer;

// ----------------------------------------------------------------------
export function logout() {
  return async () => {
    await disableListeners();
    dispatch(slice.actions.logout());
  };
}

export function disableListeners() {
  return async () => {
    listenerViews?.();
    dispatch(slice.actions.clearListenerData());
  };
}

export function enableListeners(orgIds: string[]) {
  return async () => {
    await dispatch(disableListeners());

    try {
      listenerViews = await createListener(orgIds);
    } catch (e) {
      console.error('Donation listeners permission failure: ', e);
    }
  };
}

// ---------- CREATE ----------
export function createView(payload: View.CreateReq) {
  return async () => {
    try {
      await fn.viewCreate(payload);
      analytics.view.viewCreate();
      return;
    } catch (e) {
      throw e;
    }
  };
}

// ---------- UPDATE ----------
export function updateView(payload: View.UpdateReq) {
  return async () => {
    try {
      await fn.viewUpdate(payload);
      analytics.view.viewUpdate();
      return;
    } catch (e) {
      throw e;
    }
  };
}

// ---------- DELETE ----------
export function deleteView(payload: View.DeleteReq) {
  return async () => {
    try {
      await fn.viewDelete(payload);
      analytics.view.viewDelete();
      return;
    } catch (e) {
      throw e;
    }
  };
}

// ----------------------------------------------------------------------
// LISTENERS
let listenerViews: Unsubscribe | null = null;
async function createListener(orgIds: string[]): Promise<Unsubscribe> {
  let firstRun = true;
  let listener: Unsubscribe | null = null;

  return new Promise((resolve, reject) => {
    listener = onSnapshot(
      query(collection(db, 'views'), where('orgId', 'in', orgIds)),
      async (snap) => {
        const views = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as View.View[];
        if (firstRun) {
          resolve(listener as Unsubscribe);
          firstRun = false;
        }
        dispatch(slice.actions.listenerUpdate(views));
      },
      reject
    );
  });
}
