import { endsWith, startsWith } from 'lodash';
import { isAfter, isBefore, isSameDay } from 'date-fns';

import { TColumnFilter, ColumnFilterOperator } from '@typedefs/app';

// ----------------------------------------------------------------------
export default function evaluateFilter(
  initialValue: string | number | boolean | undefined,
  { columnType, operator, value }: TColumnFilter
) {
  let itemValue = initialValue;
  let filterValue: string | number | boolean = value;
  if (['string', 'address'].includes(columnType)) {
    itemValue = String(initialValue).toLowerCase();
    filterValue = String(value).toLowerCase();

    switch (operator) {
      case ColumnFilterOperator.contains:
        return itemValue.indexOf(filterValue) !== -1;
      case ColumnFilterOperator.equals:
        return itemValue === filterValue;
      case ColumnFilterOperator.notEquals:
        return itemValue !== filterValue;
      case ColumnFilterOperator.greaterThan:
        return startsWith(itemValue, filterValue);
      case ColumnFilterOperator.lessThan:
        return endsWith(itemValue, filterValue);
      case ColumnFilterOperator.isEmpty:
        return !itemValue;
      case ColumnFilterOperator.isNotEmpty:
        return !!itemValue;
      default:
        console.error('Unsupported string/address operator!', operator);
        return false;
    }
  } else if (['number', 'currency'].includes(columnType)) {
    itemValue = Number(initialValue);
    filterValue = Number(value);

    switch (operator) {
      case ColumnFilterOperator.equals:
        return itemValue === filterValue;
      case ColumnFilterOperator.notEquals:
        return itemValue !== filterValue;
      case ColumnFilterOperator.greaterThan:
        return itemValue && filterValue ? itemValue > filterValue : false;
      case ColumnFilterOperator.lessThan:
        return itemValue && filterValue ? itemValue < filterValue : false;
      case ColumnFilterOperator.isEmpty:
        return !itemValue;
      case ColumnFilterOperator.isNotEmpty:
        return !!itemValue;
      default:
        console.error('Unsupported number/currency operator!', operator);
        return false;
    }
  } else if (columnType === 'date') {
    itemValue = initialValue ? new Date(initialValue as string).getTime() : NaN;
    filterValue = new Date(value).getTime();

    switch (operator) {
      case ColumnFilterOperator.equals:
        return isSameDay(itemValue, filterValue);
      case ColumnFilterOperator.notEquals:
        return !isSameDay(itemValue, filterValue);
      case ColumnFilterOperator.greaterThan:
        return isSameDay(itemValue, filterValue) || isAfter(itemValue, filterValue);
      case ColumnFilterOperator.lessThan:
        return isBefore(itemValue, filterValue) || isSameDay(itemValue, filterValue);
      case ColumnFilterOperator.isEmpty:
        return !itemValue;
      case ColumnFilterOperator.isNotEmpty:
        return !!itemValue;
      default:
        console.error('Unsupported date operator!', operator);
        return false;
    }
  } else if (columnType === 'boolean') {
    itemValue = Boolean(initialValue);
    filterValue = Boolean(value);

    switch (operator) {
      case ColumnFilterOperator.isEmpty:
        return !itemValue;
      case ColumnFilterOperator.isNotEmpty:
        return !!itemValue;
      default:
        console.error('Unsupported boolean operator!', operator);
        return false;
    }
  }

  console.error('Unsupported columnType!', columnType);
  return false;
}
