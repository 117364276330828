import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';

import useDonation from '@hooks/useDonation';
import Iconify from '@components/Iconify';
import FeedbackDialog from '@/layouts/AppLayout/Navbar/Feedback/FeedbackDialog';
import { Limits } from '@shared/limits';
import DefaultPaymentMethod from './DefaultPaymentMethod';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';

// ----------------------------------------------------------------------
export default function PaymentMethods() {
  const { paymentMethods } = useDonation();
  const [feedbackOpen, feedbackSetOpen] = useState(false);
  const navigate = useNavigate();
  const { org } = useOrg();

  // --------------- effects ---------------
  const donationPaymentMethods = useMemo(
    () => sortBy(paymentMethods, (p) => p.name.toUpperCase()),
    [paymentMethods]
  );

  // --------------- actions ---------------
  const handleAdd = () => {
    navigate(getPath(PATHS.org.donations.settings.PaymentMethodsCreate, { orgId: org!.id }));
  };

  const handleEdit = (paymentMethodId: string) => {
    navigate(
      getPath(PATHS.org.donations.settings.PaymentMethodsEdit, { orgId: org!.id, paymentMethodId })
    );
  };

  return (
    <Stack marginTop={3}>
      <DefaultPaymentMethod />
      <Divider sx={{ my: 1, borderStyle: 'solid' }} />

      <Stack>
        <Stack spacing={0.5} sx={{ py: 1 }}>
          {donationPaymentMethods.map((p) => (
            <Stack
              key={p.id}
              spacing={1}
              direction="row"
              alignItems="center"
              py={0.5}
              px={1}
              sx={{
                cursor: 'pointer',
                width: 'fit-content',
              }}
              onClick={() => handleEdit(p.id)}
            >
              <Typography>&#x2022;</Typography>
              <Typography variant="subtitle1">{p.name}</Typography>
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
        <Stack alignItems="flex-end">
          {paymentMethods.length < Limits.paymentMethods ? (
            <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleAdd()}>
              New Payment Method
            </Button>
          ) : (
            <Typography>
              You're reached the limit of {Limits.paymentMethods} Payment Methods. Please{' '}
              <Link sx={{ cursor: 'pointer' }} onClick={() => feedbackSetOpen(true)}>
                contact us
              </Link>{' '}
              if this is a problem for you.
            </Typography>
          )}
        </Stack>
      </Stack>

      <FeedbackDialog open={feedbackOpen} setOpen={feedbackSetOpen} />
    </Stack>
  );
}
