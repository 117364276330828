import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import type { Pledge } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// export const pledgeGetAllForOrg = httpsCallable<
//   Pledge.GetAllForOrgReq,
//   Pledge.GetAllForOrgRes
// >(firebaseFunctions, 'pledge-getAllForOrg');

// ----------------------------------------------------------------------
// Create
export const pledgeCreate = httpsCallable<Pledge.CreateReq, Pledge.CreateRes>(
  firebaseFunctions,
  'pledge-create'
);

// ----------------------------------------------------------------------
// Update
export const pledgeUpdate = httpsCallable<Pledge.UpdateReq, Pledge.UpdateRes>(
  firebaseFunctions,
  'pledge-update'
);

// ----------------------------------------------------------------------
// Delete
export const pledgeDelete = httpsCallable<Pledge.DeleteReq, Pledge.DeleteRes>(
  firebaseFunctions,
  'pledge-deleteRecord'
);
