import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { last, sortBy } from 'lodash';

import { Tag } from '@shared/types';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import { RHFTextField, RHFAutocomplete, RHFCheckbox } from '@components/hook-form';
import renderTags, {
  renderTagOption,
} from '@pages/donors/dialogs/Settings/DonorTags/utils/renderTags';
import TagDialog from '@pages/donors/dialogs/Settings/DonorTags/Dialog';

// ----------------------------------------------------------------------
type Props = {
  readonly formDisabled: boolean;
  readonly nonReceiptable: boolean;
};
// ----------------------------------------------------------------------
export default function DonorAdditionalForm({ formDisabled, nonReceiptable }: Props) {
  const { setValue, getValues } = useFormContext();
  const { tags } = useDonation();
  const { fVisibleCustomFields } = useFormat();
  const [createTagOpen, setCreateTagOpen] = useState(false);

  // Get the visible custom fields, with their indices
  const visibleCustomFields = fVisibleCustomFields();

  const handleTagCreate = (newTag?: Tag.Tag) => {
    if (newTag) {
      const currentTags = getValues('tags');
      // delay is required due to Form re-render
      setTimeout(() => setValue('tags', [...currentTags, newTag]), 250);
    }
    setCreateTagOpen(false);
  };

  return (
    <Stack spacing={2}>
      {formDisabled ? (
        <Typography variant="body2" color="text.secondary" sx={{ textWrap: 'nowrap' }}>
          Non-Receiptable: {nonReceiptable ? 'Yes' : 'No'}
        </Typography>
      ) : (
        <RHFCheckbox name="nonReceiptable" label="Non-Receiptable" disabled={formDisabled} />
      )}

      <TagDialog open={createTagOpen} onClose={handleTagCreate} />
      <RHFAutocomplete
        name="tags"
        label="Tags"
        multiple
        fullWidth
        freeSolo={false}
        disabled={formDisabled}
        options={[
          ...sortBy(
            tags.filter((t) => t.type === 'donor'),
            (t) => t.name.toUpperCase()
          ),
          { id: 'create-new', name: '', description: '' } as Tag.Tag,
        ]}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={renderTags}
        renderOption={renderTagOption}
        beforeOnChange={(newValues, commitChange) => {
          if (newValues && newValues.length > 0 && last(newValues)?.id === 'create-new') {
            setCreateTagOpen(true);
          } else {
            commitChange();
          }
        }}
      />
      {visibleCustomFields &&
        visibleCustomFields.map((f) => (
          <RHFTextField
            key={f.index}
            disabled={formDisabled}
            name={`customFields.${f.index}`}
            label={f.name}
            inputProps={{ maxLength: 50 }}
          />
        ))}
      <RHFTextField
        name="notes"
        label="Notes (internal)"
        disabled={formDisabled}
        multiline
        rows={4}
        inputProps={{ maxLength: 500 }}
      />
    </Stack>
  );
}
