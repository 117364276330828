import CustomEmailDomain from './Postmark';
import GmailDomain from './Gmail';

import { useState, useEffect, useMemo } from 'react';
import { Alert, Stack, Card, Divider, Box, Tab, Tabs } from '@mui/material';
import { FormProvider, RHFSwitch, RHFTextField } from '@components/hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import { Role, EmailSignatureType } from '@shared/types/organization';
import { OrganizationReceiptSchemaForm, organizationReceiptSchemaResolver } from '@/schemas';
import { isEqual, reduce } from 'lodash';

// ----------------------------------------------------------------------
export default function SignatoryForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { org, updateOrg } = useOrg();
  const { hasAccess } = useRole();
  const isUS = org?.address?.country === 'us';
  const canManage = hasAccess([Role.editor, Role.contributor]);
  const [selectedTab, selectTab] = useState(
    org?.emailSignature?.type || EmailSignatureType.customDomain
  );
  // --------------- form ---------------
  const defaultValues: OrganizationReceiptSchemaForm = useMemo(
    () => ({
      signatoryName: org?.signatoryName || '',
      signatoryPosition: org?.signatoryPosition || '',
      religiousBenefit: org?.religiousBenefit || false,
      signatoryEmail: org?.emailSignature?.signatoryEmail || '',
    }),
    [
      org?.signatoryName,
      org?.signatoryPosition,
      org?.religiousBenefit,
      org?.emailSignature?.signatoryEmail,
    ]
  );

  const methods = useForm<OrganizationReceiptSchemaForm>({
    resolver: organizationReceiptSchemaResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });
  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = methods;

  // --------------- effects ---------------
  useEffect(() => {
    reset(defaultValues);
  }, [reset, org, defaultValues]);

  const handleChange = (event: React.SyntheticEvent, newValue: EmailSignatureType.customDomain) => {
    selectTab(newValue);
  };

  // --------------- actions ---------------
  const onSubmit = async (data: OrganizationReceiptSchemaForm) => {
    if (!org) {
      setError('afterSubmit', { message: '[internal] Missing organization' });
      return;
    }
    if (!canManage) {
      setError('afterSubmit', { message: 'You lack management permissions' });
      return;
    }

    if (
      selectedTab === EmailSignatureType.customDomain &&
      !!data.signatoryEmail &&
      (data.signatoryEmail.toLowerCase().includes('@gmail.') ||
        data.signatoryEmail.toLowerCase().includes('@google.') ||
        data.signatoryEmail.toLowerCase().includes('@googlemail.'))
    ) {
      enqueueSnackbar('Please use the Gmail tab for Gmail addresses.');
      return;
    }

    // current form values
    const currentValues: any = {
      signatoryName: org.signatoryName,
      signatoryPosition: org.signatoryPosition,
      religiousBenefit: org.religiousBenefit,
    };

    let update: any = {
      ...reduce(
        data,
        (result, value, key) =>
          isEqual(value, (currentValues as any)[key]) ? result : { ...result, [key]: value },
        {}
      ),
    };

    // TODO: This needs to be moved away when we refactor the logic
    // Add the signatory type to the request
    update.signatoryType =
      selectedTab === EmailSignatureType.gmail
        ? EmailSignatureType.gmail
        : EmailSignatureType.customDomain;

    // if there is no signature, and email is provided, add it for create/update
    // only signatoryName can be updated, email must be deleted and re-created
    if (!org.emailSignature && !!data.signatoryEmail) {
      update.signatoryEmail = data.signatoryEmail;
    }

    try {
      const res = Object.keys(update).length ? await updateOrg({ orgId: org.id, update }) : true;
      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  function displayTabs() {
    // When there is no email signature we display both tabs
    if (!org?.emailSignature?.signatoryEmail) {
      return [
        <Tab
          key={EmailSignatureType.customDomain}
          value={EmailSignatureType.customDomain}
          label="Custom domain"
        />,
        <Tab key={EmailSignatureType.gmail} value={EmailSignatureType.gmail} label="Gmail" />,
      ];
    }

    return org.emailSignature.type === EmailSignatureType.gmail ? (
      <Tab value={EmailSignatureType.gmail} label="Gmail" />
    ) : (
      <Tab value={EmailSignatureType.customDomain} label="Custom domain" />
    );
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={2} direction="row">
          <Stack spacing={2} width="50%">
            <RHFTextField
              required={!!org?.emailSignature}
              name="signatoryName"
              label="Signatory Name"
              helperText={errors.signatoryName?.message}
              inputProps={{ maxLength: 80 }}
            />
            <RHFTextField
              name="signatoryPosition"
              label="Signatory Position"
              helperText={errors.signatoryPosition?.message}
              // disabled={emailDisabled}
              inputProps={{ maxLength: 40 }}
            />

            <Box />

            {isUS && (
              <RHFSwitch
                name="religiousBenefit"
                label="Religious Benefit"
                sx={{ width: '100%' }}
                helperText={'Do your Donors receive an "intangible religious benefit"?'}
              />
            )}
          </Stack>

          <Divider orientation="vertical" flexItem />

          <Stack spacing={2} width="50%">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              {displayTabs()}
            </Tabs>
            {selectedTab === EmailSignatureType.gmail ? (
              <GmailDomain />
            ) : (
              <CustomEmailDomain errors={errors} />
            )}
          </Stack>
        </Stack>

        <Stack spacing={2} mt={2}>
          <Divider />
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="flex-end"
            justifyContent="flex-end"
            spacing={2}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid || !isDirty}
            >
              Update
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </FormProvider>
  );
}
