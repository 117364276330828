import { forEach } from 'lodash';
import { TStepProps } from './useSteps';
import {
  DonationFieldNames,
  DonorFieldNames,
  FieldNames,
  ImportType,
  MatchAction,
  NROption,
} from '@/schemas/importData';
import { useEffect, useMemo } from 'react';
import { ImportData } from '@shared/types';
import useDonation from '@hooks/useDonation';
import { parse } from 'date-fns';

import * as yup from 'yup';
import {
  addToDonorArray,
  countryFromState,
  donorFullName,
  fixCountry,
  fixState,
  splitCityState,
  splitCityStateCountry,
  splitFirstLast,
  splitLastFirst,
  validateInputAmount,
  validateLength,
} from './splitting';
import useFormat from '@hooks/useFormat';
import { Card, Stack, Typography } from '@mui/material';
import useStripe from '@hooks/useStripe';
import { calcDonorPaywall } from '@/guards/PaywallDonorGuard';
import { TColumn } from '@typedefs/app';
import DonorsTable from './DonorsTable';
import DonationsTable from './DonationsTable';
import { ListObject } from './Lists';
import { Limits } from '@shared/limits';
import { parseDateTimezone } from '@redux/slices/donation';
import BulletList from '@components/BulletList';
import { EmailValidOrEmpty } from '@utils/regexp';

const emailSchema = yup.string().matches(EmailValidOrEmpty, 'Email must be a valid email address');

// ----------------------------------------------------------------------
export default function StepReview({ stepData, updateStepData, setError }: TStepProps) {
  const { donors, donations, getDonorById, categories, tags, paymentMethods } = useDonation();
  const {
    mapFields,
    data,
    hasHeaders,
    matchField,
    matchAction,
    nrOption,
    importType,
    tagsMap,
    categoriesMap,
    paymentMethodsMap,
  } = stepData;

  const { fDateToISO, fFullName, fJoinWithConjunction, getDateFormat } = useFormat();

  const { subscription, isFreeTrial } = useStripe();
  const planLimit = calcDonorPaywall(subscription);

  const dateFormat = getDateFormat();

  // From a map such as categoriesMap and list of its associated entities such as
  // categories, set ones in the map that are new but have a New Name matching an
  // existing entity to instead be replacements of that existing one.
  // Return the possibly changed map, and whether it was changed.
  // Error handling in List and OneList should avoid needing this, but it could fail.
  const fixMap = (
    map: ImportData.ImportEntity[],
    existing: ListObject[]
  ): [ImportData.ImportEntity[], boolean] => {
    const newMap = [...map];
    let changed = false;
    for (const m of newMap) {
      const found = existing.find((e) => e.name.toLowerCase() === m.lowerName.toLowerCase());
      if (!!found) {
        changed = true;
        m.id = found.id;
      }
    }
    return [newMap, changed];
  };

  // Do the fixMap steps for the three types of map/entity.
  useEffect(() => {
    const [newMap, changed] = fixMap(categoriesMap, categories);
    if (changed) updateStepData({ categoriesMap: newMap });
  }, [categories, categoriesMap, updateStepData]);

  useEffect(() => {
    const [newMap, changed] = fixMap(tagsMap, tags);
    if (changed) updateStepData({ tagsMap: newMap });
  }, [tags, tagsMap, updateStepData]);

  useEffect(() => {
    const [newMap, changed] = fixMap(paymentMethodsMap, paymentMethods);
    if (changed) updateStepData({ paymentMethodsMap: newMap });
  }, [paymentMethods, paymentMethodsMap, updateStepData]);

  const [
    importDonors,
    importDonations,
    errors,
    warnings,
    boldWarnings,
    donorColumns,
    donationColumns,
  ] = useMemo(() => {
    // indices of the import file columns that hold the various possible matched fields,
    // -1 means not a matched field
    const cols: Record<Exclude<FieldNames, ''>, number> = {
      email: -1,
      memberNumber: -1,
      firstName: -1,
      middleName: -1,
      lastName: -1,
      firstLast: -1,
      lastFirst: -1,
      organization: -1,
      phone: -1,
      address1: -1,
      address2: -1,
      city: -1,
      state: -1,
      cityState: -1,
      cityStateCountry: -1,
      postalCode: -1,
      country: -1,
      tag: -1,
      donorNotes: -1,
      date: -1,
      amount: -1,
      category: -1,
      paymentMethod: -1,
      paymentInfo: -1,
      description: -1,
      donationNotes: -1,
      amountEligible: -1,
      amountAdvantage: -1,
      advantageDescription: -1,
    };
    const errors: string[] = [];
    const warnings: string[] = [];
    let boldWarnings: string[] = [];

    // check limits on categories etc.
    if (categories.length + categoriesMap.filter((c) => !c.id).length > Limits.categories) {
      errors.push(
        `Adding the new categories would exceed the limit of ${Limits.categories} categories.`
      );
    }
    if (tags.length + tagsMap.filter((t) => !t.id).length > Limits.tags) {
      errors.push(`Adding the new tags would exceed the limit of ${Limits.tags} tags.`);
    }
    if (
      paymentMethods.length + paymentMethodsMap.filter((pm) => !pm.id).length >
      Limits.paymentMethods
    ) {
      errors.push(
        `Adding the new paymentMethods would exceed the limit of ${Limits.paymentMethods} payment methods.`
      );
    }

    // Fill in the actual column numbers for all mapped fields other than skipped ones
    forEach(mapFields, (value, index) => {
      if (!value.fieldName) return; // for 'Skip (don't import)'
      const key: FieldNames = value.fieldName;
      if (!!key) {
        cols[key] = index;
      }
    });
    // Arrays of the donor and donation fields to display and pass to the functions to save
    const importDonors: Partial<ImportData.ImportDonor>[] = [];
    const importDonations: Partial<ImportData.ImportDonation>[] = [];

    const currentYear = new Date().getFullYear();
    const importingOrganization = !!mapFields.find((f) => f.fieldName === 'organization');
    const importingCountry = !!mapFields.find(
      (f) => f.fieldName === 'country' || f.fieldName === 'cityStateCountry'
    );
    let addedCountry = false; // did we create a country field from the state field when we weren't explicitly importing country?

    // booleans to simplify code that depends on the action on matched donors
    const addMatchedDonors = matchAction?.value === MatchAction.add;
    const updateMatchedDonors = matchAction?.value === MatchAction.update;
    const ignoreMatchedDonors = matchAction?.value === MatchAction.ignore;

    // booleans to simplify code that depends on which field we are matching donors on
    const matchOnEmail = matchField?.value === 'email';
    const matchOnMemberNum = matchField?.value === 'memberNumber';

    // booleans to simplify code that depends on what we are importing
    const importingDonors = importType !== ImportType.onlyDonations;
    const importingOnlyDonors = importType === ImportType.onlyDonors;
    const importingDonations = importType !== ImportType.onlyDonors;
    const importingOnlyDonations = importType === ImportType.onlyDonations;
    const importingBoth = importType === ImportType.both;

    // array to hold row numbers of imported donation rows that duplicate existing rows
    // in the Firestore collection on donor, date, amount and category
    const dupRows: number[] = [];

    // work through the rows of the input file
    data.forEach((dataRow, rowNum) => {
      if (rowNum === 0 && hasHeaders) return;
      let ignoreDonorFields = false; // are we ignoring donor fields on this row
      let donorIndex = -1; // index of the importDonors row associated w/ this row
      let donationIndex = -1; // index of the importDonations row associated w/ this row
      // have we already generated certain errors on this row (prevents too many errors)
      let firstLastError = false;
      let dateError = false;
      let categoryError = false;
      let amountError = false;

      // get and validate the two fields that can be used for matching
      const email = cols.email >= 0 ? dataRow[cols.email] : '';
      if (cols.email >= 0 && !!email && !emailSchema.isValidSync(email)) {
        errors.push(
          `The Email Address "${email}" on line ${rowNum + 1} is not a valid email address.`
        );
        return;
      }
      const memberNumberStr = cols.memberNumber >= 0 ? dataRow[cols.memberNumber] : '-1';
      if (cols.memberNumber >= 0 && !!memberNumberStr && !/^[1-9]\d{0,9}$/.test(memberNumberStr)) {
        errors.push(
          `The Member/Env. # "${memberNumberStr}" on line ${rowNum + 1} is not a number, or is not at least 1.`
        );
        return;
      }
      const memberNumber = Number(memberNumberStr);

      // If we are matching to donors on the email address, validate things and
      // generate the row(s) we are saving the data to.
      if (matchOnEmail) {
        if (!email) {
          errors.push(
            `There is no email address on line ${rowNum + 1} so it cannot be matched to a donor.`
          );
          return;
        }
        if (importingDonors) {
          // did we import this from a prior row already?||
          let importDonorIndex = importDonors.findIndex(
            (d) => d.email?.toLowerCase() === email.toLowerCase()
          );
          if (importDonorIndex >= 0) {
            if (addMatchedDonors) {
              errors.push(
                `The email address ${email} on line ${rowNum + 1} has already been added by a previous line - it cannot be added twice.`
              );
              return;
            } else {
              /* If the matchAction is update, a previous row updated it so we can ignore this row; if it's ignore, we ignore! */
              ignoreDonorFields = true;
              donorIndex = importDonorIndex;
            }
            if (importingBoth && donationIndex === -1) {
              importDonations.push(
                importDonors[importDonorIndex].id
                  ? { donorId: importDonors[importDonorIndex].id }
                  : { importDonorIndex: importDonorIndex }
              );
              donationIndex = importDonations.length - 1;
            }
          }
        }
        if (!ignoreDonorFields) {
          /* Didn't already import this donor, find any matching existing donor(s) */
          let foundDonors = donors.filter((d) => d.email === email);
          if (foundDonors.length > 1 && updateMatchedDonors) {
            errors.push(
              `There is more than one donor in your current Donor List with the Email Address "${email}" that occurs on line ${rowNum + 1}.  The import cannot proceed because you are ${importingDonations ? 'adding donations' : 'updating existing donors'} based on the email address. One way to resolve this, if it is spouses who have the same email address, is to merge them into one donor record, since spouses can generally claim each others' tax receipts in Canada and the U.S.A.`
            );
            return;
          } else if (!foundDonors.length) {
            if (importingOnlyDonations) {
              errors.push(
                `Cannot find a Donor with the Email "${email}", which is on Line ${rowNum + 1} of the input file.`
              );
              return;
            } else if (donorIndex === -1) {
              importDonors.push({ email: email });
              validateLength(email, rowNum, 'Email Address', 60, warnings);
              donorIndex = importDonors.length - 1;
            }
            if (importingBoth && donationIndex === -1) {
              importDonations.push({ importDonorIndex: donorIndex });
              donationIndex = importDonations.length - 1;
            }
          } else if (foundDonors.length === 1) {
            if (importingDonors) {
              if (addMatchedDonors) {
                importDonors.push({ email: email });
                donorIndex = importDonors.length - 1;
              } else if (updateMatchedDonors) {
                importDonors.push({
                  id: foundDonors[0].id,
                  email: email,
                  tagIds: foundDonors[0].tagIds,
                  ...(foundDonors[0].memberNumbers
                    ? { memberNumbers: foundDonors[0].memberNumbers }
                    : {}),
                  customFields: foundDonors[0].customFields,
                });
                donorIndex = importDonors.length - 1;
              } else {
                ignoreDonorFields = true;
              }
            }
            if (importingDonations && donationIndex === -1) {
              importDonations.push({ donorId: foundDonors[0].id });
              donationIndex = importDonations.length - 1;
            }
          }
        }
      } else if (matchOnMemberNum) {
        // If we are matching to donors on the member / envelope #, validate things and
        // generate the row(s) we are saving the data to.
        if (!memberNumber) {
          errors.push(
            `There is no Member/Env. # on line ${rowNum + 1} so it cannot be matched to a donor.`
          );
          return;
        }

        if (importingDonors) {
          // did we already import this from a previous row?
          let importDonorIndex = importDonors.findIndex(
            (d) => d.memberNumbers?.[currentYear] === memberNumber
          );
          if (importDonorIndex >= 0) {
            if (addMatchedDonors) {
              errors.push(
                `The Member/Env. # ${memberNumber} on line ${rowNum + 1} has already been added by a previous line - it cannot be added twice.`
              );
              return;
            } else {
              /* If the matchAction is update, a previous row updated it so we can ignore this row; if it's ignore, we ignore! */
              ignoreDonorFields = true;
              donorIndex = importDonorIndex;
            }
            if (importingBoth && donationIndex === -1) {
              importDonations.push(
                importDonors[importDonorIndex].id
                  ? { donorId: importDonors[importDonorIndex].id }
                  : { importDonorIndex: importDonorIndex }
              );
              donationIndex = importDonations.length - 1;
            }
          }
        }
        if (!ignoreDonorFields) {
          /* Didn't already import this donor, find the matching existing donor */
          const foundDonor = donors.find((d) => {
            const nums = d.memberNumbers;
            return !nums ? false : nums[currentYear] === memberNumber;
          });
          if (!foundDonor) {
            if (importingOnlyDonations) {
              errors.push(
                `Cannot find a Donor with the Member/Env. # "${memberNumber}", which is on Line ${rowNum + 1} of the input file.`
              );
              return;
            } else if (donorIndex === -1) {
              importDonors.push({ memberNumbers: { [currentYear]: memberNumber } });
              donorIndex = importDonors.length - 1;
            }
            if (importingBoth && donationIndex === -1) {
              importDonations.push({ importDonorIndex: donorIndex });
              donationIndex = importDonations.length - 1;
            }
          } else {
            // found a donor
            if (importingDonors) {
              if (addMatchedDonors) {
                errors.push(
                  `The Member/Env. # "${memberNumber}" on line ${rowNum + 1} already exists on your Donor List, and you have selected to Add all rows as new donors. Member/Env. #s must be unique.`
                );
                return;
              } else if (updateMatchedDonors) {
                importDonors.push({
                  id: foundDonor.id,
                  tagIds: foundDonor.tagIds,
                  memberNumbers: { ...foundDonor.memberNumbers, [currentYear]: memberNumber },
                  customFields: foundDonor.customFields,
                });
                donorIndex = importDonors.length - 1;
              } else {
                ignoreDonorFields = true;
              }
            }
            if (importingDonations && donationIndex === -1) {
              importDonations.push({ donorId: foundDonor.id });
              donationIndex = importDonations.length - 1;
            }
          }
        }
      } else {
        // no match field, can only happen when importing only donors and adding all
        if (cols['memberNumber'] >= 0) {
          // Can't duplicate a member number!
          let foundDonor = donors.find((d) => {
            const nums = d.memberNumbers;
            return !nums ? false : nums[currentYear] === memberNumber;
          });
          if (foundDonor) {
            errors.push(
              `The Member/Env. # "${memberNumber}" on line ${rowNum + 1} already exists on your Donor List, and you have selected to Add all rows as new donors. Member/Env. #s must be unique.`
            );
            return;
          }
          if (addMatchedDonors) {
            if (importDonors.find((d) => d.memberNumbers?.[currentYear] === memberNumber)) {
              errors.push(
                `The Member/Env. # ${memberNumber} on line ${rowNum + 1} has already been added by a previous line - it cannot be added twice.`
              );
              return;
            }
          }
          importDonors.push({ memberNumbers: { [currentYear]: memberNumber } });
          donorIndex = importDonors.length - 1;
        }
      }
      // make sure we have a donor record and/or donation record that we are working on
      if ((donorIndex === -1 && importingDonors) || (donationIndex === -1 && importingDonations)) {
        if (importingOnlyDonors && ignoreMatchedDonors) return; // OK!
        if (importingDonors) {
          importDonors.push({}); // email or member # will be filled in later!
          donorIndex = importDonors.length - 1;
          if (importingBoth && donationIndex === -1) {
            importDonations.push({ importDonorIndex: donorIndex });
            donationIndex = importDonations.length - 1;
          }
        } else {
          // Not positive we can get here but protect it anyways
          errors.push(
            `The donation on line ${rowNum + 1} has no way to be associated with a donor.`
          );
          return;
        }
      }
      if (donationIndex === -1 && importingDonations) {
        errors.push(
          `Internal error: no unique ID field was found to identify the donation on line ${rowNum + 1} as being for a specific donor.`
        );
        return;
      }

      // variables for the current donor and donation we are filling with
      // field data
      const donor = importingDonors ? importDonors[donorIndex] : {};

      const donation = importingDonations ? importDonations[donationIndex] : {};

      // OK, work through the rest of the columns!
      forEach(mapFields, (mapField, colNum) => {
        const value = dataRow[colNum];
        if (!value) return;
        // first the common fields for unique ids
        if (mapField.importType === ImportType.both) {
          switch (mapField.fieldName) {
            case 'email':
              // email may have already been stored, store it if not
              if (!ignoreDonorFields && importingDonors && !donor.email) {
                if (donor.id && matchOnMemberNum) {
                  const id = donors.find((d) => d.email === value)?.id;
                  if (id && id !== donor.id) {
                    warnings.push(
                      `The Email address on line ${rowNum + 1} matches a different existing donor on your Donor List from the one matched by the Member/Env. #, which is the field on which you are matching to donors, so that could be an error.`
                    );
                    break;
                  }
                }
                donor.email = value;
                validateLength(email, rowNum, 'Email Address', 60, warnings);
              }
              break;
            case 'memberNumber':
              // memberNumber may have already been stored, store it if not
              if (!ignoreDonorFields && importingDonors && !donor.memberNumbers?.[currentYear]) {
                if (donor.id && matchOnEmail) {
                  const id = donors.find((d) => {
                    const nums = d.memberNumbers;
                    return !nums ? false : nums[currentYear] === memberNumber;
                  })?.id;
                  if (id && id !== donor.id) {
                    errors.push(
                      `The Member/Env. # ${memberNumber} on line ${rowNum + 1} is the same as that for a different donor from the one matched by the Email on line ${rowNum + 1}, and thus would create a duplicate Member/Env. #.`
                    );
                    break;
                  }
                }
                const i = importDonors.findIndex(
                  (d) => d.memberNumbers?.[currentYear] === memberNumber
                );
                if (i >= 0 && (!donor.id || donor.id !== importDonors[i].id)) {
                  errors.push(
                    `The Member/Env. # ${memberNumber} on line ${rowNum + 1} is the same as the one for a different donor being imported on an earlier line, and thus would create a duplicate Member/Env. #.`
                  );
                  break;
                }
                donor.memberNumbers = { [currentYear]: memberNumber };
              }
              break;
          }
        }
        // Now handle the donor-ony fields (if we aren't ignoring this donor)
        if (!ignoreDonorFields && mapField.importType === ImportType.onlyDonors) {
          let first = '',
            last = '',
            city = '',
            state = '',
            country = '';
          switch (mapField.fieldName) {
            case 'firstName':
              donor.firstName = value;
              validateLength(value, rowNum, 'First Name', 40, warnings);
              break;
            case 'middleName':
              donor.middleName = value;
              validateLength(value, rowNum, 'Middle Name', 40, warnings);
              break;
            case 'lastName':
              donor.lastName = value;
              validateLength(value, rowNum, 'Last Name', 40, warnings);
              break;
            case 'firstLast':
              [first, last] = splitFirstLast(value);
              if (!first) {
                errors.push(
                  `The combined "Name: First Last" field on line ${rowNum + 1} contained at most one word, so it could not be split into First and Last Name fields.`
                );
                firstLastError = true;
                break;
              }
              donor.firstName = first;
              donor.lastName = last;
              validateLength(first, rowNum, 'First Name', 40, warnings);
              validateLength(last, rowNum, 'Last Name', 40, warnings);
              break;
            case 'lastFirst':
              [first, last] = splitLastFirst(value);
              if (!first) {
                errors.push(
                  `The combined "Name: Last, First" field on line ${rowNum + 1} either did not contain a comma, or it did not contain names both before and after the comma, so it could not be split into First and Last Name fields.`
                );
                firstLastError = true;
                break;
              }
              donor.firstName = first;
              donor.lastName = last;
              validateLength(first, rowNum, 'First Name', 40, warnings);
              validateLength(last, rowNum, 'Last Name', 40, warnings);
              break;
            case 'organization':
              donor.organization = value;
              validateLength(value, rowNum, 'Business Name', 60, warnings);
              break;
            case 'phone':
              donor.phone = value;
              validateLength(value, rowNum, 'Phone Number', 20, warnings);
              break;
            case 'address1':
              donor.address1 = value;
              validateLength(value, rowNum, 'Address 1', 40, warnings);
              break;
            case 'address2':
              donor.address2 = value;
              validateLength(value, rowNum, 'Address 2', 40, warnings);
              break;
            case 'city':
              donor.city = value;
              validateLength(value, rowNum, 'City', 40, warnings);
              break;
            case 'state':
              donor.state = fixState(value);
              validateLength(value, rowNum, 'State/Province', 40, warnings);
              break;
            case 'cityState':
              [city, state] = splitCityState(value);
              if (city) {
                donor.city = city;
                validateLength(city, rowNum, 'City', 40, warnings);
              }
              if (state) {
                donor.state = state;
                validateLength(state, rowNum, 'State/Province', 40, warnings);
              }
              break;
            case 'cityStateCountry':
              [city, state, country] = splitCityStateCountry(value);
              if (city) {
                donor.city = city;
                validateLength(city, rowNum, 'City', 40, warnings);
              }
              if (state) {
                donor.state = state;
                validateLength(state, rowNum, 'State/Province', 40, warnings);
              }
              if (country) donor.country = country;
              break;
            case 'postalCode':
              donor.postalCode = value;
              validateLength(state, rowNum, 'Postal Code', 12, warnings);
              break;
            case 'country':
              country = fixCountry(value);
              if (country) {
                donor.country = country;
              } else {
                warnings.push(
                  `The country value ${country} on line ${rowNum + 1} cannot be found in our standard list of countries, so it will not be stored.`
                );
              }
              break;
            case 'tag':
              const tag = value;
              const tagMap = tagsMap.find((t) => t.lowerName === tag.toLowerCase());
              if (!tagMap) {
                errors.push(
                  `Internal error: the tag "${tag}" on line ${rowNum + 1} was not found in the previously-analyzed list of all tags.`
                );
                break;
              }
              if (!!tagMap.id) {
                addToDonorArray(donor, 'tagIds', tagMap.id, true);
              }
              addToDonorArray(donor, 'tagNames', tagMap.newName, false);
              break;
            case 'donorNotes':
              donor.notes = value;
              validateLength(value, rowNum, 'Notes', 500, warnings);
              break;
            case 'custom1':
            case 'custom2':
            case 'custom3':
            case 'custom4':
            case 'custom5':
            case 'custom6':
              if (donor.customFields === undefined) donor.customFields = [];
              const index =
                parseInt(mapField.fieldName.substring(mapField.fieldName.length - 1)) - 1;
              // see comment on addToDonorArray in splitting.ts for why the following
              // code is as it is!
              const arr = donor.customFields.slice(0);
              for (let i = 0; i < index; ++i) {
                // fill in gaps!
                if (arr[i] === undefined) arr[i] = '';
              }
              arr[index] = value;
              donor.customFields = arr;
              validateLength(value, rowNum, mapField.displayName, 50, warnings);
              break;
          }
        }
        // now work through the donation-only fields
        if (importingDonations && mapField.importType === ImportType.onlyDonations) {
          let amount = 0;
          switch (mapField.fieldName) {
            case 'date':
              const d = parse(value, dateFormat, new Date());
              if (isNaN(d.getTime())) {
                errors.push(
                  `Line ${rowNum + 1} of the input contains the value "${value}" for the Date, which cannot be converted to a date.`
                );
                dateError = true;
                break;
              }
              const year = d.getFullYear();
              if (year > currentYear || year < currentYear - 10) {
                errors.push(
                  `Line ${rowNum + 1} of the input contains the Date "${value}", which is not between 10 years ago and the current year.`
                );
                dateError = true;
                break;
              }
              donation.date = fDateToISO(d);
              break;
            case 'amount':
              amount = validateInputAmount(value, rowNum, 'Amount', errors);
              if (amount === 0) {
                // there was an error added by validateInputAmount
                amountError = true;
                break;
              }
              donation.amount = amount;
              break;
            case 'category':
              const category = value;
              const categoryMap = categoriesMap.find((c) => c.lowerName === category.toLowerCase());
              if (!categoryMap) {
                errors.push(
                  `Internal error: the category "${category}" on line ${rowNum + 1} was not found in the previously-analyzed list of all categories.`
                );
                categoryError = true;
                break;
              }
              if (!!categoryMap.id) {
                donation.categoryId = categoryMap.id;
              }
              donation.categoryName = categoryMap.newName;
              break;
            case 'paymentMethod':
              const paymentMethod = value;
              const paymentMethodMap = paymentMethodsMap.find(
                (pm) => pm.lowerName === paymentMethod.toLowerCase()
              );
              if (!paymentMethodMap) {
                errors.push(
                  `Internal error: the payment method "${paymentMethod}" on line ${rowNum + 1} was not found in the previously-analyzed list of all payment methods.`
                );
                break;
              }
              if (!!paymentMethodMap.id) {
                donation.paymentMethodId = paymentMethodMap.id;
              }
              donation.paymentMethodName = paymentMethodMap.newName;
              break;
            case 'paymentInfo':
              donation.paymentInfo = value;
              validateLength(value, rowNum, 'Check Number', 60, warnings);
              break;
            case 'description':
              donation.description = value;
              validateLength(value, rowNum, 'Description', 60, warnings);
              break;
            case 'donationNotes':
              donation.notes = value;
              validateLength(value, rowNum, 'Donation Notes', 500, warnings);
              break;
            case 'amountEligible':
              amount = validateInputAmount(value, rowNum, 'Eligible Amount', errors);
              if (amount === 0) break; // there was an error added
              donation.amountEligible = amount;
              break;
            case 'amountAdvantage':
              amount = validateInputAmount(value, rowNum, 'Advantage Amount', errors);
              if (amount === 0) break; // there was an error added
              donation.amountAdvantage = amount;
              break;
            case 'advantageDescription':
              donation.advantageDescription = value;
              validateLength(value, rowNum, 'Advantage Description', 60, warnings);
              break;
          }
        }
      }); // end of loop through the fields

      // row-level validations and fixes
      if (importingDonors && !ignoreDonorFields) {
        donor.nonReceiptable =
          nrOption?.value === NROption.allDonors ||
          (nrOption?.value === NROption.businessDonors && !!donor.organization);
        if (!(donor.organization || (donor.firstName && donor.lastName))) {
          if (importingOrganization) {
            errors.push(
              `There is neither a Business Name nor a First and Last Name on line ${rowNum + 1} - a name must be present.`
            );
          } else if (!firstLastError) {
            errors.push(`The First and Last Name are not present on line ${rowNum + 1}.`);
          }
        }
        // get a missing country from the state/province, and note whether we
        // added any (if we weren't importing countries directly)
        if (!donor.country && !!donor.state) {
          donor.country = countryFromState(donor.state);
          if (!importingCountry && !!donor.country) addedCountry = true;
        }
      }
      if (importingDonations) {
        donation.nonReceiptable = nrOption?.value === NROption.allDonations;
        if (!donation.date && !dateError) {
          errors.push(
            `Line ${rowNum + 1} of the input is missing a value for the required field Date.`
          );
        }
        if (!donation.amount && !amountError) {
          errors.push(
            `Line ${rowNum + 1} of the input is missing a value for the required field Amount.`
          );
        }
        if (!donation.categoryId && !donation.categoryName && !categoryError) {
          errors.push(
            `Line ${rowNum + 1} of the input is missing a value for the required field Category.`
          );
        }
        if (donation.amount) {
          // We can only import one of the amountAdvantage and amountEligible - add the other
          if (donation.amountAdvantage) {
            donation.amountEligible = donation.amount - donation.amountAdvantage;
          } else if (donation.amountEligible) {
            donation.amountAdvantage = donation.amount - donation.amountEligible;
          }
          if (donation.amountEligible && donation.amount < donation.amountEligible) {
            errors.push(
              `Line ${rowNum + 1} of the input contains a total Amount value that is less then the Eligible Amount value.`
            );
          }
        }
        if (donation.amountEligible && !donation.advantageDescription) {
          errors.push(
            `Line ${rowNum + 1} of the input is for a donation with Advantage, but it has no Advantage Description.`
          );
        }
        // Check for donations that duplicate ones already in the DB
        if (
          !!donation.donorId &&
          !!donation.categoryId &&
          !!donation.date &&
          donation.amount !== undefined &&
          !!donations.find(
            (d) =>
              d.donorId === donation.donorId &&
              d.amount === donation.amount &&
              fDateToISO(parseDateTimezone(d.date)) === (donation.date || '1901-01-01') &&
              d.categoryId === donation.categoryId
          )
        ) {
          dupRows.push(rowNum + 1); // will cause an error or warning later!
        }
      }
    }); // end of loop through the rows

    if (importDonors.length === 0 && importDonations.length === 0) {
      errors.push('There is no valid data in your file that needs to be imported.');
    }

    // Warning about adding enough donors to exceed your paid plan limit
    if (
      !isFreeTrial &&
      donors.length + importDonors.filter((i) => i.id === undefined).length >= planLimit
    ) {
      // important first warning
      boldWarnings.push(
        `This import will exceed the maximum number of donors (${planLimit}) on your current pricing plan. You can finish this import, but will need to upgrade your plan before any additional donors are added.`
      );
    }

    // Warnings about too-long category etc. names that will be added
    for (const c of categoriesMap) {
      if (!c.id && c.newName.length > 30)
        warnings.push(
          `The new Category "${c.newName}" is longer than is normally allowed. If it has to be edited in the future it will have a maximum length of 30 characters.`
        );
    }
    for (const t of tagsMap) {
      if (!t.id && t.newName.length > 30)
        warnings.push(
          `The new Tag "${t.newName}" is longer than is normally allowed. If it has to be edited in the future it will have a maximum length of 30 characters.`
        );
    }
    for (const pm of paymentMethodsMap) {
      if (!pm.id && pm.newName.length > 30)
        warnings.push(
          `The new Payment Method "${pm.newName}" is longer than is normally allowed. If it has to be edited in the future it will have a maximum length of 30 characters.`
        );
    }

    // set the statuses of the importDonors, for display in the table
    for (const donor of importDonors) {
      if (donor.id) {
        donor.status = 'Updated';
      } else {
        const name = donorFullName(donor);
        if (donors.find((d) => name === fFullName(d))) {
          // warn user that this is being added but might be a duplicate, based on the name
          donor.status = 'Duplicate?';
        } else {
          donor.status = 'New';
        }
      }
    }

    // get donorNames into the importDonations
    forEach(importDonations, (donation, index) => {
      let donorName = '';
      if (donation.donorId) donorName = fFullName(getDonorById(donation.donorId));
      else if (donation.importDonorIndex !== undefined) {
        donorName = donorFullName(importDonors[donation.importDonorIndex]);
      } else {
        // this probably was dealt with before so cannot happen
        errors.push(
          `Internal error: donation on line ${index + 1} cannot be associated with a donor.`
        );
      }
      donation.donorName = donorName;
    });

    // count duplicate donations and make a warning or error about them
    const maxDupDisplay = 25;
    const minDupForError = 5;
    const dupCount = dupRows.length;
    if (dupCount > 0) {
      // it's only an error if they are ALL duplicates, and there are at least 5
      const isError = dupCount >= minDupForError && dupCount === importDonations.length;
      const msg = `There ${dupCount === 1 ? 'is' : 'are'} ${dupCount} donation${dupCount === 1 ? '' : '(s)'} in your import file that ${dupCount === 1 ? 'has' : 'have'} the same donor, date, amount, and category as an existing entry. Perhaps you are importing the same file a 2nd time? Please review row${dupCount === 1 ? '' : '(s)'} ${fJoinWithConjunction(
        dupRows.slice(0, maxDupDisplay).map((r) => r.toString()),
        'and'
      )}${dupCount > maxDupDisplay ? ' etc.' : ''} of the input file${isError ? ' before proceeding' : ''}.`;
      if (isError) errors.push(msg);
      else boldWarnings.push(msg);
    }

    // set up the lists of columns for the tables
    const donorColumns: TColumn<'status' | 'nonReceiptable' | DonorFieldNames>[] = [];
    const donationColumns: TColumn<'donorName' | 'nonReceiptable' | DonationFieldNames>[] = [];

    if (errors.length === 0) {
      if (!!importDonors) {
        // Fill in the donorColumns array.
        // First create some columns that we might need to reference more than once.
        const firstNameCol: TColumn<'status' | DonorFieldNames> = {
          id: 'firstName',
          type: 'string',
          label: 'First Name',
          align: 'left',
          visible: true,
        };
        const lastNameCol: TColumn<'status' | DonorFieldNames> = {
          id: 'lastName',
          type: 'string',
          label: 'Last Name',
          align: 'left',
          visible: true,
        };
        const cityCol: TColumn<'status' | DonorFieldNames> = {
          id: 'city',
          type: 'string',
          label: 'City',
          align: 'left',
          visible: true,
        };
        const stateCol: TColumn<'status' | DonorFieldNames> = {
          id: 'state',
          type: 'string',
          label: 'State',
          align: 'left',
          visible: true,
        };
        const countryCol: TColumn<'status' | DonorFieldNames> = {
          id: 'country',
          type: 'string',
          label: 'Country',
          align: 'left',
          visible: true,
        };
        let doneTagColumn = false;
        // the Status column is always first
        donorColumns.push({
          id: 'status',
          type: 'string',
          label: 'Status',
          align: 'left',
          visible: true,
        });
        // Create columns based on the mapped fields in order, with some special cases
        for (const mf of mapFields) {
          if (!mf.fieldName || mf.importType === ImportType.onlyDonations) continue;
          switch (mf.fieldName) {
            // change the composite input fields to the fields they break out into,
            // for display
            case 'firstLast':
            case 'lastFirst':
              donorColumns.push(firstNameCol);
              donorColumns.push(lastNameCol);
              break;
            case 'cityState':
            case 'cityStateCountry':
              donorColumns.push(cityCol);
              donorColumns.push(stateCol);
              if (mf.fieldName === 'cityStateCountry' || addedCountry)
                donorColumns.push(countryCol);
              break;
            case 'state':
              donorColumns.push(stateCol);
              if (addedCountry) donorColumns.push(countryCol);
              break;
            default:
              // only show one tag column
              if (mf.fieldName === 'tag') {
                if (!doneTagColumn) doneTagColumn = true;
                else break;
              }
              donorColumns.push({
                id: mf.fieldName as DonorFieldNames,
                type: mf.type,
                label: mf.displayName,
                align: mf.align,
                visible: true,
              });
              break;
          }
        }
        if (nrOption?.value === NROption.allDonors || nrOption?.value === NROption.businessDonors) {
          donorColumns.push({
            id: 'nonReceiptable',
            type: 'boolean',
            label: 'Non-Receiptable',
            align: 'left',
            visible: true,
          });
        }
      }
      if (!!importDonations) {
        // Fill in the donationsColumns array, starting with the Name
        donationColumns.push({
          id: 'donorName',
          type: 'string',
          label: 'Name',
          align: 'left',
          visible: true,
        });
        // Now add all of the mapped donation columns in order
        for (const mf of mapFields) {
          if (!mf.fieldName || mf.importType !== ImportType.onlyDonations) continue;
          donationColumns.push({
            id: mf.fieldName as DonationFieldNames,
            type: mf.type,
            label: mf.displayName,
            align: mf.align,
            visible: true,
          });
        }
        if (nrOption?.value === NROption.allDonations) {
          donationColumns.push({
            id: 'nonReceiptable',
            type: 'boolean',
            label: 'Non-Receiptable',
            align: 'left',
            visible: true,
          });
        }
      }
    }
    return [
      importDonors,
      importDonations,
      errors,
      warnings,
      boldWarnings,
      donorColumns,
      donationColumns,
    ];
  }, [
    categories.length,
    categoriesMap,
    data,
    donations,
    donors,
    fDateToISO,
    fFullName,
    fJoinWithConjunction,
    getDonorById,
    hasHeaders,
    importType,
    isFreeTrial,
    mapFields,
    matchAction?.value,
    matchField?.value,
    nrOption?.value,
    paymentMethods.length,
    paymentMethodsMap,
    planLimit,
    tags.length,
    tagsMap,
    dateFormat,
  ]);

  useEffect(() => {
    if (errors.length) {
      setError('There are data errors preventing successful importing.');
    } else {
      updateStepData({ donors: importDonors, donations: importDonations });
    }
  }, [errors.length, importDonations, importDonors, setError, updateStepData]);

  // Finally the display! Show errors and warnings, and if there are no errors,
  // the table of donors and/or the table of donations.
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1" sx={{ pb: 1 }}>
        {errors.length > 0
          ? 'Review the displayed errors and any warnings, then correct them and try again'
          : 'Review the information below before completing your Import'}
      </Typography>
      {errors.length > 0 && (
        <Card sx={{ p: 2 }}>
          <Typography variant="h4">
            Errors{' '}
            <span style={{ fontSize: 'smaller', fontWeight: 'lighter' }}>
              (preventing importing):
            </span>
          </Typography>
          <BulletList items={errors} />
        </Card>
      )}
      {(warnings.length > 0 || boldWarnings.length > 0) && (
        <Card sx={{ p: 2 }}>
          <Typography variant="h4">
            Warnings{' '}
            <span style={{ fontSize: 'smaller', fontWeight: 'lighter' }}>
              (not preventing importing):
            </span>
          </Typography>
          <BulletList boldItems={boldWarnings} items={warnings} />
        </Card>
      )}
      {errors.length === 0 && importDonors.length > 0 && (
        <Card sx={{ p: 2 }}>
          <Typography variant="h4">Donors to be Imported</Typography>
          <DonorsTable importDonors={importDonors} tableCols={donorColumns} />
        </Card>
      )}
      {errors.length === 0 && importDonations.length > 0 && (
        <>
          <Card sx={{ p: 2 }}>
            <Typography variant="h4">Donations to be Imported</Typography>
            <DonationsTable importDonations={importDonations} tableCols={donationColumns} />
          </Card>
        </>
      )}
    </Stack>
  );
}
