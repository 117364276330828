import { styled } from '@mui/material/styles';

import cssStyles from '@utils/cssStyles';
import { HEADER, NAVBAR } from '../../../../config';

// ----------------------------------------------------------------------
type BannerStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  isFullWidth: boolean;
  error?: boolean;
};

export const BannerStyle = styled('div')<BannerStyleProps>(
  ({ theme, isCollapse, isOffset, isFullWidth, error }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    position: 'fixed',
    top: HEADER.MOBILE_HEIGHT - 10,
    right: 0,
    // height: HEADER.MOBILE_HEIGHT,
    width: '100%',
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['top', 'width', 'height'], {
      duration: theme.transitions.duration.shorter,
    }),
    background: error ? theme.palette.error.main : theme.palette.warning.main,

    [theme.breakpoints.up('lg')]: {
      top: HEADER.DASHBOARD_DESKTOP_HEIGHT - 24,
      width: isFullWidth ? `100%` : `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
      ...(isCollapse && {
        width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
      }),
      ...(isOffset && {
        top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT - 8,
      }),
    },
  })
);
