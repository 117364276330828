import { HTMLAttributes } from 'react';
import { AutocompleteGetTagProps, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { Tag } from '@shared/types';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
export default function renderTags(options: Tag.Tag[], getTagProps: AutocompleteGetTagProps) {
  return options.map((option, index) => (
    <Tooltip key={option.id} title={option.description} placement="top" enterDelay={600}>
      <Chip
        {...getTagProps({ index })}
        key={option.id}
        size="medium"
        label={option.name}
        sx={{ background: option.colorBackground, color: option.colorText }}
      />
    </Tooltip>
  ));
}

// ----------------------------------------------------------------------
export const renderTagOption = (props: HTMLAttributes<HTMLLIElement>, option: Tag.Tag) =>
  option.id === 'create-new' ? (
    <li {...props} key="create-new">
      <Stack
        direction="row"
        spacing={0.5}
        sx={{ fontWeight: 700, color: (theme) => theme.palette.primary.dark }}
      >
        <Iconify icon="icons8:plus" width={20} height={20} />
        <Typography variant="body2">Create New Tag</Typography>
      </Stack>
    </li>
  ) : (
    <li {...props} key={option.id}>
      <Chip
        key={option.id}
        size="small"
        label={option.name}
        sx={{ background: option.colorBackground, color: option.colorText }}
      />
      <Typography variant="caption" sx={{ marginLeft: 1 }}>
        {option.description}
      </Typography>
    </li>
  );

// ----------------------------------------------------------------------
export const TagItem = ({ tag, text }: { tag: Tag.Tag; text?: string }) => (
  <Tooltip title={tag.description} placement="top" enterDelay={600}>
    <Chip
      key={tag.id}
      size="medium"
      label={
        text ? (
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ) : (
          tag.name
        )
      }
      sx={{
        background: tag.colorBackground,
        color: tag.colorText,
        maxWidth: 180,
        m: 0.25,
      }}
    />
  </Tooltip>
);
