import { Stack, Typography } from '@mui/material';

import { TStepProps } from './config';
import StepActions from './StepActions';
import useFormat from '@hooks/useFormat';

// ----------------------------------------------------------------------
export default function StepOverview({ data, isSubmitting, onBack, onNext, errors }: TStepProps) {
  const { fAddress } = useFormat();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Stack>
          <Typography>
            Organization name: <strong>{data.name}</strong>
          </Typography>
          <Typography>
            Signatory name: <strong>{data.signatoryName}</strong>{' '}
            {data.signatoryPosition && `(${data.signatoryPosition})`}
          </Typography>
          <Typography>
            Address:{' '}
            <strong>
              {fAddress(
                {
                  ...data.address,
                  state: data.address.state.value,
                  country: data.address.country.value,
                },
                true
              )}
            </strong>
          </Typography>
        </Stack>

        <Stack>
          <Typography>
            Currency: <strong>{data.currency?.label}</strong>
          </Typography>

          <Typography>
            Date format (example): <strong>{data.dateFormat?.label}</strong>
          </Typography>
          <Typography>
            Time format (example): <strong>{data.timeFormat?.label}</strong>
          </Typography>
        </Stack>

        {data.address.country.value === 'us' && (
          <Typography>
            Religious Benefit: <strong>{data.religiousBenefit ? 'Yes' : 'No'}</strong>
          </Typography>
        )}
      </Stack>

      <StepActions isLast isSubmitting={isSubmitting} handleBack={onBack} errors={errors} />
    </form>
  );
}
