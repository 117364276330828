import { useMemo } from 'react';
import { orderBy as _orderBy } from 'lodash';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { RecurringDonationListHeader, RecurringDonationListItem } from './config';
import { getLabelFrequency } from '@utils/recurringDonation';
import { parseDateTimezone } from '@redux/slices/donation';
import { RecurringDonation } from '@shared/types';

// ----------------------------------------------------------------------
type Props = {
  readonly order: 'asc' | 'desc';
  readonly orderBy: RecurringDonationListHeader;
};
// ----------------------------------------------------------------------
export default function useData({ order, orderBy }: Props) {
  const { recurringDonations, getDonorById } = useDonation();
  const { fReversedName, fDate, fCurrency } = useFormat();
  const currentYear = new Date().getFullYear();

  // ----- PARSING -------
  const parsedData = useMemo(
    () =>
      recurringDonations.map(
        (recurringDonation: RecurringDonation.RecurringDonation): RecurringDonationListItem => {
          const donor = getDonorById(recurringDonation.donorId!);
          const recurringDonationExpired =
            recurringDonation.endDate &&
            parseDateTimezone(recurringDonation.endDate) <= parseDateTimezone(fDate(new Date()))
              ? true
              : false;

          return {
            id: recurringDonation.id!,
            name: fReversedName(donor, currentYear),
            frequency: `${recurringDonation.period} ${getLabelFrequency(recurringDonation.period!, parseDateTimezone(recurringDonation.startDate!))}`,
            amount: fCurrency(recurringDonation.amount!),
            finalOccurrence: recurringDonation.endDate
              ? fDate(recurringDonation.endDate)
              : 'No End Date',
            nextOccurrence: recurringDonationExpired
              ? 'n/a'
              : recurringDonation.nextOccurrence
                ? fDate(recurringDonation.nextOccurrence)
                : null,
            isExpired: recurringDonationExpired,
          };
        }
      ),
    [recurringDonations, getDonorById, currentYear, fReversedName, fDate, fCurrency]
  );

  // ----- ORDERING -------
  const orderedData = useMemo(
    () => _orderBy(parsedData, orderBy, order),
    [parsedData, orderBy, order]
  );

  return orderedData;
}
