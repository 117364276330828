import { Box, Tooltip, ListItemButtonProps, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import useLocales from '@hooks/useLocales';
import Iconify from '@components/Iconify';
import { getPath, isExternalLink } from '@routes/paths';
import { NavListProps } from './NavList';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './styles';

// ----------------------------------------------------------------------
export type NavItemProps = {
  item: NavListProps;
  depth: number;
  open: boolean;
  active: boolean;
  isCollapse?: boolean;
  hasChildren: boolean;
  orgId: string;
  path: string;
};
type Props = NavItemProps & ListItemButtonProps;

// ----------------------------------------------------------------------
export default function NavItem({
  item,
  depth,
  active,
  open,
  isCollapse,
  hasChildren,
  orgId,
  path,
  ...other
}: Props) {
  const { t } = useLocales();
  const { title, icon, info, children, disabled, caption } = item;

  const link = (
    <ListItemStyle
      depth={depth}
      active={active}
      disabled={disabled}
      {...other}
      onClick={hasChildren ? other.onClick : undefined}
    >
      {icon && <ListItemIconStyle active={active}>{icon}</ListItemIconStyle>}
      {depth !== 1 && <DotIcon active={active && depth !== 1} />}

      <ListItemTextStyle
        isCollapse={isCollapse}
        primary={t(title)}
        secondary={
          caption && (
            <Tooltip title={t(caption)} placement="top-start">
              <span>{t(caption)}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          variant: active ? 'subtitle2' : 'body2',
          color: (theme) => (active ? theme.palette.primary.main : theme.palette.grey[600]),
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {/* {!isCollapse && ( */}
      <>
        {info && (
          <Box
            component="span"
            sx={{ lineHeight: 0, marginRight: 2, marginTop: isCollapse ? -2 : 0 }}
          >
            {info}
          </Box>
        )}

        {!!children && (
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1, flexShrink: 0 }}
          />
        )}
      </>
      {/* )} */}
    </ListItemStyle>
  );

  return isExternalLink(path) ? (
    <Link href={path} target="_blank" rel="noopener" underline="none">
      {link}
    </Link>
  ) : hasChildren ? (
    link
  ) : (
    <Link to={getPath(path, { orgId })} component={RouterLink} underline="none">
      {link}
    </Link>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle active={active}>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}
