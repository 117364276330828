// Render the default payment method drop-down

import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { PaymentMethod } from '@shared/types';
import { sortBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';

const noDefaultPaymentMethod = {
  id: '',
  name: '(No Default)',
  type: 'custom',
} as PaymentMethod.PaymentMethod;

export default function DefaultPaymentMethod() {
  const { org, updateOrg } = useOrg();
  const { paymentMethods, getPaymentMethodById } = useDonation();

  const defaultPaymentMethod = useMemo(() => {
    const id = org?.defaultPaymentMethodId || '';
    return id ? getPaymentMethodById(id) : noDefaultPaymentMethod;
  }, [getPaymentMethodById, org?.defaultPaymentMethodId]);

  const [loading, setLoading] = useState(false);

  const handleChangeDefault = async (
    e: React.SyntheticEvent,
    value: PaymentMethod.PaymentMethod
  ) => {
    if (!org || !value) return;
    setLoading(true);
    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          defaultPaymentMethodId: value.id,
        },
      });
      setTimeout(() => setLoading(false), 1000);
      // once this is saved, the useMemo above will reset defaultPaymentMethod
      if (!res) enqueueSnackbar('Failed to save default payment method', { variant: 'error' });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Failed to save default payment method', { variant: 'error' });
    }
  };

  return (
    <>
      <Typography
        hidden={!loading}
        sx={{ border: 1, p: 1, width: '100%', maxWidth: '300px', fontWeight: 'bold' }}
      >
        Saving new default...
      </Typography>
      <Autocomplete
        autoComplete
        hidden={loading}
        fullWidth={false}
        size="small"
        sx={{ pb: 1, width: '100%', maxWidth: '300px' }}
        disableClearable={true}
        value={defaultPaymentMethod}
        onChange={handleChangeDefault}
        options={[noDefaultPaymentMethod, ...sortBy(paymentMethods, (c) => c.name.toUpperCase())]}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField label="Default Payment Method" {...params} />}
      />
    </>
  );
}
