import { PDFViewer } from '@react-pdf/renderer';

import { PageSize } from '@typedefs/app';
import { Country, Donor } from '@shared/types';
import { TDonorWithDonations } from '@typedefs/donation';

import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import PdfDocument from '@pages/receipts/templates/PdfDocument';
import Dialog from '@components/Dialog';
import useFormat from '@hooks/useFormat';

// ----------------------------------------------------------------------
type Props = { onClose: VoidFunction };
// ----------------------------------------------------------------------
export default function ReceiptPreview({ onClose }: Props) {
  const { fDateToISO } = useFormat();
  const { getReceiptVariables } = useDonation();
  const { org } = useOrg();

  if (!org) return null;

  const mockDonor: TDonorWithDonations = {
    id: 'mock-donor',
    _meta: { createdAt: '', createdBy: '', updatedAt: '', updatedBy: '', archived: false },
    orgId: org.id,
    firstName: 'John',
    middleName: 'T',
    lastName: 'Smith',
    address: {
      address1: 'Street 1',
      city: 'City',
      country: Country.us,
      postalCode: '101',
      state: 'Region',
    },
    type: Donor.DonorType.individual,
    tagIds: [],
    notes: '',
    memberNumbers: {},
    donations: [
      {
        id: 'mock',
        _meta: { createdAt: '', createdBy: '', updatedAt: '', updatedBy: '', archived: false },
        amount: 100,
        categoryId: '',
        date: new Date().toJSON(),
        donorId: 'mock-donor',
        corrections: [],
        notes: '',
        orgId: org.id,
        paymentInfo: '',
        receiptIds: [],
        withAdvantage: false,
      },
    ],
    donationsTotal: 100,
  };

  // --------------------------------------------------
  const donorGroups = {
    donorId: 'mock-donor',
    vars: getReceiptVariables(org, mockDonor, mockDonor.donations),
    groups: [
      {
        date: fDateToISO(),
        year: new Date().getFullYear(),
        number: 1,
        donations: mockDonor.donations,
      },
    ],
  };

  // --------------------------------------------------
  const PdfDoc = (
    <PdfDocument
      title="Receipt letter"
      description="Receipt letter"
      size={PageSize.letter}
      donorGroups={[donorGroups]}
      withLetter={false}
      preview
      options={{
        showDescription: true,
        includeDetails: true,
        includeSummary: true,
      }}
      year={donorGroups.groups[0].year}
    />
  );

  return (
    <Dialog
      title="Receipt preview"
      onClose={onClose}
      maxWidth="md"
      contentProps={{ sx: { height: '90vh' } }}
    >
      <PDFViewer showToolbar={false} width="100%" height="99%">
        {PdfDoc}
      </PDFViewer>
    </Dialog>
  );
}
