import { useTranslation } from 'react-i18next';
// import localStorageAvailable from '@utils/localStorageAvailable';
import { allLangs, defaultLang } from '../locales/config-lang';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation();
  // const storageAvailable = localStorageAvailable();

  const currentLang = allLangs.find((_lang) => _lang.value === i18n.language) || defaultLang;

  const changeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    changeLanguage,
    t,
    currentLang,
    allLangs,
  };
}
