import { useMemo, useState } from 'react';
import { Card, Stack, InputAdornment, Box, Typography, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { isArray, sortBy } from 'lodash';

import { Donation, Donor } from '@shared/types';
import useDonation from '@hooks/useDonation';
import useLocales from '@hooks/useLocales';
import { RHFTextField, RHFDatePicker, RHFAutocomplete, RHFCheckbox } from '@components/hook-form';
import useFormat from '@hooks/useFormat';
import Iconify from '@components/Iconify';
import DonorDialog from '@pages/donors/dialogs/Donor';
import { renderPaymentMethodOption } from '@pages/donations/dialogs/Settings/PaymentMethods/utils/renderPaymentMethods';
import PaywallDonorGuard from '@/guards/PaywallDonorGuard';
import { addYears, subYears } from 'date-fns';

// ----------------------------------------------------------------------
type Props = {
  readonly donorPredefined: boolean;
  readonly withAdvantage: boolean;
  readonly formDisabled: boolean;
  readonly isCA: boolean;
  readonly paymentMethodType?: string | null;
  readonly year: number;
};
// ----------------------------------------------------------------------
export default function DonationDialog({
  withAdvantage,
  donorPredefined,
  formDisabled,
  isCA,
  paymentMethodType,
  year,
}: Props) {
  const { t } = useLocales();
  const { fReversedName } = useFormat();
  const { donors, paymentMethods, getDupDonorTooltip } = useDonation();
  const { setValue, clearErrors } = useFormContext();
  const [createDonorOpen, setCreateDonorOpen] = useState(false);

  const handleDonorCreate = (newDonor?: Donor.Donor) => {
    if (newDonor) {
      setValue('donor', newDonor);
      clearErrors('donor');
    }
    setCreateDonorOpen(false);
  };

  const donorList = useMemo(
    () =>
      sortBy(
        donors.map((d) => ({ ...d, name: fReversedName(d, year) })),
        'name'
      ),
    [fReversedName, donors, year]
  );

  const renderPaymentInfo = () => {
    if (paymentMethodType === Donation.PaymentMethodInitialList.check) {
      return (
        <RHFTextField
          name="paymentInfo"
          label={`${t('Check')} Number`}
          disabled={formDisabled}
          inputProps={{ maxLength: 60 }}
        />
      );
    } else if (paymentMethodType === Donation.PaymentMethodInitialList.giftInKind) {
      return (
        <RHFTextField
          required={isCA}
          name="paymentInfo"
          label="GIK Description"
          disabled={formDisabled}
          inputProps={{ maxLength: 60 }}
        />
      );
    }

    return <Box width="100%" />;
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFDatePicker
            required
            autoFocus
            name="date"
            label="Date"
            disabled={formDisabled}
            minDate={subYears(new Date(), 10)}
            maxDate={addYears(new Date(), 1)}
          />

          {createDonorOpen && (
            <PaywallDonorGuard onLimitClose={handleDonorCreate}>
              <DonorDialog context="donations" onClose={handleDonorCreate} />
            </PaywallDonorGuard>
          )}

          <RHFAutocomplete
            name="donor"
            label="Donor"
            required
            fullWidth
            freeSolo={false}
            disabled={donorPredefined || formDisabled}
            options={[...donorList, { id: 'create-new', name: '', memberNumbers: {} } as any]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(donor) => donor.name || fReversedName(donor, year)}
            renderOption={(props, option) =>
              option.id === 'create-new' ? (
                <li key="create-new" {...props}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{ fontWeight: 700, color: (theme) => theme.palette.primary.dark }}
                  >
                    <Iconify icon="icons8:plus" width={20} height={20} />
                    <Typography variant="body2">Create New Donor</Typography>
                  </Stack>
                </li>
              ) : (
                <li {...props} key={option.id}>
                  <Tooltip title={getDupDonorTooltip(option)}>
                    {option.name || fReversedName(option, year)}
                  </Tooltip>
                </li>
              )
            }
            beforeOnChange={(newValue, commitChange) => {
              if (newValue && !isArray(newValue) && newValue.id === 'create-new') {
                setCreateDonorOpen(true);
              } else {
                commitChange();
              }
            }}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFAutocomplete
            name="paymentMethod"
            label="Payment Method"
            fullWidth
            freeSolo={false}
            disabled={formDisabled}
            options={sortBy(paymentMethods, (p) => p.name.toUpperCase())}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={renderPaymentMethodOption}
          />

          {renderPaymentInfo()}
        </Stack>

        <Stack direction="row" spacing={2}>
          <RHFTextField
            required
            name="amount"
            type="number"
            label="Amount"
            disabled={formDisabled}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{ maxLength: 6 }}
          />
          <Box width="100%" />
        </Stack>

        <Stack direction="row" spacing={2}>
          {/* when flag disabled is added here, for some reason it dirties the form */}
          {/* and switches withAdvantage from false -> undefined */}
          {!formDisabled && (
            <RHFCheckbox
              name="withAdvantage"
              label="With Advantage"
              // disabled={formDisabled}
              sx={{ minWidth: 140, height: 56 }}
            />
          )}
          {/* checkbox dirties the form if its disabled... */}
          {formDisabled && (
            <Typography variant="body2" color="text.secondary" sx={{ textWrap: 'nowrap' }}>
              With Advantage: {withAdvantage ? 'Yes' : 'No'}
            </Typography>
          )}

          {withAdvantage ? (
            <RHFTextField
              required={isCA}
              autoFocus
              name="advantageDescription"
              label="Advantage Description"
              disabled={formDisabled}
              inputProps={{ maxLength: 60 }}
            />
          ) : (
            <Box width="100%" />
          )}
        </Stack>

        {withAdvantage && (
          <Stack direction="row" spacing={2}>
            <RHFTextField
              required={withAdvantage}
              fullWidth
              name="amountEligible"
              type="number"
              label="Eligible Amount"
              disabled={formDisabled}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputProps={{ maxLength: 6 }}
            />
            <RHFTextField
              required={withAdvantage}
              autoFocus
              disabled
              name="amountAdvantage"
              type="number"
              label="Advantage Amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputProps={{ maxLength: 6, inputMode: 'decimal' }}
            />
          </Stack>
        )}

        {isCA && paymentMethodType === Donation.PaymentMethodInitialList.giftInKind && (
          <Stack direction="row" spacing={2}>
            <RHFTextField
              name="appraiserName"
              label="Appraiser Name"
              disabled={formDisabled}
              inputProps={{ maxLength: 80 }}
            />
            <RHFTextField
              name="appraiserAddress"
              label="Appraiser Address"
              disabled={formDisabled}
              inputProps={{ maxLength: 80 }}
            />
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
