import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('New Password is required'),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), undefined],
    'Passwords must match'
  ),
});

export const changePasswordSchemaResolver = yupResolver(ChangePasswordSchema);
export type ChangePasswordSchemaForm = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  afterSubmit?: string;
};
