import { View, Text } from '@react-pdf/renderer';
import { TReceiptVariables } from '@typedefs/donation';
import { CountryList } from '@typedefs/org';

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
};
// ----------------------------------------------------------------------
export default function ReceiptDonorInfo({ vars }: Props) {
  const donorCountry = CountryList.find((c) => c.value === vars['donor.address']?.country)?.label;
  const isSameCountry = vars['org.address'].country === vars['donor.address']?.country;
  const line3 = (
    (vars['donor.address']?.city + ' ' + vars['donor.address']?.state).trim() +
    '  ' +
    vars['donor.address']?.postalCode
  ).trim();
  return (
    <View>
      <Text>{vars['donor.fullLegalName']}</Text>
      <Text>{vars['donor.address']?.address1}</Text>
      {vars['donor.address']?.address2 ? <Text>{vars['donor.address']?.address2}</Text> : null}
      {line3 ? <Text>{line3}</Text> : null}
      {!isSameCountry ? <Text>{donorCountry}</Text> : null}
    </View>
  );
}
