import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  footnote: {
    marginTop: 16,
    marginBottom: 16,
    width: '85%',
    display: 'flex',
    rowGap: 8,
    fontSize: 10,
  },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
};
// ----------------------------------------------------------------------
export default function ReceiptFootnote({ vars }: Props) {
  return (
    <View style={styles.footnote}>
      {vars['org.number'] ? <Text>Tax ID / EIN: {vars['org.number']}</Text> : null}
      <Text>
        Pursuant to Internal Revenue Code requirements for substantiation of charitable
        contributions, no goods or services{' '}
        {vars['org.religiousBenefit'] && 'other than intangible religious benefits '}were provided
        in return for the Tax Deductible contributions.
      </Text>
    </View>
  );
}
