import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const DonorCustomFieldsSchema = Yup.object().shape({
  donorCustomFields: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().trim().required('Custom Field name required'),
        visible: Yup.bool(),
      })
    )
    .default([]),
});

export const donorCustomFieldsSchemaResolver = yupResolver(DonorCustomFieldsSchema);
export type DonorCustomFieldsSchemaForm = {
  donorCustomFields: { name: string; visible: boolean }[];

  afterSubmit?: string;
};
