import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import icLogo from '@assets/static/ic_logo.png';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
export default function MigrationFullscreenLock() {
  const { org } = useOrg();

  if (!org) return null;

  const { migrationLock: { isLocked } = { isLocked: false } } = org;
  return (
    <Dialog
      open={isLocked}
      maxWidth="sm"
      sx={{
        backdropFilter: 'blur(8px)',
      }}
    >
      <DialogTitle
        sx={{
          pb: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 8,
            background: (theme) => theme.palette.primary.main,
          }}
        />
        <Box component="img" src={icLogo} width={32} height={32} mt={-0.5} mr={0.5} ml={-0.5} />
        Please wait
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 0 }}>
        <Stack spacing={3}>
          <Typography>
            Your app use is <strong>temporarily paused</strong> because a data migration from the
            Donation program was initiated.
          </Typography>
          <Divider />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
