import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, MenuItem, Stack } from '@mui/material';

import { Role } from '@shared/types/organization';
import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import PATHS, { getPath } from '@routes/paths';
import { donationsViewCacheId } from '@typedefs/donation';

import Iconify from '@components/Iconify';
import MenuPopover from '@components/MenuPopover';

// ----------------------------------------------------------------------
export default function DonationsActions() {
  const { org } = useOrg();
  const { hasAccess } = useRole();

  // ------- STATE -------
  const [openViewsMenu, setViewsMenu] = useState<HTMLElement | null>(null);
  const [openAddMenu, setAddMenu] = useState<HTMLElement | null>(null);

  // ------- ACTIONS -------
  const handleOpenViewsMenu = (e: React.MouseEvent<HTMLElement>) => setViewsMenu(e.currentTarget);
  const handleCloseViewsMenu = () => setViewsMenu(null);
  const handleOpenAddMenu = (e: React.MouseEvent<HTMLElement>) => setAddMenu(e.currentTarget);
  const handleCloseAddMenu = () => setAddMenu(null);

  const handleSelectMenu = () => {
    handleCloseViewsMenu();
    handleCloseAddMenu();
  };

  const handleResetDefaultView = () => {
    localStorage.removeItem(`${donationsViewCacheId}-state`);
    localStorage.removeItem(`${donationsViewCacheId}-tags`);
    localStorage.removeItem(`${donationsViewCacheId}-categories`);
    window.location.reload();
  };

  // ------- UI -------
  return (
    <Stack spacing={1} direction="row">
      {hasAccess([Role.editor, Role.contributor]) && (
        <>
          <Button
            variant="contained"
            onClick={handleOpenViewsMenu}
            startIcon={<Iconify icon="material-symbols:view-list" />}
            // endIcon={<Iconify icon={openViewsMenu ? 'mdi:chevron-up' : 'mdi:chevron-down'} />}
          >
            Views
          </Button>
          <MenuPopover
            open={Boolean(openViewsMenu)}
            anchorEl={openViewsMenu}
            onClose={handleCloseViewsMenu}
            onClick={handleSelectMenu}
            sx={{
              width: 155,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                '& svg': { mr: 1, width: 20, height: 20 },
              },
            }}
          >
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donations.export, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'uil:file-export'} />
              Export View
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donations.saveView, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'material-symbols:save-outline'} />
              Save View
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donations.manageViews, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'material-symbols:settings-outline'} />
              Manage Views
            </MenuItem>
            <MenuItem onClick={handleResetDefaultView}>
              <Iconify icon={'radix-icons:reset'} />
              Reset View
            </MenuItem>
          </MenuPopover>
        </>
      )}

      {hasAccess([Role.editor, Role.contributor]) && (
        <>
          <Button
            variant="contained"
            onClick={handleOpenAddMenu}
            startIcon={<Iconify icon="eva:plus-fill" />}
            // endIcon={<Iconify icon={openAddMenu ? 'mdi:chevron-up' : 'mdi:chevron-down'} />}
          >
            New Donation
          </Button>
          <MenuPopover
            open={Boolean(openAddMenu)}
            anchorEl={openAddMenu}
            onClose={handleCloseAddMenu}
            sx={{
              width: 180,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
                '& svg': { mr: 1, width: 20, height: 20 },
              },
            }}
          >
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donations.create, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'mdi:donation-outline'} />
              Single Donation
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donations.batch, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'mdi:donation'} />
              Batch of Donations
            </MenuItem>
            <MenuItem
              component={Link}
              to={getPath(PATHS.org.donations.recurring, { orgId: org!.id })}
              onClick={handleSelectMenu}
            >
              <Iconify icon={'mdi:recurring-payment'} />
              Recurring Donation
            </MenuItem>

            <MenuItem
              component={Link}
              to={getPath(PATHS.org.import.root, { orgId: org!.id, source: 'donations' })}
              onClick={handleSelectMenu}
            >
              <Iconify icon="flowbite:file-import-outline" />
              Import
            </MenuItem>
          </MenuPopover>
        </>
      )}
    </Stack>
  );
}
