import { useMemo, useState } from 'react';
import { Autocomplete, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { first, maxBy, sumBy } from 'lodash';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';

import BreadcrumbsHeader from '@components/BreadcrumbsHeader';
import Page from '@components/Page';
import NumberWidget from './NumberWidget';
import DonorsChart from './DonorsChart';
import DonationsChart from './DonationsChart';

// ----------------------------------------------------------------------
export default function Overview() {
  const { isDateBetween, fDateToYearEnd } = useFormat();
  const { donorsWithDonations, getYears } = useDonation();

  // period management
  const years = getYears();
  const [yearSelected, setYearSelected] = useState<string>(first(years) as string);
  const yearStartTime = new Date(Number(yearSelected), 0, 1).getTime() || 0;
  const yearEndTime = fDateToYearEnd(yearStartTime).getTime() || Infinity;

  // ------------------------------
  // base data for donations
  const yearlyDonors = useMemo(
    () =>
      donorsWithDonations
        .map((donor) => {
          const relevantDonations = donor.donations.filter((d) =>
            isDateBetween(new Date(d.date), yearStartTime, yearEndTime)
          );
          return {
            ...donor,
            donations: relevantDonations,
            donationsTotal: relevantDonations.reduce((sum, donation) => sum + donation.amount, 0),
            donationDate: maxBy(
              relevantDonations.map((d) => d.date),
              (date) => new Date(date).getTime()
            ),
          };
        })
        .filter((d) => d.donations.length),
    [isDateBetween, donorsWithDonations, yearStartTime, yearEndTime]
  );

  // widget total and average (mean) donations amounts
  const [totalDonations, avgDonationAmountPerDonor] = useMemo(() => {
    const total = sumBy(yearlyDonors, 'donationsTotal');
    return [total, total / yearlyDonors.length];
  }, [yearlyDonors]);

  // widget mean donation per donor
  // const avgDonationAmountPerDonor = useMemo(
  //   () => fShortenNumber(meanBy(yearlyDonors, 'donationsTotal')),
  //   [fShortenNumber, yearlyDonors]
  // );

  return (
    <Page title="Overview">
      <Container maxWidth={'xl'}>
        <BreadcrumbsHeader heading="Overview">
          {/* <Card sx={{ px: 2, py: 1 }}> */}
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography>For year: </Typography>
            <Autocomplete
              id="select-year"
              size="small"
              options={years}
              sx={{ minWidth: 100 }}
              autoComplete
              autoHighlight
              disableClearable
              freeSolo={false}
              value={yearSelected}
              onChange={(e, newValue) => setYearSelected(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
          {/* </Card> */}
        </BreadcrumbsHeader>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <NumberWidget
              title="Donors with Donations this Year"
              total={yearlyDonors.length}
              icon="bi:people"
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <NumberWidget
              title="Total Donations Amount"
              total={totalDonations}
              icon="akar-icons:money"
              currency
              // suffix={<Typography variant="h6">(avg. {avgDonationPerDonor} per donor)</Typography>}
            />
          </Grid>

          <Grid item xs={6} md={4}>
            <NumberWidget
              title="Avg. per Donor"
              total={avgDonationAmountPerDonor}
              icon="carbon:chart-average"
              currency
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DonorsChart currentYearDonors={yearlyDonors} startTime={yearStartTime} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DonationsChart
              currentYearDonations={yearlyDonors.flatMap((d) => d.donations)}
              startTime={yearStartTime}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
