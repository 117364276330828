import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import { User, Invitation } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Get
export const userGet = httpsCallable<User.GetReq, User.GetRes>(firebaseFunctions, 'user-get');
export const userGetById = httpsCallable<User.GetByIdReq, User.GetByIdRes>(
  firebaseFunctions,
  'user-getById'
);
export const invitationGetAllForUser = httpsCallable<
  Invitation.GetAllForUserReq,
  Invitation.GetAllForUserRes
>(firebaseFunctions, 'invitation-getAllForUser');

// ----------------------------------------------------------------------
// Create
export const userCreate = httpsCallable<User.CreateReq, User.CreateRes>(
  firebaseFunctions,
  'user-create'
);

// ----------------------------------------------------------------------
// Update
export const userUpdate = httpsCallable<User.UpdateReq, User.UpdateRes>(
  firebaseFunctions,
  'user-update'
);
export const invitationResolve = httpsCallable<Invitation.ResolveReq, Invitation.ResolveRes>(
  firebaseFunctions,
  'invitation-resolve'
);

// ----------------------------------------------------------------------
// ConfirmEmail
export const confirmEmail = httpsCallable<User.ConfirmEmailReq, User.ConfirmEmailRes>(
  firebaseFunctions,
  'user-confirmEmail'
);

// ----------------------------------------------------------------------
// TriggerEmailConfirmation
export const triggerEmailConfirmation = httpsCallable<
  User.TriggerEmailConfirmationReq,
  User.TriggerEmailConfirmationRes
>(firebaseFunctions, 'user-triggerEmailConfirmation');
