import { Stack, ListSubheader, TextField, Autocomplete, Typography } from '@mui/material';

import {
  DonationFields,
  DonorFields,
  ImportField,
  ImportType,
  SharedFields,
} from '@/schemas/importData';
import { useEffect, useMemo } from 'react';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';
import { TStepProps } from './useSteps';
import { getErrorMessage } from './MapFieldsValidation';

// ----------------------------------------------------------------------
export default function StepMapFields({ stepData, updateStepData, setError }: TStepProps) {
  const { org } = useOrg();
  const { fVisibleCustomFields } = useFormat();
  const { mapFields, importType, headers, data, hasHeaders } = stepData;
  let seenLastField = false;

  // Get the drop-down list of mappable donor and/or donation fields,
  // and set the initial mappings based on name matches
  const dataFields = useMemo(() => {
    let dataFields = [...SharedFields];
    if (!org?.memberNumbers) dataFields.pop();
    if (importType !== ImportType.onlyDonations) {
      dataFields.push(...DonorFields);
      // add any visible custom fields
      dataFields.push(
        ...fVisibleCustomFields().map(
          (f) =>
            ({
              id: `custom${f.index}`,
              importType: ImportType.onlyDonors,
              fieldName: `custom${f.index}`,
              displayName: f.name,
              type: 'string',
              align: 'left',
            }) as ImportField
        )
      );
    }
    if (importType !== ImportType.onlyDonors) {
      dataFields.push(...DonationFields);
      if (org?.address.country === 'ca') {
        const paymentInfo = dataFields.find((f) => f.fieldName === 'paymentInfo');
        if (paymentInfo) paymentInfo.displayName = 'Cheque Number';
      }
    }

    return dataFields;
  }, [org?.memberNumbers, org?.address.country, importType, fVisibleCustomFields]);

  // set the initial mappings based on the data fields
  useEffect(() => {
    if (mapFields.length < headers.length) {
      const fields = headers.map((h, i) =>
        mapFields.length > i
          ? mapFields[i]
          : dataFields.find(
              (f) =>
                f.fieldName.toUpperCase() === h.toUpperCase().trim() ||
                f.displayName.toUpperCase() === h.toUpperCase().trim()
            ) || SharedFields[0]
      );
      updateStepData({ mapFields: fields });
    }
  }, [dataFields, mapFields, headers, updateStepData]);

  const rows = useMemo(() => {
    const index = hasHeaders ? 1 : 0;
    return headers.map((h, i) => ({
      header: h,
      example: data[index][i],
    }));
  }, [data, hasHeaders, headers]);

  const updateMapField = (i: number, value: ImportField | null) => {
    if (!!value) {
      let newMapFields = [...mapFields];
      newMapFields[i] = value;
      updateStepData({ mapFields: newMapFields });
      // Check for errors, but only once we are displaying the last field!
      // Prevents flashing errors as the rows of the display are being processed.
      if (seenLastField) {
        setError(getErrorMessage(newMapFields, importType));
      }
    }
  };

  return (
    <Stack sx={{ py: 1, '& .MuiFormHelperText-root': { marginTop: '-2px' } }} spacing={2}>
      <Typography variant="subtitle1" sx={{ pb: 1 }}>
        For each "From File" field below, select the "In DONATION" destination it should map to
      </Typography>
      {rows.map((row, i) => {
        if (i === rows.length - 1) seenLastField = true;
        return (
          <Stack direction="row" spacing={1} key={i}>
            <TextField
              sx={{ width: 300, maxWidth: 300 }}
              value={row.header}
              disabled
              label="From File"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              sx={{
                width: 300,
                maxWidth: 300,
              }}
              value={row.example}
              disabled
              fullWidth
              label="First Row Example"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
            {importType === ImportType.both ? (
              <Autocomplete
                sx={{ width: 300, maxWidth: 300 }}
                value={mapFields[i] ?? SharedFields[0]}
                fullWidth
                size="small"
                freeSolo={false}
                options={dataFields}
                onChange={(e, value) => updateMapField(i, value)}
                groupBy={(option) => option.importType}
                getOptionLabel={(option) => option.displayName}
                isOptionEqualToValue={(option, value) => option.fieldName === value.fieldName}
                renderInput={(params) => (
                  <TextField {...params} label="In DONATION" variant="outlined" />
                )}
                renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                renderGroup={(params) => [
                  <ListSubheader
                    key={params.key}
                    component="div"
                    style={{ pointerEvents: 'none' }}
                    disableGutters={true}
                  >
                    {params.group.toUpperCase()}
                  </ListSubheader>,
                  params.children,
                ]}
              />
            ) : (
              <Autocomplete
                sx={{ width: 300, maxWidth: 300 }}
                value={mapFields[i] ?? SharedFields[0]}
                fullWidth
                size="small"
                freeSolo={false}
                options={dataFields}
                renderInput={(params) => (
                  <TextField {...params} label="In DONATION" variant="outlined" />
                )}
                onChange={(e, value) => updateMapField(i, value)}
                getOptionLabel={(option) => option.displayName}
                isOptionEqualToValue={(option, value) => option.fieldName === value.fieldName}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}
