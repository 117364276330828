import { Outlet, useLocation } from 'react-router';
import { Container, Tab, Box, Tabs } from '@mui/material';

import useNavigate from '@hooks/useNavigate';
import useAuth from '@hooks/useAuth';

import Page from '@components/Page';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  readonly embedded?: boolean;
};
const ACCOUNT_TABS = [
  {
    value: 'general',
    label: 'General',
    icon: 'ic:round-account-box',
  },
  {
    value: 'security',
    label: 'Login',
    icon: 'mdi:shield-lock',
  },
];

// ----------------------------------------------------------------------
export default function Account({ embedded }: Props) {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.match(/\/.*\/settings\/account\/?([a-z]*)\/?/)?.[1];

  const onChangeTab = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    if (newValue) {
      navigate(`/:orgId/settings/account/${newValue}`);
    }
  };

  if (!user) return null;

  return (
    <Page title="Account">
      <Container sx={{ p: embedded ? 4 : 0 }}>
        {path && (
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={path}
            onChange={onChangeTab}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
                icon={<Iconify icon={tab.icon} width={20} height={20} />}
              />
            ))}
          </Tabs>
        )}

        <Box sx={{ mb: 5 }} />
        <Outlet />
      </Container>
    </Page>
  );
}
