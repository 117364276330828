import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  Unsubscribe,
  signInWithPopup,
  GoogleAuthProvider,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  linkWithPopup,
  unlink as authUnlink,
  linkWithCredential,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

import firebaseApp from '@/firebase/app';
import {
  TAuthChangePasswordAction,
  TAuthCreatePasswordAction,
  TAuthLoginAction,
  TAuthRegisterAction,
  TAuthSendResetPwdAction,
  TAuthConfirmResetPwdAction,
} from '@typedefs/auth';
import db from '@fire/store';
import { User } from '@shared/types';
import { dispatch } from '@redux/store';
import { init, updateUser, updateUserRes } from '@redux/slices/auth';
import * as fn from '@fire/functions';
import * as analytics from '@fire/analytics';

// ----------------------------------------------------------------------
export const firebaseAuth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();

// flag that stops onAuthStateChanged to trigger init
// as soon as we create a user, onAuthStateChanged is triggered and will try to get the userDoc and claims
// this might be a problem because we might be in creation phase of the doc, or in change process for claims
// if flag is up, you have to manually call auth.init
let onStateChangeSkip = false;

// ----------------------------------------------------------------------
let userListener: Unsubscribe | null = null;
function onStateChange(): Unsubscribe {
  return onAuthStateChanged(firebaseAuth, async (userAuth) => {
    analytics.setUserId(userAuth?.uid);

    if (onStateChangeSkip) return;

    if (userAuth) {
      try {
        const [{ user }, { claims }] = await Promise.all([
          onUserChange(userAuth.uid),
          // if param "true", it forces JWT refresh - not sure if this is the best way to do it
          userAuth.getIdTokenResult(true),
        ]);

        if (!user) {
          console.error('Internal error: missing user document');
        }
        dispatch(init({ user, claims }));
        return;
      } catch (e) {
        console.error('Internal error: ', e);
      }
    }

    dispatch(init({}));
    userListener?.();
  });
}

// ----------------------------------------------------------------------
// this is done this way so we don't request 2x user document
// this way, listener on a firstRun returns user doc that is used to initialize the app
// and any update on the user doc will have a separate pipe where update happens
async function onUserChange(userId: string): Promise<{ user: User.User }> {
  let firstRun = true;
  userListener?.();

  return new Promise((resolve, reject) => {
    userListener = onSnapshot(
      doc(db, 'users', userId),
      async (doc) => {
        // NOTE: this must match how functions package user
        const user = { id: userId, ...doc.data() } as User.User;

        if (firstRun) {
          resolve({ user });
          firstRun = false;
        } else {
          dispatch(updateUserRes({ user }));
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// ----------------------------------------------------------------------
async function login({ email, password }: TAuthLoginAction): Promise<void> {
  // NOTE: this will trigger onStateChange that does the work
  try {
    await signInWithEmailAndPassword(firebaseAuth, email, password);
    analytics.user.login('password');
  } catch (e) {
    throw e;
  }
  // TODO: we should check if user exists here if we want to throw an error to login form!
  // const user = await User.get(userAuth.uid);
  // return { userAuth, user };
}

// ----------------------------------------------------------------------
// loginSocial is called both from login and register
// there is no distinguishing between login/create for social providers, therefore we need to do the work for it
async function loginSocial(): Promise<void> {
  onStateChangeSkip = true;

  try {
    const { user: userAuth } = await signInWithPopup(firebaseAuth, googleProvider);

    if (!userAuth.email) {
      // TODO: be better if this happens
      throw new Error("Missing user email, can't proceed.");
    }

    let {
      data: { user },
    } = await fn.userGet();

    // NOTE: if user is missing, perform reg on the fly, because signInWithPopup will create auth user already
    if (!user) {
      const data = {
        email: userAuth.email.toLowerCase(),
        displayName: userAuth.displayName || '',
        emailVerified: false,
        providers: ['google.com'] as User.AuthProvider[],
      };
      const {
        data: { user: createUser },
      } = await fn.userCreate(data);
      user = createUser;
      analytics.user.register('google.com');
    } else {
      analytics.user.login('google.com');
    }

    onStateChangeSkip = false;
    const { claims } = await userAuth.getIdTokenResult(true);
    await dispatch(init({ user, claims }));
  } catch (e) {
    onStateChangeSkip = false;
    throw e;
  }
}

// ----------------------------------------------------------------------
async function linkSocial(): Promise<void> {
  const { currentUser } = firebaseAuth;

  if (!currentUser) return;

  const { user } = await linkWithPopup(currentUser, googleProvider);
  analytics.user.link('google.com');
  const providers = user.providerData.map((provider) => provider.providerId) as User.AuthProvider[];
  await dispatch(updateUser({ providers }));
}

// ----------------------------------------------------------------------
async function unlink(providerId: User.AuthProvider): Promise<void> {
  const { currentUser } = firebaseAuth;

  if (!currentUser) return;

  await authUnlink(currentUser, providerId);
  analytics.user.unlink('google.com');
  const providers = currentUser.providerData
    .map((provider) => provider.providerId)
    .filter((id) => id !== providerId) as User.AuthProvider[];
  await dispatch(updateUser({ providers }));
}

// ----------------------------------------------------------------------
async function logout() {
  await signOut(firebaseAuth);
}

// ----------------------------------------------------------------------
async function register(action: TAuthRegisterAction): Promise<void> {
  // NOTE: must be turned off after
  onStateChangeSkip = true;

  try {
    // creates a user on firebase.auth, and will trigger onAuthStateChanged
    const { user: userAuth } = await createUserWithEmailAndPassword(
      firebaseAuth,
      action.email.toLowerCase(),
      action.password
    );
    const data = {
      email: action.email.toLowerCase(),
      displayName: action.displayName,
      emailVerified: false,
      providers: ['password'] as User.AuthProvider[],
    };
    // creates a user on firebase.firestore
    const {
      data: { user },
    } = await fn.userCreate(data);
    const { claims } = await userAuth.getIdTokenResult(true);
    analytics.user.register('password');

    // re-enable onStateChange and manually trigger app init
    onStateChangeSkip = false;
    dispatch(init({ user, claims }));
  } catch (e) {
    onStateChangeSkip = false;
    throw e;
  }
}

// ----------------------------------------------------------------------
async function changePassword(action: TAuthChangePasswordAction): Promise<void> {
  const user = firebaseAuth.currentUser;

  if (!user || !user.email) {
    throw new Error('Missing auth user');
  }

  const credential = EmailAuthProvider.credential(user.email, action.currentPassword);
  await reauthenticateWithCredential(user, credential);
  await updatePassword(user, action.newPassword);
}

// ----------------------------------------------------------------------
async function createPassword(action: TAuthCreatePasswordAction): Promise<void> {
  const { currentUser } = firebaseAuth;

  if (!currentUser || !currentUser.email) {
    throw new Error('Missing auth user');
  }

  const credential = EmailAuthProvider.credential(currentUser.email, action.newPassword);
  const { user } = await linkWithCredential(currentUser, credential);
  analytics.user.link('password');
  const providers = user.providerData.map((provider) => provider.providerId) as User.AuthProvider[];
  await dispatch(updateUser({ providers }));
}

// ----------------------------------------------------------------------
async function sendResetPassword(action: TAuthSendResetPwdAction): Promise<void> {
  const actionCodeSettings = {
    // url: `${import.meta.env.VITE_APP_BASE_URL}/new-password?email=${action.email}`,
    url: `${import.meta.env.VITE_APP_BASE_URL}/`,
  };
  await sendPasswordResetEmail(firebaseAuth, action.email, actionCodeSettings);
  analytics.user.resetPassword();
}

async function confirmResetPassword(action: TAuthConfirmResetPwdAction): Promise<void> {
  await confirmPasswordReset(firebaseAuth, action.code, action.newPassword);
  analytics.user.resetPassword(true);
}

// ----------------------------------------------------------------------
const auth = {
  onStateChange,
  login,
  loginSocial,
  logout,
  register,
  linkSocial,
  unlink,
  changePassword,
  createPassword,
  sendResetPassword,
  confirmResetPassword,
};
export default auth;
