import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormHelperText, Box, Card, Grid, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Outlet } from 'react-router';

import {
  organizationPreferencesSchemaResolver,
  OrganizationPreferencesSchemaForm,
} from '@/schemas';

import useOrg from '@hooks/useOrg';
import { CurrencyList, FormatDateList, FormatTimeList, LanguageList } from '@typedefs/org';

import { FormProvider, RHFAutocomplete } from '@components/hook-form';

// ----------------------------------------------------------------------
export default function Preferences() {
  const { org, updateOrg } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: OrganizationPreferencesSchemaForm = useMemo(
    () => ({
      currency: CurrencyList.find((item) => item.value === org?.currency) || null,
      language: LanguageList.find((item) => item.value === org?.language) || null,
      dateFormat: FormatDateList.find((item) => item.value === org?.dateFormat) || null,
      timeFormat: FormatTimeList.find((item) => item.value === org?.timeFormat) || null,
    }),
    [org?.currency, org?.dateFormat, org?.language, org?.timeFormat]
  );

  // --------------- form ---------------
  const methods = useForm<OrganizationPreferencesSchemaForm>({
    resolver: organizationPreferencesSchemaResolver,
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const onSubmit = async (data: OrganizationPreferencesSchemaForm) => {
    if (!org) return null;

    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          // currency: data.currency?.value,
          language: data.language?.value,
          dateFormat: data.dateFormat?.value,
          timeFormat: data.timeFormat?.value,
        },
      });

      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Card sx={{ p: 3 }}>
        <Outlet />

        <Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <Box width="100%">
                <RHFAutocomplete
                  disabled
                  name="currency"
                  label="Currency"
                  fullWidth
                  freeSolo={false}
                  options={CurrencyList}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
                {errors.currency && (
                  <FormHelperText error>{errors.currency?.message}</FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box width="100%">
                <RHFAutocomplete
                  name="language"
                  label="Language"
                  fullWidth
                  freeSolo={false}
                  options={LanguageList}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
                {errors.language && (
                  <FormHelperText error>{errors.language?.message}</FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box width="100%">
                <RHFAutocomplete
                  name="dateFormat"
                  label="Date format"
                  fullWidth
                  freeSolo={false}
                  options={FormatDateList}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
                {errors.dateFormat && (
                  <FormHelperText error>{errors.dateFormat?.message}</FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box width="100%">
                <RHFAutocomplete
                  name="timeFormat"
                  label="Time format"
                  fullWidth
                  freeSolo={false}
                  options={FormatTimeList}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
                {errors.timeFormat && (
                  <FormHelperText error>{errors.timeFormat?.message}</FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}
            >
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isDirty}
                sx={{ width: { xs: '50%', md: 'auto' } }}
              >
                Update
              </LoadingButton>
            </Grid>
          </Grid>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        </Stack>
      </Card>
    </FormProvider>
  );
}
