import { useEffect, useMemo } from 'react';
import { Box, Divider, Stack, Tooltip } from '@mui/material';
import { range } from 'lodash';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Limits } from '@shared/limits';
import { donorCustomFieldsSchemaResolver, DonorCustomFieldsSchemaForm } from '@/schemas';
import useOrg from '@hooks/useOrg';
import { FormProvider, RHFCheckbox, RHFTextField } from '@components/hook-form';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------
export default function DonorCustomFields() {
  const { org, updateOrg } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: DonorCustomFieldsSchemaForm = useMemo(
    () => ({
      donorCustomFields: (org?.donorCustomFields || []).concat(
        range(org?.donorCustomFields?.length || 0, Limits.donorCustomFields).map((n) => ({
          name: `Custom ${n + 1}`,
          visible: false,
        }))
      ),
    }),
    [org?.donorCustomFields]
  );

  // --------------- form ---------------
  const methods = useForm<DonorCustomFieldsSchemaForm>({
    resolver: donorCustomFieldsSchemaResolver,
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    watch,
  } = methods;

  const watchedCustomFields = watch('donorCustomFields');

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const onSubmit = async (data: DonorCustomFieldsSchemaForm) => {
    if (!org) return null;

    // check for duplicate names
    for (let i = 0; i < data.donorCustomFields.length; i++) {
      for (let j = i + 1; j < data.donorCustomFields.length; j++) {
        if (data.donorCustomFields[i].name === data.donorCustomFields[j].name) {
          enqueueSnackbar(`Custom Fields ${i + 1} and ${j + 1} have the same name`, {
            variant: 'error',
          });
          return null;
        }
      }
    }

    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          donorCustomFields: data.donorCustomFields,
        },
      });

      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Box sx={{ paddingTop: 3 }}>
        {range(Limits.donorCustomFields).map((n) => (
          <Stack
            key={n}
            display="inline-flex"
            direction="row"
            alignItems="center"
            pr={4}
            py={1}
            sx={{ width: { xs: '100%', xl: '50%' } }}
          >
            <Tooltip
              title={
                watchedCustomFields[n].visible
                  ? `Field ${n + 1} enabled. Uncheck to disable.`
                  : `Check to enable Field ${n + 1}`
              }
              placement="top-start"
            >
              <span>
                <RHFCheckbox
                  name={`donorCustomFields[${n}].visible`}
                  // label={`Field ${n + 1} Visible`}
                  label=""
                  sx={{ mr: 0 }}
                />
              </span>
            </Tooltip>
            <RHFTextField
              required
              name={`donorCustomFields[${n}].name`}
              label={`Field ${n + 1} Name`}
              inputProps={{ maxLength: 20 }}
            />
          </Stack>
        ))}

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

        <Stack alignItems="flex-end">
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty}
            sx={{ width: { xs: '50%', md: 'auto' } }}
          >
            Update
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
