import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
  Popover,
} from '@mui/material';
import { TColumn } from '@typedefs/app';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  columns: TColumn[];
  setColumns: (columns: TColumn<any>[]) => void;
};

// ----------------------------------------------------------------------
export default function TableColumns({ columns, setColumns }: Props) {
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);

  const handleColumnChange = (id: string) => {
    setColumns(columns.map((c) => (c.id === id ? { ...c, visible: !c.visible } : c)));
  };

  const handleShowAll = () => {
    setColumns(columns.map((c) => (!c.internal ? { ...c, visible: true } : c)));
  };

  const handleHideAll = () => {
    setColumns(columns.map((c) => (!c.internal ? { ...c, visible: false } : c)));
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleOpen}
        startIcon={<Iconify icon="heroicons:view-columns" />}
        // endIcon={<Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} />}
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontWeight: 400,
        }}
      >
        Columns
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ position: 'relative', minWidth: 200 }} p={2}>
          <Stack spacing={4}>
            <Stack spacing={4}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  {columns
                    .filter((c) => !c.internal)
                    .map((column) => (
                      <FormControlLabel
                        key={column.id}
                        control={
                          <Switch
                            checked={column.visible}
                            onChange={() => handleColumnChange(column.id)}
                            name={column.id}
                          />
                        }
                        label={column.label}
                      />
                    ))}
                </FormGroup>
              </FormControl>
            </Stack>

            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button size="medium" onClick={handleHideAll}>
                Hide all
              </Button>
              <Button size="medium" onClick={handleShowAll}>
                Show all
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
