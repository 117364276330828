import { useCallback } from 'react';
import { useDispatch, useSelector } from '@redux/store';
import type { Organization, Invitation, Feedback, User } from '@shared/types';
import {
  init,
  switchOrg,
  createOrg,
  updateOrg,
  getOrgUsers,
  updateOrgUsers,
  createInvitation,
  resolveInvitation,
  confirmEmail,
  submitFeedback,
  triggerEmailSignatureVerification,
  createGmailSenderSignature,
  deleteEmailSignature,
  checkEmailSignatureState,
  triggerEmailConfirmation,
  resetOrg,
  enableLock,
  disableLock,
  archiveOrg,
} from '@redux/slices/org';
import { TOrgGetUsersReq, TOrgInitReq, TOrgSwitchReq } from '@typedefs/org';

// ----------------------------------------------------------------------
const useOrg = () => {
  const dispatch = useDispatch();
  const { isInitialized, orgs, orgId, org, orgInitialized } = useSelector((s) => s.org);

  return {
    // global
    isInitialized,
    orgs,
    // getOrgs: useCallback(() => dispatch(getOrgs()), [dispatch]),
    createOrg: useCallback((p: Organization.CreateReq) => dispatch(createOrg(p)), [dispatch]),

    // org
    orgId,
    org,
    orgInitialized,
    init: useCallback((p: TOrgInitReq) => dispatch(init(p)), [dispatch]),
    switchOrg: useCallback((p: TOrgSwitchReq) => dispatch(switchOrg(p)), [dispatch]),
    resetOrg: useCallback(() => dispatch(resetOrg()), [dispatch]),

    updateOrg: useCallback((p: Organization.UpdateReq) => dispatch(updateOrg(p)), [dispatch]),
    getOrgUsers: useCallback((p: TOrgGetUsersReq) => dispatch(getOrgUsers(p)), [dispatch]),
    updateOrgUsers: useCallback(
      (p: Organization.UpdateOrgUsersReq) => dispatch(updateOrgUsers(p)),
      [dispatch]
    ),
    deleteEmailSignature: useCallback(
      (p: Organization.DeleteEmailSignatureReq) => dispatch(deleteEmailSignature(p)),
      [dispatch]
    ),
    checkEmailSignatureState: useCallback(
      (p: Organization.CheckEmailSignatureStateReq) => dispatch(checkEmailSignatureState(p)),
      [dispatch]
    ),
    triggerEmailSignatureVerification: useCallback(
      (p: Organization.TriggerEmailSignatureVerificationReq) =>
        dispatch(triggerEmailSignatureVerification(p)),
      [dispatch]
    ),
    createGmailSenderSignature: useCallback(
      (p: Organization.CreateGmailSenderSignatureAuthReq) =>
        dispatch(createGmailSenderSignature(p)),
      [dispatch]
    ),
    createInvitation: useCallback(
      (p: Invitation.CreateReq) => dispatch(createInvitation(p)),
      [dispatch]
    ),
    resolveInvitation: useCallback(
      (p: Invitation.ResolveReq) => dispatch(resolveInvitation(p)),
      [dispatch]
    ),
    confirmEmail: useCallback((p: User.ConfirmEmailReq) => dispatch(confirmEmail(p)), [dispatch]),
    triggerEmailConfirmation: useCallback(
      (p: User.TriggerEmailConfirmationReq) => dispatch(triggerEmailConfirmation(p)),
      [dispatch]
    ),

    // generic
    archiveOrg: useCallback((p: Organization.ArchiveReq) => dispatch(archiveOrg(p)), [dispatch]),
    submitFeedback: useCallback((p: Feedback) => dispatch(submitFeedback(p)), [dispatch]),

    // locking
    enableLock: useCallback((p: Organization.SetLockReq) => dispatch(enableLock(p)), [dispatch]),
    disableLock: useCallback((p: Organization.SetLockReq) => dispatch(disableLock(p)), [dispatch]),
  };
};

export default useOrg;
