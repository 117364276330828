import { useMemo } from 'react';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { orderBy as _orderBy } from 'lodash';

import useTable, { emptyRows } from '@hooks/useTable';
import useOrg from '@hooks/useOrg';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { Receipt } from '@shared/types';
import { TColumn } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';

import TablePaginationCustom from '@components/table/TablePaginationCustom';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Scrollbar from '@components/Scrollbar';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
const RECEIPTS_LIST_HEADERS: TColumn[] = [
  { id: 'number', type: 'string', label: 'Receipt #', align: 'left', visible: true },
  { id: 'date', type: 'date', label: 'Date issued', align: 'left', visible: true },
  { id: 'donorName', type: 'string', label: 'Donor', align: 'left', visible: true },
  { id: 'state', type: 'string', label: 'Current State', align: 'left', visible: true },
  { id: 'reissueState', type: 'string', label: 'Reissue State', align: 'left', visible: true },
  {
    id: 'issues',
    type: 'string',
    label: 'Incomplete',
    align: 'left',
    visible: true,
  },
];

type Props = {
  readonly receipts: Receipt.Receipt[];
  readonly receiptDonors: TDonorWithDonations[];
  readonly issues: { [donorId: string]: string[] };
  readonly reissue: boolean;
  readonly onClick: (receiptId: string) => void;
};

// ----------------------------------------------------------------------
export default function ReceiptList({ receipts, receiptDonors, issues, reissue, onClick }: Props) {
  const { org } = useOrg();
  const { fDate, fReceiptNumber, fFullName, fReceiptActionType, fReceiptState } = useFormat();
  const { getReceiptReissueGroup } = useDonation();
  const { columns, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage, onSort } =
    useTable({
      defaultRowsPerPage: 10,
      defaultColumns: RECEIPTS_LIST_HEADERS,
      defaultOrderBy: 'number',
      defaultOrder: 'desc',
    });

  const preData = useMemo(
    () =>
      receipts.map((receipt) => {
        const donor = receiptDonors.find((d) => d.id === receipt.donorId)!;
        const donorIssues = issues[receipt.donorId] || [];
        const receiptDonations = donor.donations.filter((d) => d.receiptIds.includes(receipt.id));
        const reissueGroup = getReceiptReissueGroup(org!, receipt, receiptDonations, reissue);

        return {
          id: receipt.id,
          number: fReceiptNumber(receipt.number, receipt.year),
          date: fDate(new Date(receipt.date)),
          donorName: fFullName(donor),
          state: fReceiptState(receipt),
          reissueState: fReceiptActionType(reissueGroup.groups[0].type),

          donorIssues,
        };
      }),
    [
      fReceiptNumber,
      fDate,
      fFullName,
      fReceiptState,
      fReceiptActionType,
      getReceiptReissueGroup,
      org,
      receipts,
      receiptDonors,
      issues,
      reissue,
    ]
  );
  const data = useMemo(() => _orderBy(preData, orderBy, order), [preData, orderBy, order]);

  return (
    <Card>
      <TableContainer sx={{ position: 'relative' }}>
        <Scrollbar>
          <Table stickyHeader size={'small'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              columns={columns}
              rowCount={data.length}
              onSort={onSort}
            />

            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({ id, number, date, donorName, state, reissueState, donorIssues }) => (
                  <ListRow
                    key={id}
                    id={id}
                    number={number}
                    date={date}
                    donorName={donorName}
                    state={state}
                    reissueState={reissueState}
                    donorIssues={donorIssues}
                    onClick={onClick}
                  />
                ))}

              <TableEmptyRows height={52} emptyRows={emptyRows(page, rowsPerPage, data.length)} />
              <TableNoData isNotFound={!data.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={data.length}
        rowsPerPageOptions={[10, 25]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------
type ListRowProps = {
  readonly id: string;
  readonly number: string;
  readonly date: string;
  readonly donorName: string;
  readonly state: string;
  readonly reissueState: string;
  readonly donorIssues: string[];
  readonly onClick: (receiptId: string) => void;
};
function ListRow({
  id,
  number,
  date,
  donorName,
  state,
  reissueState,
  donorIssues,
  onClick,
}: ListRowProps) {
  
  const handleCellClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <TableRow
      key={id}
      hover
      sx={{
        cursor: 'pointer',
        height: 44,
      }}
      onClick={() => onClick(id)}
    >
      <TableCell align="left">{number}</TableCell>
      <TableCell align="left">{date}</TableCell>
      <TableCell align="left">{donorName}</TableCell>
      <TableCell align="left">{state}</TableCell>
      <TableCell align="left">{reissueState}</TableCell>
      <TableCell padding="checkbox" align="center" onClick={handleCellClick}>
        {!donorIssues.length ? (
          <Box />
        ) : (
          <Tooltip title={donorIssues.join(', ')} placement="top-end">
            <IconButton
              size="small"
              sx={{ color: (theme) => theme.palette.warning.dark }}
            >
              <Iconify icon="ic:baseline-warning-amber" width={24} height={24} />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}
