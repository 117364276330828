import { useEffect, useState } from 'react';
import { Container, Button } from '@mui/material';

import { View } from '@shared/types';
import useView from '@hooks/useView';
import useQueryParams from '@hooks/useQueryParams';

import Page from '@components/Page';
import BreadcrumbsHeader from '@components/BreadcrumbsHeader';

import List from './List';
import PATHS, { getPath } from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
export default function DonorsView() {
  const {
    queryParams: { viewId },
    setQueryParams,
  } = useQueryParams();
  const { getViewById } = useView();

  const navigate = useNavigate();
  const { org } = useOrg();

  // ------- STATE -------
  const [cachedView, setCachedView] = useState<View.View>();

  // ------- EFFECTS -------
  useEffect(() => {
    if (viewId) {
      const view = getViewById(viewId);
      if (view) {
        setCachedView(view);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  const clearCache = () => {
    setCachedView(undefined);
    setQueryParams({});
  };

  const handleSetting = () => {
    navigate(getPath(PATHS.org.donors.settings.donors.tags, { orgId: org!.id }));
  };

  // ------- UI -------
  return (
    <Page title="Donors">
      <Container maxWidth={'xl'}>
        <BreadcrumbsHeader heading="Donors">
          <Button onClick={handleSetting}>
            <Iconify color="#818991" icon="mdi:gear" style={{ fontSize: 26 }} />
          </Button>
        </BreadcrumbsHeader>

        <List cachedView={cachedView} clearCache={clearCache} />
      </Container>
    </Page>
  );
}
