import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import icLogo from '@assets/static/ic_logo.png';

import PATHS from '@routes/paths';

// ----------------------------------------------------------------------
interface Props extends BoxProps {
  disabledLink?: boolean;
  path?: string;
  withName?: boolean;
}

// ----------------------------------------------------------------------
export default function Logo({ disabledLink = false, path, sx, withName = false }: Props) {
  const logo = (
    <Box
      component="img"
      src={icLogo}
      sx={{ width: 40, minWidth: 40, height: 40, minHeight: 40, cursor: 'pointer', ...sx }}
    />
  );

  if (withName) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          component="img"
          src={icLogo}
          sx={{ width: 40, minWidth: 40, height: 40, minHeight: 40, cursor: 'pointer', ...sx }}
        />
        <Typography
          variant="subtitle2"
          color="text.primary"
          sx={{ textDecoration: 'none', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          DONATION Online
        </Typography>
      </Stack>
    );
  }

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to={path || PATHS.root} style={{ textDecoration: 'none' }}>
      {logo}
    </RouterLink>
  );
}
