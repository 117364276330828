import { analytics } from '@/firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

// ----------------------------------------------------------------------
export function viewCreate() {
  firebaseAnalytics.logEvent(analytics, 'View_create');
}

export function viewUpdate() {
  firebaseAnalytics.logEvent(analytics, 'view_update');
}

export function viewDelete() {
  firebaseAnalytics.logEvent(analytics, 'view_delete');
}

export function viewOpen() {
  firebaseAnalytics.logEvent(analytics, 'view_open');
}
