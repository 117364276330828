import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Stack, Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import PATHS from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useView from '@hooks/useView';
import useNavigate from '@hooks/useNavigate';
import { ViewSchemaForm, viewSchemaResolver } from '@/schemas';

import { RHFTextField } from '@components/hook-form';
import { useContext } from '@pages/donors/useContext';
import Dialog from '@components/Dialog';
import ConfirmDialog from '@components/ConfirmDialog';
import ConflictDialog from '@components/ConflictDialog';

// ----------------------------------------------------------------------
type Props = { context: 'donors' | 'donations' };
// ----------------------------------------------------------------------
export default function EditView({ context = 'donors' }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const viewContext = useContext();
  const { org } = useOrg();
  const { views, createView, getViewById, deleteView, updateView } = useView();

  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isInConflict, setInConflict] = useState<string>();

  // --------------- effects ---------------
  const view = useMemo(() => getViewById(params.viewId) || null, [getViewById, params.viewId]);
  const defaultValues: ViewSchemaForm = useMemo(
    () => ({
      name: view?.name || '',
      description: view?.description || '',
    }),
    [view]
  );

  // --------------- form ---------------
  const methods = useForm<ViewSchemaForm>({
    resolver: viewSchemaResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = methods;

  const handleFormReset = useCallback(() => {
    reset(defaultValues);
    setInConflict(undefined);
  }, [reset, defaultValues]);

  // --------------- routing ---------------
  const handleClose = useCallback(() => {
    if (view) {
      navigate(-1);
    } else {
      if (context === 'donations') {
        navigate(PATHS.org.donations.root);
      } else {
        navigate(PATHS.org.donors.root);
      }
    }
  }, [navigate, context, view]);

  // --------------- actions ---------------
  const onSubmit = async (data: ViewSchemaForm) => {
    const nameExists = views.find(
      (v) => v.name.toLowerCase() === data.name.toLowerCase() && v.type === viewContext.type
    );

    try {
      if (!view) {
        const { type, order, orderBy, columns, filterCategories, filterTags, filters } =
          viewContext;
        if (!!nameExists) {
          setError('afterSubmit', { message: 'View with this name already exists!' });
          return;
        }

        await createView({
          orgId: org!.id,
          name: data.name,
          description: data.description,

          type,
          config: {
            order,
            orderBy: orderBy.id,
            columnIds: columns.filter((c) => c.visible).map(({ id }) => id),
            categoryIds: filterCategories.map(({ id }) => id),
            tagIds: filterTags.map(({ id }) => id),
            filters,
          },
          version: 1,
        });

        enqueueSnackbar(`View "${data.name}" created!`);
      } else {
        await updateView({
          orgId: org!.id,
          viewId: view.id,
          update: {
            name: data.name,
            description: data.description,
          },
        });

        enqueueSnackbar(`View updated!`);
      }
      handleClose();
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  const handleDelete = useCallback(async () => {
    if (!org) {
      setError('afterSubmit', { message: '[internal] Missing organization!' });
      return;
    }

    if (!view) {
      setError('afterSubmit', { message: '[internal] Missing view!' });
      return;
    }

    setDeleting(true);
    try {
      await deleteView({ orgId: org.id, viewId: view.id });
    } catch (e) {
      setError('afterSubmit', { message: e.message });
    }
    setDeleting(false);
    handleClose();
  }, [deleteView, handleClose, setError, org, view]);

  return (
    <Dialog
      title={view ? 'Edit view' : 'Save this View'}
      onClose={handleClose}
      maxWidth="xs"
      isDirty={isDirty}
      isLoading={isSubmitting}
      methods={methods}
      actions={
        <Stack direction="row" justifyContent={view ? 'space-between' : 'flex-end'} width="100%">
          {view && (
            <>
              <LoadingButton
                color="error"
                onClick={() => setConfirmingDelete(true)}
                loading={isDeleting}
              >
                Delete view
              </LoadingButton>
              <ConfirmDialog
                open={isConfirmingDelete}
                onClose={() => setConfirmingDelete(false)}
                onConfirm={handleDelete}
                loading={isDeleting}
              />
            </>
          )}
          <LoadingButton
            size="large"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {view ? 'Save' : 'Save View'}
          </LoadingButton>
        </Stack>
      }
    >
      <ConflictDialog onConfirm={handleFormReset} contentText={isInConflict} />
      <Stack spacing={1} alignItems="center" justifyContent="center" mx={4}>
        <RHFTextField
          required
          autoFocus
          name="name"
          type="text"
          label="Name"
          disabled={isSubmitting}
          inputProps={{ maxLength: 30 }}
        />

        <RHFTextField
          name="description"
          type="text"
          label="Description"
          disabled={isSubmitting}
          inputProps={{ maxLength: 80 }}
        />
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </Dialog>
  );
}
