import { ReactNode } from 'react';
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------
type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export default function FormProvider({ children, onSubmit, methods, ...other }: Props) {
  return (
    <Form {...methods}>
      <form
        onSubmit={onSubmit}
        {...other}
        style={{ display: 'flex', flexDirection: 'column', flex: '0 auto' }}
      >
        {children}
      </form>
    </Form>
  );
}
