import { useMemo } from 'react';
import { Box, InputAdornment, Stack, Tooltip } from '@mui/material';

import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import { RHFTextField } from '@components/hook-form';

// ----------------------------------------------------------------------
type Props = {
  readonly formDisabled: boolean;
};
// ----------------------------------------------------------------------
export default function MemberNumbers({ formDisabled }: Props) {
  const { org } = useOrg();
  const { nextMemberNumber } = useDonation();
  const currentYear = new Date().getFullYear();

  const [currLowest, currHighest] = useMemo(
    () => nextMemberNumber(currentYear),
    [nextMemberNumber, currentYear]
  );
  const [prevLowest, prevHighest] = useMemo(
    () => nextMemberNumber(currentYear - 1),
    [nextMemberNumber, currentYear]
  );
  const [nextLowest, nextHighest] = useMemo(
    () => nextMemberNumber(currentYear + 1),
    [nextMemberNumber, currentYear]
  );

  // NOTE: keep this for now until we test and validate Yup schema
  // TODO: after form submit, it won't generate an error in UI anymore...?
  // const handleValidate = useCallback(
  //   (year: number, number: number) => {
  //     console.log('validate', number);
  //     if (number && number <= 0) {
  //       setError(`memberNumbers.${year}`, { message: 'Number must be positive!' });
  //     } else if (isMemberNumberInUse(year, number)) {
  //       setError(`memberNumbers.${year}`, { message: 'Number in use!' });
  //     } else {
  //       clearErrors(`memberNumbers.${year}`);
  //     }
  //   },
  //   [isMemberNumberInUse, setError, clearErrors]
  // );

  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     if (type === 'change' && name?.includes('memberNumbers')) {
  //       const year = Number(name.split('.')[1]);
  //       handleValidate(year, Number(value.memberNumbers[year]));
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch, handleValidate]);

  if (!org?.memberNumbers) return null;

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip
        placement="top"
        title={`Lowest available: ${currLowest}, Next highest: ${currHighest}`}
      >
        <Box width="100%">
          <RHFTextField
            name={`memberNumbers.${currentYear}`}
            label="Member/Envelope #"
            type="number"
            fullWidth
            disabled={formDisabled}
            placeholder="Not set"
            inputProps={{ maxLength: 10, min: 1, max: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end" sx={{ mt: '1px', mr: '4px' }}>
                  {currentYear} -
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Tooltip>
      <Tooltip
        placement="top"
        title={`Lowest available: ${prevLowest}, Next highest: ${prevHighest}`}
      >
        <Box width="100%">
          <RHFTextField
            name={`memberNumbers.${currentYear - 1}`}
            label="Previous Year #"
            type="number"
            fullWidth
            disabled={formDisabled}
            placeholder="Not set"
            inputProps={{ maxLength: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end" sx={{ mt: '1px', mr: '4px' }}>
                  {currentYear - 1} -
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Tooltip>
      <Tooltip
        placement="top"
        title={`Lowest available: ${nextLowest}, Next highest: ${nextHighest}`}
      >
        <Box width="100%">
          <RHFTextField
            name={`memberNumbers.${currentYear + 1}`}
            label="Next Year #"
            type="number"
            fullWidth
            disabled={formDisabled}
            placeholder="Not set"
            inputProps={{ maxLength: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end" sx={{ mt: '1px', mr: '4px' }}>
                  {currentYear + 1} -
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Tooltip>
    </Stack>
  );
}
