import { useState } from 'react';
import { Box, Button, ClickAwayListener, Grid, Popper, Stack, Typography } from '@mui/material';

import colors from '@utils/colors';

// ----------------------------------------------------------------------
type Props = {
  readonly colorText: string;
  readonly colorBackground: string;
  readonly onSelect: (colorText: string, colorBackground: string) => void;
  readonly disabled?: boolean;
};

// ----------------------------------------------------------------------
export default function ColorPalette({ colorText, colorBackground, onSelect, disabled }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        onClick={handleOpen}
        disabled={disabled}
        sx={{
          textTransform: 'lowercase',
          minWidth: 'auto',
          width: 40,
          height: 40,
          borderRadius: '50%',
          fontSize: (theme) => theme.typography.subtitle1,
          background: colorBackground,
          color: colorText,
        }}
      >
        a
      </Button>

      {isOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper open={isOpen} anchorEl={anchorEl} sx={{ zIndex: 1301 }}>
            <Box
              sx={{
                p: 1,
                borderRadius: 1,
                bgcolor: 'background.paper',
                boxShadow: (t) => t.customShadows.card,
              }}
            >
              <Grid container width={160}>
                {colors.map(({ colorText, colorBackground }) => (
                  <Grid
                    key={colorBackground}
                    item
                    xs={3}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Stack
                      key={colorBackground}
                      alignItems="center"
                      justifyContent="center"
                      onClick={() => onSelect(colorText, colorBackground)}
                      sx={{
                        width: 26,
                        height: 26,
                        margin: 0.5,
                        borderRadius: '50%',
                        background: colorBackground,
                        color: colorText,
                        '&:hover': {
                          border: '1px solid black',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <Typography>a</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
}
