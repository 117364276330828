import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Box, Button, Divider, Link, Stack, Typography, darken } from '@mui/material';

import useDonation from '@hooks/useDonation';
import Iconify from '@components/Iconify';
import FeedbackDialog from '@/layouts/AppLayout/Navbar/Feedback/FeedbackDialog';
import { Limits } from '@shared/limits';
import PATHS, { getPath } from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import useOrg from '@hooks/useOrg';
import { Outlet } from 'react-router';

// ----------------------------------------------------------------------
export default function DonorTags() {
  const { tags } = useDonation();

  const [feedbackOpen, feedbackSetOpen] = useState(false);
  const navigate = useNavigate();
  const { org } = useOrg();
  // --------------- effects ---------------
  const donorTags = useMemo(
    () =>
      sortBy(
        tags.filter((t) => t.type === 'donor'),
        (t) => t.name.toUpperCase()
      ),
    [tags]
  );

  // --------------- actions ---------------
  const handleAdd = () => {
    navigate(getPath(PATHS.org.donors.settings.donors.tagCreate, { orgId: org!.id }));
  };

  const handleEdit = (tagId: string) => {
    navigate(getPath(PATHS.org.donors.settings.donors.tagsEdit, { orgId: org!.id, tagId }));
  };

  return (
    <Box sx={{ paddingTop: 3 }}>
      <Outlet />
      <Stack>
        <Stack spacing={0.5} sx={{ py: 1 }}>
          {donorTags.map((tag) => (
            <Stack
              key={tag.id}
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                p: 0.5,
                borderRadius: 2,
                // border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                cursor: 'pointer',
                width: 'fit-content',
              }}
              onClick={() => handleEdit(tag.id)}
            >
              <Stack
                py={0.25}
                px={1.5}
                sx={{
                  m: 0.25,
                  borderRadius: 3,
                  background: tag.colorBackground,
                  color: tag.colorText,
                  '&:hover': {
                    cursor: 'pointer',
                    bgcolor: darken(tag.colorBackground, 0.2),
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    maxWidth: 240,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {tag.name}
                </Typography>
              </Stack>

              {tag.description && (
                <>
                  <Typography>-</Typography>
                  <Typography variant="body2">{tag.description}</Typography>
                </>
              )}
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
        <Stack alignItems="flex-end">
          {tags.length < Limits.tags ? (
            <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd}>
              New Tag
            </Button>
          ) : (
            <Typography>
              You've reached the limit of {Limits.tags} Tags. Please{' '}
              <Link sx={{ cursor: 'pointer' }} onClick={() => feedbackSetOpen(true)}>
                contact us
              </Link>{' '}
              if this is a problem for you.
            </Typography>
          )}
        </Stack>
      </Stack>

      <FeedbackDialog open={feedbackOpen} setOpen={feedbackSetOpen} />
    </Box>
  );
}
