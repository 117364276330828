import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';

import cssStyles from '@utils/cssStyles';

import { HEADER, NAVBAR } from '../../../config';

// ----------------------------------------------------------------------
type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  isFullWidth: boolean;
};

export const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'isFullWidth',
})<RootStyleProps>(({ isCollapse, isOffset, isFullWidth, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: isFullWidth ? `100%` : `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));
