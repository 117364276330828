import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import auth from '@fire/auth';
import useIsMountedRef from '@hooks/useIsMountedRef';
import { RegisterSchemaForm, registerSchemaResolver } from '@/schemas';

import Iconify from '@components/Iconify';
import { FormProvider, RHFTextField } from '@components/hook-form';
import AuthSocial from '@pages/auth/AuthSocial';

// ----------------------------------------------------------------------
export default function RegisterForm() {
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);

  const defaultValues: RegisterSchemaForm = {
    displayName: '',
    email: '',
    password: '',
  };

  const methods = useForm<RegisterSchemaForm>({
    resolver: registerSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data: RegisterSchemaForm) => {
    try {
      await auth.register({
        email: data.email,
        password: data.password,
        displayName: data.displayName,
      });
      reset();
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  const onSubmitSocial = async (data: any) => {
    setSocialLoading(true);

    try {
      await auth.loginSocial();
    } catch (e) {
      console.error(e);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...e, message: e.message });
      }
    }

    setSocialLoading(false);
  };

  const isFormLoading = isSubmitting || socialLoading;

  return (
    <>
      <AuthSocial onSubmit={onSubmitSocial} isLoading={isFormLoading} />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFTextField name="displayName" label="Your full name" disabled={isFormLoading} />
          <RHFTextField name="email" label="Email address" disabled={isFormLoading} />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            disabled={isFormLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isFormLoading}
            disabled={!isDirty}
          >
            Register
          </LoadingButton>
        </Stack>
      </FormProvider>
    </>
  );
}
