import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { TReceiptVariables } from '@typedefs/donation';
import ReceiptHeader from './PdfReceipt/components/Header';

const styles = StyleSheet.create({
  letter: {},
  section: {
    marginBottom: 34,
  },
});

// ----------------------------------------------------------------------
type Props = {
  year: number;
  vars: TReceiptVariables;
};
// ----------------------------------------------------------------------
export default function PdfDocument({ year, vars }: Props) {
  return (
    <View style={styles.letter}>
      <ReceiptHeader vars={vars} />

      <View style={styles.section}>
        <Text>{vars['donor.fullName']}</Text>
        <Text>
          {vars['donor.address']?.address1}{' '}
          {vars['donor.address']?.address2 && `, ${vars['donor.address']?.address2}`}
        </Text>
        <Text>
          {vars['donor.address']?.city && vars['donor.address']?.city}
          {vars['donor.address']?.state ||
            (vars['donor.address']?.postalCode &&
              `, ${vars['donor.address']?.state} ${vars['donor.address']?.postalCode}`)}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={{ marginBottom: 16 }}>Dear {vars['donor.fullName']}</Text>

        <Text style={{ marginBottom: 16 }}>
          Thank you for your donations to {vars['org.name']}, which allow us to continue our
          important work. This year you donated a total of {vars['donations.totalByYear'][year]}
          {vars['donations.totalByYear'][year - 1]
            ? ` and last year you donated ${vars['donations.totalByYear'][year - 1]}`
            : ''}
        </Text>

        <Text>Sincerely,</Text>
      </View>

      <View>
        <Text>
          {vars['signatory.name']}{' '}
          {vars['signatory.position'] ? `, ${vars['signatory.position']}` : ''}
        </Text>
      </View>
    </View>
  );
}
