import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import * as analytics from '@fire/analytics';
import PATHS, { getPath } from '@routes/paths';
import builtinReports from './Builtin';

// ----------------------------------------------------------------------
export default function Picker() {
  const { reportId, orgId } = useParams();
  const navigate = useNavigate();

  // matchers
  const builtinReport = builtinReports.find((r) => r.id === reportId);

  // ---------- effects
  useEffect(() => {
    if (!builtinReport) {
      navigate(getPath(PATHS.org.reports.root, { orgId }));
    }
    if (reportId) {
      analytics.donation.reportView(reportId);
    }
  }, [navigate, orgId, builtinReport, reportId]);

  // ---------- ui
  if (builtinReport) {
    return (
      <builtinReport.Component
        id={reportId!}
        name={builtinReport.name}
        description={builtinReport.description}
      />
    );
  }

  return null;
}
