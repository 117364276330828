import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const MemberEnvelopeSettingsSchema = Yup.object().shape({
  memberNumbers: Yup.bool(),
});

export const memberEnvelopeSettingsSchemaResolver = yupResolver(MemberEnvelopeSettingsSchema);

export type MemberEnvelopeSettingsSchemaForm = {
  memberNumbers: boolean;
  afterSubmit?: string;
};
