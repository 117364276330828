import { Stack, Typography, IconButton } from '@mui/material';

import { TColumn, TColumnFilter } from '@typedefs/app';
import useFormat from '@hooks/useFormat';

import Iconify from '@components/Iconify';
import { getOperatorLabel } from './getOperator';

// ----------------------------------------------------------------------
type Props = {
  filter: TColumnFilter;
  columns: TColumn[];
  onRemove?: (filterId: string) => void;
};

// ----------------------------------------------------------------------
export default function TableFilterRow({ filter, columns, onRemove }: Props) {
  const { fDate } = useFormat();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[600]}14`,
        borderRadius: 1,
        background: (theme) => theme.palette.grey[100],
        color: 'text.primary',
        pl: 1,
        py: 0.25,
        m: 0.5,
      }}
      spacing={0.5}
    >
      <Typography variant="body2" fontWeight="bold">
        {columns.find((c) => c.id === filter.columnId)!.label}
      </Typography>
      <Typography variant="body2">{getOperatorLabel(filter)}</Typography>
      <Typography variant="body2" fontWeight="bold">
        {filter.columnType === 'date' ? fDate(filter.value) : filter.value}
      </Typography>

      {onRemove && (
        <IconButton size="small" color="inherit" onClick={() => onRemove(filter.id)}>
          <Iconify icon="material-symbols:close" width={20} height={20} />
        </IconButton>
      )}
    </Stack>
  );
}
