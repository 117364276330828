import { useFormContext, Controller } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextFieldProps } from '@mui/material';
import useFormat from '@hooks/useFormat';

// ----------------------------------------------------------------------
type Props = TextFieldProps & {
  name: string;
  minDate?: Date | number;
  maxDate?: Date | number;
};

export default function RHFDatePicker({ name, minDate, maxDate, ...other }: Props) {
  const { control } = useFormContext();
  const { getDateFormat } = useFormat();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DesktopDatePicker
          {...field}
          format={getDateFormat()}
          value={field.value}
          disabled={other.disabled}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            textField: {
              type: 'text',
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
              ...other,
            },
          }}
        />
      )}
    />
  );
}
