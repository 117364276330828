import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import auth from '@fire/auth';
import PATHS from '@routes/paths';
import useIsMountedRef from '@hooks/useIsMountedRef';
import Iconify from '@components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '@components/hook-form';
import AuthSocial from '@pages/auth/AuthSocial';
import { LoginSchemaForm, loginSchemaResolver } from '@/schemas';

// ----------------------------------------------------------------------
export default function LoginForm() {
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [socialLoading, setSocialLoading] = useState(false);

  const defaultValues: LoginSchemaForm = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm<LoginSchemaForm>({
    resolver: loginSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data: LoginSchemaForm) => {
    try {
      await auth.login({ email: data.email, password: data.password });
    } catch (e) {
      console.error(e);

      if (isMountedRef.current) {
        setError('afterSubmit', { ...e, message: e.message });
      }
    }
  };

  const onSubmitSocial = async (data: any) => {
    setSocialLoading(true);

    try {
      await auth.loginSocial();
      reset();
    } catch (e) {
      console.error(e);
      if (isMountedRef.current) {
        setError('afterSubmit', { ...e, message: e.message });
      }
    }

    setSocialLoading(false);
  };

  const isFormLoading = isSubmitting || socialLoading;

  return (
    <>
      <AuthSocial onSubmit={onSubmitSocial} isLoading={isFormLoading} />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} py={1}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFTextField name="email" label="Email address" disabled={isFormLoading} />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            disabled={isFormLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <RHFCheckbox name="remember" label="Remember me" />
          <Link component={RouterLink} variant="subtitle2" to={PATHS.resetPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isFormLoading}
          disabled={!isDirty}
        >
          Login
        </LoadingButton>
      </FormProvider>
    </>
  );
}
