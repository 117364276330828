import { View, StyleSheet } from '@react-pdf/renderer';
import { TReceiptRenderGroup, TReceiptVariables } from '@typedefs/donation';

import ReceiptHeader from '../components/Header';
import ReceiptDonorInfo from '../components/DonorInfo';
import ReceiptSignature from '../components/Signature';
import ReceiptDonationsTable from '../components/DonationsTable';

import ReceiptBody from './Body';
import ReceiptInfo from './ReceiptInfo';
import ReceiptFootnote from './Footnote';
import { ReceiptOptions } from '@/schemas';
import ReceiptSummaryTable from '../components/SummaryTable';

const styles = StyleSheet.create({
  body: { display: 'flex', flexDirection: 'row' },
  bodyLeft: { flex: '1 1 auto', display: 'flex', rowGap: 16 },
  bodyRight: { width: '38%', display: 'flex', rowGap: 32 },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
  group: TReceiptRenderGroup;
  options: ReceiptOptions;
  year: number;
};
// ----------------------------------------------------------------------
export default function ReceiptUS({ vars, group, options, year }: Props) {
  const receiptTotal = group.donations.reduce((acc, d) => acc + d.amount, 0);

  return (
    <View>
      <ReceiptHeader vars={vars} />

      <View style={styles.body}>
        <View style={styles.bodyLeft}>
          <ReceiptBody vars={vars} year={year} receiptTotal={receiptTotal} />
          <ReceiptDonorInfo vars={vars} />
        </View>

        <View style={styles.bodyRight}>
          <ReceiptInfo
            vars={vars}
            receiptNumber={group.number}
            receiptDate={group.date}
            year={year}
            type={group.type}
          />
          <ReceiptSignature vars={vars} />
        </View>
      </View>

      <ReceiptFootnote vars={vars} />

      {options.includeDetails ? (
        <ReceiptDonationsTable
          vars={vars}
          donations={group.donations}
          showDescription={options.showDescription}
        />
      ) : null}
      {options.includeSummary ? (
        <ReceiptSummaryTable vars={vars} donations={group.donations} />
      ) : null}
    </View>
  );
}
