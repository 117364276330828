import { useCallback, useMemo } from 'react';
import { Card, CardHeader, Box } from '@mui/material';
import { groupBy, maxBy } from 'lodash';

import { TDonorWithDonations } from '@typedefs/donation';
import useFormat from '@hooks/useFormat';
import Chart, { useChart } from '@components/chart';
import useDonation from '@hooks/useDonation';

// ----------------------------------------------------------------------
interface Props {
  readonly currentYearDonors: TDonorWithDonations[];
  readonly startTime: number;
}

// ----------------------------------------------------------------------
export default function DonorsChart({ currentYearDonors, startTime }: Props) {
  const { isDateBetween, fDateToPreviousYearStart, fDateToPreviousYearEnd, fShortenNumber } =
    useFormat();
  const { donorsWithDonations } = useDonation();

  // previous year calc
  const previousYearStart = fDateToPreviousYearStart(startTime);
  const previousYearEnd = fDateToPreviousYearEnd(startTime);

  // donors who donated previous year
  const previousYearDonors = useMemo(
    () =>
      donorsWithDonations
        .map((donor) => {
          const relevantDonations = donor.donations.filter((d) =>
            isDateBetween(new Date(d.date), previousYearStart, previousYearEnd)
          );
          return {
            ...donor,
            donations: relevantDonations,
            donationsTotal: relevantDonations.reduce((sum, donation) => sum + donation.amount, 0),
            donationDate: maxBy(
              relevantDonations.map((d) => d.date),
              (date) => new Date(date).getTime()
            ),
          };
        })
        .filter((d) => d.donations.length),
    [isDateBetween, donorsWithDonations, previousYearStart, previousYearEnd]
  );

  const getByMonth = useCallback((donors: TDonorWithDonations[]) => {
    const monthsInYear = new Array(12).fill(0);
    donors.forEach(({ donations }) => {
      const dByMonth = groupBy(donations, (d) => new Date(d.date).getMonth());
      Object.keys(dByMonth).forEach(
        (key) => (monthsInYear[parseInt(key)] = monthsInYear[parseInt(key)] + 1)
      );
    });
    return monthsInYear;
  }, []);

  // we count unique donor by donation by month
  // if donor donated in Jan 1x and Oct 3x, we could this donor Jan 1 and Oct 1 (as donated)
  const prevByMonth = useMemo(
    () => getByMonth(previousYearDonors),
    [getByMonth, previousYearDonors]
  );
  const currByMonth = useMemo(() => getByMonth(currentYearDonors), [getByMonth, currentYearDonors]);

  const chartOptions = useChart({
    colors: ['#2EC4B6', '#C4CDD5'],
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    yaxis: {
      labels: {
        formatter: fShortenNumber,
      },
    },
    series: [
      { name: 'Current year', data: currByMonth },
      { name: 'Previous year', data: prevByMonth },
    ],
  });

  return (
    <Card>
      <CardHeader title="Donors with Donations" />
      <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
        <Chart type="area" series={chartOptions.series} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
