import { TableRow, TableCell, Tooltip, Chip, Checkbox, IconButton } from '@mui/material';
import { sortBy, uniqBy } from 'lodash';

import { TColumn } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';
import useDonation from '@hooks/useDonation';
import useFormat from '@hooks/useFormat';
import Iconify from '@components/Iconify';
import type { RecipientsListHeader } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly donor: TDonorWithDonations;
  readonly issues?: string[];
  readonly selected: boolean;
  readonly onSelectRow: (id: string) => void;
  readonly columns: TColumn<RecipientsListHeader>[];
  readonly onClickRow: (donorId: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ donor, issues, selected, onSelectRow, columns, onClickRow }: Props) {
  const { fCurrency, fFullName } = useFormat();
  const { getTagsFromIds, getDonationCategory } = useDonation();

  const handleCellClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSelectRow(donor.id);
  };

  const cells = columns
    .filter((c) => c.visible)
    .map((column) => {
      switch (column.id) {
        case 'exclude':
          return (
            <TableCell key={column.id} padding="checkbox" align="center">
              <Checkbox checked={selected} onClick={handleSelect} />
            </TableCell>
          );
        case 'issues':
          if (!issues)
            return (
              <TableCell key={column.id} padding="checkbox">
                &nbsp;
              </TableCell>
            );
          return (
            <TableCell key={column.id} padding="checkbox" align="center" onClick={handleCellClick}>
              <Tooltip title={issues.join(', ')} placement="top-end">
                <IconButton size="small" sx={{ color: (theme) => theme.palette.warning.dark }}>
                  <Iconify icon="ic:baseline-warning-amber" width={24} height={24} />
                </IconButton>
              </Tooltip>
            </TableCell>
          );
        case 'donationsTotal':
          return (
            <TableCell key={column.id} align={column.align}>
              {fCurrency(donor.donationsTotal)}
            </TableCell>
          );
        case 'donationsCount':
          return (
            <TableCell key={column.id} align={column.align}>
              {donor.donations.length}
            </TableCell>
          );
        case 'name':
          return (
            <TableCell key={column.id} align={column.align}>
              {fFullName(donor)}
            </TableCell>
          );
        case 'tags':
          return (
            <TableCell key={column.id} align={column.align}>
              {getTagsFromIds(donor.tagIds).map((tag) => (
                <Tooltip key={tag.id} title={tag.description} placement="top" enterDelay={600}>
                  <Chip
                    key={tag.id}
                    size="medium"
                    label={tag.name}
                    sx={{ background: tag.colorBackground, color: tag.colorText }}
                  />
                </Tooltip>
              ))}
            </TableCell>
          );
        case 'categories':
          return (
            <TableCell key={column.id} align={column.align}>
              {sortBy(
                uniqBy(donor.donations.map(getDonationCategory), 'id').filter((d) => d),
                'name'
              )
                .map((c) => c!.name)
                .join(', ')}
            </TableCell>
          );
        default:
          const value = donor[column.id] || '';
          return (
            <TableCell key={column.id} align={column.align}>
              {value}
            </TableCell>
          );
      }
    });

  return (
    <TableRow
      hover
      selected={selected}
      onClick={() => onClickRow(donor.id)}
      sx={{ cursor: 'pointer', '&.Mui-selected td': { color: (theme) => theme.palette.grey[600] } }}
    >
      {cells}
    </TableRow>
  );
}
