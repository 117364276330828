import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';

import { FormProvider, RHFSwitch, RHFTextField } from '@components/hook-form';
import { OrganizationReceiptSchemaForm, organizationReceiptSchemaResolver } from '@/schemas';
import { TStepProps } from './config';
import StepActions from './StepActions';

// ----------------------------------------------------------------------
export default function StepReceipts({ data, isSubmitting, onBack, onNext }: TStepProps) {
  const defaultValues: OrganizationReceiptSchemaForm = {
    signatoryName: data.signatoryName,
    signatoryPosition: data.signatoryPosition,
    religiousBenefit: data.religiousBenefit,
    signatoryEmail: '',
  };

  const methods = useForm<OrganizationReceiptSchemaForm>({
    resolver: organizationReceiptSchemaResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const isUS = data.address.country.value === 'us';

  const onSubmit = async (data: OrganizationReceiptSchemaForm) => {
    const stepData = {
      signatoryName: data.signatoryName,
      signatoryPosition: data.signatoryPosition,
      religiousBenefit: data.religiousBenefit,
    };
    onNext(stepData);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={4}>
        <RHFTextField
          required
          name="signatoryName"
          label="Signatory Name"
          helperText={errors.signatoryName?.message}
        />
        <RHFTextField
          name="signatoryPosition"
          label="Signatory Position"
          helperText={errors.signatoryPosition?.message}
          // disabled={emailDisabled}
        />

        {isUS && (
          <RHFSwitch
            name="religiousBenefit"
            label="Religious Benefit"
            sx={{ width: '100%' }}
            helperText={'Do your Donors receive an "intangible religious benefit"'}
          />
        )}

        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField
              name="address.address1"
              label="Address"
              helperText={errors.address?.address1?.message}
            />
            <RHFTextField
              name="address.address2"
              label="Address"
              helperText={errors.address?.address2?.message}
            />
          </Stack> */}
      </Stack>

      <StepActions
        isSubmitting={isSubmitting}
        errors={Object.values(errors).map((e) => e.message || '')}
        handleBack={onBack}
      />
    </FormProvider>
  );
}
