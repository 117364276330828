import {
  TColumnFilter,
  ColumnFilterOperatorString,
  ColumnFilterOperatorNumber,
  ColumnFilterOperatorDate,
  ColumnFilterOperatorBoolean,
  TColumnType,
} from '@typedefs/app';

// ----------------------------------------------------------------------
export function getOperator(type?: TColumnType) {
  switch (type) {
    case 'string':
      return ColumnFilterOperatorString;
    case 'address':
      return ColumnFilterOperatorString;
    case 'date':
      return ColumnFilterOperatorDate;
    case 'number':
      return ColumnFilterOperatorNumber;
    case 'currency':
      return ColumnFilterOperatorNumber;
    case 'boolean':
      return ColumnFilterOperatorBoolean;

    default:
      return [];
  }
}

// ----------------------------------------------------------------------
export const getOperatorLabel = (filter: TColumnFilter) => {
  const operatorBase = getOperator(filter.columnType);
  return operatorBase.find((o) => o.value === filter.operator)?.label || '';
};
