export default function addClarity() {
  const clarity = function (
    c: any,
    l: any,
    a: any,
    r: any,
    i: any,
    t: any = undefined,
    y: any = undefined
  ) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  };
  clarity(window, document, 'clarity', 'script', 'ejg4hyud7n');
}
