// import useDonation from '@hooks/useDonation';

import { Alert, Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { sortBy } from 'lodash';
import { ImportEntity } from '@shared/types/importData';
import { ListObject } from './Lists';

type Props = {
  name: string;
  pluralName: string;
  myErrors: string[]; // errors from this section
  setMyErrors: (errors: string[]) => void;
  newObject: ListObject;
  objectList: ListObject[];
  objectsMap: ImportEntity[];
  updateStepDataMap: (newMap: ImportEntity[]) => void;
};
// ----------------------------------------------------------------------
export default function OneList({
  name,
  pluralName,
  myErrors,
  setMyErrors,
  newObject,
  objectList,
  objectsMap,
  updateStepDataMap,
}: Props) {
  const [rows, setRows] = useState(
    objectsMap
      .filter((o) => o.isNew)
      .map((o) => ({ ...o, listObject: objectList.find((ol) => ol.id === o.id) || newObject }))
  );

  const updateErrors = (err: string, i: number) => {
    let errors = [...myErrors];
    errors[i] = err;
    if (!errors.find((e) => !!e)) errors = [];
    setMyErrors(errors);
  };

  const handleSelectObject = (myNewObject: ListObject, i: number, reason: string) => {
    const newRows = [...rows];
    newRows[i].listObject = myNewObject;
    newRows[i].id = myNewObject.id;
    const newName = !myNewObject.id ? newRows[i].sampleName : myNewObject.name;
    newRows[i].newName = newName;
    setRows(newRows);

    if (myNewObject.id) {
      const obj = objectList.find((o) => o.id === myNewObject.id);
      if (!!obj?.importAliases && obj.importAliases?.length + 1 > 10)
        updateErrors(
          `There are already 10 import aliases for the ${name} ${myNewObject.name}, which is the maximum allowed.`,
          i
        );
      else updateErrors('', i);
    }

    const newMap = [...objectsMap];
    const mapElement = newMap.find((m) => m.lowerName === newRows[i].lowerName);
    if (mapElement) {
      mapElement.id = myNewObject.id;
      mapElement.newName = newName;
      updateStepDataMap(newMap);
    }
  };

  const handleChangeNewName = (newName: string, i: number) => {
    if (!newName) {
      updateErrors('New name cannot be empty', i);
    } else if (!!objectList.find((o) => o.name.toLowerCase() === newName.toLowerCase())) {
      updateErrors(
        `The name ${newName} for a new ${name} is the same as an existing ${name}, please just select that as the Existing ${name}.`,
        i
      );
    } else updateErrors('', i);
    const newRows = [...rows];
    newRows[i].newName = newName;
    setRows(newRows);

    const newMap = [...objectsMap];
    const mapElement = newMap.find((m) => m.lowerName === newRows[i].lowerName);
    if (mapElement) {
      mapElement.newName = newName;
      updateStepDataMap(newMap);
    }
  };

  return (
    <Stack sx={{ py: 1, '& .MuiFormHelperText-root': { marginTop: '-2px' } }} spacing={2}>
      <Typography variant="h5" sx={{ mb: '20px' }}>
        New {pluralName} in Input File
      </Typography>
      {rows.map((row, i) => (
        <Stack direction="row" spacing={1} key={i}>
          <TextField
            sx={{ width: '33%', maxWidth: 300 }}
            value={row.sampleName}
            label={`Imported ${name} Name`}
            size="small"
            fullWidth
            disabled
            InputProps={{
              readOnly: true,
            }}
          />
          <Autocomplete
            value={row.listObject || newObject}
            sx={{ width: '33%', maxWidth: 300 }}
            size="small"
            disableClearable
            options={[newObject, ...sortBy(objectList, (c) => c.name.toUpperCase())]}
            renderInput={(params) => (
              <TextField {...params} label={`Existing ${name}`} variant="outlined" />
            )}
            onChange={(e, value, reason) => handleSelectObject(value, i, reason)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // renderTags={renderCategories}
            // renderOption={renderCategoryOption}
          />
          { !row.id && <TextField
            sx={{ width: '33%', maxWidth: 300 }}
            value={row.newName}
            fullWidth
            label={`New ${name} Name`}
            size="small"
            disabled={!!row.id}
            onChange={(e) => handleChangeNewName(e.target.value, i)}
          /> }
        </Stack>
      ))}
      {myErrors.length > 0 && (
        <Alert severity="error">
          {myErrors
            .filter((e) => !!e)
            .map((e, i) => (
              <div key={i}>{e}</div>
            ))}
        </Alert>
      )}
    </Stack>
  );
}
