import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EmailValidOrEmpty, 'Email must be a valid email address')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const loginSchemaResolver = yupResolver(LoginSchema);
export type LoginSchemaForm = {
  email: string;
  password: string;
  remember: boolean;

  afterSubmit?: string;
};
