import * as Yup from 'yup';
import { endOfDay, startOfYear } from 'date-fns';
import { isUndefined } from 'lodash';

import { Category, Donation, Donor, Receipt, Tag, Country } from '@shared/types';
import { TDonorWithDonations } from '@typedefs/donation';
import { AutocompleteItem, PageSize } from '@typedefs/app';
import { PdfDocumentProps } from '@pages/receipts/templates/PdfDocument';

// ----------------------------------------------------------------------
// base schema
// reissue is an additional step that changes validation enforcement
// so its conditionally added - for create its undefined, and for reissues its boolean
const ReceiptBaseSchema = Yup.object().shape({
  step: Yup.number().required(),

  // step 1
  actionType: Yup.string().when(['step', 'reissue'], ([step, dr], schema) =>
    step > -1 + (!isUndefined(dr) ? 1 : 0) ? schema.required('Send type required') : schema
  ),
  printAllDonors: Yup.bool(),

  // step 2
  dateFrom: Yup.date().when(['step', 'dateTo', 'reissue'], ([step, dateTo, dr], schema) => {
    if (step > 0 + (!isUndefined(dr) ? 1 : 0)) {
      schema = schema.required('Date range is required');
    }
    if (dateTo) {
      const yearStart = startOfYear(dateTo);
      schema = schema.min(yearStart, 'Date range must be within same year');
    }
    return schema;
  }),
  dateTo: Yup.date().when(['step', 'reissue'], ([step, dr], schema) =>
    step > 0 + (!isUndefined(dr) ? 1 : 0)
      ? schema
          .required('Date range is required')
          .max(endOfDay(new Date()), "Date range can't include future dates.")
      : schema
  ),
  issues: Yup.number().when(['step', 'reissue'], ([step, dr], schema) =>
    step > 0 + (!isUndefined(dr) ? 1 : 0)
      ? schema.max(0, ({ value }) => `${value} donor(s) are incomplete`)
      : schema
  ),
  // step 3
  letter: Yup.object().when(['step', 'reissue'], ([step, dr], schema) =>
    step > 1 + (!isUndefined(dr) ? 1 : 0)
      ? schema.shape({
          size: Yup.string().required('Pdf size required'),
          withLetter: Yup.boolean(),
          // Doc - no validation possible as its a function
        })
      : schema
  ),
  // step 4
  email: Yup.object().when(['step', 'actionType', 'reissue'], ([step, actionType, dr], schema) =>
    step > 2 + (!isUndefined(dr) ? 1 : 0) && actionType === Receipt.ReceiptActionType.email
      ? schema.shape({
          subject: Yup.string().required('Email Subject required'),
          html: Yup.string().required('Email body required').min(1),
        })
      : schema
  ),
  // step 5
  emailAndPrint: Yup.bool(),
});
export type ReceiptBaseSchemaForm = {
  step: number;

  // step 1
  actionType?: Receipt.ReceiptActionType;
  printAllDonors: boolean;
  // step 2
  dateFrom?: Date;
  dateTo?: Date;
  tags: Tag.Tag[];
  categories: Category.Category[];
  issues: number;
  // step 3/4
  letter: {
    withLetter: boolean;
    size: PageSize;
    Doc: (props: PdfDocumentProps) => JSX.Element;
  };
  email: {
    subject: string;
    html: string;
    state: string;
  };
  // step 4/4
  emailAndPrint: boolean;
  options: ReceiptOptions;
};

// ----------------------------------------------------------------------
// Receipt issue schema
export const ReceiptIssueSchema = ReceiptBaseSchema.shape({
  donors: Yup.array().when('step', ([step], schema) =>
    step > 0
      ? schema.required('Provide at least one donor').min(1, 'At least one donor is required')
      : schema
  ),
  filterMinAmount: Yup.number().nullable().typeError('Minimum donation amount must be a number'),
});

export enum MinAmountType {
  atLeastOneGE = 'oneGE',
  totalGE = 'totalGE',
  eachOnlyGE = 'eachGE',
}

export const MinAmountTypeList: AutocompleteItem<MinAmountType>[] = [
  {
    value: MinAmountType.atLeastOneGE,
    label: 'Include all donations from donors with at least one donation >= the Min. Amount',
  },
  {
    value: MinAmountType.totalGE,
    label: 'Include all donations from donors whose donations total at least the Min. Amount',
  },
  {
    value: MinAmountType.eachOnlyGE,
    label: 'Include only donations that are themselves >= the Min. Amount',
  },
];

export type ReceiptIssueSchemaForm = ReceiptBaseSchemaForm & {
  donors: TDonorWithDonations[];
  excludedIds: string[]; // donorIds excluded from list
  singleDonor?: Donor.Donor; // if creating receipt for one donor
  filterDonor?: Donor.Donor; // donor selected with the filter in Recipients step
  filterMinAmount?: number;
  filterMinAmountType: MinAmountType;
};

// ----------------------------------------------------------------------
// Receipt reissue schema
export const ReceiptReissueSchema = ReceiptBaseSchema.shape({
  dateFrom: Yup.date(),
  dateTo: Yup.date(), // TODO: validation

  receipt: Yup.object().required('[internal] Receipt is required'),
  reissue: Yup.bool(),
  // donations: Yup.array().when('step', ([step], schema) =>
  //   step > 1
  //     ? schema.required('Provide at least one donation').min(1, 'Donor issues detected')
  //     : schema
  // ),
});

export type ReceiptReissueSchemaForm = ReceiptBaseSchemaForm & {
  receipt: Receipt.Receipt;
  reissue: boolean;
  donations: Donation.Donation[];
  includeDonations: boolean;
};

// ----------------------------------------------------------------------
// Receipt reissue batch schema
export const ReceiptReissueBatchSchema = ReceiptBaseSchema.shape({
  rangeFrom: Yup.number(),
  rangeTo: Yup.number(),
  rangeConfirmed: Yup.bool().when('step', ([step], schema) =>
    step > 1 ? schema.oneOf([true], 'Receipt numbers not confirmed') : schema
  ),

  receiptingYear: Yup.number()
    .nullable()
    .when('step', ([step], schema) =>
      step > 1 ? schema.required('Receipting year required') : schema
    ),
  receipts: Yup.array().when('step', ([step], schema) =>
    step > 1 ? schema.min(1, 'At least one receipt is required') : schema
  ),
  reissue: Yup.bool(),
});

export type ReceiptReissueBatchSchemaForm = ReceiptBaseSchemaForm & {
  receiptingYear?: number;
  rangeFrom?: number;
  rangeTo?: number;
  rangeConfirmed: boolean;
  receipts: Receipt.Receipt[];
  reissue: boolean;
};

// ----------------------------------------------------------------------
// Receipt donor validation
export const ReceiptDonorSchema = (
  receiptActionType: Receipt.ReceiptActionType,
  country: String | undefined
) =>
  Yup.object().shape({
    firstName: Yup.string().when(['type'], ([type], schema) =>
      type === Donor.DonorType.individual ? schema.required('Missing first name') : schema
    ),
    lastName: Yup.string().when(['type'], ([type], schema) =>
      type === Donor.DonorType.individual ? schema.required('Missing last name') : schema
    ),
    // eslint-disable-next-line no-empty-pattern
    email: Yup.string().when([], ([], schema) =>
      receiptActionType === Receipt.ReceiptActionType.email
        ? schema.required('Missing donor email')
        : schema
    ),
    address: Yup.object().shape({
      // eslint-disable-next-line no-empty-pattern
      address1: Yup.string().when([], ([], schema) =>
        Country.ca === country || receiptActionType === Receipt.ReceiptActionType.print
          ? schema.required('Missing donor address')
          : schema
      ),
      city: Yup.string(),
      state: Yup.string(),
      postalCode: Yup.string(),
      // eslint-disable-next-line no-empty-pattern
      country: Yup.string().when([], ([], schema) =>
        Country.ca === country || receiptActionType === Receipt.ReceiptActionType.print
          ? schema.required('Missing donor country')
          : schema
      ),
    }),
  });

// bottom section options for receipts
export type ReceiptOptions = {
  showDescription: boolean;
  includeDetails: boolean;
  includeSummary: boolean;
};
