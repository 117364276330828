import { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import { assignNumbersSchemaResolver, AssignNumbersSchemaForm } from '@/schemas';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import * as analytics from '@fire/analytics';

import { RHFRadioGroup, RHFTextField } from '@components/hook-form';
import ConfirmDialog from '@components/ConfirmDialog';
import Dialog from '@components/Dialog';
import { compact, orderBy, shuffle } from 'lodash';
import { Donor } from '@shared/types';

// ----------------------------------------------------------------------
export default function AssignMemberNumbers() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const { donors, nextMemberNumber, assignMemberNumbers } = useDonation();
  const [isConfirming, setConfirming] = useState(false);
  const canEdit = hasAccess([Role.editor, Role.contributor]);

  // --------------- routing ---------------
  const handleClose = () => {
    navigate(-1);
  };

  // --------------- effects ---------------
  useEffect(() => analytics.donation.memberAssignView(), []);
  const currentYear = new Date().getFullYear();

  const defaultValues: AssignNumbersSchemaForm = useMemo(
    () => ({
      year: currentYear + 1,
      selection: null,
      order: null,
      start: 1,
    }),
    [currentYear]
  );

  // --------------- form ---------------
  const methods = useForm<AssignNumbersSchemaForm>({
    resolver: assignNumbersSchemaResolver,
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    watch,
    reset,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;
  const isLoading = isSubmitting;
  const watchedYear = watch('year');
  const watchedSelection = watch('selection');

  const [memberLowest, memberHighest] = useMemo(
    () => nextMemberNumber(watchedYear),
    [nextMemberNumber, watchedYear]
  );
  const targetedDonors = useMemo(() => {
    switch (watchedSelection) {
      case 'withoutNumber': {
        return donors.filter((d) => !d.memberNumbers[watchedYear]);
      }
      case 'withNumber': {
        return donors.filter((d) => d.memberNumbers[watchedYear]);
      }
      case 'all': {
        return donors.filter((d) => d.type !== 'business');
      }
      case 'copy': {
        return donors.filter((d) => d.memberNumbers[watchedYear - 1]);
      }
      default: {
        return [];
      }
    }
  }, [donors, watchedYear, watchedSelection]);
  const watchedYearNumbers = useMemo(
    () => donors.filter((d) => d.memberNumbers[watchedYear]).length,
    [donors, watchedYear]
  );

  useEffect(() => {
    if (!watchedYearNumbers && watchedSelection === 'withNumber') {
      setValue('selection', null);
    }
  }, [setValue, watchedYearNumbers, watchedSelection]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const calcNumbers = (data: AssignNumbersSchemaForm) => {
    const ordered =
      data.order === 'alphabetical' ? orderBy(targetedDonors, 'lastName') : shuffle(targetedDonors);

    if (data.selection === 'copy') {
      // order of donors here makes no difference
      return ordered.reduce((acc, d) => ({ ...acc, [d.id]: d.memberNumbers[data.year - 1] }), {});
    } else if (data.selection === 'withoutNumbers') {
      // assign numbers to all donors without the number in the given year
      // meaning: some donors might have numbers already and we need to check against that
      const numbersInUse = compact(donors.map((d) => d.memberNumbers[data.year]));
      let nextNumber = data.start;
      return ordered.reduce((acc: any, d: Donor.Donor) => {
        while (numbersInUse.includes(nextNumber)) {
          nextNumber++;
        }
        return { ...acc, [d.id]: nextNumber++ };
      }, {});
    } else if (data.selection === 'withNumbers') {
      // assign numbers to all donors that already have it in this year
      // meaning we are doing an overwrite so no danger
      let nextNumber = data.start;
      return ordered.reduce((acc: any, d: Donor.Donor) => ({ ...acc, [d.id]: nextNumber++ }), {});
    } else {
      // assign numbers to all donors (except business), but businesses could also have a number assigned
      // meaning: check against usage of business donors
      const numbersInUse = compact(
        donors.filter((d) => d.type === 'business').map((d) => d.memberNumbers[data.year])
      );
      let nextNumber = data.start;
      return ordered.reduce((acc: any, d: Donor.Donor) => {
        while (numbersInUse.includes(nextNumber)) {
          nextNumber++;
        }
        return { ...acc, [d.id]: nextNumber++ };
      }, {});
    }
  };

  const handleSave = async (data: AssignNumbersSchemaForm) => {
    setConfirming(false);
    if (!canEdit) return;

    if (!targetedDonors.length) {
      setError('afterSubmit', { message: 'No donors to assign numbers to!' });
      return;
    }

    if (!org) {
      setError('afterSubmit', { message: '[internal] Missing org!' });
      return;
    }

    delete data.afterSubmit;
    try {
      await assignMemberNumbers({
        orgId: org.id,
        year: data.year,
        donors: calcNumbers(data),
      });

      enqueueSnackbar('Member/Envelope #s assigned!');
      handleClose();
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
    setConfirming(false);
  };

  return (
    <Dialog
      open={true}
      title="Assign new Member/Envelope #s to multiple donors"
      maxWidth="md"
      isDirty={isDirty}
      isLoading={isLoading}
      methods={methods}
      actions={
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={isLoading}
            disabled={!isDirty}
            onClick={handleSubmit(() => setConfirming(true))}
          >
            Assign Numbers to Donors
          </LoadingButton>
        </Stack>
      }
    >
      <ConfirmDialog
        open={isConfirming}
        onClose={() => setConfirming(false)}
        onConfirm={handleSubmit(handleSave)}
        title="Are you sure?"
      >
        <Typography>
          This action will assign numbers to {targetedDonors.length} donors in {watchedYear} and
          cannot be reversed.
        </Typography>
      </ConfirmDialog>

      <Stack spacing={2}>
        <RHFRadioGroup
          name="year"
          label="Which year would you like to assign numbers for?"
          options={[
            {
              value: currentYear,
              label: `${currentYear}`,
            },
            {
              value: currentYear + 1,
              label: `${currentYear + 1}`,
            },
          ]}
        />

        <RHFRadioGroup
          name="selection"
          label="Which donors would you like to assign numbers to?"
          options={[
            {
              value: 'withoutNumber',
              label: (
                <Typography variant="body2">
                  Donors who <strong>don't yet have</strong> a number in {watchedYear}
                </Typography>
              ),
            },
            {
              value: 'withNumber',
              label: (
                <Typography variant="body2">
                  Donors who <strong>already have</strong> a number in {watchedYear}
                </Typography>
              ),
              disabled: !watchedYearNumbers,
            },
            {
              value: 'all',
              label: (
                <Typography variant="body2">
                  <strong>All</strong> donors (excludes business donors) in {watchedYear}
                </Typography>
              ),
            },
            {
              value: 'copy',
              label: (
                <Typography variant="body2">
                  <strong>Copy</strong> donor numbers from {watchedYear - 1}
                </Typography>
              ),
            },
          ]}
        />

        {/* {!!prevYearNumbers && (
          <RHFCheckbox
            name="keepNumbers"
            label={`${prevYearNumbers} donor(s) have numbers in the prior year. Would you like to keep those same numbers for ${watchedYear}? `}
            sx={{ marginLeft: '-10px !important' }}
          />
        )} */}

        {watchedSelection !== 'copy' && (
          <>
            <RHFRadioGroup
              name="order"
              label="What ordering would you like for newly generated numbers?"
              options={[
                {
                  value: 'alphabetical',
                  label: 'Alphabetical by last name',
                },
                {
                  value: 'random',
                  label: 'Random',
                },
              ]}
            />

            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="body2">What number would you like to start with?</Typography>
              <Tooltip
                placement="top"
                title={`Lowest available: ${memberLowest}, Next highest: ${memberHighest}`}
              >
                <Box>
                  <RHFTextField
                    fullWidth={false}
                    name="start"
                    type="number"
                    size="small"
                    inputProps={{ maxLength: 4 }}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: 120 }}
                    disabled={isLoading}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </>
        )}
      </Stack>

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
    </Dialog>
  );
}
