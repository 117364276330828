import { useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { last, filter, orderBy, groupBy } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import { PageOrientation, PageSize } from '@typedefs/app';
import useNavigate from '@hooks/useNavigate';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '@pages/reports/ReportDocument';
import ReportLayout from '@pages/reports/ReportLayout';
import {
  AvailableSortOrder,
  SortOrder,
  sortByOrders,
  orderDescription,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToPreviousYearStart,
    fDateToYearStart,
    fDateToPreviousYearEnd,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    fDate,
    fLongDate,
    getDateFormat,
    fCurrency,
    fDateToISO,
    fReversedName,
  } = useFormat();
  const { org } = useOrg();
  const { donorsWithDonations } = useDonation();

  // ---------- form state
  const [dateFromPreviousPeriod, setDateFromPreviousPeriod] = useState<Date>(
    fDateToPreviousYearStart(new Date())
  );
  const [dateToPreviousPeriod, setDateToPreviousPeriod] = useState<Date>(
    fDateToPreviousYearEnd(new Date())
  );
  const [dateFrom, setDateFrom] = useState<Date>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('landscape');
  const [size, setSize] = useState<PageSize>('letter');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([{ availablesIndex: 0 }]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Name', field: (d) => d.name },
      { label: 'Last date', field: (d: any) => fDateToISO(d.lastDate) },
      { label: 'Last amount', field: 'lastAmount' },
      { label: 'Cons. years', field: 'conseqYears' },
      { label: 'Total years', field: 'totalYears' },
      { label: 'Last period $', field: 'lastPeriodAmount' },
      { label: 'To date $', field: 'toDateAmount' },
    ],
    [fDateToISO]
  );

  // Set matchingDonors to the donors who have donated in the previous period but not this period
  const matchingDonors = useMemo(() => {
    const donatedPreviousPeriod = filter(donorsWithDonations, (donor) => {
      const donationsInPreviousPeriod = filter(donor.donations, (d) =>
        isDateBetween(
          new Date(d.date),
          fDateToDayStart(dateFromPreviousPeriod),
          fDateToDayEnd(dateToPreviousPeriod)
        )
      );
      const donationsInCurrentPeriod = filter(donor.donations, (d) =>
        isDateBetween(new Date(d.date), fDateToDayStart(dateFrom), fDateToDayEnd(dateTo))
      );

      // true if there are donations in previous period, but not in current
      return !!donationsInPreviousPeriod.length && !donationsInCurrentPeriod.length;
    });

    return donatedPreviousPeriod;
  }, [
    donorsWithDonations,
    isDateBetween,
    fDateToDayStart,
    dateFromPreviousPeriod,
    fDateToDayEnd,
    dateToPreviousPeriod,
    dateFrom,
    dateTo,
  ]);

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 20 },
      { name: 'Email', width: 22 },
      { name: 'Last date', width: 14 },
      { name: 'Last amount', width: 8, align: 'right' },
      { name: 'Cons. Years #', width: 8, align: 'right' },
      { name: 'Total Years #', width: 8, align: 'right' },
      { name: 'Last period', width: 10, align: 'right' },
      { name: 'Given to date', width: 10, align: 'right' },
    ];

    const itemsUnsorted = matchingDonors.map((donor) => {
      const totalYears = orderBy(
        Object.keys(groupBy(donor.donations, (d) => new Date(d.date).getFullYear())).map((y) =>
          Number(y)
        )
      ).reverse();
      const donationsInPreviousPeriod = filter(donor.donations, (d) =>
        isDateBetween(
          new Date(d.date),
          fDateToDayStart(dateFromPreviousPeriod),
          fDateToDayEnd(dateToPreviousPeriod)
        )
      ).reduce((acc, d) => (d.amount || 0) + acc, 0);

      let latestYear = totalYears[0];
      const conseqYears = totalYears.slice(1).reduce((acc, year) => {
        if (latestYear - year - 1 === 0) {
          latestYear = year;
          return acc + 1;
        }

        return acc;
      }, 1);

      return {
        name: fReversedName(donor),
        email: donor.email || '',
        lastDate: new Date(donor.donationDate!),
        lastAmount: last(donor.donations)?.amount || 0,
        conseqYears: conseqYears, // consecutive years
        totalYears: totalYears.length, // total years
        lastPeriodAmount: donationsInPreviousPeriod, // last period
        toDateAmount: donor.donationsTotal, // given to date
      };
    });
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((s) => [
      s.name,
      s.email,
      fDate(s.lastDate),
      fCurrency(s.lastAmount),
      s.conseqYears.toString(),
      s.totalYears.toString(),
      fCurrency(s.lastPeriodAmount),
      fCurrency(s.toDateAmount),
    ]);

    return { header, items };
  }, [
    matchingDonors,
    sortOrders,
    availableSortOrders,
    fReversedName,
    isDateBetween,
    fDateToDayStart,
    dateFromPreviousPeriod,
    fDateToDayEnd,
    dateToPreviousPeriod,
    fDate,
    fCurrency,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleChangeDateFrom = (newDateFrom: Date) => {
    // const daysDiff = differenceInDays(dateTo, newDateFrom) + 1;
    // const previousPeriodFrom = sub(newDateFrom, { days: daysDiff });
    // setDateFromPreviousPeriod(previousPeriodFrom);
    // setDateToPreviousPeriod(sub(newDateFrom, { days: 1 }));
    setDateFrom(newDateFrom);
  };

  const handleChangeDateTo = (newDateTo: Date) => {
    // const daysDiff = differenceInDays(newDateTo, dateFrom) + 1;
    // const previousPeriodFrom = sub(dateFrom, { days: daysDiff });
    // setDateFromPreviousPeriod(previousPeriodFrom);
    setDateTo(newDateTo);
  };

  const handleChangeDateFromPrevious = (newDateFrom: Date) => {
    setDateFromPreviousPeriod(newDateFrom);
  };

  const handleChangeDateToPrevious = (newDateTo: Date) => {
    setDateToPreviousPeriod(newDateTo);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={`Donors with donations between ${fLongDate(dateFromPreviousPeriod)} and ${fLongDate(
        dateToPreviousPeriod
      )} but none between ${fLongDate(dateFrom)} and ${fLongDate(dateTo)}`}
      description={description}
      dateReport={new Date()}
      dateFrom={null}
      dateTo={null}
      orgName={org!.name}
      orderBy={orderDescription(sortOrders, availableSortOrders)}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Old Donors report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!isDateValid(dateFrom) || !isDateValid(dateTo) || !url || !!error}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'old_donors'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Stack mb={1}>
                <Typography variant="button" sx={{ textTransform: 'inherit' }}>
                  Previous Giving Period
                </Typography>
                <Typography variant="caption">
                  Select a date range for donors who previously gave in this period.
                </Typography>
              </Stack>
              <DesktopDatePicker
                format={getDateFormat()}
                label="From"
                value={dateFromPreviousPeriod}
                // ignoreInvalidInputs
                onChange={(v, context) =>
                  !context.validationError && v && handleChangeDateFromPrevious(v)
                }
              />

              <DesktopDatePicker
                format={getDateFormat()}
                label="To"
                value={dateToPreviousPeriod}
                onChange={(v, context) =>
                  !context.validationError && v && handleChangeDateToPrevious(v)
                }
              />
            </Stack>

            <Stack spacing={1} direction="column" width={'100%'}>
              <Stack mb={1}>
                <Typography variant="button" sx={{ textTransform: 'inherit' }}>
                  Current Non-Giving Period
                </Typography>
                <Typography variant="caption">
                  Select a date range to show donors who have not (yet) given a donation in this
                  period.
                </Typography>
              </Stack>
              <DesktopDatePicker
                format={getDateFormat()}
                label="From"
                value={dateFrom}
                // ignoreInvalidInputs
                onChange={(v, context) => !context.validationError && v && handleChangeDateFrom(v)}
              />

              <DesktopDatePicker
                format={getDateFormat()}
                label="To"
                value={dateTo}
                onChange={(v, context) => !context.validationError && v && handleChangeDateTo(v)}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
