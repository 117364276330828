import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router';
import { Alert, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { random, isUndefined } from 'lodash';

import { Role } from '@shared/types/organization';
import { tagSchemaResolver, TagSchemaForm } from '@/schemas';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useRole from '@hooks/useRole';
import * as analytics from '@fire/analytics';
import { Tag } from '@shared/types';
import colors from '@utils/colors';

import { RHFTextField } from '@components/hook-form';
import ConfirmDialog from '@components/ConfirmDialog';
import Dialog from '@components/Dialog';
import ColorPalette from './ColorPalette';

// ----------------------------------------------------------------------
type Props = {
  readonly tagId?: string;
  readonly open?: boolean;
  readonly onClose?: (tag?: Tag.Tag) => void;
};
// ----------------------------------------------------------------------
export default function TagDialog({ tagId, open, onClose }: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const { createTag, updateTag, deleteTag, getTagById, isTagInUse, tags } = useDonation();
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  // --------------- routing ---------------
  const handleClose = (newTag?: Tag.Tag) => {
    if (isUndefined(open)) {
      navigate(-1);
    } else {
      onClose?.(newTag);
    }
  };

  // --------------- effects ---------------
  const canEdit = hasAccess([Role.editor, Role.contributor, Role.donorOnly]);

  const id = params.tagId || tagId;

  const tag = useMemo(() => getTagById(id), [getTagById, id]);

  useEffect(() => tag && analytics.donation.tagView(), [tag]);

  const inUse = useMemo(() => !!tag && isTagInUse(tag.id), [isTagInUse, tag]);

  const canDelete = useMemo(() => canEdit && !!tag && !inUse, [canEdit, tag, inUse]);

  const defaultValues: TagSchemaForm = useMemo(() => {
    const randomColor = random(colors.length - 1);
    return {
      type: 'donor',
      name: tag?.name || '',
      description: tag?.description || '',
      colorText: tag?.colorText || colors[randomColor].colorText,
      colorBackground: tag?.colorBackground || colors[randomColor].colorBackground,
    };
  }, [tag]);

  // --------------- form ---------------
  const methods = useForm<TagSchemaForm>({
    resolver: tagSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = methods;
  const isLoading = isSubmitting || isDeleting;
  const watchedName = watch('name');
  const watchedColorText = watch('colorText');
  const watchedColorBackground = watch('colorBackground');

  const isDuplicate = useMemo(
    () => tags.some((c) => c.id !== tag?.id && c.name.toUpperCase() === watchedName.toUpperCase()),
    [tags, tag?.id, watchedName]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [open, reset, defaultValues]);

  // --------------- actions ---------------
  const handleError = (msg: string) => {
    enqueueSnackbar(msg, { variant: 'error' });
    setConfirmingDelete(false);
  };
  const handleDelete = async () => {
    if (!canEdit) {
      handleError('You lack permissions!');
      return;
    }
    if (!org) {
      handleError('[internal] Missing organization!');
      return;
    }
    if (!tag) {
      handleError('Missing tag to delete!');
      return;
    }
    if (isTagInUse(tag.id)) {
      handleError("Can't delete a tag in use!");
      return;
    }

    setConfirmingDelete(false);
    setDeleting(true);
    try {
      await deleteTag({ orgId: org.id, tagId: tag.id });
      handleClose();
    } catch (e) {
      handleError(e.message);
    }
    setDeleting(false);
  };

  const handleSave = async (data: TagSchemaForm) => {
    if (!canEdit) return;

    delete data.afterSubmit;
    try {
      if (!tag) {
        const newTag = await createTag({ orgId: org!.id, ...data });
        reset(defaultValues);

        enqueueSnackbar('Donor tag created');
        handleClose(newTag);
      } else {
        await updateTag({
          orgId: org!.id,
          tagId: tag.id,
          update: { orgId: org!.id, ...data },
        });

        enqueueSnackbar('Donor tag updated');
        handleClose();
      }
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      open={isUndefined(open) ? true : open}
      onClose={() => handleClose()}
      title={!tag ? 'Create tag' : 'Tag'}
      maxWidth="sm"
      isDirty={isDirty}
      isLoading={isLoading}
      methods={methods}
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          {canDelete ? (
            <LoadingButton color="error" onClick={() => setConfirmingDelete(true)}>
              Delete
            </LoadingButton>
          ) : (
            <div />
          )}
          {canEdit && (
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
              disabled={isDuplicate || (!isDirty && watchedName === defaultValues.name)}
              onClick={handleSubmit(handleSave)}
            >
              {!tag ? 'Create' : 'Save'}
            </LoadingButton>
          )}
        </Stack>
      }
    >
      <ConfirmDialog
        open={isConfirmingDelete}
        onClose={() => setConfirmingDelete(false)}
        onConfirm={handleDelete}
      />
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <RHFTextField
            autoFocus
            name="name"
            label="Name"
            size="small"
            disabled={isLoading || !canEdit}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 30 }}
            onChange={(e) => setValue('name', e.target.value, { shouldDirty: true })}
          />
        </Grid>

        <Grid item xs={3}>
          <ColorPalette
            colorText={watchedColorText}
            colorBackground={watchedColorBackground}
            onSelect={(cT, cB) => {
              setValue('colorText', cT, { shouldDirty: true });
              setValue('colorBackground', cB, { shouldDirty: true });
            }}
            disabled={isLoading || !canEdit}
          />
        </Grid>

        <Grid item xs={12}>
          <RHFTextField
            name="description"
            size="small"
            label="Description"
            InputLabelProps={{ shrink: true }}
            disabled={isLoading || !canEdit}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      </Grid>
      {inUse && watchedName !== defaultValues.name && (
        <Alert severity="info" variant="standard" sx={{ marginTop: 1, backgroundColor: 'white' }}>
          Clicking Save will update this Name immediately in all Donors using it.
        </Alert>
      )}
      {isDuplicate && (
        <Alert severity="error" sx={{ marginTop: 1 }}>
          You cannot save this because it has the same name as another Tag.
        </Alert>
      )}

      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      <Outlet />
    </Dialog>
  );
}
