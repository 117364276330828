import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Donation } from '@shared/types';
import { TReceiptVariables } from '@typedefs/donation';
import { groupBy, sortBy } from 'lodash';

// ----------------------------------------------------------------------
type Props = {
  readonly vars: TReceiptVariables;
  readonly donations: Donation.Donation[];
};

const styles = StyleSheet.create({
  row: { columnGap: 8, flexDirection: 'row' },
  heading: {
    fontFamily: 'Helvetica-Bold',
    borderBottom: '1px solid #000',
    paddingBottom: 2,
  },
  /* need the following on a view wrapping a text to get the text to align right, tried a bunch of other ways and none worked except for this! */
  alignRight: { display: 'flex', alignItems: 'flex-end' },
});

// ----------------------------------------------------------------------
export default function ReceiptSummaryTable({ vars, donations }: Props) {
  const grouped = groupBy(donations, 'categoryId');
  const itemsUnsorted = Object.keys(grouped).map((categoryId) => {
    const category = vars.getCategoryById(categoryId);
    const categoryTotal = grouped[categoryId].reduce((t, { amount }) => t + Number(amount), 0);
    return { category: category?.name, total: vars.fCurrency(categoryTotal) };
  });
  const items = sortBy(itemsUnsorted, (item) => item.category?.toUpperCase);
  return (
    <View style={{ marginTop: 16 }}>
      <View style={[styles.row, { marginBottom: 2 }]}>
        <Text style={[styles.heading, { width: 160 }]}>Category</Text>
        <View style={[styles.heading, styles.alignRight, { width: 75 }]}>
          <Text>Amount</Text>
        </View>
      </View>

      <View>
        {items.map((item, index) => (
          <View key={index} style={styles.row}>
            <Text style={{ width: 160 }}>{item.category}</Text>
            <View style={[styles.alignRight, { width: 75 }]}>
              <Text>{item.total}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}
