import { useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { filter, sortBy, groupBy, flatten } from 'lodash';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import { PageOrientation, PageSize } from '@typedefs/app';
import useNavigate from '@hooks/useNavigate';

import Dialog from '@components/Dialog';
import {
  PdfTableData,
  PdfTableHeaderProps,
  PdfTableSubtotal,
  PdfTableSummaryField,
} from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '@pages/reports/ReportDocument';
import ReportLayout from '@pages/reports/ReportLayout';
import { TableDateRange } from '@components/table';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    fCurrency,
    fReversedName,
  } = useFormat();
  const { org } = useOrg();
  const { donorsWithDonations, getCategoryById } = useDonation();

  // ---------- form state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  // ---------- memo
  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  // Set matchingDonors to the donors with donations in the date range
  const matchingDonors = useMemo(() => 
    filter(donorsWithDonations, (donor) => 
      !!donor.donations.find((d) =>
        isDateBetween(
          new Date(d.date),
          fDateToDayStart(dateFrom || minDateFrom),
          fDateToDayEnd(dateTo || maxDateTo)
        )
      )), [
    donorsWithDonations,
    isDateBetween,
    fDateToDayStart,
    dateFrom,
    minDateFrom,
    fDateToDayEnd,
    dateTo,
    maxDateTo,
  ]);

  const donorsInDateRange = useMemo(() =>
    matchingDonors.map((d) => ({ ...d, donations: d.donations.filter((o) => isDateBetween(
      new Date(o.date),
      fDateToDayStart(dateFrom || minDateFrom),
      fDateToDayEnd(dateTo || maxDateTo)
    )) }))
  , [dateFrom, dateTo, fDateToDayEnd, fDateToDayStart, isDateBetween, matchingDonors, maxDateTo, minDateFrom]);

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 25 },
      { name: 'Category', width: 30 },
      { name: 'Amount ($)', width: 15, align: 'right' },
    ];

    let subtotals: PdfTableSubtotal[] = [];
    let grandTotal = 0;
    let afterRowNumber = -1;
    const sortedDonors = sortBy(donorsInDateRange, (d) => fReversedName(d));
    const itemsUnsorted: string[][] = flatten(
      sortedDonors.map((donor) => {
        const reversedName = fReversedName(donor);
        const grouped = groupBy(donor.donations, 'categoryId');
        let donorTotal = 0;
        const categoryIds = Object.keys(grouped);
        afterRowNumber += categoryIds.length;
        const rows = categoryIds.map((categoryId: string) => {
          const categoryTotal = grouped[categoryId].reduce(
            (t, { amount }) => t + Number(amount),
            0
          );
          donorTotal += categoryTotal;
          grandTotal += categoryTotal;
          return [
            reversedName,
            getCategoryById(categoryId)?.name,
            fCurrency(categoryTotal),
          ] as string[];
        });
        if (donorsInDateRange.length > 1) {
          subtotals.push({
            afterRowNumber: afterRowNumber,
            subtotal: [
              { columns: 2, text: `Subtotal for ${reversedName}` },
              { columns: 1, text: `${fCurrency(donorTotal)}` },
            ],
          });
        }
        return sortBy(rows, (row) => row[1]);
      })
    );
    const items = sortBy(itemsUnsorted, (item) => item[0]);

    const summary: PdfTableSummaryField[] = [
      { columns: 2, text: 'Grand Total' },
      { columns: 1, text: `${fCurrency(grandTotal)}` },
    ];

    return { header, items, summary, subtotals };
  }, [donorsInDateRange, fReversedName, getCategoryById, fCurrency]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={'Donations by Donor by Category'}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      orderBy=""
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Summary by Donor and Category report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !url ||
                !!error
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'summary_donor_category'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />

            <Stack spacing={1} direction="column" width={'100%'}>
              <Stack mb={1}>
                <Typography variant="button" sx={{ textTransform: 'inherit' }}>
                  Change date range
                </Typography>
              </Stack>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
