import {
  Stack,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';

import { ImportType } from '@/schemas/importData';
import CSVFile from './CSVFile';
import { parse } from 'csv-parse/browser/esm/sync';
import { range } from 'lodash';
import { emptyStepData, TStepProps } from './useSteps';
import { ChangeEvent } from 'react';

// ----------------------------------------------------------------------
export default function StepGetFile({
  stepData,
  updateStepData,
  setError,
  isValid,
  setIsValid,
}: TStepProps) {
  const { hasHeaders, importType } = stepData;

  // if there are no headers in the file, create them to be like Excel column names:
  // Column A to Column Z, then Column AA etc.
  const createColumnHeaders = (len: number) => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return range(len).map((n) => {
      const extra = Math.trunc(n / 26);
      const mod = n % 26;
      return `Column ${extra ? letters[extra - 1] : ''}${letters[mod]}`;
    });
  };

  const clearAll = () =>
    updateStepData({
      ...emptyStepData,
      importType: !!stepData.paywallWarning ? ImportType.onlyDonations : ImportType.both,
    });

  const handleUpdateFile = async (file: File | undefined) => {
    if (!file) {
      clearAll();
      setIsValid(false);
      return;
    }

    const text = await file.text();
    let newData = [];
    try {
      newData = await parse(text, { skip_empty_lines: true });
    } catch (e) {
      setError(`${file.name} - Error parsing CSV file. Please try again.`);
      clearAll();
      return;
    }
    if (newData.length === 0 || (newData.length === 1 && hasHeaders)) {
      setError(
        `${file.name} - CSV File ${newData.length > 0 ? 'only contains a header row' : 'is empty'}. Please try again.`
      );
      clearAll();
      return;
    }
    updateStepData({
      file: file,
      data: newData,
      mapFields: [],
      headers: calcHeaders(file, newData, hasHeaders),
    });
    setError('');
    setIsValid(true);
  };

  const calcHeaders = (file: File | undefined, data: string[][] | undefined, checked: boolean) => {
    const headers = !file || !data ? [] : checked ? data[0] : createColumnHeaders(data[0].length);
    return headers;
  };

  const handleClickHasHeaders = (event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    updateStepData({
      hasHeaders: checked,
      headers: calcHeaders(stepData.file, stepData.data, checked),
    });

  const handleChangeImportType = (event: SelectChangeEvent<ImportType>) => {
    const newImportType = event.target.value as ImportType;
    updateStepData({
      ...emptyStepData, // clear everything from later steps, except the following:
      file: stepData.file,
      data: stepData.data,
      hasHeaders: stepData.hasHeaders,
      headers: stepData.headers,
      importType: newImportType,
    });
  };

  return (
    <FormGroup>
      <Stack spacing={3}>
        <CSVFile
          currentFile={stepData.file}
          setCurrentFile={handleUpdateFile}
          isValid={isValid}
          setError={setError}
        />
        <FormControlLabel
          control={<Checkbox checked={hasHeaders} onChange={handleClickHasHeaders} />}
          label="File has a header row"
        />
        <FormControl>
          <InputLabel id="whatLabel">What to Import</InputLabel>
          <Tooltip title={stepData.paywallWarning} placement="right">
            <Select
              labelId="whatLabel"
              value={importType}
              label="What to Import"
              onChange={handleChangeImportType}
              sx={{ width: '50%' }}
            >
              <MenuItem disabled={!!stepData.paywallWarning} value={ImportType.both}>
                Both Donors and Donations
              </MenuItem>
              <MenuItem disabled={!!stepData.paywallWarning} value={ImportType.onlyDonors}>
                Only Donors
              </MenuItem>
              <MenuItem value={ImportType.onlyDonations}>Only Donations</MenuItem>
            </Select>
          </Tooltip>
        </FormControl>
      </Stack>
    </FormGroup>
  );
}
