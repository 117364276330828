import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { Stack, Alert, Divider, Card, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useOrg from '@hooks/useOrg';
import useRole from '@hooks/useRole';
import { Role } from '@shared/types/organization';
import useIsMountedRef from '@hooks/useIsMountedRef';
import { OrganizationGeneralSchemaForm, organizationGeneralSchemaResolver } from '@/schemas';
import { FormProvider, RHFAutocomplete, RHFTextField } from '@components/hook-form';
import { CountryList, StateList } from '@typedefs/org';
import useLocales from '@hooks/useLocales';

import DeleteOrg from './DeleteOrg';

// ----------------------------------------------------------------------
export default function General() {
  const { org, updateOrg } = useOrg();
  const { hasAccess } = useRole();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const canManage = hasAccess([Role.editor, Role.contributor]);

  const defaultValues: OrganizationGeneralSchemaForm = useMemo(
    () => ({
      name: org?.name || '',
      registrationNumber: org?.registrationNumber || '',
      phone: org?.phone || '',
      address: {
        address1: org?.address.address1 || '',
        address2: org?.address.address2 || '',
        city: org?.address.city || '',
        state: StateList[org!.address.country].find((c) => c.value === org?.address.state)!,
        postalCode: org?.address.postalCode || '',
        country: CountryList.find((c) => c.value === org?.address.country)!,
      },
    }),
    [org]
  );

  const methods = useForm<OrganizationGeneralSchemaForm>({
    resolver: organizationGeneralSchemaResolver,
    defaultValues,
  });

  const {
    watch,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = methods;
  const watchCountry = watch('address.country');

  // --------------- effects ---------------
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- effects ---------------
  const onSubmit = async (data: OrganizationGeneralSchemaForm) => {
    if (!org || !canManage) return;

    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          ...data,
          // NOTE: country can't be changed
          address: {
            ...data.address,
            state: data.address.state.value,
            country: org.address.country,
          },
        },
      });

      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <Stack spacing={2}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField
                required
                name="name"
                label="Organization name"
                inputProps={{ maxLength: 60 }}
              />
              <RHFTextField
                required={watchCountry.value === 'ca'}
                name="registrationNumber"
                label="Charitable registration number"
                inputProps={{ maxLength: 40 }}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField
                name="phone"
                type="tel"
                label="Phone"
                disabled={isSubmitting}
                inputProps={{ maxLength: 20 }}
              />
              <Box width="100%" />
            </Stack>

            <Divider />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField
                required
                name="address.address1"
                label="Address"
                inputProps={{ maxLength: 40 }}
              />
              <RHFTextField
                name="address.address2"
                label="Address"
                inputProps={{ maxLength: 40 }}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField
                required
                name="address.city"
                label="City"
                inputProps={{ maxLength: 40 }}
              />
              <RHFAutocomplete
                required
                name="address.state"
                label={t('State')}
                fullWidth
                freeSolo={false}
                options={StateList[watchCountry.value]}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
              <RHFTextField
                required
                name="address.postalCode"
                label={t('Zip Code')}
                inputProps={{ maxLength: 12 }}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFAutocomplete
                required
                disabled
                name="address.country"
                label="Country"
                fullWidth
                freeSolo={false}
                options={CountryList}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
              <Box width="100%" />
            </Stack>

            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" spacing={2}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isDirty}
              >
                Update
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
      </FormProvider>

      <DeleteOrg />
    </Stack>
  );
}
