import { ReactNode, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import PATHS, { getPath } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import LoadingScreen from '@components/LoadingScreen';

// ----------------------------------------------------------------------
type Props = { children?: ReactNode };
// ----------------------------------------------------------------------
export default function OrgsGuard({ children }: Props) {
  const location = useLocation();
  const { isInitialized, orgs, orgId, org } = useOrg();

  const underOrgPath = useMemo(
    () => orgs.some((org) => location.pathname.includes(org.id)),
    [orgs, location.pathname]
  );

  if (!isInitialized) {
    return <LoadingScreen withLoader id="orgs-guard" />;
  }

  if (!orgs.length && location.pathname !== PATHS.createOrganization) {
    return <Navigate to={PATHS.createOrganization} replace />;
  }

  // if there are orgs available, but none selected, redirect to first one
  // unless any available org path is already selected
  if (orgs.length && !org && !underOrgPath) {
    return <Navigate to={getPath(PATHS.org.root, { orgId: orgId || orgs[0].id })} replace />;
  }

  return children ? children : <Outlet />;
}
