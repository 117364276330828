import { TableRow, TableCell, Tooltip } from '@mui/material';

import { TColumn } from '@typedefs/app';

import { RecurringDonationListHeader, RecurringDonationListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<RecurringDonationListHeader>[];
  readonly item: RecurringDonationListItem;
  readonly onEdit: (donorId: string, donationId: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ item, onEdit, columns }: Props) {
  const cells = columns
    .filter((c) => c.visible || ['options'].includes(c.id))
    .map((column) => {
      const value = item[column.id] || '';
      const isFinalOccurrenceColumn = column.id === 'finalOccurrence';
      const cellContent = (
        <TableCell
          key={column.id}
          align={column.align}
          sx={{
            color: isFinalOccurrenceColumn && item.isExpired ? 'red' : 'inherit',
          }}
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );

      return isFinalOccurrenceColumn && item.isExpired ? (
        <Tooltip
          key={column.id}
          title="Red indicates a date in the past. This recurring donation has expired."
        >
          {cellContent}
        </Tooltip>
      ) : (
        cellContent
      );
    });
  return (
    <TableRow
      hover
      onClick={() => onEdit(item.id, item.id)}
      sx={{
        cursor: 'pointer',
        '&.MuiTableRow-hover:hover': {
          background: '#007b5514',
        },
        '& strong': { color: (theme) => theme.palette.secondary.light },
      }}
    >
      {cells}
    </TableRow>
  );
}
