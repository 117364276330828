const colors = [
  {
    colorText: '#464646',
    colorBackground: '#e7e7e7',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#c2c2c2',
  },

  {
    colorText: '#8a1c0a',
    colorBackground: '#f2b2a8',
  },

  {
    colorText: '#994a64',
    colorBackground: '#f691b2',
  },
  {
    colorText: '#7a2e0b',
    colorBackground: '#ffc8af',
  },
  {
    colorText: '#7a4706',
    colorBackground: '#ffdeb5',
  },
  {
    colorText: '#594c05',
    colorBackground: '#fbe983',
  },
  {
    colorText: '#684e07',
    colorBackground: '#fdedc1',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#ffad46',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#ff7537',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#fb4c2f',
  },
  {
    colorText: '#662e37',
    colorBackground: '#ebdbde',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#cca6ac',
  },
  {
    colorText: '#711a36',
    colorBackground: '#fbd3e0',
  },
  {
    colorText: '#3d188e',
    colorBackground: '#e3d7ff',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#b99aff',
  },
  {
    colorText: '#0d3472',
    colorBackground: '#b6cff5',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#4986e7',
  },
  {
    colorText: '#0d3b44',
    colorBackground: '#98d7e4',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#2da2bb',
  },
  {
    colorText: '#0b4f30',
    colorBackground: '#b3efd3',
  },
  {
    colorText: '#04502e',
    colorBackground: '#a2dcc1',
  },
  {
    colorText: '#094228',
    colorBackground: '#42d692',
  },
  {
    colorText: '#ffffff',
    colorBackground: '#16a765',
  },
];

export default colors;
