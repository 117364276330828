import { useMemo, useState } from 'react';
import { Autocomplete, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import filter from 'lodash/filter';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { Category } from '@shared/types';
import FilterCategories from '@components/table/TableFilters/FilterCategories';
import {
  AvailableSortOrder,
  SortOrder,
  orderDescription,
  sortByOrders,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import { sortBy, sumBy } from 'lodash';
import { TableDateRange } from '@components/table';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    fDate,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    isDateValid,
    fCurrency,
    fDateToISO,
    fFullName,
    fReversedName,
    fJoinWithConjunction,
  } = useFormat();
  const { donors, getDonorById, donations, getCategoryById, getPaymentMethod, getDupDonorTooltip } =
    useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [donorId, setDonorId] = useState('');
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>([]);
  const [filterDescription, setFilterDescription] = useState('');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([
    { availablesIndex: 0 },
    { availablesIndex: 2 },
  ]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Date', field: (d) => fDateToISO(d.date) },
      { label: 'Amount', field: 'amount' },
      { label: 'Category', field: (d) => d.category.toUpperCase() },
      { label: 'Payment Method', field: (d) => d.paymentMethod.toUpperCase() },
      { label: 'Description', field: (d) => d.description.toUpperCase() },
    ],
    [fDateToISO]
  );

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Date', width: 16 },
      { name: 'Amount ($)', width: 12, align: 'right' },
      { name: 'Category', width: 24 },
      { name: 'Payment Method', width: 24 },
      { name: 'Description', width: 24 },
    ];

    let filtered = filter(
      donations,
      (d) =>
        d.donorId === donorId &&
        isDateBetween(
          new Date(d.date),
          fDateToDayStart(dateFrom || minDateFrom),
          fDateToDayEnd(dateTo || maxDateTo)
        )
    );
    if (filterCategories.length) {
      const categoryIds = filterCategories.map((c) => c.id);
      filtered = filter(filtered, (d) => categoryIds.includes(d.categoryId));
      const categoryNames = filterCategories.map((c) => c.name);
      setFilterDescription(
        `Including only ${
          filterCategories.length === 1 ? 'category' : 'categories'
        } ${fJoinWithConjunction(categoryNames, 'and')}`
      );
    }
    const total = sumBy(filtered, 'amount');
    const itemsUnsorted = filtered.map((d) => ({
      date: new Date(d.date),
      amount: d.amount,
      category: getCategoryById(d.categoryId)?.name || '',
      paymentMethod: getPaymentMethod(d.paymentMethodId, d.paymentInfo),
      description: d.description || '',
    }));
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((s) => [
      fDate(s.date),
      fCurrency(s.amount),
      s.category,
      s.paymentMethod,
      s.description,
    ]);
    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `${items.length} donations` },
      { columns: 1, text: `${fCurrency(total)}` },
    ];
    return { header, items, summary };
  }, [
    donations,
    filterCategories,
    sortOrders,
    availableSortOrders,
    fCurrency,
    donorId,
    isDateBetween,
    fDateToDayStart,
    dateFrom,
    minDateFrom,
    fDateToDayEnd,
    dateTo,
    maxDateTo,
    fJoinWithConjunction,
    getCategoryById,
    getPaymentMethod,
    fDate,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={`Donations for ${fFullName(getDonorById(donorId))}`}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      filterDescription={filterDescription}
      orderBy={orderDescription(sortOrders, availableSortOrders)}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Single Donor Donations report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !blob ||
                !!error ||
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !donorId
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'single_donor'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Autocomplete
              fullWidth
              autoComplete
              size="small"
              disableClearable
              value={getDonorById(donorId)}
              onChange={(e, newValue) => {
                setDonorId(newValue!.id);
              }}
              options={sortBy(donors, (d) => fReversedName(d).toUpperCase())}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField label="Select donor" {...params} />}
              getOptionLabel={(option) => fReversedName(option)}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  <Tooltip title={getDupDonorTooltip(option)}>
                    <Typography>{fReversedName(option) || ''}</Typography>
                  </Tooltip>
                </li>
              )}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
            <FilterCategories
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
