import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { TReceiptVariables } from '@typedefs/donation';
import { compact } from 'lodash';

const MAX_WIDTH = 160;
const MAX_HEIGHT = 60;
const styles = StyleSheet.create({
  header: {
    position: 'relative',
    zIndex: 1,
    marginTop: 24,
    paddingTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    zIndex: -1,
    marginTop: '-26px',
    maxWidth: MAX_WIDTH,
    maxHeight: MAX_HEIGHT,
  },
  divider: {
    width: '100%',
    borderBottom: '1px solid #000',
    marginTop: 8,
    marginBottom: 12,
  },
});

// ----------------------------------------------------------------------
type Props = {
  vars: TReceiptVariables;
};
// ----------------------------------------------------------------------
export default function ReceiptHeader({ vars }: Props) {
  const addrLine3 = (
    (vars['org.address'].city + ' ' + vars['org.address'].state).trim() +
    '  ' +
    vars['org.address'].postalCode
  ).trim();
  const addressLine = compact([
    vars['org.address'].address1,
    vars['org.address'].address2,
    addrLine3,
  ]).join(', ');

  let logoWidth = vars['signatory.signature']?.width! / 2 || 0;
  let logoHeight = vars['signatory.signature']?.height! / 2 || 0;
  const wr = MAX_WIDTH / logoWidth;
  const wh = MAX_HEIGHT / logoHeight;
  if (wr < 1 || wh < 1) {
    if (wr < wh) {
      logoWidth = MAX_WIDTH;
      logoHeight = logoHeight * wr;
    } else {
      logoHeight = MAX_HEIGHT;
      logoWidth = logoWidth * wh;
    }
  }

  return (
    <>
      <View style={styles.header}>
        <View>
          <Text style={{ fontSize: 20, fontFamily: 'Helvetica-Bold' }}>{vars['org.name']}</Text>
          <Text>
            {addressLine} {vars['org.phone']}
          </Text>
        </View>

        {vars['org.logo'] ? (
          <Image
            src={vars['org.logo'].url}
            style={{
              ...styles.logo,
              width: vars['org.logo'].width / 2,
              height: vars['org.logo'].height / 2,
            }}
          />
        ) : null}
      </View>

      <View style={styles.divider} />
    </>
  );
}
