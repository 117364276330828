import { BaseKeys, Category, Donor, Meta, PaymentMethod } from '../index';
export * from './requests';

// ----------------------------------------------------------------------
export type Donation = {
  readonly id: string;
  readonly _meta: Meta;

  // base fields
  readonly orgId: string;
  readonly donorId: string;
  readonly date: string | Date;
  readonly amount: number;
  readonly paymentMethodId?: string;
  readonly paymentInfo: string; // required for CA if GIK paymentMethod
  readonly categoryId: string;
  readonly notes: string;

  // additional properties
  readonly withAdvantage: boolean;
  readonly amountEligible?: number;
  readonly amountAdvantage?: number;
  readonly advantageDescription?: string; // required for CA if withAdvantage

  readonly appraiserName?: string;
  readonly appraiserAddress?: string;

  readonly description?: string; // shown in receipt summary if it exists

  // receipt
  readonly nonReceiptable?: boolean; // not able to issue for tax return
  // donation can be part of multiple receipts
  // if donation under any receipt is changed, receipt is invalidated - and new one has to be issued
  readonly receiptIds: string[];
  readonly corrections: Correction[];
  readonly recurringDonationId?: string; // id of the recurring donation that created this donation
};

export type DonationBatchUpdateForm = {
  readonly id?: string | null | undefined;
  readonly donor: Donor.Donor | null;
  readonly amount: number;
  readonly category: Category.Category | null;
  readonly paymentMethod: PaymentMethod.PaymentMethod | null;
  readonly paymentInfo: string;
  readonly isExisting?: boolean;
};

type Correction = {
  readonly date: string;
  readonly receiptId: string;
  readonly change: Partial<Omit<Donation, BaseKeys>>;
};

export enum PaymentMethodInitialList {
  cash = 'cash',
  check = 'check',
  ach = 'ach',
  canadaHelps = 'CanadaHelps',
  credit = 'credit',
  debit = 'debit',
  directDebit = 'direct_debit',
  eft = 'eft',
  etransfer = 'e-Transfer',
  giftInKind = 'gift_in_kind',
  master = 'master',
  moneyOrder = 'money_order',
  nonReceiptable = 'non_receiptable',
  preReceiptable = 'pre-receiptable',
  pac = 'pac',
  par = 'par',
  paypal = 'paypal',
  visa = 'visa',
}
