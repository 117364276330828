import { useCallback } from 'react';
import { useSelector } from '@redux/store';

import useAuth from '@hooks/useAuth';
import { Role } from '@shared/types/organization';

// ----------------------------------------------------------------------
const useRole = () => {
  const { user, claims } = useAuth();
  const { org } = useSelector((s) => s.org);

  const withinOrg = !!org;
  const role = user && org?.users[user.id]?.role;

  const hasAccess = useCallback(
    (roles: Role[]) => (role && [Role.owner, ...roles].includes(role)) || claims?.admin || false,
    [role, claims]
  );

  return {
    withinOrg,
    role,
    hasAccess,
  };
};

export default useRole;
