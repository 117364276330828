import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------
const PATHS = {
  root: '/',

  // auth
  login: '/login',
  logout: '/logout',
  register: '/register',
  verify: '/verify',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  confirmEmail: '/confirm-email/:tokenId',

  // general
  createOrganization: '/create-organization',
  account: '/account',
  permissionDenied: '/permission-denied',
  p404: '/404',

  // org protected
  org: {
    root: '/:orgId',
    overview: '/:orgId/overview',

    donors: {
      root: '/:orgId/donors',
      export: '/:orgId/donors/export',
      saveView: '/:orgId/donors/save-view',
      manageViews: '/:orgId/donors/manage-views',
      manageView: '/:orgId/donors/manage-views/:viewId',

      donor: {
        create: '/:orgId/donors/create',
        edit: '/:orgId/donors/:donorId',

        donation: {
          create: '/:orgId/donors/:donorId/create',
          edit: '/:orgId/donors/:donorId/:donationId',
        },
        pledge: {
          create: '/:orgId/donors/:donorId/pledgeCreate',
          edit: '/:orgId/donors/:donorId/pledge/:pledgeId',
        },
      },
      settings: {
        root: '/:orgId/donors/settings',
        donors: {
          tags: '/:orgId/donors/settings/tags',
          tagCreate: '/:orgId/donors/settings/tags/create',
          tagsEdit: '/:orgId/donors/settings/tags/:tagId',
          customFields: '/:orgId/donors/settings/custom-fields',
          memberEnvelope: '/:orgId/donors/settings/member-envelope',
          memberEnvelopeAssign: '/:orgId/donors/settings/member-envelope/assign-numbers',
        },
      },
    },

    donations: {
      root: '/:orgId/donations',
      export: '/:orgId/donations/export',
      saveView: '/:orgId/donations/save-view',
      manageViews: '/:orgId/donations/manage-views',
      manageView: '/:orgId/donations/manage-views/:viewId',
      create: '/:orgId/donations/create',
      batch: '/:orgId/donations/donation/batch',
      edit: '/:orgId/donations/:donationId',

      recurring: '/:orgId/donations/recurring-donation/create',
      recurringDonationEdit: '/:orgId/donations/settings/recurring/:recurringDonationId',
      donationsPaymentMethod: '/:orgId/donations/settings/paymentMethod/:paymentMethodId',
      donationsCategory: '/:orgId/donations/settings/category/:catId',

      settings: {
        root: '/:orgId/donations/settings',
        categories: '/:orgId/donations/settings/categories',
        categoriesCreate: '/:orgId/donations/settings/categories/create',
        categoriesEdit: '/:orgId/donations/settings/categories/:catId',

        paymentMethods: '/:orgId/donations/settings/payment-methods',
        PaymentMethodsCreate: '/:orgId/donations/settings/payment-methods/create',
        PaymentMethodsEdit: '/:orgId/donations/settings/payment-methods/:paymentMethodId',

        recurringDonations: '/:orgId/donations/settings/recurring-donations',
        recurringDonationsCreate: '/:orgId/donations/settings/recurring-donations/create',
        recurringDonationsEdit:
          '/:orgId/donations/settings/recurring-donations/:recurringDonationId',

        pledges: '/:orgId/donations/settings/pledges',
      },
    },

    import: {
      root: '/:orgId/import/:source',
    },

    reports: {
      root: '/:orgId/reports',
      report: '/:orgId/reports/:reportId',
    },

    receipts: {
      root: '/:orgId/receipts',
      export: '/:orgId/receipts/export',
      view: '/:orgId/receipts/:receiptId',
      create: {
        root: '/:orgId/receipts/create',
        // edit the donor after click from Receipt Recipients
        donor: '/:orgId/receipts/create/donor/:donorId',
        donation: '/:orgId/receipts/create/donor/:donorId/:donationId',
      },
      reissue: {
        root: '/:orgId/receipts/reissue/:receiptId',
        donor: '/:orgId/receipts/reissue/:receiptId/:donorId',
        donation: '/:orgId/receipts/reissue/:receiptId/:donorId/:donationId',
      },

      reissueBatch: {
        root: '/:orgId/receipts/reissue/batch',
        receipt: '/:orgId/receipts/reissue/batch/:receiptId',
      },
      settings: {
        receipts: '/:orgId/receipts/settings',
      },
    },

    settings: {
      root: '/:orgId/settings',
      account: {
        root: '/:orgId/settings/account',
        general: '/:orgId/settings/account/general',
        security: '/:orgId/settings/account/security',
      },
      org: {
        root: '/:orgId/settings/org',
        general: '/:orgId/settings/org/general',
        preferences: '/:orgId/settings/org/preferences',
        users: '/:orgId/settings/org/users',
        billing: '/:orgId/settings/org/billing',
        donors: '/:orgId/settings/org/donors',
        donations: '/:orgId/settings/org/donations',
        receipts: '/:orgId/settings/org/receipts',
      },
    },

    prototype: {
      receipts: '/:orgId/proto/receipts',
      editor: '/:orgId/proto/editor',
    },
  },
};

export default PATHS;

// ----------------------------------------------------------------------
export function getPath(
  baseRoute: string,
  dataMap: { [key: string]: string | undefined | null }
): string {
  let route = baseRoute;

  Object.keys(dataMap).forEach((key) => {
    const value = dataMap[key];
    if (value) {
      route = route.replace(`:${key}`, value);
    }
  });

  return route;
}

// ----------------------------------------------------------------------
export function isExternalLink(path: string) {
  return path.includes('http');
}

// ----------------------------------------------------------------------
export function getActive(path: string, pathname: string) {
  const isMatch = !!matchPath({ path, end: false }, pathname);
  return isMatch;
}
