import { Box, Card, Divider, Stack } from '@mui/material';

import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';

import EditorTheme from './theme';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
// import StatePlugin from './plugins/StatePlugin';
import './editor.css';

// ----------------------------------------------------------------------
type Props = {
  readonly defaultState: string;
  readonly onChange: (htmlString: string, stateString: string) => void;
  readonly onReset: (htmlString: string) => void;
};
// ----------------------------------------------------------------------
export default function Editor({ defaultState, onChange, onReset }: Props) {
  const userEditorState = '';

  const editorConfig: InitialConfigType = {
    namespace: 'editor',
    // The editor theme
    theme: EditorTheme,
    // Handling of errors during update
    onError(error) {
      console.error('Editor error: ', error);
      throw error;
    },
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, AutoLinkNode, LinkNode],
    editorState: userEditorState || defaultState,
  };

  return (
    <Card sx={{ p: 0.5, overflow: 'initial' }}>
      <LexicalComposer initialConfig={editorConfig}>
        {/* editor container */}
        <Stack
          sx={{
            borderRadius: '2px',
            minWidth: '240px',
            width: '100%',
            color: '#212B36;',
            position: 'relative',
            lineHeight: '20px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <ToolbarPlugin onChange={onChange} onReset={onReset} />
          <Divider />
          {/* editor inner */}
          <Box sx={{ background: (theme) => theme.palette.common.white, position: 'relative' }}>
            <RichTextPlugin
              contentEditable={
                // editor input
                <ContentEditable
                  className="editor-input"
                  style={{
                    minHeight: '250px',
                    resize: 'none',
                    fontSize: '15px',
                    tabSize: 1,
                    outline: 0,
                    padding: '15px 10px',
                  }}
                />
              }
              placeholder={
                <Box
                  sx={{
                    color: '#999',
                    overflow: 'hidden',
                    position: 'absolute',
                    textOverflow: 'ellipsis',
                    top: '15px',
                    left: '10px',
                    fontSize: '15px',
                    userSelect: 'none',
                    display: 'inline-block',
                    pointerEvents: 'none',
                  }}
                >
                  Enter some text...
                </Box>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />

            {/* state change in ToolbarPlugin */}
            {/* <StatePlugin initialState={editorConfig.editorState} onChange={onChange} /> */}
          </Box>
        </Stack>
      </LexicalComposer>
    </Card>
  );
}
