import { Stack } from '@mui/material';
import SignatoryForm from './SignatoryForm';
import Logo from './Logo';
import SignatorySignature from './SignatorySignature';

// ----------------------------------------------------------------------
export default function Receipts() {
  return (
    <Stack spacing={2}>
      <SignatoryForm />

      <Stack spacing={2} direction="row">
        <Logo />
        <SignatorySignature />
      </Stack>
    </Stack>
  );
}
