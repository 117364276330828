import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import PATHS from '@routes/paths';
import LoadingScreen from '@components/LoadingScreen';

// ----------------------------------------------------------------------
type GuestGuardProps = {
  children?: ReactNode;
};

// ----------------------------------------------------------------------
export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATHS.root} />;
  }

  if (!isInitialized) {
    return <LoadingScreen withLoader id="guest-guard" />;
  }

  return children ? <>{children}</> : <Outlet />;
}
