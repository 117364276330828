import { useCallback, useMemo } from 'react';
import { orderBy as _orderBy, cloneDeep, filter, isString } from 'lodash';
import Fuse from 'fuse.js';

import { TColumn } from '@typedefs/app';
import { View } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useView from '@hooks/useView';
import useOrg from '@hooks/useOrg';
import { ViewListHeader, ViewListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<ViewListHeader>[];
  readonly order: 'asc' | 'desc';
  readonly orderBy: ViewListHeader;
  readonly search: string;

  // filters
  readonly viewType: View.ViewType | '';
};
// ----------------------------------------------------------------------
export default function useData({ columns, search, order, orderBy, ...filters }: Props) {
  const { org } = useOrg();
  const { views } = useView();
  const { fDate } = useFormat();
  // We are forcing all columns to be searchable (though in this list they were already all searchable!)
  const searchableColumns = columns.filter((c) => c.visible /* && c.searchable */).map((c) => c.id);

  // ----- parsing -------
  const getCreator = useCallback(
    (userId: string) => org?.users[userId]?.displayName || '',
    [org?.users]
  );

  const parsedData = useMemo(
    () =>
      views.map(
        (view): ViewListItem => ({
          id: view.id,
          type: view.type,
          typeDisplay: view.type === 'donor' ? 'Donors' : 'Donations',
          name: view.name,
          description: view.description,
          createdBy: getCreator(view._meta.createdBy),
          createdAt: new Date(view._meta.createdAt),
          createdDisplay: fDate(view._meta.createdAt),
        })
      ),
    [fDate, getCreator, views]
  );

  // ----- FILTERING -------
  const filteredData = useMemo(() => applyFilters(parsedData, filters), [parsedData, filters]);

  // ----- SEARCHING -------
  const fuse = useMemo(
    () =>
      new Fuse(filteredData, {
        keys: searchableColumns,
        includeMatches: true,
        includeScore: false,
        threshold: 0.2,
        shouldSort: false,
      }),
    [filteredData, searchableColumns]
  );

  const searchedData = useMemo(
    () =>
      search
        ? fuse.search(search).map(({ item, matches, score }) => {
            let clone = cloneDeep(item) as any;
            matches?.forEach(({ key, indices }) => {
              let prop = clone[key as string];
              if (isString(prop)) {
                [...indices].reverse().forEach(([start, finish]) => {
                  prop = prop.substring(0, finish + 1) + '</strong>' + prop.substring(finish + 1);
                  prop = prop.substring(0, start) + '<strong>' + prop.substring(start);
                });
                clone[key as string] = prop;
              }
            });

            return clone as ViewListItem;
          })
        : ((fuse as any)._docs as ViewListItem[]),
    [fuse, search]
  );

  // ----- ORDERING -------
  return useMemo(() => {
    switch (orderBy) {
      case 'createdDisplay':
        return _orderBy(searchedData, (f) => f.createdAt.getTime(), order);
      default:
        return _orderBy(searchedData, orderBy, order);
    }
  }, [searchedData, orderBy, order]);
}

// ----------------------------------------------------------------------
type FilterProps = Omit<Props, 'columns' | 'search' | 'order' | 'orderBy'>;
function applyFilters(views: ViewListItem[], dataProps: FilterProps): ViewListItem[] {
  return filter(views, (view) => [applyTypeFilter(view, dataProps.viewType)].every(Boolean));
}

// -----
function applyTypeFilter(view: ViewListItem, viewType: View.ViewType | '') {
  return viewType ? view.type === viewType : true;
}
