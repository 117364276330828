import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColumnFilterOperator, TColumn } from '@typedefs/app';

// ----------------------------------------------------------------------
const TableFilterSchema = Yup.object().shape({
  column: Yup.object().required('Column is required').nullable(),
  operator: Yup.object().required('Operator is required'),
  value: Yup.string(),
  valueDate: Yup.date(),
});

export const tableFilterSchemaResolver = yupResolver(TableFilterSchema);
export type TableFilterSchemaForm = {
  column: TColumn | null;
  operator: { value: ColumnFilterOperator; label: string } | null;
  value?: string;
  valueDate?: Date;
};
