import { ReactNode, useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import useOrg from '@hooks/useOrg';
import PATHS from '@routes/paths';
import useLocales from '@hooks/useLocales';
import LoadingScreen from '@components/LoadingScreen';

// ----------------------------------------------------------------------
type Props = { children?: ReactNode };
// ----------------------------------------------------------------------
export default function OrgGuard({ children }: Props) {
  const params = useParams();
  const { currentLang, changeLanguage } = useLocales();
  const { orgs, org, orgInitialized, switchOrg, resetOrg } = useOrg();

  useEffect(() => {
    if (params.orgId) {
      switchOrg({ orgId: params.orgId });
    }
  }, [switchOrg, params.orgId]);

  useEffect(() => {
    if (orgInitialized && !org) {
      resetOrg();
      // navigate done in DOM to prevent screen flickering
    }
  }, [resetOrg, orgInitialized, org]);

  useEffect(() => {
    if (org?.language && org.language !== currentLang.value) {
      changeLanguage(org.language);
    }
  }, [changeLanguage, org?.language, currentLang.value]);

  // ---------------
  if (!orgs.length) {
    return <Navigate to={PATHS.root} />;
  }

  if (orgInitialized && !org) {
    return <Navigate to={PATHS.root} />;
  }

  // ---------------
  if (!orgInitialized) {
    return <LoadingScreen withLoader id="org-guard" />;
  }

  return children ? children : <Outlet />;
}
