import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const ViewSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().optional(),
});

export const viewSchemaResolver = yupResolver(ViewSchema);
export type ViewSchemaForm = {
  name: string;
  description: string;

  afterSubmit?: string;
};
