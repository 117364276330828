import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const PaymentMethodSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

export const paymentMethodSchemaResolver = yupResolver(PaymentMethodSchema);
export type PaymentMethodSchemaForm = {
  name: string;

  afterSubmit?: string;
};
