import { List, StackProps, Stack } from '@mui/material';

import navConfig, { TNavConfig } from '../../config';
import NavList from './NavList';
import { ListSubheaderStyle } from './styles';
import useRole from '@hooks/useRole';

// ----------------------------------------------------------------------
interface NavSectionProps extends StackProps {
  isCollapse?: boolean;
}

// ----------------------------------------------------------------------
export default function NavbarSection({ isCollapse, ...other }: NavSectionProps) {
  const { hasAccess } = useRole();

  const renderGroup = (group: TNavConfig) => {
    const items = group.items.filter((item) => hasAccess(item.roles || []));

    return (
      !!items.length && (
        <List key={group.key} disablePadding sx={{ px: 2 }}>
          {group.subheader && (
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>
          )}

          {items.map((item) => (
            <NavList
              key={item.title + item.path}
              data={item}
              depth={1}
              hasChildren={!!item.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      )
    );
  };

  return (
    <Stack flexDirection="column" {...other} mt={{ xs: 1, xl: 3 }}>
      {navConfig.map(renderGroup)}
    </Stack>
  );
}
