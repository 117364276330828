// Render the default category drop-down and process changes in it

import { Typography } from '@mui/material';
import { Category } from '@shared/types';
import { sortBy } from 'lodash';
import useDonation from '@hooks/useDonation';
import { RHFAutocomplete } from '@components/hook-form';
import { renderCategoryOption } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';

type Props = {
  readonly defaultPledgeCat: Category.Category;
  readonly loading: boolean;
};

// special category for there being no default
export const allCategories = {
  id: '',
  name: '(All Categories)',
  description: '',
} as Category.Category;

export default function DefaultPledgeCategory({ defaultPledgeCat, loading }: Props) {
  const { categories } = useDonation();

  return (
    <>
      <Typography
        hidden={!loading}
        sx={{ border: 1, p: 1, width: '100%', maxWidth: '300px', fontWeight: 'bold' }}
      >
        Saving new default...
      </Typography>
      <RHFAutocomplete
        hidden={loading}
        name="defaultPledgeCategory"
        label="Default Pledge Category"
        autoComplete
        fullWidth={false}
        size="small"
        sx={{ pb: 1, width: '100%', maxWidth: '300px' }}
        disableClearable={true}
        value={defaultPledgeCat}
        options={[allCategories, ...sortBy(categories, (c) => c.name.toUpperCase())]}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={renderCategoryOption}
        getOptionLabel={(option) => (option as Category.Category).name}
      />
    </>
  );
}
