import * as Yup from 'yup';
import { AutocompleteItem, TColumnType } from '@typedefs/app';
import { PaymentMethod, Tag } from '@shared/types';
import { ImportDonation, ImportDonor, ImportEntity } from '@shared/types/importData';

// ----------------------------------------------------------------------
export enum ImportType {
  onlyDonors = 'donors',
  onlyDonations = 'donations',
  both = 'common',
}

export type DonorFieldNames =
  | 'email'
  | 'memberNumber'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'firstLast'
  | 'lastFirst'
  | 'organization'
  | 'phone'
  | 'address1'
  | 'address2'
  | 'city'
  | 'state'
  | 'cityState'
  | 'cityStateCountry'
  | 'postalCode'
  | 'country'
  | 'tag'
  | 'donorNotes'
  | `custom${number}`;

export type DonationFieldNames =
  | 'date'
  | 'amount'
  | 'category'
  | 'paymentMethod'
  | 'paymentInfo'
  | 'description'
  | 'donationNotes'
  | 'amountEligible'
  | 'amountAdvantage'
  | 'advantageDescription';

export type FieldNames = '' | DonorFieldNames | DonationFieldNames;

export type ImportField = {
  importType: ImportType;
  fieldName: FieldNames;
  displayName: string;
  type: TColumnType;
  align?: 'left' | 'right';
};

export const SharedFields: ImportField[] = [
  {
    importType: ImportType.both,
    fieldName: '',
    displayName: "Skip (don't import)",
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.both,
    fieldName: 'email',
    displayName: 'Email',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.both,
    fieldName: 'memberNumber' /* needs special handling! */,
    displayName: 'Member/Envelope #',
    type: 'number',
    align: 'right',
  },
];

export const DonorFields: ImportField[] = [
  {
    importType: ImportType.onlyDonors,
    fieldName: 'firstName',
    displayName: 'First Name',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'middleName',
    displayName: 'Middle Name',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'lastName',
    displayName: 'Last Name',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'firstLast',
    displayName: 'Name: First Last',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'lastFirst',
    displayName: 'Name: Last, First',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'organization',
    displayName: 'Business Name',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'phone',
    displayName: 'Phone',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'address1',
    displayName: 'Address 1',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'address2',
    displayName: 'Address 2',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'city',
    displayName: 'City',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'state',
    displayName: 'State / Province',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'cityState',
    displayName: 'City + State',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'cityStateCountry',
    displayName: 'City + State + Country',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'postalCode',
    displayName: 'Postal Code',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'country',
    displayName: 'Country',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'tag',
    displayName: 'Tag',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonors,
    fieldName: 'donorNotes',
    displayName: 'Donor Notes',
    type: 'string',
    align: 'left',
  },
];

export const DonationFields: ImportField[] = [
  {
    importType: ImportType.onlyDonations,
    fieldName: 'date',
    displayName: 'Date',
    type: 'date',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'amount',
    displayName: 'Amount',
    type: 'number',
    align: 'right',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'category',
    displayName: 'Category',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'paymentMethod',
    displayName: 'Payment Method',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'paymentInfo',
    displayName: 'Check Number',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'description',
    displayName: 'Description',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'donationNotes',
    displayName: 'Donation Notes',
    type: 'string',
    align: 'left',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'amountEligible',
    displayName: 'Eligible Amount',
    type: 'number',
    align: 'right',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'amountAdvantage',
    displayName: 'Advantage Amount',
    type: 'number',
    align: 'right',
  },
  {
    importType: ImportType.onlyDonations,
    fieldName: 'advantageDescription',
    displayName: 'Advantage Description',
    type: 'string',
    align: 'left',
  },
];

export enum MatchAction {
  add,
  update,
  ignore,
}
export const MatchActions: AutocompleteItem<MatchAction>[] = [
  {
    value: MatchAction.add,
    label: 'Add all rows from the file as new donors, ignoring matches',
  },
  {
    value: MatchAction.update,
    label: 'Update existing donors from data in matching rows from the file',
  },
  {
    value: MatchAction.ignore,
    label: 'Ignore rows from the file matching existing donors',
  },
];

// Options for who to set to be NR (Non Receiptable)
export enum NROption { 
  none,
  allDonations,
  businessDonors,
  allDonors,
}
export const NROptions: AutocompleteItem<NROption>[] = [
  {
    value: NROption.none,
    label: 'No',
  },
  {
    value: NROption.allDonations,
    // Note fake bold indicators (same before and after) will be used by code
    label: 'Yes, <b>all donations<b> are non-receiptable',
  },
  {
    value: NROption.businessDonors,
    label: 'Yes, <b>donors with a business name<b> are non-receiptable',
  },
  {
    value: NROption.allDonors,
    label: 'Yes, <b>all donors<b> are non-receiptable',
  },
];

// ----------------------------------------------------------------------
export const ImportDataSchema = Yup.object().shape({
  file: Yup.mixed().required('File is required'),
});

export type ImportDataSchemaForm = {
  file: File | undefined;
  data: string[][];
  importType: ImportType;
  hasHeaders: boolean;
  matchField: AutocompleteItem<string> | undefined;
  matchAction: AutocompleteItem<MatchAction> | undefined;
  paymentMethod: PaymentMethod.PaymentMethod | undefined; // used if no paymentMethod mapped
  tag: Tag.Tag | undefined; // used if no tag mapped
  nrOption: AutocompleteItem<NROption> | undefined; // who or what to set to be non-receiptable
  afterSubmit?: string;
  step: number;
  headers: string[];
  mapFields: ImportField[];
  calculatedNewObjects: boolean; // have we filled in the three Maps below
  hasNewObjects: boolean; // are any of the entries in the three Maps below new
  categoriesMap: ImportEntity[];
  tagsMap: ImportEntity[];
  paymentMethodsMap: ImportEntity[];
  paywallWarning: string;
  donors: Partial<ImportDonor>[];
  donations: Partial<ImportDonation>[];
};
