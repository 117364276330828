import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, AutocompleteValue, TextField } from '@mui/material';

// ----------------------------------------------------------------------
interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  name: string;
  label: string;
  required?: boolean;
  beforeOnChange?: (
    newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
    commitChange: VoidFunction
  ) => void;
}

// ----------------------------------------------------------------------
export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  required,
  autoFocus,
  beforeOnChange,
  ...other
}: Props<T, Multiple, DisableClearable, FreeSolo>) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          {...other}
          autoComplete
          onChange={
            other.onChange ||
            ((e, newValue) =>
              beforeOnChange
                ? beforeOnChange(newValue, () => setValue(name, newValue, { shouldDirty: true }))
                : setValue(name, newValue, { shouldDirty: true }))
          }
          renderInput={(params) => (
            <TextField
              autoFocus={autoFocus}
              inputRef={ref}
              label={label}
              required={required}
              error={!!error}
              helperText={error?.message}
              {...params}
            />
          )}
        />
      )}
    />
  );
}
