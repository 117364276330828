import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ReactCrop, { type PixelCrop, type Crop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Dialog from '@components/Dialog';
import { CustomFile, Upload } from '@components/upload';

// ----------------------------------------------------------------------
type Props = {
  readonly open: boolean;
  readonly onClose: VoidFunction;
  readonly onSubmit: (blob: Blob) => void;

  readonly title: string;
  readonly submitText: string;
  readonly loading?: boolean;

  readonly maxWidth?: number;
  readonly maxHeight?: number;
};
// ----------------------------------------------------------------------
export default function ImageUploadDialog({
  open,
  onClose,
  onSubmit,
  title,
  submitText,
  loading,
  maxWidth,
  maxHeight,
}: Props) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [file, setFile] = useState<CustomFile | undefined>();
  const [error, setError] = useState<string | undefined>();

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const finalCanvasRef = useRef<HTMLCanvasElement>(null);

  // --------------- effects ---------------
  useEffect(() => {
    setFile(undefined);
    setError(undefined);
    setCrop(undefined);
    setCompletedCrop(undefined);
  }, [setFile, setError, open]);

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current &&
      finalCanvasRef.current
    ) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      finalPreview(previewCanvasRef.current, finalCanvasRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  // --------------- actions ---------------
  // user selected a file - load and create preview
  const handleDrop = useCallback(
    async (files: File[]) => {
      const file = files[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      setFile(newFile);
    },
    [setFile]
  );

  // initial crop suggestion
  function handleImgLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        {
          unit: 'px',
          width: maxWidth ? Math.min(maxWidth, width * 0.9) : width * 0.9,
          height: maxHeight ? Math.min(maxHeight, height * 0.9) : height * 0.9,
        },
        width,
        height
      )
    );
  }

  // --------------- submit ---------------
  const handleSubmit = async () => {
    if (!imgRef.current || !previewCanvasRef.current || !completedCrop || !finalCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    // refresh previews to ensure we are on the latest
    canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
    finalPreview(previewCanvasRef.current, finalCanvasRef.current);
    // generate blob from canvas (with crop)
    const blob: Blob = await new Promise((accept, reject) => {
      finalCanvasRef.current!.toBlob((blob) =>
        blob ? accept(blob) : reject('Failed to create blob')
      );
    });

    onSubmit(blob);
  };

  function finalPreview(preview: HTMLCanvasElement, final: HTMLCanvasElement) {
    const ctx = final.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    let { width, height } = preview;

    // will downsample but won't strech or resize - keeps aspect that user selects
    if (width > height) {
      if (maxWidth && width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }
    } else {
      if (maxHeight && height > maxHeight) {
        width = width * (maxHeight / height);
        height = maxHeight;
      }
    }

    final.width = width;
    final.height = height;
    ctx.drawImage(preview, 0, 0, width, height);

    // will downsample/upsample and treat whitespace - adjusts aspect to match max w/h
    // final.width = maxWidth ? Math.min(maxWidth, width) : width;
    // final.height = maxHeight ? Math.min(maxHeight, height) : height;

    // const hRatio = final.width / width;
    // const vRatio = final.height / height;
    // const ratio = Math.min(hRatio, vRatio);
    // const centerShiftX = (final.width - width * ratio) / 2;
    // const centerShiftY = (final.height - height * ratio) / 2;
    // // if we want to use full size image and add white background
    // // ctx.fillStyle = 'white';
    // // ctx.fillRect(0, 0, final.width, final.height);
    // ctx.clearRect(0, 0, final.width, final.height);
    // ctx.drawImage(
    //   preview,
    //   0,
    //   0,
    //   width,
    //   height,
    //   centerShiftX,
    //   centerShiftY,
    //   width * ratio,
    //   height * ratio
    // );
  }

  function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0
  ) {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio;
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * (Math.PI / 180);
    const centerX = image.naturalWidth / 2;
    const centerY = image.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);
    ctx.drawImage(
      image,
      0, // sx, the x-coordinate,
      0, // sy, and the y-coordinate from which to start cropping the image
      image.naturalWidth, // the width,
      image.naturalHeight, // and height of the cropped version, starting from sx/sy
      0, // The x coordinate,
      0, // and y from which to start drawing the cropped version on the canvas
      image.naturalWidth, // The width,
      image.naturalHeight // and height of the cropped version to be displayed
    );
    ctx.restore();
  }

  return (
    <Dialog
      open={open}
      isLoading={loading}
      title={title}
      onClose={onClose}
      maxWidth="md"
      actions={
        <LoadingButton
          type="submit"
          size="large"
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          disabled={!completedCrop || loading}
        >
          {submitText}
        </LoadingButton>
      }
    >
      {/* #1 upload phase to select image */}
      {!file && (
        <Upload accept={{ 'image/*': [] }} file={file} onDrop={handleDrop} error={!!error} />
      )}

      {/* #2 crop phase where user selects part of the image */}
      {!!file?.preview && (
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          onComplete={setCompletedCrop}
          // aspect={maxWidth && maxHeight ? maxWidth / maxHeight : undefined}
        >
          <img ref={imgRef} alt="Crop me" src={file.preview} onLoad={handleImgLoad} />
        </ReactCrop>
      )}

      {/* #3 hidden canvas from which we generate crop selection */}
      {completedCrop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />
      )}

      {/* #4 hidden canvas in which we turn crop selection and resize, down/up sample */}
      {completedCrop && (
        <canvas
          ref={finalCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />
      )}

      {!!error && <Alert severity="error">{error}</Alert>}
    </Dialog>
  );
}
