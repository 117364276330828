import { ChangeEvent } from 'react';
import { TablePagination, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------
type Props = {
  readonly count: number;
  readonly selected?: number;
  readonly customSelected?: React.ReactNode;
  readonly rowsPerPageOptions: number[];
  readonly rowsPerPage: number;
  readonly page: number;
  readonly onPageChange: (event: unknown, newPage: number) => void;
  readonly onRowsPerPageChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

// ----------------------------------------------------------------------
export default function TablePaginationCustom({
  count,
  selected,
  customSelected,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
}: Props) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ borderTop: 1, borderColor: (theme) => theme.palette.divider }}
    >
      {customSelected ? (
        customSelected
      ) : selected !== undefined ? (
        <Typography variant="body2" px={2} py={1}>
          {selected} selected
        </Typography>
      ) : (
        <div />
      )}

      <TablePagination
        component="div"
        count={count}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        sx={{
          border: 0,
        }}
      />
    </Stack>
  );
}
