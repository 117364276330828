import { DonationFieldNames } from '@/schemas/importData';
import { TableEmptyRows, TableHeadCustom } from '@components/table';
import useFormat from '@hooks/useFormat';
import useTable, { emptyRows } from '@hooks/useTable';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { ImportData } from '@shared/types';
import { TColumn } from '@typedefs/app';

type Props = {
  importDonations: Partial<ImportData.ImportDonation>[];
  tableCols: TColumn<'donorName' | 'nonReceiptable' | DonationFieldNames>[];
};
export default function DonationsTable({ importDonations, tableCols }: Props) {
  const {
    columns,
    order,
    orderBy,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage /* , onSort */,
  } = useTable({
    defaultRowsPerPage: 10,
    defaultColumns: tableCols,
    defaultOrderBy: 'lastName',
    defaultOrder: 'asc',
  });
  const { fCurrency, fDate } = useFormat();

  const getTableCols = (donation: Partial<ImportData.ImportDonation>) =>
    tableCols.map((col) => {
      let key = col.id;
      let val: string | undefined = '';
      switch (key) {
        case 'category':
          val = donation.categoryName;
          break;
        case 'paymentMethod':
          val = donation.paymentMethodName;
          break;
        case 'donationNotes':
          val = donation.notes;
          break;
        default:
          val = donation[key]?.toString();
          break;
      }
      if (col.type === 'number') val = !!val ? fCurrency(val) : '';
      else if (col.type === 'date') val = !!val ? fDate(val) : '';

      return { id: col.id, align: col.align, value: val };
    });

  return (
    <>
      <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
        <Table stickyHeader size="small">
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            columns={columns}
            rowCount={importDonations.length}
            // onSort={onSort}
            cellsSx={{ whiteSpace: 'nowrap' }}
          />

          <TableBody>
            {importDonations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((donation, index) => (
                <TableRow key={index} hover sx={{ height: 44 }}>
                  {getTableCols(donation).map((col) => (
                    <TableCell key={col.id} align={col.align} sx={{ whiteSpace: 'nowrap' }}>
                      {col.value || ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            <TableEmptyRows
              height={44}
              emptyRows={emptyRows(page, rowsPerPage, importDonations.length)}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={importDonations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
