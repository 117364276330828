import { analytics } from '@/firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

// ----------------------------------------------------------------------
export function register(type: 'password' | 'google.com') {
  firebaseAnalytics.logEvent(analytics, 'sign_up', { type });
}
export function login(type: 'password' | 'google.com') {
  firebaseAnalytics.logEvent(analytics, 'login', { type });
}
export function link(type: 'password' | 'google.com') {
  firebaseAnalytics.logEvent(analytics, 'login', { type });
}
export function unlink(type: 'password' | 'google.com') {
  firebaseAnalytics.logEvent(analytics, 'login', { type });
}
export function update() {
  firebaseAnalytics.logEvent(analytics, 'user_update');
}
export function changePassword() {
  firebaseAnalytics.logEvent(analytics, 'user_update');
}
export function resetPassword(confirmed?: boolean) {
  firebaseAnalytics.logEvent(
    analytics,
    confirmed ? 'user_reset_pwd_confirm' : 'user_reset_pwd_send'
  );
}
export function logout() {
  firebaseAnalytics.logEvent(analytics, 'logout');
}
