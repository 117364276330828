import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const CreatePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('New Password is required'),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), undefined],
    'Passwords must match'
  ),
});

export const createPasswordSchemaResolver = yupResolver(CreatePasswordSchema);
export type CreatePasswordSchemaForm = {
  newPassword: string;
  confirmNewPassword: string;

  afterSubmit?: string;
};
