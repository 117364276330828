import { useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { Category, Tag } from '@shared/types';
import FilterCategories from '@components/table/TableFilters/FilterCategories';
import FilterTags from '@components/table/TableFilters/FilterTags';
import {
  AvailableSortOrder,
  SortOrder,
  orderDescription,
  sortByOrders,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import { TableDateRange } from '@components/table';
import { intersection, filter } from 'lodash';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';
import { isBefore } from 'date-fns';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    fDateToPreviousYear,
    isDateBetween,
    fCurrency,
    fReversedName,
    fJoinWithConjunction,
  } = useFormat();
  const { donors, donations } = useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>([]);
  const [filterTags, setFilterTags] = useState<Tag.Tag[]>([]);
  const [filterDescription, setFilterDescription] = useState('');
  const [showPrevYr, setShowPrevYr] = useState(false);
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([{ availablesIndex: 0 }]);
  const [includeWithoutDonations, setIncludeWithoutDonations] = useState(false);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(() => {
    const arr = [
      { label: 'Name', field: (d: any) => d.name.toUpperCase() },
      { label: 'Amount ($)', field: 'thisYearAmount' },
    ];
    if (showPrevYr) arr.push({ label: 'Last year ($)', field: 'lastYearAmount' });
    return arr;
  }, [showPrevYr]);

  // Set the description of the filter based on the selections
  useEffect(() => {
    let tagsDescription = '';
    if (filterTags.length) {
      const tagNames = filterTags.map((t) => t.name);
      tagsDescription = `Including only donors with ${
        filterTags.length === 1 ? 'the tag ' : 'any of the tags '
      }${fJoinWithConjunction(tagNames, 'or')}`;
    }
    let categoriesDescription = '';
    if (filterCategories.length) {
      const categoryNames = filterCategories.map((c) => c.name);
      categoriesDescription = `${tagsDescription ? ' and' : 'Including'} only donations with ${
        filterCategories.length === 1 ? 'the category ' : 'any of the categories '
      } ${fJoinWithConjunction(categoryNames, 'or')}`;
    }
    setFilterDescription(
      `${tagsDescription}${
        tagsDescription && categoriesDescription ? ' ' : ''
      }${categoriesDescription}`
    );
  }, [fJoinWithConjunction, filterCategories, filterTags]);

  const canShowPrevYr = useMemo(() => {
    const canShow =
      !!dateFrom &&
      !!dateTo &&
      isBefore(fDateToPreviousYear(dateTo), dateFrom) &&
      isBefore(dateFrom, dateTo);
    if (!canShow) setShowPrevYr(false);
    return canShow;
  }, [dateFrom, dateTo, fDateToPreviousYear]);

  const data: PdfTableData = useMemo(() => {
    const isThisYear = (d: Date) =>
      isDateBetween(
        d,
        fDateToDayStart(dateFrom || minDateFrom),
        fDateToDayEnd(dateTo || maxDateTo)
      );

    const isLastYear = (d: Date) =>
      showPrevYr &&
      isDateBetween(
        d,
        fDateToDayStart(fDateToPreviousYear(dateFrom || minDateFrom)),
        fDateToDayEnd(fDateToPreviousYear(dateTo || maxDateTo))
      );

    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 39 },
      { name: 'Amount ($)', width: 12, align: 'right' },
    ];
    if (showPrevYr) {
      header[1].name = 'This year ($)';
      header.push({ name: 'Last year ($)', width: 12, align: 'right' });
    }

    let filteredDonors = donors;
    if (filterTags.length) {
      const tagIds = filterTags.map((t) => t.id);
      filteredDonors = filter(donors, (d) => intersection(tagIds, d.tagIds).length > 0);
    }
    let donorsAndDonations = filteredDonors.map((d) => {
      let myDonations = donations.filter(
        (donation) =>
          d.id === donation.donorId &&
          (isThisYear(new Date(donation.date)) || isLastYear(new Date(donation.date)))
      );
      if (filterCategories.length) {
        const categoryIds = filterCategories.map((c) => c.id);
        myDonations = filter(myDonations, (d) => categoryIds.includes(d.categoryId));
      }
      return {
        donor: d,
        donations: myDonations,
      };
    });
    if (!includeWithoutDonations) {
      donorsAndDonations = donorsAndDonations.filter((d) => d.donations.length > 0);
    }
    const itemsUnsorted = donorsAndDonations.map((dd) => ({
      name: fReversedName(dd.donor),
      thisYearAmount: dd.donations
        .filter((d) => isThisYear(new Date(d.date)))
        .reduce((n, { amount }) => n + Number(amount), 0),
      lastYearAmount: dd.donations
        .filter((d) => isLastYear(new Date(d.date)))
        .reduce((n, { amount }) => n + Number(amount), 0),
    }));
    const thisYearTotal = itemsUnsorted.reduce(
      (n, { thisYearAmount: amount }) => n + Number(amount),
      0
    );
    const lastYearTotal = itemsUnsorted.reduce(
      (n, { lastYearAmount: amount }) => n + Number(amount),
      0
    );
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((s) => {
      const arr = [s.name, s.thisYearAmount ? fCurrency(s.thisYearAmount) : ''];
      if (showPrevYr) arr.push(s.lastYearAmount ? fCurrency(s.lastYearAmount) : '');
      return arr;
    });
    const summary: PdfTableSummaryField[] = [
      { columns: 1, text: `Totals: ${items.length} donors` },
      { columns: 1, text: `${fCurrency(thisYearTotal)}` },
    ];
    if (showPrevYr) summary.push({ columns: 1, text: `${fCurrency(lastYearTotal)}` });
    return { header, items, summary };
  }, [
    showPrevYr,
    donors,
    filterTags,
    includeWithoutDonations,
    sortOrders,
    availableSortOrders,
    fCurrency,
    isDateBetween,
    fDateToDayStart,
    dateFrom,
    minDateFrom,
    fDateToDayEnd,
    dateTo,
    maxDateTo,
    fDateToPreviousYear,
    donations,
    filterCategories,
    fReversedName,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={name}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      filterDescription={filterDescription}
      orderBy={orderDescription(sortOrders, availableSortOrders)}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="Summary by Donor report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !blob ||
                !!error
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'summary_by_donor'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
            <FilterTags filterTags={filterTags} setFilterTags={setFilterTags} />
            <FilterCategories
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeWithoutDonations}
                    onChange={() => {
                      setIncludeWithoutDonations(!includeWithoutDonations);
                    }}
                  />
                }
                label={'Include donors w/o donations'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPrevYr}
                    disabled={!canShowPrevYr}
                    onChange={() => {
                      setShowPrevYr(!showPrevYr);
                    }}
                  />
                }
                label={'Also show previous year'}
              />
            </FormGroup>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
