import { useCallback } from 'react';
import useNavigate from '@hooks/useNavigate';
import PATHS from '@routes/paths';
import Dialog from '@components/Dialog';

import Receipts from './Receipts';

// --------------- effects ---------------
export default function SettingsReceipts() {
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate(PATHS.org.receipts.root);
  }, [navigate]);

  return (
    <>
      <Dialog
        title="Receipt Settings"
        onClose={handleClose}
        maxWidth="md"
        sx={{
          '& .MuiDialogContent-root': {
            minHeight: { sx: '100vh', sm: '35vh' },
            maxHeight: { sx: '100vh', sm: '70vh' },
          },
          '& form': {
            flex: '1 1 auto !important',
          },
        }}
      >
        <Receipts />
      </Dialog>
    </>
  );
}
