import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { LocalizationProvider as DatePickerProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// i18n
import './locales/i18n';

import Router from './routes';
import ThemeProvider from './theme';
import ThemeLocalization from './locales';
import auth from '@fire/auth';

import ScrollToTop from '@components/ScrollToTop';
import { ProgressBarStyle } from '@components/ProgressBar';
import MotionLazyContainer from '@components/animate/MotionLazyContainer';
import NotistackProvider from '@components/NotistackProvider';
import ErrorPage from './ErrorPage';
import { useSelector } from '@redux/store';
import PendoProvider from '@components/PendoProvider';

// ----------------------------------------------------------------------
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://cb1007deb8274e9c8b4cc1189a231836@o1428741.ingest.sentry.io/6779137',
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_APP_RELEASE,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
      // stops sentry from taking over console.log (nicer debugging)
      Sentry.breadcrumbsIntegration({
        console: import.meta.env.PROD,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: import.meta.env.PROD ? 1.0 : 0,

    // error ignoring
    // https://github.com/getsentry/sentry-javascript/issues/3440
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'TypeError: Load failed',
      'Non-Error promise rejection captured',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

// ----------------------------------------------------------------------
const fallbackComponent = <ErrorPage />;
export default function App() {
  const { hasError } = useSelector((s) => s.app);

  useEffect(() => {
    auth.onStateChange();
  }, []);

  if (hasError) return fallbackComponent;

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeLocalization>
          <NotistackProvider>
            <DatePickerProvider dateAdapter={AdapterDateFns}>
              <ProgressBarStyle />
              <ScrollToTop />

              <Sentry.ErrorBoundary fallback={fallbackComponent}>
                <PendoProvider />
                <Router />
              </Sentry.ErrorBoundary>
            </DatePickerProvider>
          </NotistackProvider>
        </ThemeLocalization>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
