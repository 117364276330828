// This file was originally copied and adapted from renderCategories when basing
// the implementation of payment methods on that of categories.
// However, it was determined that there was no use for the functions
// renderPaymentMethods or PaymentMethodItem so they were removed.

import { HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import { PaymentMethod } from '@shared/types';

// ----------------------------------------------------------------------
export const renderPaymentMethodOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: PaymentMethod.PaymentMethod
) => (
  <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
    <Typography>{option.name}</Typography>
  </li>
);
