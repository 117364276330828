import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { List, Collapse } from '@mui/material';

import { Role } from '@shared/types/organization';
import { getActive } from '@routes/paths';
import useOrg from '@hooks/useOrg';
import NavItem from './NavItem';

// ----------------------------------------------------------------------
export type NavListProps = {
  title: string;
  path: string;
  icon?: React.ReactElement;
  info?: React.ReactElement;
  caption?: string;
  disabled?: boolean;
  roles: Role[];
  children?: any;
};

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChildren: boolean;
  isCollapse?: boolean;
};

// ----------------------------------------------------------------------
export default function NavList({
  data,
  depth,
  hasChildren,
  isCollapse = false,
}: NavListRootProps) {
  const { pathname } = useLocation();
  const active = getActive(data.path, pathname);
  const { orgId } = useOrg();
  const [open, setOpen] = useState(active);

  const handleClickItem = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isCollapse={isCollapse}
        hasChildren={hasChildren}
        orgId={orgId!}
        path={data.path}
        onClick={handleClickItem}
      />

      {!isCollapse && hasChildren && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            <NavSubList data={data.children} depth={depth} />
          </List>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChildren={!!list.children}
        />
      ))}
    </>
  );
}
