import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import { Helmet } from 'react-helmet';

// ----------------------------------------------------------------------
interface Props extends BoxProps {
  children: ReactNode;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title, sx, ...other }, ref) => (
  <Box ref={ref} {...other} sx={{ ...sx, height: '100%' }} title={undefined}>
    <Helmet titleTemplate="App - %s | Software4Nonprofits" defaultTitle="App | Software4Nonprofits">
      <title>{title}</title>
    </Helmet>
    {children}
  </Box>
));

export default Page;
