import { useCallback, useEffect, useState } from 'react';
import { Stack, TextField, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';

import { TColumn } from '@typedefs/app';
import Iconify from '@components/Iconify';
import { TableColumns, TableDateRange } from '@components/table';
import ToolbarFilters, { FilterProps } from './Filters';

// ----------------------------------------------------------------------
type Props = {
  columns: TColumn[];
  setColumns: (columns: TColumn<any>[]) => void;
  dateFrom?: Date;
  setDateFrom: (d?: Date) => void;
  dateTo?: Date;
  setDateTo: (d?: Date) => void;
  search: string;
  onSearchChange: (search: string) => void;

  filterProps: FilterProps;

  actions: React.ReactNode;
};

// ----------------------------------------------------------------------
export default function Toolbar({
  columns,
  setColumns,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  search,
  onSearchChange,
  filterProps,
  actions,
}: Props) {
  const [filter, setFilter] = useState(search);

  const handleFilterSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setFilter(event.target.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeFilter = useCallback(debounce(onSearchChange, 400), []);
  useEffect(() => changeFilter(filter), [changeFilter, filter]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={2}>
        <TextField
          value={filter}
          onChange={handleFilterSearchChange}
          placeholder="Search"
          sx={{
            width: { sm: 140, xl: 320 },
            background: (theme) => theme.palette.common.white,
          }}
          inputProps={{ sx: { p: 1 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />

        <TableDateRange
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
        />

        <TableColumns columns={columns} setColumns={setColumns} />
        <ToolbarFilters {...filterProps} />
      </Stack>

      <Stack direction="row" spacing={2}>
        {actions}
      </Stack>
    </Stack>
  );
}
