export * from './requests';

// ----------------------------------------------------------------------
// Enums
// ----------------------------------------------------------------------
export enum Currency {
  usd = 'usd',
  cad = 'cad',
}

export enum RecurringInterval {
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum TaxBehavior {
  inclusive = 'inclusive',
  exclusive = 'exclusive',
  unspecified = 'unspecified',
}

// ----------------------------------------------------------------------
// Subscription
// ----------------------------------------------------------------------
export type Subscription = {
  readonly customerId: string;
  donation?: SubscriptionDetails;
  account?: SubscriptionDetails;
};

export type SubscriptionDetails = {
  readonly subscriptionId: string;
  readonly subscriptionStatus: string; // TODO: do we know which ones are?
  readonly dateExpiration: string;
  readonly cancelAtPeriodEnd: boolean;
  readonly product: Product;
  readonly addons: Array<Product>;
};

export type Product = {
  readonly id: ProductTierValue;
  readonly priceId: string;
  readonly price: number | null;
  readonly priceCurrency: Currency;
  readonly name: string;
  readonly recurringInterval: RecurringInterval;
};

// ----------------------------------------------------------------------
// Pricing page products
export enum ProductTierValue {
  'prod_P8Jly742Xvtl04' = 'prod_P8Jly742Xvtl04',
  'prod_P8Jp1YLbqlYtPF' = 'prod_P8Jp1YLbqlYtPF',
  'prod_P8JtksvkvqtBLk' = 'prod_P8JtksvkvqtBLk',
  'prod_P8JvyzpMytNfQQ' = 'prod_P8JvyzpMytNfQQ',
  'prod_P8JwwIyB1tO8lq' = 'prod_P8JwwIyB1tO8lq',
  'prod_P8JyH7rrI3W0BN' = 'prod_P8JyH7rrI3W0BN',
  'prod_PAB8w7q3DtUWeI' = 'prod_PAB8w7q3DtUWeI',
  'prod_PAB8MWmdm0Z2wG' = 'prod_PAB8MWmdm0Z2wG',
  'prod_PAB8wbDSutUiwd' = 'prod_PAB8wbDSutUiwd',
  'prod_PAB85xXGdI8thK' = 'prod_PAB85xXGdI8thK',
  'prod_PAB8OA1vvBdBZ6' = 'prod_PAB8OA1vvBdBZ6',
  'prod_PAB8GsgKNwHqbv' = 'prod_PAB8GsgKNwHqbv',
}

export type ProductTier = { [key in ProductTierValue]?: ProductTierKey };
export enum ProductTierKey {
  'up_to_100' = 'up_to_100',
  'up_to_250' = 'up_to_250',
  'up_to_500' = 'up_to_500',
  'up_to_1000' = 'up_to_1000',
  'up_to_5000' = 'up_to_5000',
  'up_to_20000' = 'up_to_20000',
}

export type PricingPageProduct = {
  readonly id: ProductTierValue;
  readonly lookup_key: string;
  readonly donor_limit: number;
  readonly name: string;
  readonly description: string;
  readonly trial_period: boolean;
  readonly recurring_interval: RecurringInterval;
  readonly details: Array<PricingPageProductDetails>;
};

export type PricingPageProductDetails = {
  readonly currency: Currency;
  readonly unit_amount: number;
  readonly tax_behaviour: TaxBehavior | null;
};
