import { useMemo } from 'react';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { round } from 'lodash';

import { ReceiptReissueSchemaForm } from '@/schemas';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { TStepProps } from '../../../useSteps';
import { Receipt } from '@shared/types';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
export default function Overview({
  stepData,
  submittingData,
  updateStepData,
}: TStepProps<ReceiptReissueSchemaForm>) {
  const { fReceiptNumber } = useFormat();
  const { getReceiptReissueType } = useDonation();

  const total = 4;
  const normalize = (value: number) => ((value - 0) * 100) / (total - 0);
  const progress = round(normalize(submittingData.progress));

  const handleSelectPrintAllDonors = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({ emailAndPrint: e.target.checked });
  };

  const receiptIssue = useMemo(() => {
    const { receipt, reissue, donations } = stepData;
    const donationsAdded = donations.some((d) => !d.receiptIds.includes(receipt.id));
    const { isNewReceipt, type } = getReceiptReissueType(receipt, reissue, donationsAdded);

    let text: React.ReactNode =
      "Receipt will be updated with no further changes because you marked that donor didn't receive it.";
    // ca
    if (isNewReceipt) {
      text = (
        <>
          <strong>New receipt will be issued</strong> and will be{' '}
          <strong>
            {type === Receipt.ReceiptActionType.replacement ? 'replacement' : 'correction'}
          </strong>{' '}
          of current receipt.
        </>
      );
      // us
    } else if (type) {
      text = (
        <>
          Current receipt will be updated and marked as{' '}
          <strong>
            {type === Receipt.ReceiptActionType.replacement ? 'duplicate' : 'corrected'}
          </strong>
          .
        </>
      );
    }

    return <Typography>{text}</Typography>;
  }, [getReceiptReissueType, stepData]);

  return (
    <Stack alignItems="center" justifyContent="center" flex="1 1 auto">
      <Card
        sx={{
          py: 4,
          px: 8,
          width: '50%',
          border: (theme) => `1px solid ${theme.palette.grey[600]}40`,
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {submittingData.isSubmitting ? (
          <Stack spacing={3} textAlign="center">
            <Typography variant="h6">Issuing receipts... </Typography>

            <Stack direction="column" spacing={1}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Stack spacing={2} textAlign="center">
              <Typography variant="h5">Are you ready to reissue Receipt?</Typography>
              <Typography variant="h6" color="text.secondary">
                <strong>{fReceiptNumber(stepData.receipt.number, stepData.receipt.year)}</strong>
              </Typography>

              {receiptIssue}
            </Stack>

            {stepData.actionType === 'email' && (
              <>
                <Divider sx={{ width: '100%' }} />

                <Stack spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={stepData.emailAndPrint}
                          onChange={handleSelectPrintAllDonors}
                        />
                      }
                      label={
                        <Typography variant="body2" color="text.secondary">
                          Upon "Reissue", also <strong>download a PDF of the receipt</strong>{' '}
                          for my own organization's records.
                        </Typography>
                      }
                    />
                  </FormGroup>

                  <Stack spacing={1} direction="row" alignItems="center" color="text.secondary">
                    <Iconify
                      icon="material-symbols:info-outline"
                      width={22}
                      minWidth={22}
                      height={22}
                    />
                    <Typography variant="body2" sx={{ mt: 1, lineHeight: '15px' }}>
                      PDF can be printed, but <strong>should not be distributed to donor</strong> as
                      it duplicate the attachment donor will already receive via this email send.
                    </Typography>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Card>
    </Stack>
  );
}
