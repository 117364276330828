import { useCallback } from 'react';
import { useNavigate as useRouterNavigate, useParams, NavigateOptions, To } from 'react-router';
import { isObject, isString } from 'lodash';

import useOrg from '@hooks/useOrg';
import { getPath } from '@routes/paths';

// ----------------------------------------------------------------------
export default function useNavigate() {
  const routerNavigate = useRouterNavigate();
  const params = useParams();
  const { org } = useOrg();

  const navigate = useCallback(
    (path: To | number, overrides: { [key: string]: any } = {}, options?: NavigateOptions) => {
      if (isString(path)) {
        routerNavigate(getPath(path, { orgId: org?.id, ...params, ...overrides }), options);
      } else if (isObject(path) && path.pathname) {
        routerNavigate(
          {
            ...path,
            pathname: getPath(path.pathname, { orgId: org?.id, ...params, ...overrides }),
          },
          options
        );
      } else {
        routerNavigate(path as To, options);
      }
    },
    [org?.id, params, routerNavigate]
  );

  return navigate;
}
