// Filter fields for Minimum Amount and Type
import {
  TextField,
  Stack,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { MinAmountType, MinAmountTypeList } from '@/schemas';

// ----------------------------------------------------------------------
type Props = {
  filterMinAmount?: number;
  onFilterMinAmountChange: (newValue: number | undefined) => void;
  filterMinAmountType: MinAmountType;
  onFilterMinAmountTypeChange: (newValue: MinAmountType) => void;
};

// ----------------------------------------------------------------------
export default function FilterMinAmount({
  filterMinAmount,
  onFilterMinAmountChange,
  filterMinAmountType,
  onFilterMinAmountTypeChange,
}: Props) {
  return (
    <Stack spacing={2}>
      <TextField
        type="number"
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{ maxLength: 3 }}
        value={filterMinAmount ? filterMinAmount.toString() : ''}
        label="Minimum Amount"
        onChange={(e) => onFilterMinAmountChange(Number(e.target.value))}
      />
      <FormControl>
        <InputLabel id="select-min">Minimum Amount Type</InputLabel>
        { /* styles in the Select and MenuItem below ensure the values will wrap */ }
        <Select
          value={filterMinAmountType}
          size="small"
          labelId="select-min"
          label="Minimum Amount Type"
          onChange={(e) => onFilterMinAmountTypeChange(e.target.value as MinAmountType)}
          renderValue={(val) => (
            <Typography style={{ whiteSpace: 'normal' }}>
              {MinAmountTypeList.find((t) => val === t.value)?.label}
            </Typography>
          )}
        >
          {MinAmountTypeList.map((t) => (
            <MenuItem key={t.value} value={t.value} style={{ whiteSpace: 'normal' }}>
              {t.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
