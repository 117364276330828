import {
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import useTable, { emptyRows } from '@hooks/useTable';
import { Donation } from '@shared/types';

import { TColumn } from '@typedefs/app';
import TablePaginationCustom from '@components/table/TablePaginationCustom';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '@components/table';
import Scrollbar from '@components/Scrollbar';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';

import applyFilters from './filters';

// ----------------------------------------------------------------------
const DONATIONS_LIST_HEADERS: TColumn[] = [
  { id: 'date', type: 'date', label: 'Date', align: 'left', visible: true },
  { id: 'amount', type: 'number', label: 'Amount', align: 'right', visible: true },
  { id: 'paymentMethod', type: 'string', label: 'Payment method', align: 'left', visible: true },
  { id: 'category', label: 'Category', align: 'left', visible: true },
];

type Props = {
  readonly donations: Donation.Donation[];
  readonly onClick: (donorId: string, donationId: string) => void;
};

// ----------------------------------------------------------------------
export default function ReceiptReissueDonations({ donations, onClick }: Props) {
  const { fDate, fCurrency } = useFormat();
  const { getDonationCategory, categories, getPaymentMethod, paymentMethods } = useDonation();
  const { columns, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage, onSort } =
    useTable({
      defaultRowsPerPage: 10,
      defaultColumns: DONATIONS_LIST_HEADERS,
      defaultOrderBy: 'date',
    });

  // ----- FILTERING -------
  const data = applyFilters({
    data: donations,
    order,
    orderBy,

    categories,
    paymentMethods,
  });

  return (
    <Card>
      <TableContainer sx={{ position: 'relative' }}>
        <Scrollbar>
          <Table stickyHeader size={'small'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              columns={columns}
              rowCount={data.length}
              onSort={onSort}
            />

            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((donation) => {
                const donationCategory = getDonationCategory(donation);
                const addedToReceipt = !donation.receiptIds.length;
                return (
                  <TableRow
                    key={donation.id}
                    hover
                    sx={{
                      cursor: 'pointer',
                      height: 44,
                      backgroundColor: (theme) =>
                        addedToReceipt ? theme.palette.primary.lighter : 'inherit',
                    }}
                    onClick={() => onClick(donation.donorId, donation.id)}
                  >
                    <TableCell align="left">{fDate(new Date(donation.date))}</TableCell>
                    <TableCell align="right">{fCurrency(donation.amount)}</TableCell>
                    <TableCell align="left">
                      {getPaymentMethod(donation.paymentMethodId, donation.paymentInfo)}
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip
                        title={donationCategory?.description}
                        placement="top"
                        enterDelay={600}
                      >
                        <Stack direction="row" alignItems="center">
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              width: 16,
                              height: 16,
                              margin: 0.5,
                              marginRight: 1,
                              borderRadius: '50%',
                              background: donationCategory?.colorBackground || undefined,
                            }}
                          />
                          <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(14) }}>
                            {donationCategory?.name || ''}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableEmptyRows height={52} emptyRows={emptyRows(page, rowsPerPage, data.length)} />

              {/* change this isNotFound */}
              <TableNoData isNotFound={!data.length} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={data.length}
        rowsPerPageOptions={[10, 25]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Card>
  );
}
