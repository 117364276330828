// Render the default category drop-down and process changes in it

import { Autocomplete, Chip, TextField, Typography } from '@mui/material';
import { Category } from '@shared/types';
import { sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { enqueueSnackbar } from 'notistack';

// special category for there being no default
const noDefaultCategory = {
  id: '',
  name: '(No Default)',
  description: '',
} as Category.Category;

export default function DefaultCategory() {
  const { org, updateOrg } = useOrg();
  const { categories, getCategoryById } = useDonation();

  const defaultCategory = useMemo(() => {
    const id = org?.defaultCategoryId || '';
    return id ? getCategoryById(id) : noDefaultCategory;
  }, [getCategoryById, org?.defaultCategoryId]);

  const [loading, setLoading] = useState(false);

  const handleChangeDefault = async (e: React.SyntheticEvent, value: Category.Category) => {
    if (!org || !value) return;
    setLoading(true);
    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          defaultCategoryId: value.id,
        },
      });
      setTimeout(() => setLoading(false), 1000);
      // once this is saved, the useMemo above will reset defaultCategory
      if (!res) enqueueSnackbar('Failed to save default category', { variant: 'error' });
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Failed to save default category', { variant: 'error' });
    }
  };

  return (
    <>
      <Typography
        hidden={!loading}
        sx={{ border: 1, p: 1, width: '100%', maxWidth: '300px', fontWeight: 'bold' }}
      >
        Saving new default...
      </Typography>
      <Autocomplete
        autoComplete
        hidden={loading}
        fullWidth={false}
        size="small"
        sx={{ pb: 1, width: '100%', maxWidth: '300px' }}
        disableClearable={true}
        value={defaultCategory}
        onChange={handleChangeDefault}
        options={[noDefaultCategory, ...sortBy(categories, (c) => c.name.toUpperCase())]}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField label="Default Category" {...params} />}
        renderTags={(options, getTagProps) =>
          options.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              size="small"
              key={option.id}
              label={option.name}
              variant="outlined"
              avatar={
                <div
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    background: option.colorBackground,
                  }}
                />
              }
            />
          ))
        }
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 22,
                height: 22,
                borderRadius: '50%',
                marginRight: 8,
                background: option.colorBackground,
              }}
            />
            <Typography>{option.name}</Typography>
          </li>
        )}
      />
    </>
  );
}
