import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from './slices/app';
import authReducer from './slices/auth';
import orgReducer from './slices/org';
import donationReducer from './slices/donation';
import viewReducer from './slices/view';

// ----------------------------------------------------------------------
const rootPersistConfig = {
  keyPrefix: 'redux-',
  key: 'root',
  storage,
  whitelist: [],
};

const authPersistConfig = {
  keyPrefix: 'redux-',
  key: 'auth',
  storage,
  whitelist: [],
};

const orgPersistConfig = {
  keyPrefix: 'redux-',
  key: 'org',
  storage,
  whitelist: ['orgId'],
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  org: persistReducer(orgPersistConfig, orgReducer),
  donation: donationReducer,
  view: viewReducer,
});

export { rootPersistConfig, rootReducer };
