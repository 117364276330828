import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import PATHS from '@routes/paths';
import auth from '@fire/auth';
import useIsMountedRef from '@hooks/useIsMountedRef';
import { FormProvider, RHFTextField } from '@components/hook-form';
import { useSnackbar } from 'notistack';
import { EmailValidOrEmpty } from '@utils/regexp';

// ----------------------------------------------------------------------
type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

// ----------------------------------------------------------------------
export default function ResetPasswordForm() {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .matches(EmailValidOrEmpty, 'Email must be a valid email address')
      .required('Email is required'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: {
      email: '',
      afterSubmit: '',
    },
  });

  const {
    handleSubmit,
    setError,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async ({ email }: FormValuesProps) => {
    try {
      await auth.sendResetPassword({ email });

      // navigate(PATHS.newPassword + `?email=${email}`);
      enqueueSnackbar('We have sent you an email with a link to reset your password', {
        variant: 'success',
      });
      navigate(PATHS.login);
    } catch (e) {
      console.error(e);
      if (isMountedRef.current) {
        setError('afterSubmit', { ...e, message: e.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={!isDirty}
          loading={isSubmitting}
        >
          Send Request
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
