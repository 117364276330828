// Multi-select autocomplete drop-down for tags
import { Typography, Autocomplete, TextField, Chip, SxProps } from '@mui/material';

import useDonation from '@hooks/useDonation';

import { Tag } from '@shared/types';
import { sortBy } from 'lodash';

// ----------------------------------------------------------------------
type Props = {
  filterTags: Tag.Tag[];
  setFilterTags: (tags: Tag.Tag[]) => void;
  fullWidth?: boolean;
  sxProps?: SxProps;
};

// ----------------------------------------------------------------------
export default function FilterTags({
  filterTags,
  setFilterTags,
  fullWidth = true,
  sxProps,
}: Props) {
  const { tags } = useDonation();

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      autoComplete
      size="small"
      sx={sxProps}
      limitTags={2}
      disableCloseOnSelect
      value={filterTags}
      onChange={(e, newValue) => {
        setFilterTags(newValue);
      }}
      options={sortBy(tags, (t) => t.name.toUpperCase())}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField label="Tags" {...params} />}
      renderTags={(options, getTagProps) =>
        options.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            size="small"
            key={option.id}
            label={option.name}
            sx={{ background: option.colorBackground, color: option.colorText }}
          />
        ))
      }
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: 22,
              height: 22,
              borderRadius: '50%',
              marginRight: 8,
              background: option.colorBackground,
              color: option.colorText,
            }}
          />
          <Typography>{option.name}</Typography>
        </li>
      )}
    />
  );
}
