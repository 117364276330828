import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Donation } from '@shared/types';
import { TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  row: { columnGap: 8, flexDirection: 'row' },
  heading: {
    fontFamily: 'Helvetica-Bold',
    borderBottom: '1px solid #000',
    paddingBottom: 2,
  },
  /* need the following on a view wrapping a text to get the text to align right, tried a bunch of other ways and none worked except for this! */
  alignRight: { display: 'flex', alignItems: 'flex-end' },
});

// ----------------------------------------------------------------------
type Props = {
  readonly vars: TReceiptVariables;
  readonly donations: Donation.Donation[];
  readonly showDescription: boolean;
};

// ----------------------------------------------------------------------
export default function ReceiptDonationsTable({ vars, donations, showDescription }: Props) {
  return (
    <View style={{ marginTop: 16 }}>
      {/* header */}
      <View style={[styles.row, { marginBottom: 2 }]}>
        <Text style={[styles.heading, { width: 100 }]}>Date</Text>
        <View style={[styles.heading, styles.alignRight, { width: 75 }]}>
          <Text>Amount</Text>
        </View>
        <Text style={[styles.heading, { width: showDescription ? 120 : 160 }]}>Category</Text>
        <Text style={[styles.heading, { width: showDescription ? 120 : 160 }]}>Payment Method</Text>
        {showDescription ? (
          <Text style={[styles.heading, { width: showDescription ? 120 : 160 }]}>Description</Text>
        ) : null}
      </View>

      {/* body */}
      <View>
        {donations.map((d) => (
          <View key={d.id} style={styles.row}>
            <Text style={{ width: 100 }}>{vars.fDate(d.date)}</Text>
            <View style={[styles.alignRight, { width: 75 }]}>
              <Text>{vars.fCurrency(d.amount)}</Text>
            </View>
            <Text style={{ width: showDescription ? 120 : 160 }}>
              {vars.getCategoryById(d.categoryId)?.name || ''}
            </Text>
            <Text style={{ width: showDescription ? 120 : 160 }}>
              {vars.getPaymentMethod(d.paymentMethodId, d.paymentInfo)}
            </Text>
            {showDescription ? <Text style={{ width: 120 }}>{d.description || ''}</Text> : null}
          </View>
        ))}
      </View>
    </View>
  );
}
