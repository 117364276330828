import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import PATHS from '@routes/paths';
import { Limits } from '@shared/limits';
import { StripeUtil } from '@shared/stripe';
import useNavigate from '@hooks/useNavigate';
import useStripe from '@hooks/useStripe';

import Dialog from '@components/Dialog';
import useDonation from '@hooks/useDonation';
import { SubscriptionDetails } from '@shared/types/stripe';

// Shareable method to return the planLimit so it can be used elsewhere for 
// more subtle contexts, like importData.
export function calcDonorPaywall(subscription: SubscriptionDetails | undefined): number {
  const productTierKey = subscription?.product.id
    ? StripeUtil.getProductTierKey(
        import.meta.env.MODE === 'production' ? 'production' : 'development',
        subscription?.product.id
      )
    : undefined;

  return Limits.donors(productTierKey);
}
// ----------------------------------------------------------------------
type Props = { children?: ReactNode; onLimitClose?: VoidFunction };
// ----------------------------------------------------------------------
export default function PaywallDonorGuard({ children, onLimitClose }: Props) {
  const navigate = useNavigate();
  const { subscription, isFreeTrial } = useStripe();
  const { donors } = useDonation();

  // --------------- vars ---------------
  const [ack, setAck] = useState(false);
  const currentCount = donors.length;

  const planLimit = calcDonorPaywall(subscription);

  const limitReached = isFreeTrial ? false : currentCount >= planLimit;
  // show warning on 10, 5 and 1 remaining
  const warningThreshold =
    currentCount === planLimit - 10 ||
    currentCount === planLimit - 5 ||
    currentCount === planLimit - 1;

  // --------------- actions ---------------
  // TODO: CTA if user doesn't have settings permissions
  const handleNavigateToBilling = () => {
    navigate(PATHS.org.settings.org.billing);
  };

  const handleAck = () => setAck(true);

  // --------------- paywall ---------------
  if (limitReached) {
    return (
      <Dialog
        onClose={onLimitClose}
        title="Upgrade to increase your Donor limit"
        maxWidth="sm"
        actions={
          <Button variant="contained" onClick={handleNavigateToBilling}>
            See Pricing Plans
          </Button>
        }
      >
        <Typography>
          You've added the <strong>maximum number of Donors</strong> ({planLimit}), on your current
          pricing plan. Please upgrade to add more Donors.
        </Typography>
      </Dialog>
    );
  } else if (warningThreshold && !ack) {
    return (
      <Dialog
        onClose={handleAck}
        title="Upgrade soon to increase your Donor limit"
        maxWidth="sm"
        actions={
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Button onClick={handleAck}>Skip for now</Button>

            <Button variant="contained" onClick={handleNavigateToBilling}>
              See Pricing Plans
            </Button>
          </Stack>
        }
      >
        <Typography variant="body1">
          You have {currentCount} Donors saved so far and are approaching the maximum number of
          added Donors ({planLimit}) on your current pricing plan. Please upgrade your plan soon to
          add more Donors.
        </Typography>
      </Dialog>
    );
  }

  return children ? children : <Outlet />;
}
