import { RHFTextField } from '@components/hook-form';
import useOrg from '@hooks/useOrg';
import { Stack, Typography } from '@mui/material';
import EmailStatus from '../EmailStatus';
import { FieldErrors } from 'react-hook-form';
import { OrganizationReceiptSchemaForm } from '@/schemas';

// ----------------------------------------------------------------------
type Props = {
  readonly errors: FieldErrors<OrganizationReceiptSchemaForm>;
};
// ----------------------------------------------------------------------
export default function CustomSignatoryForm({ errors }: Props) {
  const { org } = useOrg();

  return (
    <>
      <Stack>
        <Typography variant="subtitle2">Email configuration</Typography>
        <Typography variant="caption" sx={{ pb: 1 }}>
          This setup is for emails that look like “name@organization.com”.
        </Typography>
        <Typography variant="caption">
          {org?.emailSignature?.confirmed
            ? '"Signatory Email" can only be deleted and re-created again.'
            : 'Step 1: Enter your signatory email below and click "Update"'}
        </Typography>
        {!org?.emailSignature?.confirmed && (
          <>
            <Typography variant="caption">
              Step 2: Check your inbox and click the "Confirm Sender Signature" in the email
            </Typography>
            <Typography variant="caption">Step 3: Check here and click "Verify"</Typography>
          </>
        )}
      </Stack>
      <RHFTextField
        disabled={!!org?.emailSignature?.signatoryEmail}
        name="signatoryEmail"
        label="Signatory Email"
        helperText={
          errors.signatoryEmail?.message || 'Email address from which user will receive an email'
        }
        inputProps={{ maxLength: 60 }}
        // disabled={emailDisabled}
      />
      <EmailStatus signature={org?.emailSignature} />
    </>
  );
}
