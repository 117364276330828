// Functions to calc and display the errors in the Map Fields step.

import { ImportField, ImportType } from '@/schemas/importData';

// Return a message on error, empty string otherwise
export function getErrorMessage(mapFields: ImportField[], importType: ImportType): string {
  const fieldNames = mapFields.map((f) => f.fieldName);
  const displayNames = mapFields.map((f) => f.displayName);
  if (importType !== ImportType.onlyDonations) {
    const hasFirst = fieldNames.includes('firstName');
    const hasLast = fieldNames.includes('lastName');
    const hasFirstLast = fieldNames.includes('firstLast');
    const hasLastFirst = fieldNames.includes('lastFirst');

    // both or only donors
    if (!hasFirstLast && !hasLastFirst && !(hasFirst && hasLast)) {
      return 'You need to include the First Name and Last Name fields, or a field that combines them, to import donations';
    }
    if (
      (hasFirstLast && hasLastFirst) ||
      ((hasFirstLast || hasLastFirst) && (hasFirst || hasLast))
    ) {
      return 'You cannot include more than one field that includes the first name, or more than one that includes the last name';
    }
    const hasCity = fieldNames.includes('city');
    const hasState = fieldNames.includes('state');
    const hasCountry = fieldNames.includes('country');
    const hasCityState = fieldNames.includes('cityState');
    const hasCityStateCountry = fieldNames.includes('cityStateCountry');

    if (
      (hasCityState && hasCityStateCountry) ||
      ((hasCityState || hasCityStateCountry) && (hasCity || hasState)) ||
      (hasCityStateCountry && hasCountry)
    ) {
      return 'You cannot include more than one field that includes the same part of the address (city, state or province, or country)';
    }
  }
  if (importType !== ImportType.onlyDonors) {
    // both or only donations - needs matching field
    if (!fieldNames.includes('email') && !fieldNames.includes('memberNumber')) {
      return 'Email or Member/Envelope # field required for matching';
    }
    if (
      !(
        fieldNames.includes('date') &&
        fieldNames.includes('amount') &&
        fieldNames.includes('category')
      )
    ) {
      return 'You need to include at least the Date, Amount and Category fields to import donations';
    }
  }
  const hasAmountAdvantage = fieldNames.includes('amountAdvantage');
  const hasAmountEligible = fieldNames.includes('amountEligible');

  if (hasAmountAdvantage && hasAmountEligible) {
    return 'You can only include one of the Eligible Amount and Advantage Amount fields - the other will be calculated from the Amount and the included field';
  }
  if ((hasAmountAdvantage || hasAmountEligible) && !fieldNames.includes('advantageDescription')) {
    return `If you  include the ${hasAmountEligible ? 'Eligible Amount' : 'Advantage Amount'} field, you must also include the Advantage Description field`;
  }

  // check for dups
  for (let i = 0; i < fieldNames.length; ++i) {
    const name = fieldNames[i];
    if (!!name && name !== 'tag' && fieldNames.includes(name, i + 1)) {
      return `You have included the field ${displayNames[i]} more than once. The only field that can be included multiple times is Tag.`;
    }
  }

  return '';
}
