import { TableRow, TableCell, Link, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import PATHS, { getPath } from '@routes/paths';
import { TColumn } from '@typedefs/app';
import { TagItem } from '@pages/donors/dialogs/Settings/DonorTags/utils/renderTags';
import { CategoryItem } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import type { ReceiptListHeader, ReceiptListItem } from './config';

// ----------------------------------------------------------------------
type Props = {
  readonly columns: TColumn<ReceiptListHeader>[];
  readonly receipt: ReceiptListItem;
  readonly onClick: (id: string) => void;
};

// ----------------------------------------------------------------------
export default function Row({ receipt, onClick, columns }: Props) {
  const cells = columns
    .filter((c) => c.visible || ['options'].includes(c.id))
    .map((column) => {
      switch (column.id) {
        case 'donorName':
          return (
            <TableCell
              key={column.id}
              align={column.align}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Link
                variant="body2"
                component={RouterLink}
                to={`${getPath(PATHS.org.donors.donor.edit, {
                  orgId: receipt._receipt.orgId,
                  donorId: receipt._donor.id,
                })}`}
                sx={{ color: 'initial' }}
              >
                <Box dangerouslySetInnerHTML={{ __html: receipt.donorName }} />
              </Link>
            </TableCell>
          );

        case 'tags':
          return (
            <TableCell key={column.id} align={column.align}>
              {receipt._tags.map((tag) => (
                <TagItem key={tag.id} tag={tag} />
              ))}
            </TableCell>
          );
        case 'categories':
          return (
            <TableCell key={column.id} align={column.align}>
              {receipt._categories.map((c) => (
                <CategoryItem key={c.id} category={c} />
              ))}
            </TableCell>
          );

        default:
          const value = receipt[column.id] || '';
          return (
            <TableCell
              key={column.id}
              align={column.align}
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            />
          );
      }
    });

  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
        '&.MuiTableRow-hover:hover': {
          background: '#007b5514',
        },
        '& strong': { color: (theme) => theme.palette.secondary.light },
      }}
      onClick={() => onClick(receipt.id)}
    >
      {cells}
    </TableRow>
  );
}
