import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Receipt } from '@shared/types';
import { TReceiptVariables } from '@typedefs/donation';

const styles = StyleSheet.create({
  row: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  bold: { fontFamily: 'Helvetica-Bold' },
});

// ----------------------------------------------------------------------
type Props = {
  readonly vars: TReceiptVariables;
  readonly receiptNumber: number;
  readonly receiptDate: string | Date;
  readonly year: number;
  type?: Receipt.ReceiptActionType;
};
// ----------------------------------------------------------------------
export default function ReceiptInfo({ vars, receiptNumber, receiptDate, year, type }: Props) {
  const renderType = (type: Receipt.ReceiptActionType) => {
    if (
      type === Receipt.ReceiptActionType.correction ||
      type === Receipt.ReceiptActionType.corrected
    )
      return 'CORRECTED RECEIPT';

    return 'DUPLICATE RECEIPT';
  };

  return (
    <View>
      {type === Receipt.ReceiptActionType.correction ||
      type === Receipt.ReceiptActionType.corrected ||
      type === Receipt.ReceiptActionType.replacement ||
      type === Receipt.ReceiptActionType.replaced ? (
        <View style={styles.row}>
          <Text style={styles.bold} />
          <Text style={styles.bold}>{renderType(type)}</Text>
        </View>
      ) : null}

      <View style={styles.row}>
        <Text style={styles.bold}>Receipt No.</Text>
        <Text>{vars.fReceiptNumber(receiptNumber, year)}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.bold}>Issued on:</Text>
        <Text>{vars.fLongDate(receiptDate)}</Text>
      </View>

      {/* <View style={styles.row}>
            <Text style={styles.bold}>Envelope #:</Text>
            <Text>1234</Text>
          </View> */}
    </View>
  );
}
