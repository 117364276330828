import { View, Text } from '@react-pdf/renderer';
import { TReceiptVariables } from '@typedefs/donation';

// ----------------------------------------------------------------------
type Props = {
  readonly vars: TReceiptVariables;
  readonly receiptTotal: number;
  readonly year: number;
};
// ----------------------------------------------------------------------
export default function ReceiptBody({ vars, receiptTotal, year }: Props) {
  return (
    <View>
      <Text>Official {year} Receipt for Income Tax Purposes</Text>

      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
        <Text style={{ fontFamily: 'Helvetica-Bold', marginRight: 4 }}>
          Thank you for your contribution of:
        </Text>
        <Text style={{ fontSize: 14, marginTop: -2 }}>{vars.fCurrency(receiptTotal)}</Text>
      </View>
    </View>
  );
}
