import { Currency, Organization, Role } from '@shared/types/organization';
import { AutocompleteItem } from '@typedefs/app';
import { allLangs } from '../locales/config-lang';
import { Country } from '@shared/types';

// ----------------------------------------------------------------------
export type TOrgState = {
  // org global state
  isInitialized: boolean;
  orgs: Organization[];

  // specific org state
  orgId?: string;
  org?: Organization;
  orgInitialized: boolean;
};

// ----------------------------------------------------------------------
// Types

// ----------------------------------------------------------------------
// ACTIONS & RESPONSES
export type TOrgInitReq = { readonly userId?: string };
export type TOrgInitRes = { readonly orgs: Organization[] };

export type TOrgSwitchReq = { readonly orgId?: string };
export type TOrgSwitchRes = { readonly org?: Organization };

export type TOrgGetUsersReq = { readonly orgId: string; readonly userIds: string[] };

// ----------------------------------------------------------------------
// values
export const CurrencyList: AutocompleteItem<Currency, Country>[] = [
  { value: Currency.usd, label: 'USD (US Dollar)', related: [Country.us] },
  { value: Currency.cad, label: 'CAD (Canadian Dollar)', related: [Country.ca] },
];
export const CountryList: AutocompleteItem<Country>[] = [
  { value: Country.us, label: 'United States' },
  { value: Country.ca, label: 'Canada' },
];
export const LanguageList: AutocompleteItem[] = allLangs.map(
  ({ value, label, relatedCountries }) => ({
    value,
    label,
    related: relatedCountries,
  })
);

// These are the date entry and usual date display formats the user can choose.
// Some dates, like in the headers of reports and receipts, are not displayed with this,
// but rather with fLongDate().
export const FormatDateList: AutocompleteItem<string, Country>[] = [
  { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY', related: [Country.us] },
  { value: 'dd/MM/yyyy', label: 'DD/MM/YYYY' },
  { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD', related: [Country.ca] },
];

export const FormatTimeList: AutocompleteItem[] = [
  { value: 'p', label: '12h (1:13 PM)', related: [Country.us, Country.ca] },
  { value: 'HH:mm', label: '24h (13:13)', related: [] },
];

export const RoleList: AutocompleteItem<Role>[] = [
  { value: Role.owner, label: 'Admin', helperText: 'Full access' },
  {
    value: Role.editor,
    label: 'Editor',
    helperText: 'Full access, but cannot manage users',
  },
  {
    value: Role.contributor,
    label: 'Contributor',
    helperText: 'Full access, but cannot manage users or issue receipts.',
  },
  {
    value: Role.donorOnly,
    label: 'Donor-Only',
    helperText:
      'Partial access. Can view and modify donors only. Cannot view or modify donations, receipts, or users',
  },
  {
    value: Role.readOnly,
    label: 'Read-Only',
    helperText: 'Partial access. Can view everything but cannot modify anything',
  },
  // { value: Role.limited, label: 'Envelope-Only', helperText: 'Partial access for "counters" or "tellers". Can view envelope #s (not donor names) and only work in the current week' },
];

export const FeedbackTopics: AutocompleteItem[] = [
  { value: 'account-billing', label: 'Account & Billing' },
  { value: 'product-feedback', label: 'Product Feedback' },
  { value: 'request-report', label: 'Report Request' },
  { value: 'request-feature', label: 'Feature Request' },
  { value: 'other', label: 'Other' },
];

const CanadaProvinces: AutocompleteItem[] = [
  { value: 'ab', label: 'Alberta' },
  { value: 'bc', label: 'British Columbia' },
  { value: 'mb', label: 'Manitoba' },
  { value: 'nl', label: 'Newfoundland and Labrador' },
  { value: 'nb', label: 'New Brunswick' },
  { value: 'nt', label: 'Northwest Territories' },
  { value: 'ns', label: 'Nova Scotia' },
  { value: 'nu', label: 'Nunavut' },
  { value: 'on', label: 'Ontario' },
  { value: 'pe', label: 'Prince Edward Island' },
  { value: 'qc', label: 'Quebec' },
  { value: 'sk', label: 'Saskatchewan' },
  { value: 'yt', label: 'Yukon' },
];

const USStates: AutocompleteItem[] = [
  { value: 'al', label: 'Alabama' },
  { value: 'ak', label: 'Alaska' },
  { value: 'az', label: 'Arizona' },
  { value: 'ar', label: 'Arkansas' },
  { value: 'as', label: 'American Samoa' },
  { value: 'ca', label: 'California' },
  { value: 'co', label: 'Colorado' },
  { value: 'ct', label: 'Connecticut' },
  { value: 'de', label: 'Delaware' },
  { value: 'dc', label: 'District of Columbia' },
  { value: 'fl', label: 'Florida' },
  { value: 'ga', label: 'Georgia' },
  { value: 'gu', label: 'Guam' },
  { value: 'hi', label: 'Hawaii' },
  { value: 'id', label: 'Idaho' },
  { value: 'il', label: 'Illinois' },
  { value: 'in', label: 'Indiana' },
  { value: 'ia', label: 'Iowa' },
  { value: 'ks', label: 'Kansas' },
  { value: 'ky', label: 'Kentucky' },
  { value: 'la', label: 'Louisiana' },
  { value: 'me', label: 'Maine' },
  { value: 'md', label: 'Maryland' },
  { value: 'ma', label: 'Massachusetts' },
  { value: 'mi', label: 'Michigan' },
  { value: 'mn', label: 'Minnesota' },
  { value: 'ms', label: 'Mississippi' },
  { value: 'mo', label: 'Missouri' },
  { value: 'mt', label: 'Montana' },
  { value: 'ne', label: 'Nebraska' },
  { value: 'nv', label: 'Nevada' },
  { value: 'nh', label: 'New Hampshire' },
  { value: 'nj', label: 'New Jersey' },
  { value: 'nm', label: 'New Mexico' },
  { value: 'ny', label: 'New York' },
  { value: 'nc', label: 'North Carolina' },
  { value: 'nd', label: 'North Dakota' },
  { value: 'mp', label: 'Northern Mariana Islands' },
  { value: 'oh', label: 'Ohio' },
  { value: 'ok', label: 'Oklahoma' },
  { value: 'or', label: 'Oregon' },
  { value: 'pa', label: 'Pennsylvania' },
  { value: 'pr', label: 'Puerto Rico' },
  { value: 'ri', label: 'Rhode Island' },
  { value: 'sc', label: 'South Carolina' },
  { value: 'sd', label: 'South Dakota' },
  { value: 'tn', label: 'Tennessee' },
  { value: 'tx', label: 'Texas' },
  { value: 'ut', label: 'Utah' },
  { value: 'vt', label: 'Vermont' },
  { value: 'va', label: 'Virginia' },
  { value: 'vi', label: 'Virgin Islands' },
  { value: 'wa', label: 'Washington' },
  { value: 'wv', label: 'West Virginia' },
  { value: 'wi', label: 'Wisconsin' },
  { value: 'wy', label: 'Wyoming' },
];
export const StateList = {
  us: USStates,
  ca: CanadaProvinces,
};
