import { useState } from 'react';
import {
  Button,
  Stack,
  Popover,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { PageOrientation, PageSize } from '@typedefs/app';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  orientation: PageOrientation;
  setOrientation: (orientation: PageOrientation) => void;
  size: PageSize;
  setSize: (size: PageSize) => void;
};

// ----------------------------------------------------------------------
export default function ReportLayout(props: Props) {
  const { orientation, size, setOrientation, setSize } = props;
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);

  const handleChangeOrientation = (e: React.SyntheticEvent, orientation: PageOrientation) => {
    setOrientation(orientation);
  };

  const handleChangeSize = (e: SelectChangeEvent<PageSize>) => {
    setSize(e.target.value as PageSize);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        startIcon={<Iconify icon="mdi:pencil" />}
        sx={{ justifyContent: 'left', width: 'fit-content', minWidth: 160 }}
      >
        Edit Page Layout
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack spacing={2} sx={{ position: 'relative', minWidth: 280 }} p={2}>
          <Stack spacing={0} direction="column" width={'100%'}>
            <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
              Select page orientation
            </Typography>
            <ToggleButtonGroup
              value={orientation}
              exclusive
              onChange={handleChangeOrientation}
              aria-label="PDF orientation"
            >
              <ToggleButton value="portrait" aria-label="portrait" sx={{ width: '100%' }}>
                Portrait
              </ToggleButton>
              <ToggleButton value="landscape" aria-label="landscape" sx={{ width: '100%' }}>
                Landscape
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          <Stack spacing={0} direction="column" width={'100%'}>
            <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
              Select page format
            </Typography>
            <Select id="select-page-size" value={size} onChange={handleChangeSize}>
              {Object.keys(PageSize).map((s: string) => (
                <MenuItem key={s} value={s}>
                  {PageSize[s as PageSize].value}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}
