import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import type { ImportData } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Create
export const processImportData = httpsCallable<ImportData.ImportDataReq, boolean>(
  firebaseFunctions,
  'importData-doImport'
);
