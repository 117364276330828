import { orderBy as _orderBy } from 'lodash';
import { Category, Donation, PaymentMethod } from '@shared/types';

// ----------------------------------------------------------------------
type TFilters = {
  data: Donation.Donation[];
  order: 'asc' | 'desc';
  orderBy: string;

  categories: Category.Category[];
  paymentMethods: PaymentMethod.PaymentMethod[];
};
export default function applyFilters({
  data,
  order,
  orderBy,
  categories,
  paymentMethods,
}: TFilters) {
  if (orderBy === 'date') {
    return _orderBy(data, (d) => new Date(d.date).getTime(), order);
  }
  if (orderBy === 'paymentMethod') {
    return _orderBy(
      data,
      (d) => paymentMethods.find((p) => p.id === d.paymentMethodId)?.name,
      order
    );
  }
  if (orderBy === 'category') {
    return _orderBy(data, (d) => categories.find((cat) => cat.id === d.categoryId)?.name, order);
  }
  return _orderBy(data, orderBy, order);
}
