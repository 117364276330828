import { useState } from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';

import useRole from '@hooks/useRole';
import DeleteDialog from './DeleteDialog';

// ----------------------------------------------------------------------
export default function DeleteOrg() {
  const [dialogOpen, dialogSetOpen] = useState(false);
  const { hasAccess } = useRole();

  if (!hasAccess([])) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="subtitle1" mb={2}>
              Delete organization
            </Typography>

            <Typography variant="body2">
              Would you like to permanently delete this organization? You will immediately lose
              access and all data will be erased within 30 days. Please save any data you need in
              advance.
            </Typography>

            <Stack spacing={2} direction="row" alignItems="center">
              <DeleteDialog open={dialogOpen} setOpen={dialogSetOpen} />
              <Typography variant="body2">
                By proceeding, <strong>I acknowledge the risk</strong>.{' '}
              </Typography>
              <Button
                size="medium"
                sx={{ minWidth: 150 }}
                color="error"
                onClick={() => dialogSetOpen(true)}
              >
                Delete Organization
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>

      {/* <Card sx={{ p: 2 }}>
        <Typography variant="subtitle2">Debug information:</Typography>
        <Typography variant="body2">Env: {import.meta.env.MODE}</Typography>
        <Typography variant="body2">Release: {import.meta.env.VITE_APP_RELEASE}</Typography>
      </Card> */}
    </Stack>
  );
}
