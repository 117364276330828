import { useFormContext, Controller } from 'react-hook-form';
import {
  Switch,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
} from '@mui/material';

// ----------------------------------------------------------------------
type IProps = Omit<FormControlLabelProps, 'control'>;
interface Props extends IProps {
  name: string;
  helperText?: string;
}

// ----------------------------------------------------------------------
export default function RHFSwitch({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <FormGroup sx={other.sx}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => <Switch {...field} checked={field.value} />}
          />
        }
        {...other}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormGroup>
  );
}
