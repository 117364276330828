import { useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import useOrg from '@hooks/useOrg';
import PATHS, { getPath } from '@routes/paths';
import { useSnackbar } from 'notistack';

import LoadingScreen from '@components/LoadingScreen';

export default function ConfirmEmail() {
  const { user } = useAuth();
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const { confirmEmail } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmEmail = useCallback(async () => {
    if (user?.emailVerified) {
      navigate(getPath(PATHS.root, {}), { replace: true });
      return;
    }

    if (!user || !tokenId) {
      enqueueSnackbar('There was an issue with email confirmation. Please try again!', {
        variant: 'error',
      });
      navigate(getPath(PATHS.root, {}), { replace: true });
      return;
    }

    try {
      const userRes = await confirmEmail({
        user: user!,
        tokenId: tokenId!,
      });

      userRes?.emailVerified
        ? enqueueSnackbar('Email was successfully confirmed!')
        : enqueueSnackbar(`There was an issue with email confirmation. Please try again!`, {
            variant: 'error',
          });
    } catch (e) {
      enqueueSnackbar('There was an issue with email confirmation. Please try again!', {
        variant: 'error',
      });
    }
    navigate(getPath(PATHS.root, {}), { replace: true });
  }, [confirmEmail, enqueueSnackbar, navigate, tokenId, user]);

  useEffect(() => {
    handleConfirmEmail();
  }, [user, tokenId, handleConfirmEmail]);

  return <LoadingScreen withLoader id="email-confirmation" text="Confirming email..." />;
}
