import { useMemo, useState } from 'react';
import { Autocomplete, Card, Divider, Stack, TextField, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { pdf } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { PageSize } from '@typedefs/app';
import blobToDataUrl from '@utils/blobToDataUrl';
import { TDonorWithDonations } from '@typedefs/donation';
import useResponsive from '@hooks/useResponsive';

import Editor from '@components/Editor';
import { ReceiptIssueSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import EmailTemplate, {
  renderTemplate,
  renderSubject,
  defaultState,
  defaultSubject,
} from './EmailTemplate';

// ----------------------------------------------------------------------
export default function ReviewEmail({
  stepData,
  updateStepData,
}: TStepProps<ReceiptIssueSchemaForm>) {
  const isDesktop = useResponsive('up', 'lg');
  const { enqueueSnackbar } = useSnackbar();
  const { fFullName } = useFormat();
  const { sendReceiptTestEmail, getReceiptPartitions, getReceiptIssueGroups } = useDonation();
  const { org } = useOrg();
  const [isLoading, setLoading] = useState(false);

  const donorNames = useMemo(
    () => sortBy(stepData.donors, (d) => fFullName(d)),
    [fFullName, stepData.donors]
  );

  // const [preset, setPreset] = useState('10');
  const [previewDonor, setPreviewDonor] = useState<TDonorWithDonations>(donorNames[0]);

  // --------------------------------------------------
  const donorGroups = useMemo(() => {
    const receiptPartitions = getReceiptPartitions(org!.address.country, [previewDonor]);
    return getReceiptIssueGroups(org!, receiptPartitions);
  }, [getReceiptPartitions, getReceiptIssueGroups, org, previewDonor]);

  // --------------------------------------------------
  // const handlePresetChange = (event: SelectChangeEvent) => {
  //   setPreset(event.target.value as string);
  //   updateStepData({ email: { ...stepData.email, template: renderTemplate } });
  // };

  const handleIssueTestEmail = async () => {
    if (
      !org ||
      !previewDonor.email ||
      !stepData.email.subject ||
      !stepData.letter.size ||
      !stepData.letter.Doc
    ) {
      enqueueSnackbar('Error sending test email!', { variant: 'error' });
      console.error('Missing data for test email');
      return;
    } else if (!org.emailSignature) {
      enqueueSnackbar('You need to setup your email signature before you can send emails', {
        variant: 'error',
      });
      return;
    }

    setLoading(true);

    const pdfDoc = (
      <stepData.letter.Doc
        title="Receipt"
        description="Receipt"
        size={PageSize[stepData.letter.size]}
        donorGroups={donorGroups}
        withLetter={stepData.letter.withLetter}
        preview
        options={stepData.options}
        year={donorGroups[0].groups[0].year}
      />
    );
    const attachment = await blobToDataUrl(await pdf(pdfDoc).toBlob(), true);
    try {
      await sendReceiptTestEmail({
        orgId: org.id,
        emailSignature: org.emailSignature,
        email: {
          to: '',
          subject: renderSubject(donorGroups[0].vars, stepData.email.subject),
          body: renderTemplate(donorGroups[0].vars, stepData.email.html),
          attachment,
        },
      });
      enqueueSnackbar('Test email sent!');
    } catch (e) {
      enqueueSnackbar('Error sending test email!', { variant: 'error' });
    }

    setLoading(false);
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateStepData({
      email: {
        ...stepData.email,
        subject: event.target.value,
      },
    });
  };

  const handleEditorChange = (htmlString: string, stateString: string) => {
    updateStepData({
      email: {
        ...stepData.email,
        state: stateString,
        html: htmlString,
      },
    });
  };

  const handleEditorReset = (htmlString: string) => {
    updateStepData({
      email: {
        subject: defaultSubject,
        html: htmlString,
        state: defaultState,
      },
    });
  };

  // if we don't include the following, the reset can fail to then display the right subject
  const currentSubject = useMemo(() => stepData.email.subject || '', [stepData.email.subject]);

  // --------------------------------------------------
  if (!org || !org.emailSignature) return null;

  return (
    <Stack spacing={1} height="100%" direction={{ xs: 'column', lg: 'row' }}>
      <Stack spacing={2} p={2}>
        {/* <TextField
          fullWidth
          label="Email from"
          value={`${org.emailSignature.signatoryName} <${org.emailSignature.signatoryEmail}>`}
          disabled
        /> */}

        <TextField
          required
          fullWidth
          label="Email subject"
          value={currentSubject}
          onChange={handleSubjectChange}
          inputProps={{ maxLength: 100 }}
        />

        <Editor
          defaultState={stepData.email?.state}
          onChange={handleEditorChange}
          onReset={handleEditorReset}
        />
      </Stack>

      {isDesktop ? <Divider orientation="vertical" flexItem /> : <Divider />}

      <Stack spacing={2} p={2}>
        {/* <Typography variant="h6">Preview</Typography> */}
        <Stack direction="row" spacing={2}>
          <Autocomplete
            disablePortal
            id="select-preview-donor"
            options={donorNames}
            sx={{ width: '70%' }}
            autoHighlight
            autoComplete
            disableClearable
            freeSolo={false}
            value={previewDonor}
            onChange={(e, newValue) => setPreviewDonor(newValue)}
            getOptionLabel={(option) => `${fFullName(option)} (${option.email})`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="Choose a preview donor" />}
          />
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            onClick={handleIssueTestEmail}
            sx={{ width: '30%', flexDirection: 'column' }}
          >
            Send test email to me
            <Typography
              color={isLoading ? 'inherit' : 'gray'}
              variant="body2"
              mt={-0.5}
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              {org.emailSignature.signatoryEmail}
            </Typography>
          </LoadingButton>
        </Stack>

        <Card
          sx={{
            p: 2,
            overflow: 'visible',
            background: (theme) => theme.palette.grey[100],
            boxShadow: 'none',
          }}
        >
          {renderSubject(donorGroups[0].vars, stepData.email.subject)}
        </Card>
        <EmailTemplate vars={donorGroups[0].vars} html={stepData.email.html} />
      </Stack>
    </Stack>
  );
}
