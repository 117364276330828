import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Stack, Typography, Button, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Outlet } from 'react-router';

import { MemberEnvelopeSettingsSchemaForm, memberEnvelopeSettingsSchemaResolver } from '@/schemas';
import PATHS from '@routes/paths';
import useOrg from '@hooks/useOrg';
import useNavigate from '@hooks/useNavigate';
import { FormProvider, RHFCheckbox } from '@components/hook-form';

// ----------------------------------------------------------------------
export default function MemberEnvelope() {
  const navigate = useNavigate();
  const { org, updateOrg } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: MemberEnvelopeSettingsSchemaForm = useMemo(
    () => ({
      memberNumbers: org?.memberNumbers || false,
    }),
    [org?.memberNumbers]
  );

  // --------------- form ---------------
  const methods = useForm<MemberEnvelopeSettingsSchemaForm>({
    resolver: memberEnvelopeSettingsSchemaResolver,
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const onSubmit = async (data: MemberEnvelopeSettingsSchemaForm) => {
    if (!org) return null;
    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          memberNumbers: data.memberNumbers,
        },
      });

      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  const handleAssignNumbers = () => {
    navigate(PATHS.org.donors.settings.donors.memberEnvelopeAssign, {});
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Outlet />

      <Stack sx={{ marginTop: 3 }}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <RHFCheckbox
                name="memberNumbers"
                label=""
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28, width: 28, height: 28 } }}
              />

              <Stack>
                <Typography variant="subtitle2">
                  Would you like to enable Member/Envelope #s for your organization?
                </Typography>
                <Typography variant="caption">
                  This is most commonly used for churches that want to anonymize their donors during
                  collection and data entry.
                </Typography>
              </Stack>
            </Stack>

            {org?.memberNumbers && <Button onClick={handleAssignNumbers}>Assign Numbers</Button>}
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          paddingTop={2}
          sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}
        >
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty}
            sx={{ width: { xs: '50%', md: 'auto' } }}
          >
            Update
          </LoadingButton>
        </Grid>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </FormProvider>
  );
}
