import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Stack, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Outlet } from 'react-router';

import { PledgeSettingsSchemaForm, pledgeSettingsSchemaResolver } from '@/schemas';

import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';

import { FormProvider, RHFCheckbox } from '@components/hook-form';
import DefaultPledgeCategory, { allCategories } from './DefaultPledgeCategory';

// ----------------------------------------------------------------------
export default function Pledges() {
  const { org, updateOrg } = useOrg();
  const { getCategoryById } = useDonation();
  const { enqueueSnackbar } = useSnackbar();

  // Use this to hide the default pledge category briefly while saving so it
  // doesn't show the old value
  const [loading, setLoading] = useState(false);

  const defaultPledgeCategory = getCategoryById(org?.defaultPledgeCategoryId) || allCategories;

  const defaultValues: PledgeSettingsSchemaForm = useMemo(
    () => ({
      pledgesEnabled: !!org?.pledgesEnabled,
      defaultPledgeCategory: defaultPledgeCategory,
    }),
    [defaultPledgeCategory, org?.pledgesEnabled]
  );

  // --------------- form ---------------
  const methods = useForm<PledgeSettingsSchemaForm>({
    resolver: pledgeSettingsSchemaResolver,
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    watch,
  } = methods;

  const watchedPledgesEnabled = watch('pledgesEnabled');

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // --------------- actions ---------------
  const onSubmit = async (data: PledgeSettingsSchemaForm) => {
    if (!org) return null;

    setLoading(true);
    try {
      const res = await updateOrg({
        orgId: org.id,
        update: {
          pledgesEnabled: data.pledgesEnabled,
          defaultPledgeCategoryId: data.defaultPledgeCategory?.id || '',
        },
      });

      setTimeout(() => setLoading(false), 1000);
      res ? enqueueSnackbar('Updated!') : enqueueSnackbar('Failed to update', { variant: 'error' });
    } catch (error) {
      setLoading(false);
      reset();
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Outlet />
      <Stack marginTop={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" sx={{ pt: 2 }}>
              <RHFCheckbox
                name="pledgesEnabled"
                label=""
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28, width: 28, height: 28 } }}
              />

              <Stack>
                <Typography variant="subtitle2">
                  Would you like to enable Pledges for your organization's donors?
                </Typography>
                <Typography variant="caption">
                  Pledges are undertakings from your donors to donate a certain amount within a
                  certain range of dates (within one year), optionally for one donation category.
                </Typography>
              </Stack>
            </Stack>
            {watchedPledgesEnabled && (
              <Stack sx={{ pt: 2, pl: 5 }}>
                <DefaultPledgeCategory defaultPledgeCat={defaultPledgeCategory} loading={loading} />
              </Stack>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty}
              sx={{ width: { xs: '50%', md: 'auto' } }}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </FormProvider>
  );
}
