import {
  Button,
  Stack,
} from '@mui/material';

import { Category, Donor, Tag } from '@shared/types';
import Dialog from '@components/Dialog';
import Iconify from '@components/Iconify';
import FilterCategories from '@components/table/TableFilters/FilterCategories';
import FilterTags from '@components/table/TableFilters/FilterTags';
import { TDonorWithDonations } from '@typedefs/donation';
import FilterDonor from './FilterDonor';
import { useState } from 'react';
import { MinAmountType } from '@/schemas';
import FilterMinAmount from './FilterMinAmount';

// ----------------------------------------------------------------------
export type FilterProps = {
  filterTags: Tag.Tag[];
  onFilterTagChange: (newValue: Tag.Tag[]) => void;
  filterCategories: Category.Category[];
  onFilterCategoryChange: (newValue: Category.Category[]) => void;
  filterDonor?: Donor.Donor;
  onFilterDonorChange: (donor: Donor.Donor | undefined) => void;
  donorsWithDonations: TDonorWithDonations[];
  isSingleDonor: boolean;
  filterMinAmount?: number;
  onFilterMinAmountChange: (newValue: number | undefined) => void;
  filterMinAmountType: MinAmountType;
  onFilterMinAmountTypeChange: (newValue: MinAmountType) => void;
  handleClearAll: () => void;
};

// ----------------------------------------------------------------------
export default function ToolbarFilters({
  filterTags,
  onFilterTagChange,
  filterCategories,
  onFilterCategoryChange,
  filterDonor,
  onFilterDonorChange,
  filterMinAmount,
  onFilterMinAmountChange,
  filterMinAmountType,
  onFilterMinAmountTypeChange,
  donorsWithDonations,
  isSingleDonor,
  handleClearAll,
}: FilterProps) {
  const [openDialog, setOpenDialog] = useState<HTMLElement | null>(null);

  const handleOpenFilters = (e: React.MouseEvent<HTMLElement>) => setOpenDialog(e.currentTarget);
  const handleCloseFilters = () => setOpenDialog(null);

  /* Using the method below only cleared one filter at a time, not all of them!
     So calling a method passed in from Recipients/index.tsx that clears all at once.
  const handleClearAll = () => {
    onFilterTagChange([]);
    onFilterCategoryChange([]);
    onFilterDonorChange(undefined);
    filterMinAmount = undefined;
    onFilterMinAmountChange(undefined);
  }; */

  const donorCount = donorsWithDonations.length;

  const filterCount =
    (!!filterDonor && donorCount > 1 ? 1 : 0) +
    (!!filterTags.length ? 1 : 0) +
    (!!filterCategories.length ? 1 : 0) +
    (!!filterMinAmount && filterMinAmount > 0 ? 1 : 0);

  return (
    <>
      <Button
        variant="text"
        startIcon={<Iconify icon="material-symbols:filter-alt-outline" />}
        onClick={handleOpenFilters}
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontWeight: 400,
        }}
      >
        Filters {!!filterCount && `(${filterCount})`}
      </Button>
      <Dialog
        open={!!openDialog}
        title="Filters"
        maxWidth="sm"
        onClose={handleCloseFilters}
        actions={
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Button variant="text" onClick={handleClearAll}>
              Clear all
            </Button>
            <Button variant="contained" onClick={handleCloseFilters}>
              Close
            </Button>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {!isSingleDonor && (
              <FilterTags
                filterTags={filterTags}
                setFilterTags={onFilterTagChange}
                sxProps={{ minWidth: 240 }}
              />
            )}
            <FilterCategories
              filterCategories={filterCategories}
              setFilterCategories={onFilterCategoryChange}
              sxProps={{ minWidth: 240 }}
            />
          </Stack>
          {!isSingleDonor && (
            <FilterDonor
              filterDonor={filterDonor}
              onFilterDonorChange={onFilterDonorChange}
              donorsWithDonations={donorsWithDonations}
              sxProps={{ minWidth: 240 }}
            />
          )}
          <FilterMinAmount
            filterMinAmount={filterMinAmount}
            onFilterMinAmountChange={onFilterMinAmountChange}
            filterMinAmountType={filterMinAmountType}
            onFilterMinAmountTypeChange={onFilterMinAmountTypeChange}
          />
        </Stack>
      </Dialog>
    </>
  );
}
