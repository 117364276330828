import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';

import LogoOnlyLayout from '@/layouts/LogoLayout';
import PATHS from '@routes/paths';
import Page from '@components/Page';
import ResetPasswordForm from './ResetPasswordForm';
import { ContentStyle } from './styles';

// ----------------------------------------------------------------------
export default function ResetPassword() {
  return (
    <Page title="Reset Password">
      <LogoOnlyLayout />

      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Forgot your password?
          </Typography>

          <Typography sx={{ color: 'text.secondary', mb: 5 }}>
            Please enter the email address associated with your account and We will email you a link
            to reset your password.
          </Typography>

          <ResetPasswordForm />

          <Button size="large" component={RouterLink} to={PATHS.login} sx={{ mt: 1 }}>
            Back
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
