import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import { Tag } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Get
// export const tagGetAllForOrg = httpsCallable<Tag.GetAllForOrgReq, Tag.GetAllForOrgRes>(
//   firebaseFunctions,
//   'tag-getAllForOrg'
// );

// ----------------------------------------------------------------------
// Create
export const tagCreate = httpsCallable<Tag.CreateReq, Tag.CreateRes>(
  firebaseFunctions,
  'tag-create'
);

// ----------------------------------------------------------------------
// Update
export const tagUpdate = httpsCallable<Tag.UpdateReq, Tag.UpdateRes>(
  firebaseFunctions,
  'tag-update'
);

// ----------------------------------------------------------------------
// Delete
export const tagDelete = httpsCallable<Tag.DeleteReq, Tag.DeleteRes>(
  firebaseFunctions,
  'tag-deleteRecord'
);
