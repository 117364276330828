import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import { Category } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Create
export const categoryCreate = httpsCallable<Category.CreateReq, Category.CreateRes>(
  firebaseFunctions,
  'category-create'
);

// ----------------------------------------------------------------------
// Update
export const categoryUpdate = httpsCallable<Category.UpdateReq, Category.UpdateRes>(
  firebaseFunctions,
  'category-update'
);

// ----------------------------------------------------------------------
// Delete
export const categoryDelete = httpsCallable<Category.DeleteReq, Category.DeleteRes>(
  firebaseFunctions,
  'category-deleteRecord'
);
