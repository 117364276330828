import { useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { Category } from '@shared/types';
import FilterCategories from '@components/table/TableFilters/FilterCategories';
import {
  AvailableSortOrder,
  SortOrder,
  orderDescription,
  sortByOrders,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import { TableDateRange } from '@components/table';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const {
    isDateValid,
    fDateToYearStart,
    fDateToYearEnd,
    fDateToDayStart,
    fDateToDayEnd,
    isDateBetween,
    fDate,
    fCurrency,
    fDateToISO,
    fReversedName,
    fJoinWithConjunction,
  } = useFormat();
  const { donors, donations, getCategoryById, getPaymentMethod } = useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fDateToYearStart(new Date()));
  const [dateTo, setDateTo] = useState<Date | undefined>(fDateToYearEnd(new Date()));
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');
  const [showDescription, setShowDescription] = useState(false);
  const [filterCategories, setFilterCategories] = useState<Category.Category[]>([]);
  const [filterDescription, setFilterDescription] = useState('');
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([
    { availablesIndex: 0 },
    { availablesIndex: 1 },
    { availablesIndex: 3 },
  ]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const [minDateFrom, maxDateTo] = useMemo(
    () => [new Date(1900, 0, 1), new Date(2099, 11, 31)],
    []
  );

  // ---------- memo
  const availableSortOrders: AvailableSortOrder[] = useMemo(() => {
    let arr: AvailableSortOrder[] = [
      { label: 'Name', field: (d) => d.name.toUpperCase() },
      { label: 'Date', field: (d) => fDateToISO(d.date) },
      { label: 'Amount', field: 'amount' },
      { label: 'Category', field: (d) => d.category.toUpperCase() },
      { label: 'Payment Method', field: (d) => d.paymentMethod.toUpperCase() },
    ];
    if (showDescription)
      arr.push({ label: 'Description', field: (d) => d.description.toUpperCase() });
    return arr;
  }, [fDateToISO, showDescription]);

  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 26 },
      { name: 'Date', width: 16 },
      { name: 'Amount ($)', width: 12, align: 'right' },
      { name: 'Category', width: 20 },
      { name: 'Payment Method', width: 24 },
    ];
    if (showDescription) {
      header.push({ name: 'Description', width: 24 });
      // Having added that, the total percentage widths is now > 100.
      // Calc the new total, and reduce the widths by a factor that ensures the
      // total percentage is now just under 100.
      const totalPercent = header.reduce((n, { width }) => n + Number(width), 0);
      header.forEach((h) => (h.width /= totalPercent / 100));
      setOrientation('landscape');
    }

    let filtered = filter(donations, (d) =>
      isDateBetween(
        new Date(d.date),
        fDateToDayStart(dateFrom || minDateFrom),
        fDateToDayEnd(dateTo || maxDateTo)
      )
    );
    if (filterCategories.length) {
      const categoryIds = filterCategories.map((c) => c.id);
      filtered = filter(filtered, (d) => categoryIds.includes(d.categoryId));
      const categoryNames = filterCategories.map((c) => c.name);
      setFilterDescription(
        `Including only ${
          filterCategories.length === 1 ? 'category' : 'categories'
        } ${fJoinWithConjunction(categoryNames, 'and')}`
      );
    }
    const total = filtered.reduce((n, { amount }) => n + Number(amount), 0);
    const grouped = groupBy(filtered, 'donorId');
    const itemsUnsorted = Object.keys(grouped)
      .map((donorId) => {
        const donor = donors.find((d) => d.id === donorId);
        return grouped[donorId].map((donation) => {
          let row = {
            name: fReversedName(donor),
            date: new Date(donation.date),
            amount: donation.amount,
            category: getCategoryById(donation.categoryId)?.name || '',
            paymentMethod: getPaymentMethod(donation.paymentMethodId, donation.paymentInfo),
            description: donation.description || '',
          };
          return row;
        });
      })
      .flat();
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map((s) => {
      const row = [s.name, fDate(s.date), fCurrency(s.amount), s.category, s.paymentMethod];
      if (showDescription) row.push(s.description);
      return row;
    });
    const summary: PdfTableSummaryField[] = [
      { columns: 2, text: `Totals: ${items.length} donations` },
      { columns: 1, text: `${fCurrency(total)}` },
    ];
    return { header, items, summary };
  }, [
    showDescription,
    donations,
    filterCategories,
    sortOrders,
    availableSortOrders,
    fCurrency,
    isDateBetween,
    fDateToDayStart,
    dateFrom,
    minDateFrom,
    fDateToDayEnd,
    dateTo,
    maxDateTo,
    fJoinWithConjunction,
    donors,
    fReversedName,
    getCategoryById,
    getPaymentMethod,
    fDate,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={name}
      description={description}
      dateReport={new Date()}
      dateFrom={dateFrom}
      dateTo={dateTo}
      orgName={org!.name}
      filterDescription={filterDescription}
      orderBy={orderDescription(sortOrders, availableSortOrders)}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="All Donations report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, url, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={
                !isDateValid(dateFrom || minDateFrom) ||
                !isDateValid(dateTo || maxDateTo) ||
                !blob ||
                !!error
              }
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'all_donations'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={1} direction="column" width={'100%'}>
              <Typography variant="button" sx={{ mb: 1, textTransform: 'inherit' }}>
                Change date range
              </Typography>
              <TableDateRange
                alignLeft
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            </Stack>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDescription}
                    onChange={() => {
                      setShowDescription(!showDescription);
                    }}
                  />
                }
                label={'Add Description Column'}
              />
            </FormGroup>
            <FilterCategories
              filterCategories={filterCategories}
              setFilterCategories={setFilterCategories}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
