import { useEffect, useMemo, useState } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import { formatDistance } from 'date-fns';

import ConfirmButton from '@components/ConfirmButton';
import useAuth from '@hooks/useAuth';
import useOrg from '@hooks/useOrg';

import Iconify from '@components/Iconify';
import Dialog from '@components/Dialog';
import { NAVBAR } from '../../config';

// ----------------------------------------------------------------------
export default function LockBanner() {
  const { enqueueSnackbar } = useSnackbar();
  const { org, disableLock } = useOrg();
  const { user: currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [now, setNow] = useState(Date.now);

  // refreshes the banner if lock is active
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (org?.lock.isLocked) {
      interval = setInterval(() => setNow(Date.now), 1000 * 30);
    }

    return () => {
      clearInterval(interval);
    };
  }, [org?.lock.isLocked]);

  useEffect(() => {
    if (!org?.lock.isLocked) {
      setOpen(false);
      setLoading(false);
    }
  }, [org?.lock.isLocked]);

  const lockAgo = useMemo(() => {
    if (org?.lock.lockedAt) {
      const lockedAt = new Date(org.lock.lockedAt);
      return formatDistance(lockedAt, now);
    }

    return '';
  }, [org?.lock.lockedAt, now]);

  if (!org) return null;
  const { lock } = org;
  const user = (lock.lockedBy && org.users[lock.lockedBy]?.displayName) || '';
  const currentUserIssued = currentUser?.id === lock.lockedBy;

  const handleForceUnlock = async () => {
    setLoading(true);
    try {
      await disableLock({ orgId: org.id });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Failed to remove lock', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (!lock.isLocked || (lock.isLocked && currentUserIssued)) {
    return null;
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 30,
        marginLeft: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px`,
        background: (theme) => theme.palette.secondary.light,
        zIndex: 1200,
      }}
    >
      <Iconify icon="material-symbols:warning-outline" width={20} height={20} mt="-2px" />

      <Typography variant="body2">
        <strong>{user}</strong> is currently issuing receipts (started {lockAgo} ago).
      </Typography>

      <Link variant="subtitle2" onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
        Learn more
      </Link>

      <Dialog
        open={open}
        title="Receipt issue in progress"
        maxWidth="sm"
        isLoading={isLoading}
        onClose={() => setOpen(false)}
        actions={
          <ConfirmButton color="error" loading={isLoading} onClick={handleForceUnlock}>
            Override and stop issuance
          </ConfirmButton>
        }
      >
        <Stack spacing={2}>
          <Typography>
            Any edits you make to donors or donations during this process will be updated
            immediately, even if they are part of <strong>{user}</strong>'s current receipt batch.
          </Typography>
          <Typography>
            If you want to stop the issuance and remove <strong>{user}</strong> from the process,
            click “Override and stop issuance”. Otherwise, the warning banner will automatically
            disappear once it's complete.
          </Typography>
        </Stack>
      </Dialog>
    </Stack>
  );
}
