import { uniq, orderBy as _orderBy } from 'lodash';
import { TDonorWithDonations } from '@typedefs/donation';
import { Donor } from '@shared/types';

// ----------------------------------------------------------------------
type TSorts = {
  data: TDonorWithDonations[];
  order: 'asc' | 'desc';
  orderBy: string;
  fReversedName: (donor?: Donor.Donor | undefined, memberYear?: number) => string;
};
export default function applySorts({ data, order, orderBy, fReversedName }: TSorts) {
  if (orderBy === 'name') {
    return _orderBy(data, (donor) => fReversedName(donor), order);
  }
  if (orderBy === 'donationsCount') {
    return _orderBy(data, (donor) => donor.donations.length, order);
  }
  if (orderBy === 'tags') {
    return _orderBy(data, (donor) => donor.tagIds.length, order);
  }
  if (orderBy === 'categories') {
    return _orderBy(data, (donor) => uniq(donor.donations.map((d) => d.categoryId)).length, order);
  }
  return _orderBy(data, orderBy, order);
}
