import { useMemo, useState } from 'react';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import filter from 'lodash/filter';

import * as analytics from '@fire/analytics';
import PATHS from '@routes/paths';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import { PageOrientation, PageSize } from '@typedefs/app';

import Dialog from '@components/Dialog';
import { PdfTableData, PdfTableHeaderProps, PdfTableSummaryField } from '@components/pdf';
import { ReportProps } from '@pages/reports/ReportCard';
import ReportDocument from '../../ReportDocument';
import ReportLayout from '../../ReportLayout';
import { first } from 'lodash';
import {
  AvailableSortOrder,
  SortOrder,
  sortByOrders,
  orderDescription,
} from '@pages/reports/reportSorting';
import SortDialog from '@pages/reports/Dialogs/Sort';
import PdfCsvMenu from '@components/pdf/PdfCsvMenu';

// ----------------------------------------------------------------------
export default function Report({ id, name, description }: ReportProps) {
  const navigate = useNavigate();
  const { fDate, fCurrency, fDateToISO, fReversedName, fReceiptNumber, fReceiptState } =
    useFormat();
  const { receipts, getReceiptDonor, getReceiptDonations, getYears } = useDonation();
  const { org } = useOrg();

  // ---------- form state
  const [orientation, setOrientation] = useState<PageOrientation>('portrait');
  const [size, setSize] = useState<PageSize>('letter');

  // period management
  const years = getYears();

  const [yearSelected, setYearSelected] = useState(Number(first(years)));
  const [sortOrders, setSortOrders] = useState<SortOrder[]>([{ availablesIndex: 3 }]);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  const availableSortOrders: AvailableSortOrder[] = useMemo(
    () => [
      { label: 'Name', field: (d) => d.name.toUpperCase() },
      { label: 'Date', field: (d: any) => fDateToISO(d.date) },
      { label: 'Amount', field: 'amount' },
      { label: 'Number', field: 'number' },
      { label: 'Type', field: 'type' },
    ],
    [fDateToISO]
  );

  // ---------- memo
  const data: PdfTableData = useMemo(() => {
    const header: PdfTableHeaderProps[] = [
      { name: 'Name', width: 30 },
      { name: 'Date', width: 20 },
      { name: 'Amount ($)', width: 12, align: 'right' },
      { name: 'Number', width: 13 },
      { name: 'Type', width: 14 },
    ];

    const filtered = filter(receipts, (r) => r.year === yearSelected);
    let total = 0;
    const itemsUnsorted = filtered.map((r) => {
      const donor = getReceiptDonor(r);
      const donations = getReceiptDonations(r);
      const amount = donations.reduce((sum, d) => (sum += d.amount), 0);
      total += amount;
      return {
        name: fReversedName(donor),
        date: new Date(r.date),
        amount: amount,
        number: fReceiptNumber(r.number, r.year),
        type: fReceiptState(r),
      };
    });
    const sorted = sortByOrders(itemsUnsorted, sortOrders, availableSortOrders);
    const items = sorted.map(
      (s) => [s.name, fDate(s.date), fCurrency(s.amount), s.number, s.type] as string[]
    );
    const summary: PdfTableSummaryField[] = [
      { columns: 2, text: `Totals: ${items.length} receipts` },
      { columns: 1, text: `${fCurrency(total)}` },
    ];
    return { header, items, summary };
  }, [
    receipts,
    sortOrders,
    availableSortOrders,
    fCurrency,
    yearSelected,
    getReceiptDonor,
    getReceiptDonations,
    fReversedName,
    fReceiptNumber,
    fReceiptState,
    fDate,
  ]);

  // --------------------------------------------------
  const handleClose = () => {
    navigate(PATHS.org.reports.root);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, blob: Blob) => {
    setPdfBlob(blob);
    e.preventDefault();
    e.stopPropagation();
    setOpenMenuActions(e.currentTarget);
  };

  // --------------------------------------------------
  const ReportDoc = (
    <ReportDocument
      title={name}
      description={description}
      dateReport={new Date()}
      dateFrom={new Date(Number(yearSelected), 0, 1)}
      dateTo={new Date(Number(yearSelected), 11, 31)}
      orgName={org!.name}
      orderBy={orderDescription(sortOrders, availableSortOrders)}
      data={data}
      orientation={orientation}
      size={PageSize[size]}
    />
  );

  return (
    <Dialog
      title="All Receipts report"
      maxWidth="lg"
      onClose={handleClose}
      actions={
        <BlobProvider document={ReportDoc}>
          {({ blob, loading, error }) => (
            <LoadingButton
              size="large"
              variant="contained"
              disabled={!blob || !!error}
              loading={loading}
              onClick={blob ? (e) => handleOpenMenu(e, blob) : undefined}
            >
              Download
            </LoadingButton>
          )}
        </BlobProvider>
      }
    >
      <PdfCsvMenu
        tableData={data}
        pdfBlob={pdfBlob}
        basename={'all_receipts'}
        openMenu={openMenu}
        setOpenMenu={setOpenMenuActions}
        handleClose={handleClose}
        analyticsPdfFn={() => analytics.donation.reportDownloadPDF(id)}
        analyticsCsvFn={() => analytics.donation.reportDownloadCSV(id)}
      />
      <Grid container spacing={3} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={3}>
          <Stack spacing={2} direction="column" width={'100%'}>
            <ReportLayout
              orientation={orientation}
              setOrientation={setOrientation}
              size={size}
              setSize={setSize}
            />
            <SortDialog
              availableOrders={availableSortOrders}
              currentOrders={sortOrders}
              setOrders={setSortOrders}
            />
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography>For year: </Typography>
              <Autocomplete
                id="select-year"
                size="small"
                options={years}
                sx={{ minWidth: 100 }}
                autoComplete
                autoHighlight
                disableClearable
                freeSolo={false}
                value={yearSelected.toString()}
                onChange={(e, newValue) => setYearSelected(Number(newValue))}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={9}>
          <PDFViewer showToolbar={false} width="100%" style={{ height: '50vh' }}>
            {ReportDoc}
          </PDFViewer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
