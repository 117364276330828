// Menu component that pops up for options of downloading a PdfTable to PDF or CSV

import { MenuItem } from '@mui/material';
import { PdfTableData } from './PdfTable';
import Iconify from '@components/Iconify';
import MenuPopover from '@components/MenuPopover';
import { Dispatch, SetStateAction } from 'react';
import { useSnackbar } from 'notistack';
import { pdf } from '@react-pdf/renderer';
import { format } from 'date-fns';
import saveAs from 'file-saver';

type Props = {
  tableData: PdfTableData | PdfTableData[];
  reportDoc?: JSX.Element | null; // need either reportDoc or pdfBlob
  pdfBlob?: Blob | null;
  basename: string;
  openMenu: HTMLElement | null;
  setOpenMenu: Dispatch<SetStateAction<HTMLElement | null>>;
  handleClose: () => void;
  analyticsPdfFn: () => void;
  analyticsCsvFn: () => void;
};

// ----------------------------------------------------------------------
export default function PdfTable({
  tableData,
  reportDoc = null,
  pdfBlob = null,
  basename,
  openMenu,
  setOpenMenu,
  handleClose,
  analyticsPdfFn,
  analyticsCsvFn,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenMenu(null);
  };

  // Note: this could fail if neither the pdfBob nor the reportDoc was passed in,
  // since both are optional args (but passing one of them is required for it to work).
  const handleCreateDownloadPDF = async () => {
    const blob = pdfBlob || (await pdf(reportDoc!).toBlob());
    handleDownloadPDF(blob, basename);
  };

  const handleDownloadPDF = (blob: Blob, basename: string) => {
    saveAs(blob, `${basename}_${format(new Date(), 'yyyy-MM-dd')}.pdf`);
    setTimeout(() => {
      enqueueSnackbar('Report exported to PDF!');
      analyticsPdfFn();
      handleClose();
    }, 0);
  };

  // This can handle a single PdfTableData or an array of them, to be shown sequentially
  // in the output CSV file.
  const handleDownloadCSV = () => {
    if (!(tableData instanceof Array)) tableData = [tableData];
    const csvsArray = tableData.map((data) => {
      const csvHeaders = data.header.map((h) => h.name);
      return [csvHeaders]
        .concat(data.items)
        .map((row) =>
          row
            .map((cell) => cell.replace(new RegExp('"', 'g'), '""')) // escape double colons
            .map((cell) => `"${cell}"`) // quote it
            .join(',')
        )
        .join('\r\n');
    });
    const csv = csvsArray.join('\r\n\r\n');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${basename}_${format(new Date(), 'yyyy-MM-dd')}.csv`);
    setTimeout(() => {
      enqueueSnackbar('Report exported to CSV!');
      analyticsCsvFn();
      handleClose();
    }, 0);
  };

  return (
    <MenuPopover
      open={Boolean(openMenu)}
      anchorEl={openMenu}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      arrow="bottom-left"
      sx={{
        mt: -1,
        width: 120,
        '& .MuiMenuItem-root': {
          px: 1,
          typography: 'body2',
          borderRadius: 0.75,
          '& svg': { mr: 1, width: 20, height: 20 },
        },
      }}
    >
      <MenuItem onClick={handleCreateDownloadPDF}>
        <Iconify icon={'fa6-solid:file-pdf'} />
        PDF
      </MenuItem>
      <MenuItem onClick={() => handleDownloadCSV()}>
        <Iconify icon={'fa6-solid:file-csv'} />
        CSV
      </MenuItem>
    </MenuPopover>
  );
}
