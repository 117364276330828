import { ImportDataSchemaForm, ImportField } from '@/schemas/importData';
import { Category, PaymentMethod, Tag } from '@shared/types';
import { ImportEntity } from '@shared/types/importData';
import { sortBy, sortedUniqBy } from 'lodash';

// Examine the data for new categories, tags and payment methods.
// Fill in categoriesMap, tagsMap and paymentMethodsMap.
// Return whether or not there are any new objects in any of them, plus the three maps,
//   as an array.
export default function calcNewObjects(
  stepData: ImportDataSchemaForm,
  categories: Category.Category[],
  tags: Tag.Tag[],
  paymentMethods: PaymentMethod.PaymentMethod[]
): [boolean, ImportEntity[], ImportEntity[], ImportEntity[]] {
  const { mapFields, data, hasHeaders } = stepData;

  const realData = hasHeaders ? data.slice(1) : data;

  const categoriesMap = getCategoriesMap(realData, mapFields, categories);
  const tagsMap = getTagsMap(realData, mapFields, tags);
  const paymentMethodsMap = getPaymentMethodsMap(realData, mapFields, paymentMethods);

  // console.log('categoriesMap', categoriesMap);
  // console.log('paymentMethodsMap', paymentMethodsMap);
  // console.log('tagsMap', tagsMap);

  return [
    categoriesMap.filter((c) => c.isNew).length +
      tagsMap.filter((t) => t.isNew).length +
      paymentMethodsMap.filter((pm) => pm.isNew).length >
      0,
    categoriesMap,
    tagsMap,
    paymentMethodsMap,
  ];
}

const getCategoriesMap = (
  realData: string[][],
  mapFields: ImportField[],
  categories: Category.Category[]
): ImportEntity[] => {
  let categoriesMap: ImportEntity[] = [];
  const i = mapFields.findIndex((f) => f.fieldName === 'category');
  if (i >= 0) {
    categoriesMap = sortedUniqBy(
      sortBy(
        realData
          .map((d) => ({
            lowerName: d[i].toLowerCase(),
            sampleName: '',
            newName: '',
            id: '',
            isNew: true,
          }))
          .filter((o) => !!o.lowerName),
        'lowerName'
      ),
      'lowerName'
    );
    categoriesMap = categoriesMap.map((c) => {
      const instance = realData.find((d) => d[i].toLowerCase() === c.lowerName);
      const sampleName = instance ? instance[i] : '';
      const category = categories.find(
        (cat) =>
          cat.name.toLowerCase() === c.lowerName ||
          cat.importAliases?.find((a) => a === c.lowerName)
      );
      const [id, newName] = category ? [category.id, category.name] : ['', sampleName];
      return {
        lowerName: c.lowerName,
        sampleName: sampleName,
        newName: newName,
        id: id,
        isNew: !id,
      };
    });
  }
  return categoriesMap;
};

const getTagsMap = (
  realData: string[][],
  mapFields: ImportField[],
  tags: Tag.Tag[]
): ImportEntity[] => {
  let tagsMap: ImportEntity[] = [];

  // Tags can have more than one field mapped to them!
  const tagIndices = mapFields.reduce((acc: number[], f, i) => {
    if (f.fieldName === 'tag') acc.push(i);
    return acc;
  }, []);
  tagIndices.forEach((i) =>
    tagsMap.push(
      ...realData
        .filter((d) => !!d[i])
        .map((d) => ({
          lowerName: d[i].toLowerCase(),
          sampleName: '',
          newName: '',
          id: '',
          isNew: true,
        }))
    )
  );
  if (tagsMap.length > 0) {
    tagsMap = sortedUniqBy(sortBy(tagsMap, 'lowerName'), 'lowerName');
    tagsMap = tagsMap.map((t) => {
      // debugger;
      let sampleName = '';
      // Get the sampleName from the first row, and first "tag" column in that row,
      // with the matching name.
      for (const d of realData) {
        for (const i of tagIndices) {
          if (d[i].toLowerCase() === t.lowerName) {
            sampleName = d[i];
            break;
          }
        }
        if (sampleName) break;
      }
      const tag = tags.find(
        (tag) =>
          tag.name.toLowerCase() === t.lowerName ||
          tag.importAliases?.find((a) => a === t.lowerName)
      );
      const [id, newName] = tag ? [tag.id, tag.name] : ['', sampleName];
      return {
        lowerName: t.lowerName,
        sampleName: sampleName,
        newName: newName,
        id: id,
        isNew: !id,
      };
    });
  }
  return tagsMap;
};

const getPaymentMethodsMap = (
  realData: string[][],
  mapFields: ImportField[],
  paymentMethods: PaymentMethod.PaymentMethod[]
): ImportEntity[] => {
  let paymentMethodsMap: ImportEntity[] = [];

  const i = mapFields.findIndex((f) => f.fieldName === 'paymentMethod');
  if (i >= 0) {
    paymentMethodsMap = sortedUniqBy(
      sortBy(
        realData
          .map((d) => ({
            lowerName: d[i].toLowerCase(),
            sampleName: '',
            newName: '',
            id: '',
            isNew: true,
          }))
          .filter((o) => !!o.lowerName),
        'lowerName'
      ),
      'lowerName'
    );
    paymentMethodsMap = paymentMethodsMap.map((pm) => {
      const instance = realData.find((d) => d[i].toLowerCase() === pm.lowerName);
      const sampleName = instance ? instance[i] : '';
      const paymentMethod = paymentMethods.find(
        (paymentMethod) =>
          paymentMethod.name.toLowerCase() === pm.lowerName ||
          paymentMethod.importAliases?.find((a) => a === pm.lowerName)
      );
      const [id, newName] = paymentMethod
        ? [paymentMethod.id, paymentMethod.name]
        : ['', sampleName];

      return {
        lowerName: pm.lowerName,
        sampleName: sampleName,
        newName: newName,
        id: id,
        isNew: !id,
      };
    });
  }
  return paymentMethodsMap;
};
