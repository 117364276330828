import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Alert, Stack, Button, Box, Card, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { isEqual, isNumber, reduce, sortBy } from 'lodash';

import * as analytics from '@fire/analytics/index';
import PATHS from '@routes/paths';
import { Category, Pledge, Organization } from '@shared/types';
import useOrg from '@hooks/useOrg';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';

import { pledgeSchemaResolver, PledgeSchemaForm } from '@/schemas/pledge';
import Dialog from '@components/Dialog';
import ConfirmDialog from '@components/ConfirmDialog';
import ConflictDialog from '@components/ConflictDialog';
import useRole from '@hooks/useRole';
import useFormat from '@hooks/useFormat';
import { RHFAutocomplete, RHFDatePicker, RHFTextField } from '@components/hook-form';
import { addYears, subYears } from 'date-fns';
import { renderCategoryOption } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import { parseDateTimezone } from '@redux/slices/donation';

// ----------------------------------------------------------------------
export default function PledgeDialog() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { org } = useOrg();
  const { hasAccess } = useRole();
  const { fDateToISO, fDateToYearStart, fDateToYearEnd, isDateBetween } = useFormat();
  const {
    getCategoryById,
    getDonorById,
    getPledgeById,
    createPledge,
    updatePledge,
    getPledgeCategory,
    deletePledge,
    categories,
    pledges,
  } = useDonation();
  const [isConfirmingDelete, setConfirmingDelete] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isInConflict, setInConflict] = useState<string>();

  // --------------- defaults ---------------
  const canEdit = hasAccess([Organization.Role.editor, Organization.Role.contributor]);
  const pledge = useMemo(() => getPledgeById(params.pledgeId), [getPledgeById, params.pledgeId]);

  const donor = useMemo(() => {
    if (pledge) {
      return getDonorById(pledge.donorId);
    } else {
      return getDonorById(params.donorId);
    }
  }, [getDonorById, params.donorId, pledge]);

  const donorPledges = useMemo(
    () => pledges.filter((p) => p.donorId === donor?.id),
    [donor?.id, pledges]
  );

  useEffect(() => pledge && analytics.donation.pledgeView(), [pledge]);

  const allCategories = useMemo(
    () => ({ id: '', name: '(All Categories)', description: '' }) as Category.Category,
    []
  );

  const defaultValues: PledgeSchemaForm = useMemo(
    () => ({
      amount: pledge?.amount || '',
      dateStart:
        (pledge?.dateStart && parseDateTimezone(pledge.dateStart)) || fDateToYearStart(new Date()),
      dateEnd: (pledge?.dateEnd && parseDateTimezone(pledge.dateEnd)) || fDateToYearEnd(new Date()),
      category:
        getPledgeCategory(pledge) || getCategoryById(org?.defaultPledgeCategoryId) || allCategories,
    }),
    [
      pledge,
      fDateToYearStart,
      fDateToYearEnd,
      getPledgeCategory,
      getCategoryById,
      org?.defaultPledgeCategoryId,
      allCategories,
    ]
  );

  // --------------- form ---------------
  const methods = useForm<PledgeSchemaForm>({
    resolver: pledgeSchemaResolver(),
    defaultValues,
    mode: 'all',
    criteriaMode: 'all',
  });

  const {
    reset,
    setError,
    setFocus,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
  } = methods;

  // --------------- routing ---------------
  const handleClose = useCallback(
    (andNew?: boolean) => {
      if (andNew) {
        reset(defaultValues);
        return;
      }

      navigate(PATHS.org.donors.donor.edit);
    },
    [navigate, reset, defaultValues]
  );

  // --------------- effects ---------------
  useEffect(() => {
    if (isDirty && Object.keys(dirtyFields).length && !isSubmitting) {
      const updateByUser = org?.users[pledge?._meta.updatedBy as string]?.displayName;
      setInConflict(
        `${updateByUser} updated this pledge. To avoid data loss, we need to reset this form.`
      );
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, defaultValues]);

  useEffect(() => {
    const firstError = Object.keys(errors)[0];

    if (firstError) {
      setFocus(firstError as any);
    }
  }, [errors, setFocus]);

  // --------------- actions ---------------
  const handleFormReset = useCallback(() => {
    reset(defaultValues);
    setInConflict(undefined);
  }, [reset, defaultValues]);

  const handleCreatePledge = useCallback(
    async (data: PledgeSchemaForm, orgId: string) => {
      if (!data.dateStart || !data.dateEnd) {
        setError('afterSubmit', { message: 'Missing date' });
        return;
      }
      if (!data.category) {
        setError('afterSubmit', { message: 'Missing category' });
        return;
      }
      if (!isNumber(data.amount)) {
        setError('afterSubmit', { message: 'Missing amount' });
        return;
      }
      if (!donor) {
        setError('afterSubmit', { message: 'Missing donor' });
        return;
      }

      try {
        await createPledge({
          orgId,
          dateStart: fDateToISO(data.dateStart),
          dateEnd: fDateToISO(data.dateEnd),
          donorId: donor.id,
          amount: data.amount,
          categoryId: data.category.id,
        });
        enqueueSnackbar('Pledge created!');
      } catch (error) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    },
    [donor, setError, createPledge, fDateToISO, enqueueSnackbar]
  );

  const handleUpdatePledge = useCallback(
    async (data: PledgeSchemaForm, pledge: Pledge.Pledge) => {
      // NOTE: form always gives all fields back, changed or in initial state
      // we verify if they have changed and pull only those
      const update = {
        ...reduce(
          data,
          function (result, value, key) {
            switch (key) {
              case 'dateStart':
              case 'dateEnd':
                return isEqual((value as Date)?.toJSON(), pledge[key]) ||
                  isEqual(fDateToISO(value as Date), pledge[key])
                  ? result
                  : { ...result, [key]: fDateToISO(value as Date) };
              case 'category':
                return isEqual((value as Category.Category).id, pledge.categoryId)
                  ? result
                  : {
                      ...result,
                      categoryId: (value as Category.Category)?.id,
                    };
              default:
                return isEqual(value, (pledge as any)[key]) ? result : { ...result, [key]: value };
            }
          },
          {}
        ),
        orgId: org?.id,
      };

      try {
        await updatePledge({ pledgeId: pledge.id, update });
        enqueueSnackbar('Pledge updated');
      } catch (error) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    },
    [enqueueSnackbar, fDateToISO, setError, updatePledge, org?.id]
  );

  const onSubmit = useCallback(
    async (data: PledgeSchemaForm, andNew?: boolean) => {
      if (!org) {
        setError('afterSubmit', { message: 'Missing organization' });
        return;
      }
      if (!canEdit) {
        setError('afterSubmit', { message: 'You lack editing permissions' });
        return;
      }
      /* Check for overlapping pledges that aren't allowed.
         The only case when you can have two (or more) pledges that have the same
         or overlapping date ranges is if they have different categories, with
         none of them being for All Categories.
      */
      const overlaps = donorPledges.filter(
        (p) =>
          p.id !== pledge?.id &&
          (isDateBetween(parseDateTimezone(p.dateStart), data.dateStart, data.dateEnd) ||
            isDateBetween(parseDateTimezone(p.dateEnd), data.dateStart, data.dateEnd))
      );
      const overlapError =
        !data.category?.id && overlaps.length > 0
          ? 'This pledge for All Categories overlaps dates with an existing pledge'
          : overlaps.some((p) => !p.categoryId)
            ? 'This pledge overlaps dates with an existing pledge for All Categories'
            : overlaps.some((p) => p.categoryId === data.category?.id)
              ? 'This pledge overlaps dates with an existing pledge for the same Category'
              : '';
      if (overlapError) {
        setError('afterSubmit', { message: overlapError });
        return;
      }
      if (pledge) {
        await handleUpdatePledge(data, pledge);
      } else {
        await handleCreatePledge(data, org.id);
      }
      handleClose(andNew);
    },
    [
      org,
      canEdit,
      donorPledges,
      pledge,
      handleClose,
      setError,
      isDateBetween,
      handleUpdatePledge,
      handleCreatePledge,
    ]
  );

  const handleSave = useCallback(
    (data: PledgeSchemaForm, andNew?: boolean) => onSubmit(data, andNew),
    [onSubmit]
  );

  const handleDelete = useCallback(async () => {
    if (!canEdit) {
      setError('afterSubmit', { message: 'You lack permissions!' });
      return;
    }
    if (!org) {
      setError('afterSubmit', { message: '[internal] Missing organization!' });
      return;
    }
    if (!pledge) {
      setError('afterSubmit', { message: 'Missing pledge to delete!' });
      return;
    }

    setDeleting(true);
    try {
      await deletePledge({ orgId: org.id, pledgeId: pledge.id });
    } catch (e) {
      setError('afterSubmit', { message: e.message });
    }
    setDeleting(false);
    handleClose();
  }, [canEdit, deletePledge, handleClose, setError, org, pledge]);

  const renderActions = useMemo(() => {
    if (canEdit) {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          {!!pledge ? (
            <>
              <ConfirmDialog
                open={isConfirmingDelete}
                onClose={() => setConfirmingDelete(false)}
                onConfirm={handleDelete}
                loading={isDeleting}
              />
              <LoadingButton
                color="error"
                onClick={() => setConfirmingDelete(true)}
                loading={isDeleting}
              >
                Delete pledge
              </LoadingButton>
            </>
          ) : (
            <Box />
          )}

          <Stack direction="row" spacing={2}>
            {!pledge && (
              <LoadingButton
                size="large"
                variant="outlined"
                loading={isSubmitting}
                onClick={handleSubmit((d) => handleSave(d, true))}
              >
                Create and New
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isSubmitting}
              onClick={handleSubmit((d) => handleSave(d))}
            >
              {!!pledge ? 'Save' : 'Create'}
            </LoadingButton>
          </Stack>
        </Stack>
      );
    }

    return <Button onClick={() => handleClose()}>Close</Button>;
  }, [
    canEdit,
    isSubmitting,
    handleClose,
    handleSubmit,
    handleSave,
    setConfirmingDelete,
    handleDelete,
    pledge,
    isDeleting,
    isConfirmingDelete,
  ]);

  const renderTitle = useMemo(
    () => `${!!pledge ? 'Edit' : 'Create'} pledge for ${donor?.firstName + ' ' + donor?.lastName}`,
    [donor, pledge]
  );

  const formDisabled = !canEdit || isSubmitting;

  return (
    <Dialog
      title={renderTitle}
      onClose={() => handleClose()}
      maxWidth="md"
      isDirty={isDirty && Object.keys(dirtyFields).length > 0}
      isLoading={isSubmitting}
      methods={methods}
      actions={renderActions}
    >
      <ConflictDialog onConfirm={handleFormReset} contentText={isInConflict} />

      <Card sx={{ p: 3 }}>
        <Stack direction={'column'} spacing={2}>
          <RHFDatePicker
            required
            autoFocus
            name="dateStart"
            label="Start Date"
            disabled={formDisabled}
            minDate={subYears(new Date(), 10)}
            maxDate={addYears(new Date(), 1)}
          />
          <RHFDatePicker
            required
            autoFocus
            name="dateEnd"
            label="End Date"
            disabled={formDisabled}
            minDate={subYears(new Date(), 10)}
            maxDate={addYears(new Date(), 1)}
          />
          <RHFAutocomplete
            name="category"
            label="Category"
            required
            freeSolo={false}
            disabled={formDisabled}
            options={[allCategories, ...sortBy(categories, (c) => c.name.toUpperCase())]}
            // getOptionLabel={(c) => c.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            renderOption={renderCategoryOption}
          />
          <RHFTextField
            required
            name="amount"
            type="number"
            label="Amount"
            disabled={formDisabled}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{ maxLength: 6 }}
          />
        </Stack>
      </Card>

      {!!errors.afterSubmit && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {errors.afterSubmit.message}
        </Alert>
      )}
    </Dialog>
  );
}
