// Autocomplete drop-down for one donor
import { Autocomplete, TextField, SxProps } from '@mui/material';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import { sortBy } from 'lodash';
import { TDonorWithDonations } from '@typedefs/donation';
import { Donor } from '@shared/types';

// ----------------------------------------------------------------------
type Props = {
  filterDonor: Donor.Donor | undefined;
  onFilterDonorChange: (donor: Donor.Donor | undefined) => void;
  donorsWithDonations: TDonorWithDonations[];
  fullWidth?: boolean;
  sxProps?: SxProps;
};

// ----------------------------------------------------------------------
export default function FilterDonor({
  filterDonor,
  onFilterDonorChange,
  donorsWithDonations,
  fullWidth = true,
  sxProps,
}: Props) {
  const { fFullName, fReversedName } = useFormat();
  const { getDonorById } = useDonation();
  const donors = donorsWithDonations.map((d) => ({
    id: d.id,
    name: fFullName(d),
    reversedName: fReversedName(d),
  }));
  const displayDonors = [ { id: '', name: 'All Donors', reversedName: '' }, ...sortBy(donors, 'reversedName')];
  const displayFilterDonor = {
    id: filterDonor?.id || '',
    name: fFullName(filterDonor),
    reversedName: fReversedName(filterDonor),
  };

  return (
    <Autocomplete
      fullWidth={fullWidth}
      autoComplete
      size="small"
      sx={sxProps}
      limitTags={1}
      value={displayFilterDonor}
      onChange={(e, newValue) => {
        onFilterDonorChange(getDonorById(newValue?.id));
      }}
      options={displayDonors}
      isOptionEqualToValue={(option, value) => option && value && option.id === value.id}
      renderInput={(params) => <TextField label="Donor" {...params} />}
      getOptionLabel={(option) => option?.name}
    />
  );
}
