import { useCallback } from 'react';
import { Table, TableBody, TableContainer, TableRow, TableCell, TableHead } from '@mui/material';

import { Receipt } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useOrg from '@hooks/useOrg';

// ----------------------------------------------------------------------
type Props = { readonly receipt: Receipt.Receipt };

// ----------------------------------------------------------------------
export default function ReceiptHistory({ receipt }: Props) {
  const { fDate, fReceiptActionType } = useFormat();
  const { org } = useOrg();

  const getUser = useCallback((userId: string) => org?.users[userId], [org?.users]);

  if (!org) return null;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>User</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {[...receipt.actions].reverse().map((action) => (
            <TableRow key={`${action.createdAt}+${action.type}`} hover>
              <TableCell>{fDate(action.createdAt || new Date().toISOString())}</TableCell>
              <TableCell>{fReceiptActionType(action.type)}</TableCell>
              <TableCell>{getUser(action.userId)?.displayName || 'Unknown'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
