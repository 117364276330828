import { KeyboardEventHandler, ReactNode, useState } from 'react';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  DialogContentProps,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { UseFormReturn } from 'react-hook-form';

import { FormProvider } from '@components/hook-form';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = Omit<DialogProps, 'onClose' | 'open'> & {
  readonly title: string;
  readonly open?: boolean;
  readonly onClose?: (open: boolean) => void;
  readonly actions?: React.ReactNode;
  readonly actionProps?: DialogActionsProps;
  readonly contentProps?: DialogContentProps;
  readonly confirmText?: string;
  readonly helpUrl?: string;

  readonly isLoading?: boolean;
  readonly isDirty?: boolean;
  readonly methods?: UseFormReturn<any, any>;
  readonly withConfirmation?: boolean;
  readonly helpInfoTitle?: ReactNode;
};

// ----------------------------------------------------------------------
export default function Dialog({
  title,
  open = true,
  onClose,
  children,
  actions,
  actionProps,
  contentProps,
  confirmText = 'Leave without saving?',
  helpUrl,
  isLoading,
  isDirty,
  methods,
  withConfirmation = true,
  fullWidth = true,
  helpInfoTitle,
  ...dialogProps
}: Props) {
  const navigate = useNavigate();
  const [confirmClose, setConfirmClose] = useState(false);

  // --------------- actions ---------------
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Escape') {
      setConfirmClose(false);
    } else if (e.key === 'Enter') {
      handleConfirmClose();
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }

    if (withConfirmation && isDirty) {
      setConfirmClose(true);
      return;
    }

    onClose ? onClose(false) : navigate(-1);
  };

  const handleConfirmClose = () => {
    setConfirmClose(false);

    onClose ? onClose(false) : navigate(-1);
  };

  // --------------- dialog ---------------
  return (
    <MuiDialog
      open={open}
      fullWidth={fullWidth}
      scroll="paper"
      {...dialogProps}
      onClose={handleClose}
    >
      <MuiDialog open={confirmClose} onKeyDown={handleKeyDown}>
        <DialogTitle>{confirmText}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmClose(false)}>Cancel</Button>
          <Button color="error" variant="contained" onClick={handleConfirmClose}>
            Leave
          </Button>
        </DialogActions>
      </MuiDialog>

      <DialogTitle>
        <Stack direction="row" alignItems="center">
          {title} {helpInfoTitle && helpInfoTitle}
          {helpUrl && (
            <IconButton>
              <Iconify icon="material-symbols:help-outline" width={24} height={24} mb={0} />
            </IconButton>
          )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            disabled={isLoading}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[700],
            }}
          >
            <Iconify icon="material-symbols:close" />
          </IconButton>
        </Stack>
      </DialogTitle>

      {methods ? (
        <FormProvider methods={methods}>
          <DialogContent dividers {...contentProps}>
            {children}
          </DialogContent>

          {actions && <DialogActions {...actionProps}>{actions}</DialogActions>}
        </FormProvider>
      ) : (
        <>
          <DialogContent dividers {...contentProps}>
            {children}
          </DialogContent>
          {actions && <DialogActions {...actionProps}>{actions}</DialogActions>}
        </>
      )}
    </MuiDialog>
  );
}
