// Display a standard bulleted list (like with HTML <ul>), left-aligned in the space,
// containing a possible first list of bold strings, then a possible list of regular
// strings, all displayed as the given typography variant textVariant (default body2).
// Nothing is displayed if there are no strings.

import { List, ListItem, ListItemText } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

type Props = {
  readonly boldItems?: string[];
  readonly items?: string[];
  readonly textVariant?: Variant | undefined;
};
// ----------------------------------------------------------------------
export default function BulletList({ boldItems = [], items = [], textVariant = 'body2' }: Props) {
  return boldItems.length > 0 || items.length > 0 ? (
    <List sx={{ listStyleType: 'disc', pl: 2 }}>
      {boldItems.map((t, i) => (
        <ListItem key={i} sx={{ display: 'list-item' }}>
          <ListItemText
            primary={t}
            sx={{ ml: -2 }}
            primaryTypographyProps={{ variant: textVariant, fontWeight: 'bold' }}
          />
        </ListItem>
      ))}
      {items.map((t, i) => (
        <ListItem key={i + boldItems.length} sx={{ display: 'list-item' }}>
          <ListItemText
            primary={t}
            sx={{ ml: -2 }}
            primaryTypographyProps={{ variant: textVariant }}
          />
        </ListItem>
      ))}
    </List>
  ) : null;
}
