import { useMemo } from 'react';
import { Grid } from '@mui/material';

import useResponsive from '@hooks/useResponsive';
// import CreatePassword from './CreatePassword';
import ChangePassword from './ChangePassword';
import LinkedAccounts from './LinkedAccounts';
import Logout from './Logout';
import useAuth from '@hooks/useAuth';

// ----------------------------------------------------------------------
export default function AccountSecurity() {
  const { user } = useAuth();

  const isMobile = useResponsive('down', 'sm');
  const isPasswordAuth = useMemo(
    () => user?.providers.includes('password') || false,
    [user?.providers]
  );
  const isGoogleAuth = useMemo(
    () => user?.providers.includes('google.com') || false,
    [user?.providers]
  );

  return (
    <Grid container spacing={4}>
      {isPasswordAuth && (
        <Grid item xs={12} lg={7}>
          {/* {isPasswordAuth ? <ChangePassword /> : <CreatePassword />} */}
          <ChangePassword />
        </Grid>
      )}
      <Grid container spacing={isMobile ? 4 : 1} item xs={12} lg={5}>
        <Grid item xs={12}>
          <LinkedAccounts isGoogleAuth={isGoogleAuth} />
        </Grid>
        <Grid item xs={12}>
          <Logout />
        </Grid>
      </Grid>
    </Grid>
  );
}
