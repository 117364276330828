import { useEffect, useMemo } from 'react';
import { Alert, Stack, TextareaAutosize } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import useOrg from '@hooks/useOrg';
import useAuth from '@hooks/useAuth';
import useDonation from '@hooks/useDonation';
import { feedbackSchemaResolver, FeedbackSchemaForm } from '@/schemas';
import { RHFTextField } from '@components/hook-form';
import Dialog from '@components/Dialog';

// ----------------------------------------------------------------------
type Props = {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
};

// ----------------------------------------------------------------------
export default function ContactUsDialog({ open, setOpen }: Props) {
  const { user } = useAuth();
  const { donors, donations } = useDonation();
  const { org, submitFeedback } = useOrg();
  const { enqueueSnackbar } = useSnackbar();

  // --------------- effects ---------------
  const defaultValues: FeedbackSchemaForm = useMemo(
    () => ({
      topic: 'Scott TBD',
      text: '',
    }),
    []
  );

  // --------------- form ---------------
  const methods = useForm<FeedbackSchemaForm>({
    resolver: feedbackSchemaResolver,
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = methods;

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  // --------------- actions ---------------
  const onSubmit = async (data: FeedbackSchemaForm) => {
    if (!user || !org) {
      setError('afterSubmit', { message: 'Internal error, contact support' });
      return null;
    }

    try {
      await submitFeedback({
        org,
        user,
        context: data.topic,
        text: data.text,
        donorCount: donors.length,
        donationCount: donations.length,
      });
      enqueueSnackbar(`Contact us submitted!`);
      setOpen(false);
    } catch (error) {
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={setOpen}
      title="Contact us"
      isDirty={isDirty}
      isLoading={isSubmitting}
      methods={methods}
      maxWidth="sm"
      actions={
        <LoadingButton
          size="large"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </LoadingButton>
      }
    >
      <Stack
        spacing={1}
        alignItems="center"
        justifyContent="center"
        sx={{ width: '80%', margin: '0 auto' }}
      >
        <RHFTextField
          name="text"
          type="text"
          label="Reason"
          disabled={isSubmitting}
          fullWidth
          required
          InputProps={{
            inputComponent: TextareaAutosize,
          }}
          inputProps={{
            minRows: 4,
            rows: 4,
            maxRows: 6,
          }}
        />

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>
    </Dialog>
  );
}
