import { useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------
export default function useQueryParams() {
  let [searchParamsIterator, setSearchParams] = useSearchParams();

  const queryParams: { [key: string]: string | null } = {};
  Array.from(searchParamsIterator.keys()).forEach(
    (key) => (queryParams[key] = searchParamsIterator.get(key))
  );

  const setQueryParams = setSearchParams;
  return { queryParams, setQueryParams };
}
