import { useState } from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { LexicalEditor, $getSelection, $isRangeSelection, $createParagraphNode } from 'lexical';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
const blockTypeToBlockName = {
  paragraph: 'Normal',
  h1: 'Large Heading',
  h2: 'Small Heading',
  ul: 'Bulleted List',
  ol: 'Numbered List',
};

// ----------------------------------------------------------------------
export default function TextSize({
  editor,
  blockType,
}: {
  editor: LexicalEditor;
  blockType: string;
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleOpen = () => {
    setMenuOpen(true);
  };

  const handleNull = (e: SelectChangeEvent<string>) => {};

  const handleChange = (selectedBlock: string) => {
    switch (selectedBlock) {
      case 'paragraph': {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createParagraphNode());
          }
        });
        break;
      }
      case 'h1': {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode('h1'));
          }
        });
        break;
      }
      case 'h2': {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode('h2'));
          }
        });
        break;
      }
      case 'ol': {
        if (blockType !== 'ol') {
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
        break;
      }
      case 'ul': {
        if (blockType !== 'ul') {
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
          editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
        break;
      }
      default: {
        console.info('Unknown formatting text node.');
      }
    }

    setMenuOpen(false);
  };

  return (
    <FormControl size="small" sx={{ minWidth: 160 }}>
      <Select
        id="editor-text-size"
        value={blockType}
        onChange={handleNull}
        open={menuOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{
          fontSize: 14,
          alignItems: 'center',
        }}
      >
        <MenuItem value="paragraph" onClick={() => handleChange('paragraph')} sx={{ fontSize: 14 }}>
          <Stack direction="row">
            <Iconify icon="uil:align-alt" width={20} height={20} mr={1} />
            {blockTypeToBlockName.paragraph}
          </Stack>
        </MenuItem>
        <MenuItem value="h1" onClick={() => handleChange('h1')} sx={{ fontSize: 14 }}>
          <Stack direction="row">
            <Iconify icon="material-symbols:format-h1" width={20} height={20} mr={1} />
            {blockTypeToBlockName.h1}
          </Stack>
        </MenuItem>
        <MenuItem value="h2" onClick={() => handleChange('h2')} sx={{ fontSize: 14 }}>
          <Stack direction="row">
            <Iconify icon="material-symbols:format-h2" width={20} height={20} mr={1} />
            {blockTypeToBlockName.h2}
          </Stack>
        </MenuItem>
        <MenuItem value="ol" onClick={() => handleChange('ol')} sx={{ fontSize: 14 }}>
          <Stack direction="row">
            <Iconify icon="mdi:format-list-numbered" width={20} height={20} mr={1} />
            {blockTypeToBlockName.ol}
          </Stack>
        </MenuItem>
        <MenuItem value="ul" onClick={() => handleChange('ul')} sx={{ fontSize: 14 }}>
          <Stack direction="row">
            <Iconify icon="mdi:format-list-bulleted" width={20} height={20} mr={1} />
            {blockTypeToBlockName.ul}
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
