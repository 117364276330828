import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// ----------------------------------------------------------------------
const TagSchema = Yup.object().shape({
  type: Yup.string().oneOf(['donor']).required('Tag type is required (internal)'),
  name: Yup.string().required('Name is required'),
  description: Yup.string(),
  colorBackground: Yup.string().required(),
  colorText: Yup.string().required(),
});

export const tagSchemaResolver = yupResolver(TagSchema);
export type TagSchemaForm = {
  type: 'donor';
  name: string;
  description: string;
  colorBackground: string;
  colorText: string;

  afterSubmit?: string;
};
