import { useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { PageSize } from '@typedefs/app';

import { ReceiptReissueSchemaForm } from '@/schemas';
import { TStepProps } from '@pages/receipts/useSteps';
import PdfDocument from '@pages/receipts/templates/PdfDocument';
import Iconify from '@components/Iconify';
import ReviewReceiptSections from '@pages/receipts/Create/steps/ReviewReceiptSections';

// ----------------------------------------------------------------------
export default function ReviewLetter({
  stepData,
  updateStepData,
}: TStepProps<ReceiptReissueSchemaForm>) {
  const { getReceiptReissueGroup } = useDonation();
  const { fDateToISO } = useFormat();
  const { org } = useOrg();

  const [size] = useState<PageSize>('letter');

  // --------------------------------------------------
  const donorGroup = useMemo(
    () => getReceiptReissueGroup(org!, stepData.receipt, stepData.donations, stepData.reissue),
    [getReceiptReissueGroup, org, stepData.receipt, stepData.donations, stepData.reissue]
  );

  // --------------------------------------------------
  // const handleWithLetterChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   updateStepData({
  //     letter: {
  //       ...stepData.letter,
  //       withLetter: checked,
  //     },
  //   });
  // };

  const handleDownloadTest = async () => {
    const blob = await pdf(PdfDoc).toBlob();
    saveAs(blob, `${fDateToISO()}_preview_letter.pdf`);
  };

  // --------------------------------------------------
  const PdfDoc = useMemo(
    () => (
      <PdfDocument
        title="Receipt letter"
        description="Receipt letter"
        size={PageSize[size]}
        donorGroups={[donorGroup]}
        withLetter={stepData.letter.withLetter}
        preview
        options={stepData.options}
        year={stepData.receipt.year}
      />
    ),
    [size, donorGroup, stepData.letter.withLetter, stepData.options, stepData.receipt.year]
  );

  // --------------------------------------------------
  // useEffect(() => {
  //   updateStepData({ letter: { size, Doc: PdfDocument } });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (!org) return null;

  return (
    <Grid container spacing={1} height="100%">
      <Grid item xs={5} height="100%" mx="auto">
        <Stack spacing={2} height="100%">
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={stepData.letter.withLetter} onChange={handleWithLetterChange} />
              }
              label="Include letter"
              sx={{ whiteSpace: 'nowrap' }}
            />
          </FormGroup>

          <Divider /> */}

          <Typography variant="h6">Preview</Typography>
          <Stack direction="row" spacing={2}>
            <LoadingButton
              variant="outlined"
              onClick={handleDownloadTest}
              startIcon={<Iconify icon="material-symbols:download" />}
            >
              Download preview receipt
            </LoadingButton>
          </Stack>
          <ReviewReceiptSections stepData={stepData} updateStepData={updateStepData} />
        </Stack>
      </Grid>

      <Grid item xs={7} height="100%">
        <PDFViewer showToolbar={false} width="100%" height="100%">
          {PdfDoc}
        </PDFViewer>
      </Grid>
    </Grid>
  );
}
