import { useMemo } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  Typography,
  TableCell,
  TableRow,
  Stack,
  Box,
  TablePagination,
} from '@mui/material';

import { Receipt } from '@shared/types';
import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useNavigate from '@hooks/useNavigate';
import PATHs from '@routes/paths';
import useTable from '@hooks/useTable';
import { TColumn } from '@typedefs/app';
import { TableHeadCustom } from '@components/table';
import { CategoryItem } from '@pages/donations/dialogs/Settings/DonationCategories/utils/renderCategories';
import { orderBy as _orderBy } from 'lodash';

// ----------------------------------------------------------------------
const TABLE_HEAD: TColumn[] = [
  { id: 'date', type: 'date', label: 'Date', align: 'left', visible: true },
  { id: 'amount', type: 'number', label: 'Amount $', align: 'right', visible: true },
  { id: 'category', label: 'Category', align: 'left', visible: true },
];

type Props = { readonly receipt: Receipt.Receipt };
// ----------------------------------------------------------------------
export default function ReceiptDonations({ receipt }: Props) {
  const navigate = useNavigate();
  const { getReceiptDonations, getDonationCategory } = useDonation();
  const { fDate, fCurrency } = useFormat();

  // --------------- effects ---------------
  const receiptDonations = useMemo(
    () => getReceiptDonations(receipt).map((d) => ({ category: getDonationCategory(d), ...d })),
    [getDonationCategory, getReceiptDonations, receipt]
  );

  const receiptAmountTotal = useMemo(
    () => receiptDonations.reduce((sum, d) => sum + d.amount, 0),
    [receiptDonations]
  );

  const { columns, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage, onSort } =
    useTable({
      defaultRowsPerPage: 5,
      defaultColumns: TABLE_HEAD,
      defaultOrderBy: 'date',
      defaultOrder: 'desc',
    });

  const sortedDonations = useMemo(() => {
    switch (orderBy) {
      case 'category':
        return _orderBy(receiptDonations, (d) => d.category?.name, order);
      default:
        return _orderBy(receiptDonations, orderBy, order);
    }
  }, [order, orderBy, receiptDonations]);

  // --------------- actions ---------------
  const handleOpenDonation = (donationId: string) => {
    navigate(PATHs.org.donors.donor.donation.edit, { donorId: receipt.donorId, donationId });
  };

  // --------------- ui ---------------
  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="subtitle2" color="text.secondary" mb={1}>
        Donations
      </Typography>

      <Stack direction="row" spacing={1} my={2}>
        <Typography variant="body2">Total amount:</Typography>
        <Typography variant="subtitle2">{fCurrency(receiptAmountTotal)}</Typography>
      </Stack>

      <TableContainer>
        <Table size="small">
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            columns={columns}
            rowCount={receiptDonations.length}
            onSort={onSort}
          />

          <TableBody>
            {sortedDonations.map((donation) => (
                <TableRow
                  key={donation.id}
                  hover
                  onClick={() => handleOpenDonation(donation.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{fDate(donation.date)}</TableCell>
                  <TableCell align="right">{fCurrency(donation.amount)}</TableCell>
                  <TableCell>{donation.category && <CategoryItem category={donation.category} />}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={receiptDonations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}
