import { useCallback, useEffect, useState } from 'react';
import { Card, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import orderBy from 'lodash/orderBy';

import { User } from '@shared/types/user';
import { Invitation } from '@shared/types';
import useOrg from '@hooks/useOrg';
import CurrentUsers from './CurrentUsers';
import Invitations from './Invitations';
import { difference } from 'lodash';

// ----------------------------------------------------------------------
export default function Users() {
  const { org, getOrgUsers } = useOrg();
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [invitations, setInvitations] = useState<Invitation.Invitation[]>([]);

  const getAllUsers = useCallback(
    async (orgId: string, userIds: string[]) => {
      setLoading(true);
      const response = await getOrgUsers({ orgId, userIds });
      setUsers(response?.users || []);
      setInvitations(
        response?.invitations ? orderBy(response.invitations, '_meta.createdAt', 'asc') : []
      );
      setLoading(false);
    },
    [getOrgUsers]
  );

  const handleCreateInv = useCallback(
    (inv: Invitation.Invitation) => {
      if (inv.state !== 'accepted') {
        setInvitations([...invitations, inv]);
      }
    },
    [invitations]
  );

  const handleCancelInv = useCallback(
    (invId: string) => {
      setInvitations(invitations.filter((inv) => inv.id !== invId));
    },
    [invitations]
  );

  useEffect(() => {
    if (org) {
      const currentUsers = users.map((u) => u.id);
      const userIds = Object.keys(org.users);
      if (!!difference(userIds, currentUsers).length) {
        getAllUsers(org.id, userIds);
      } else if (!!difference(currentUsers, userIds).length) {
        // user is removed from org, refresh users
        setUsers(users.filter((u) => userIds.includes(u.id)));
      }
    }
  }, [getAllUsers, org, users]);

  if (isLoading) {
    return (
      <Card sx={{ p: 3 }}>
        <Stack spacing={3} direction="column" alignItems="center" my={8}>
          <CircularProgress />
          <Typography variant="subtitle2">Loading users...</Typography>
        </Stack>
      </Card>
    );
  }

  if (!org) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <CurrentUsers org={org} users={users} />
      </Grid>
      <Grid item xs={12} md={5}>
        <Invitations
          org={org}
          users={users}
          invitations={invitations}
          onCreate={handleCreateInv}
          onCancel={handleCancelInv}
        />
      </Grid>
    </Grid>
  );
}
