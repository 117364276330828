import Iconify from '@components/Iconify';
import { Card, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';

// ----------------------------------------------------------------------
export type ReportProps = {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly longDescription?: string;
};
export type ReportCardProps = ReportProps & {
  readonly onClickReport: VoidFunction;
  readonly bookmarked: boolean;
  readonly onClickBookmark: VoidFunction;
};
// ----------------------------------------------------------------------
export default function ReportCard({
  name,
  description,
  longDescription,
  onClickReport /* previously had : onClick */,
  bookmarked: initBookmarked,
  onClickBookmark,
}: ReportCardProps) {
  /* The grid item sizes below were chose to try to make sure that the report name never wraps onto two lines */
  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
      <Card
        sx={{
          py: 0.5,
          px: 1,
          height: 98,
          cursor: 'pointer',
          borderRadius: 1,
          '&:hover': {
            background: (theme) => theme.palette.background.default,
          },
        }}
        onClick={onClickReport}
      >
        <Stack spacing={0}>
          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">{name}</Typography>
            <Tooltip title={`${initBookmarked ? 'Remove Bookmark' : 'Add Bookmark'}`}>
              <IconButton
                aria-label="bookmark"
                onClick={(e) => {
                  e.stopPropagation();
                  // setBookmarked(!bookmarked);
                  onClickBookmark();
                }}
                sx={{
                  color: (theme) => theme.palette.grey[700],
                }}
              >
                <Iconify
                  icon={`ri:bookmark-${initBookmarked ? 'fill' : 'line'}`}
                  width={20}
                  height={20}
                />
              </IconButton>
            </Tooltip>
          </Stack>

          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="body2">
              {description}{' '}
              {longDescription && (
                <Tooltip title={longDescription}>
                  <span>
                    <Iconify
                      icon="material-symbols:info-outline"
                      width={20}
                      height={20}
                      marginBottom="-4px"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </span>
                </Tooltip>
              )}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
}
