import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import { View } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
export const viewCreate = httpsCallable<View.CreateReq, void>(firebaseFunctions, 'view-create');
export const viewUpdate = httpsCallable<View.UpdateReq, void>(firebaseFunctions, 'view-update');
export const viewDelete = httpsCallable<View.DeleteReq, void>(
  firebaseFunctions,
  'view-deleteRecord'
);
