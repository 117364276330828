import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Popover,
  Grid,
  Divider,
  Typography,
  FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { isValid, sub } from 'date-fns';

import useFormat from '@hooks/useFormat';
import Iconify from '@components/Iconify';

// ----------------------------------------------------------------------
type Props = {
  dateFrom?: Date;
  minDate?: Date;
  setDateFrom: ((dateFrom?: Date) => void) | Dispatch<SetStateAction<Date | undefined>>;
  dateTo?: Date;
  maxDate?: Date;
  setDateTo: ((dateTo?: Date) => void) | Dispatch<SetStateAction<Date | undefined>>;
  error?: string;
  alignLeft?: boolean;
};

// ----------------------------------------------------------------------
export default function TableDateRange({
  dateFrom,
  minDate,
  setDateFrom,
  dateTo,
  maxDate,
  setDateTo,
  error,
  alignLeft,
}: Props) {
  const {
    fDate,
    getDateFormat,
    fDateToDayStart,
    fDateToDayEnd,
    fDateToMonthStart,
    fDateToMonthEnd,
    fDateToYearStart,
    fDateToPreviousYearStart,
    fDateToPreviousYearEnd,
  } = useFormat();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [dateFromInternal, setDateFromInternal] = useState<Date | undefined>(
    dateFrom ? fDateToDayStart(dateFrom) : undefined
  );
  const [dateToInternal, setDateToInternal] = useState<Date | undefined>(
    dateTo ? fDateToDayEnd(dateTo) : undefined
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDateFromInternal(dateFrom);
    setDateToInternal(dateTo);
    setOpen(event.currentTarget);
  };
  const handleClose = () => setOpen(null);

  // ----- PRESETS -------
  const handleThisMonth = () => {
    const from = fDateToMonthStart();
    const to = fDateToDayEnd();
    setDateFromInternal(from);
    setDateToInternal(to);
  };

  const handleLastMonth = () => {
    const prevMonthNow = sub(new Date(), { months: 1 });
    const from = fDateToMonthStart(prevMonthNow);
    const now = fDateToMonthEnd(prevMonthNow);
    setDateFromInternal(from);
    setDateToInternal(now);
  };

  const handleYearToDate = () => {
    const from = fDateToYearStart();
    const now = fDateToDayEnd();
    setDateFromInternal(from);
    setDateToInternal(now);
  };

  const handleLastYear = () => {
    const from = fDateToPreviousYearStart();
    const now = fDateToPreviousYearEnd();
    setDateFromInternal(from);
    setDateToInternal(now);
  };

  const handleAllTime = () => {
    setDateFromInternal(undefined);
    setDateToInternal(undefined);
  };

  // ----- EFFECTS -------
  useEffect(() => {
    if (!dateFromInternal) {
      setDateFrom(dateFromInternal);
    } else if (isValid(dateFromInternal)) {
      setDateFrom(fDateToDayStart(dateFromInternal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromInternal, setDateFrom]);
  useEffect(() => {
    if (!dateToInternal) {
      setDateTo(dateToInternal);
    } else if (isValid(dateToInternal)) {
      setDateTo(fDateToDayEnd(dateToInternal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateToInternal, setDateTo]);

  const renderDatePreview = () => {
    if (dateFrom || dateTo) {
      return `${dateFrom ? fDate(dateFrom) : ''} - ${dateTo ? fDate(dateTo) : ''}`;
    }
    return 'All Time';
  };

  return (
    <>
      <Stack whiteSpace="nowrap">
        <Button
          // fullWidth
          variant="text"
          onClick={handleOpen}
          startIcon={<Iconify icon="material-symbols:date-range" />}
          // endIcon={<Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} />}
          // color={!!error ? 'error' : 'primary'}
          sx={{
            color: (theme) => theme.palette.text.primary,
            height: '100%',
            ...(alignLeft && {
              justifyContent: 'left',
              width: 'fit-content',
            }),
          }}
        >
          <Typography variant="body2">{renderDatePreview()}</Typography>
        </Button>

        {error && <FormHelperText error>{error}</FormHelperText>}
      </Stack>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ position: 'relative', minWidth: 420 }} py={3} px={2}>
          <Grid container>
            <Grid item xs={7} display="flex" alignItems="center">
              <Stack spacing={2}>
                <Stack spacing={3}>
                  <DatePicker
                    autoFocus
                    format={getDateFormat()}
                    label="From"
                    value={dateFromInternal || null}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(v) => setDateFromInternal(v || undefined)}
                    // slotProps={{
                    //   actionBar: {
                    //     actions: ['cancel'],
                    //   },
                    // }}
                  />

                  <DatePicker
                    format={getDateFormat()}
                    label="To"
                    value={dateToInternal || null}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(v) => setDateToInternal(v || undefined)}
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={1} display="flex" justifyContent="center">
              <Divider orientation="vertical" />
            </Grid>

            <Grid item xs={4}>
              <Stack spacing={1}>
                <Button variant="outlined" size="small" onClick={handleThisMonth}>
                  This Month
                </Button>
                <Button variant="outlined" size="small" onClick={handleLastMonth}>
                  Last Month
                </Button>
                <Button variant="outlined" size="small" onClick={handleYearToDate}>
                  Year to Date
                </Button>
                <Button variant="outlined" size="small" onClick={handleLastYear}>
                  Last Year
                </Button>
                <Button variant="outlined" size="small" onClick={handleAllTime}>
                  All Time
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
