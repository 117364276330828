import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';
import type { Donation } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// export const donationGetAllForOrg = httpsCallable<
//   Donation.GetAllForOrgReq,
//   Donation.GetAllForOrgRes
// >(firebaseFunctions, 'donation-getAllForOrg');

// ----------------------------------------------------------------------
// Create
export const donationCreate = httpsCallable<Donation.CreateReq, Donation.CreateRes>(
  firebaseFunctions,
  'donation-create'
);
export const donationBatchCreate = httpsCallable<Donation.CreateBatchReq, void>(
  firebaseFunctions,
  'donation-createBatch'
);

// ----------------------------------------------------------------------
// Update
export const donationUpdate = httpsCallable<Donation.UpdateReq, Donation.UpdateRes>(
  firebaseFunctions,
  'donation-update'
);

// ----------------------------------------------------------------------
// Delete
export const donationDelete = httpsCallable<Donation.DeleteReq, Donation.DeleteRes>(
  firebaseFunctions,
  'donation-deleteRecord'
);
