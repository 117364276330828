import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '@/firebase/app';

import { PaymentMethod } from '@shared/types';

// ----------------------------------------------------------------------
const firebaseFunctions = getFunctions(firebaseApp);

// ----------------------------------------------------------------------
// Create
export const paymentMethodCreate = httpsCallable<PaymentMethod.CreateReq, PaymentMethod.CreateRes>(
  firebaseFunctions,
  'paymentMethod-create'
);

// ----------------------------------------------------------------------
// Update
export const paymentMethodUpdate = httpsCallable<PaymentMethod.UpdateReq, PaymentMethod.UpdateRes>(
  firebaseFunctions,
  'paymentMethod-update'
);

// ----------------------------------------------------------------------
// Delete
export const paymentMethodDelete = httpsCallable<PaymentMethod.DeleteReq, PaymentMethod.DeleteRes>(
  firebaseFunctions,
  'paymentMethod-deleteRecord'
);
