import { useMemo, useState } from 'react';
import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { sortBy } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';

import useFormat from '@hooks/useFormat';
import useDonation from '@hooks/useDonation';
import useOrg from '@hooks/useOrg';
import { PageSize } from '@typedefs/app';
import { TDonorWithDonations } from '@typedefs/donation';

import { ReceiptIssueSchemaForm } from '@/schemas';
import { TStepProps } from '../../../useSteps';
import PdfDocument from '@pages/receipts/templates/PdfDocument';
import Iconify from '@components/Iconify';
import ReviewReceiptSections from '../ReviewReceiptSections';

// ----------------------------------------------------------------------
export default function ReviewReceipt({
  stepData,
  updateStepData,
}: TStepProps<ReceiptIssueSchemaForm>) {
  const { getReceiptPartitions, getReceiptIssueGroups } = useDonation();
  const { fFullName, fDateToISO } = useFormat();
  const { org } = useOrg();
  const donorNames = useMemo(
    () => sortBy(stepData.donors, (d) => fFullName(d)),
    [fFullName, stepData.donors]
  );

  const [size] = useState<PageSize>('letter');
  // const [preset, setPreset] = useState('10');
  const [previewDonor, setPreviewDonor] = useState<TDonorWithDonations>(donorNames[0]);

  // --------------------------------------------------
  const receiptPartitions = getReceiptPartitions(org!.address.country, [previewDonor]);
  const donorGroups = getReceiptIssueGroups(org!, receiptPartitions);
  const { year } = donorGroups[0].groups[0];
  // --------------------------------------------------
  // const handlePresetChange = (event: SelectChangeEvent) => {
  //   setPreset(event.target.value as string);
  // };

  // const handleWithLetterChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   updateStepData({
  //     letter: {
  //       ...stepData.letter,
  //       withLetter: checked,
  //     },
  //   });
  // };

  const handleDownloadTest = async () => {
    const blob = await pdf(PdfDoc).toBlob();
    saveAs(blob, `${fDateToISO()}_preview_receipt.pdf`);
  };

  // --------------------------------------------------
  const PdfDoc = useMemo(
    () => (
      <PdfDocument
        title="Receipt letter"
        description="Receipt letter"
        size={PageSize[size]}
        donorGroups={donorGroups}
        withLetter={stepData.letter.withLetter}
        preview
        options={stepData.options}
        year={year}
      />
    ),
    [size, donorGroups, stepData.letter.withLetter, stepData.options, year]
  );

  // --------------------------------------------------
  // useEffect(() => {
  //   updateStepData({ letter: { size, Doc: PdfDocument } });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (!org) return null;

  return (
    <Grid container spacing={1} height="100%">
      {/* <Grid item xs={12} md={6}>
        <Stack spacing={2} p={2}>
          <Typography variant="h6">Letter</Typography>

          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="preset-select-label">Choose a preset</InputLabel>
            <Select
              labelId="preset-select-label"
              id="preset-select"
              value={preset}
              label="Choose a preset"
              onChange={handlePresetChange}
            >
              <MenuItem value={10}>Default</MenuItem>
            </Select>
          </FormControl>

          <PdfTemplate org={org} />
        </Stack>
      </Grid> */}

      <Grid item xs={5} height="100%" mx="auto">
        <Stack spacing={2} height="100%">
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={stepData.letter.withLetter} onChange={handleWithLetterChange} />
              }
              label="Include letter"
              sx={{ whiteSpace: 'nowrap' }}
            />
          </FormGroup>

          <Divider /> */}
          <Typography variant="h6">Preview</Typography>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              disablePortal
              id="select-preview-donor"
              options={donorNames}
              sx={{ width: '70%' }}
              autoComplete
              autoHighlight
              disableClearable
              freeSolo={false}
              value={previewDonor}
              onChange={(e, newValue) => setPreviewDonor(newValue)}
              getOptionLabel={(option) => fFullName(option)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="Choose a preview donor" />}
            />

            <LoadingButton
              variant="outlined"
              onClick={handleDownloadTest}
              startIcon={<Iconify icon="material-symbols:download" />}
            >
              Download preview receipt
            </LoadingButton>
          </Stack>
          <ReviewReceiptSections stepData={stepData} updateStepData={updateStepData} />
        </Stack>
      </Grid>

      <Grid item xs={7} height="100%">
        <PDFViewer showToolbar={false} width="100%" height="100%">
          {PdfDoc}
        </PDFViewer>
      </Grid>
    </Grid>
  );
}
