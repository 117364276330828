import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMemo, useState } from 'react';
import ConfirmDialog from '@components/ConfirmDialog';
import { ImportDataSchemaForm } from '@/schemas/importData';
import BulletList from '@components/BulletList';

// ----------------------------------------------------------------------
type Props = {
  readonly stepData: ImportDataSchemaForm;
  readonly isLast?: boolean;
  readonly isSubmitting: boolean;
  readonly handleNext: VoidFunction;
  readonly handleBack?: VoidFunction;
  readonly disabled?: boolean;
  readonly hasFailed?: boolean;
  readonly error: string;
  readonly actionText?: string;
};
// ----------------------------------------------------------------------
export default function StepActions({
  stepData,
  isLast,
  isSubmitting,
  handleNext,
  handleBack,
  disabled,
  hasFailed,
  error = '',
  actionText,
}: Props) {
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleClose = () => {
    setConfirmDialog(false);
  };

  const handleConfirm = () => {
    setConfirmDialog(false);
    handleNext();
  };

  const confirmTexts = useMemo(() => {
    const texts = [];
    if (stepData.donors.length > 0) {
      const newCount = stepData.donors.filter((d) => !d.id).length;
      const updatedCount = stepData.donors.length - newCount;
      if (newCount) texts.push(`${newCount} Donors will be added`);
      if (updatedCount) texts.push(`${updatedCount} Donors will be updated`);
    }
    if (stepData.donations.length > 0) {
      texts.push(`${stepData.donations.length} Donations will be added`);
    }
    if (stepData.categoriesMap.length > 0) {
      const newCount = stepData.categoriesMap.filter((c) => !c.id).length;
      if (newCount) texts.push(`${newCount} Categories will be added`);
    }
    if (stepData.paymentMethodsMap.length > 0) {
      const newCount = stepData.paymentMethodsMap.filter((pm) => !pm.id).length;
      if (newCount) texts.push(`${newCount} Payment Methods will be added`);
    }
    if (stepData.tagsMap.length > 0) {
      const newCount = stepData.tagsMap.filter((t) => !t.id).length;
      if (newCount) texts.push(`${newCount} Tags will be added`);
    }
    return texts;
  }, [stepData]);

  return (
    <>
      <ConfirmDialog open={confirmDialog} onClose={handleClose} onConfirm={handleConfirm}>
        <BulletList items={confirmTexts} />
      </ConfirmDialog>

      <Stack direction="row" spacing={2}>
        {handleBack && (
          <Button
            color="inherit"
            disabled={hasFailed || isSubmitting}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
        )}

        {isLast ? (
          <Tooltip
            title={
              hasFailed
                ? 'Importing data failed, please close and start again. If the issue persists, please contact support.'
                : ''
            }
            placement="top-start"
            arrow
          >
            <span>
              <LoadingButton
                size="large"
                variant="contained"
                disabled={hasFailed || disabled}
                loading={isSubmitting}
                onClick={() => setConfirmDialog(true)}
                sx={{ minWidth: 180 }}
              >
                {actionText}
              </LoadingButton>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={!!error ? <Typography variant="body2">{error}</Typography> : ''}
            placement="top-start"
            arrow
          >
            <span>
              <Button
                size="large"
                variant="contained"
                disabled={disabled}
                onClick={handleNext}
                sx={{ minWidth: 180 }}
              >
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Stack>
    </>
  );
}
