import { useForm } from 'react-hook-form';
import { Stack, Box, Divider } from '@mui/material';

import { FormProvider, RHFAutocomplete, RHFTextField } from '@components/hook-form';
import { organizationGeneralSchemaResolver, OrganizationGeneralSchemaForm } from '@/schemas';
import {
  CountryList,
  CurrencyList,
  FormatDateList,
  FormatTimeList,
  LanguageList,
  StateList,
} from '@typedefs/org';
import { AutocompleteItem } from '@typedefs/app';
import { Country } from '@shared/types';
import useLocales from '@hooks/useLocales';
import { TStepProps } from './config';
import StepActions from './StepActions';

// ----------------------------------------------------------------------
export default function StepGeneral({ data, isSubmitting, onBack, onNext }: TStepProps) {
  const { t } = useLocales();
  const defaultValues: OrganizationGeneralSchemaForm = {
    name: data.name || '',
    registrationNumber: data.registrationNumber || '',
    phone: '',
    address: data.address,
  };

  const methods = useForm<OrganizationGeneralSchemaForm>({
    resolver: organizationGeneralSchemaResolver,
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const watchCountry = watch('address.country');

  const handleCountryChange = (newCountry: AutocompleteItem<Country>) => {
    const prefCurrency = CurrencyList.find((o) => o.related?.includes(newCountry.value));
    const prefLanguage = LanguageList.find((o) => o.related?.includes(newCountry.value));
    const prefDateFormat = FormatDateList.find((o) => o.related?.includes(newCountry.value));
    const prefTimeFormat = FormatTimeList.find((o) => o.related?.includes(newCountry.value));

    // update preferences based on the country
    onNext(
      {
        currency: prefCurrency,
        language: prefLanguage,
        dateFormat: prefDateFormat,
        timeFormat: prefTimeFormat,
      },
      true
    );
    setValue('address.state', StateList[newCountry.value][0], { shouldDirty: true });
    setValue('address.country', newCountry, { shouldDirty: true });
  };

  const onSubmit = async (data: OrganizationGeneralSchemaForm) => {
    const stepData = {
      name: data.name,
      registrationNumber: data.registrationNumber,
      address: {
        address1: data.address.address1,
        address2: data.address.address2,
        city: data.address.city,
        state: data.address.state,
        postalCode: data.address.postalCode,
        country: data.address.country,
      },
    };
    onNext(stepData);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField
            required
            name="name"
            label="Organization name"
            inputProps={{ maxLength: 60 }}
          />
          <RHFTextField
            required={watchCountry?.value === 'ca'}
            name="registrationNumber"
            label="Charitable registration number"
            inputProps={{ maxLength: 40 }}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField
            name="phone"
            type="tel"
            label="Phone"
            disabled={isSubmitting}
            inputProps={{ maxLength: 20 }}
          />
          <Box width="100%" />
        </Stack>

        <Divider />

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField
            required
            name="address.address1"
            label="Address"
            inputProps={{ maxLength: 40 }}
          />
          <RHFTextField name="address.address2" label="Address" inputProps={{ maxLength: 40 }} />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField required name="address.city" label="City" inputProps={{ maxLength: 40 }} />
          <RHFAutocomplete
            required
            name="address.state"
            label={t('State')}
            fullWidth
            freeSolo={false}
            options={StateList[watchCountry.value]}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
          <RHFTextField
            required
            name="address.postalCode"
            label={t('Zip Code')}
            inputProps={{ maxLength: 12 }}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box width="100%">
            <RHFAutocomplete
              required
              name="address.country"
              label="Country"
              fullWidth
              freeSolo={false}
              onChange={(e, newValue) =>
                newValue && Array.isArray(newValue)
                  ? handleCountryChange(newValue[0])
                  : newValue && handleCountryChange(newValue)
              }
              options={CountryList}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </Box>
          <div style={{ width: '100%' }} />
        </Stack>
      </Stack>

      <StepActions
        isSubmitting={isSubmitting}
        errors={Object.values(errors).map((e) => e.message || '')}
        handleBack={onBack}
      />
    </FormProvider>
  );
}
