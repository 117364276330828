import { useState } from 'react';
import {
  Card,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Outlet } from 'react-router';

import PATHS from '@routes/paths';
import useNavigate from '@hooks/useNavigate';
import useTable from '@hooks/useTable';
import * as analytics from '@fire/analytics';

import Dialog from '@components/Dialog';
import Iconify from '@components/Iconify';
import Scrollbar from '@components/Scrollbar';
import { TableHeadCustom, TableNoData } from '@components/table';
import { VIEW_LIST_HEADERS, ViewListHeader } from './config';
import useData from './useData';
import Row from './Row';

// ----------------------------------------------------------------------
type Props = { context: 'donors' | 'donations' };
// ----------------------------------------------------------------------
export default function ManageViews({ context = 'donors' }: Props) {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  // --------------- effects ---------------
  const { columns, order, orderBy, onSort } = useTable<ViewListHeader>(
    {
      defaultRowsPerPage: 25,
      defaultColumns: VIEW_LIST_HEADERS,
      defaultOrderBy: 'name',
    }
    // 'cache-view-donors'
  );

  const data = useData({
    columns,
    order,
    orderBy,
    search,

    viewType: context === 'donations' ? 'donation' : 'donor',
  });

  // --------------- routing ---------------
  const handleClose = () => {
    if (context === 'donations') {
      navigate(PATHS.org.donations.root);
    } else {
      navigate(PATHS.org.donors.root);
    }
  };
  const handleOpen = (viewId: string) => {
    analytics.view.viewOpen();
    navigate({
      pathname: context === 'donations' ? PATHS.org.donations.root : PATHS.org.donors.root,
      search: `?viewId=${viewId}`,
    });
  };
  const handleEdit = (viewId: string) => {
    navigate(PATHS.org.donors.manageView, { viewId });
  };

  // --------------- actions ---------------
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  // --------------- ui ---------------
  return (
    <Dialog
      title="Manage Views"
      onClose={handleClose}
      maxWidth="md"
      actions={
        <LoadingButton size="small" variant="contained" onClick={handleClose}>
          Close
        </LoadingButton>
      }
    >
      <Outlet />

      <Stack spacing={1}>
        <Stack direction="row" justifyContent="flex-start" spacing={2}>
          {/* <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="select-type">Type</InputLabel>
            <Select labelId="select-type" id="type-select" value={type} onChange={handleChange}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="donor">Donor</MenuItem>
              <MenuItem value="donation">Donation</MenuItem>
            </Select>
          </FormControl> */}

          <TextField
            value={search}
            onChange={handleSearch}
            placeholder="Search"
            inputProps={{ sx: { p: 1 } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack>
          <Card>
            <TableContainer sx={{ minWidth: '100%', position: 'relative', overflow: 'unset' }}>
              <Scrollbar sx={{ maxHeight: 320, minHeight: 320 }}>
                <Table stickyHeader size="small">
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    columns={columns}
                    rowCount={data.length}
                    onSort={onSort as (id: string) => void}
                  />

                  <TableBody>
                    {data.map((item) => (
                      <Row
                        key={item.id}
                        columns={columns}
                        item={item}
                        onClick={handleOpen}
                        onEdit={handleEdit}
                      />
                    ))}

                    <TableNoData isNotFound={!data.length} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Card>
        </Stack>
      </Stack>
    </Dialog>
  );
}
