import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { Stack, Card, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import auth from '@fire/auth';
import useIsMountedRef from '@hooks/useIsMountedRef';
import { changePasswordSchemaResolver, ChangePasswordSchemaForm } from '@/schemas';
import { FormProvider, RHFTextField } from '@components/hook-form';

// ----------------------------------------------------------------------
export default function AccountChangePassword() {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues: ChangePasswordSchemaForm = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm<ChangePasswordSchemaForm>({
    resolver: changePasswordSchemaResolver,
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isSubmitting },
  } = methods;

  const onSubmit = async (data: ChangePasswordSchemaForm) => {
    try {
      await auth.changePassword(data);
      reset();
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={5}>
        <Typography variant="h5">Change password</Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} alignItems="flex-end">
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <RHFTextField name="currentPassword" type="password" label="Old Password" />
            <RHFTextField name="newPassword" type="password" label="New Password" />
            <RHFTextField name="confirmNewPassword" type="password" label="Confirm New Password" />

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty}
            >
              Change password
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
    </Card>
  );
}
