import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Container, Typography } from '@mui/material';

import PATHS from '@routes/paths';
import useResponsive from '@hooks/useResponsive';
import Page from '@components/Page';
import Logo from '@components/Logo';

import RegisterForm from './RegisterForm';
import { RootStyle, HeaderStyle, SectionStyle, ContentStyle } from './styles';

// ----------------------------------------------------------------------
export default function Register() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body1" sx={{ mt: { md: -2 } }}>
              Already have an account? {''}
              <Link variant="subtitle1" component={RouterLink} to={PATHS.login}>
                Login
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && <SectionStyle />}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Get Started for Free with DONATION Online
                </Typography>
                <Typography gutterBottom sx={{ color: 'text.secondary' }}>
                  This is only for our DONATION <strong>Online</strong> product. For Standard or
                  OnDemand versions, go{' '}
                  <Link
                    underline="always"
                    color="text.primary"
                    href="https://www.software4nonprofits.com/pricing-legacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>
                  . For ACCOUNTS, go{' '}
                  <Link
                    underline="always"
                    color="text.primary"
                    href="https://www.software4nonprofits.com/pricing/#accounts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link>
                  .
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  No credit card needed. Your first 25 donors are free.
                </Typography>
              </Box>
            </Box>
            <RegisterForm />
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              By registering, I agree to Software4Nonprofits'
              <Link
                underline="always"
                color="text.primary"
                href="https://www.software4nonprofits.com/terms-of-use/"
                target="_blank"
                rel="noopener"
              >
                {' '}
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link
                underline="always"
                color="text.primary"
                href="https://www.software4nonprofits.com/privacy-policy/"
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </Link>
              .
            </Typography>
            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Already have an account?{' '}
                <Link variant="subtitle2" to={PATHS.login} component={RouterLink}>
                  Login
                </Link>
              </Typography>
            )}{' '}
            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
              Questions? Contact{' '}
              <Link
                underline="always"
                color="text.primary"
                href="mailto:info@software4nonprofits.com"
                target="_blank"
                rel="noreferrer"
              >
                info@software4nonprofits.com
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
