import { useState } from 'react';
import { Stack } from '@mui/material';

import useLocales from '@hooks/useLocales';
import Iconify from '@components/Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from '../NavbarSection/styles';
import FeedbackDialog from './FeedbackDialog';

// ----------------------------------------------------------------------
type Props = {
  readonly isCollapse: boolean;
};

// ----------------------------------------------------------------------
export default function Feedback({ isCollapse }: Props) {
  const { t } = useLocales();
  const [feedbackOpen, feedbackSetOpen] = useState(false);

  return (
    <Stack
      sx={{
        px: 2,
        py: { xl: 2 },
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <FeedbackDialog open={feedbackOpen} setOpen={feedbackSetOpen} />
      <ListItemStyle onClick={() => feedbackSetOpen(true)}>
        <ListItemIconStyle active={false}>
          <Iconify icon="icons8:support" sx={{ width: 1, height: 1 }} />
        </ListItemIconStyle>

        <ListItemTextStyle
          isCollapse={isCollapse}
          primary={t('Support')}
          primaryTypographyProps={{
            noWrap: true,
            variant: 'body2',
            textTransform: 'none',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
          }}
        />
      </ListItemStyle>

      {/* <Link
        href="https://www.software4nonprofits.com"
        target="_blank"
        rel="noopener"
        underline="none"
      >
        <ListItemStyle>
          <ListItemIconStyle active={false}>
            <Iconify icon="ph:books-light" sx={{ width: 1, height: 1 }} />
          </ListItemIconStyle>

          <ListItemTextStyle
            isCollapse={isCollapse}
            primary={t('Help')}
            primaryTypographyProps={{
              noWrap: true,
              variant: 'body2',
            }}
            secondaryTypographyProps={{
              noWrap: true,
              variant: 'caption',
            }}
          />
        </ListItemStyle>
      </Link> */}
    </Stack>
  );
}
